import React, { useEffect, useState, ChangeEvent } from "react";
import { Typography, Stack } from "@mui/material";
import { IAssementHistory } from "src/ts/interfaces/companyAssets";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useAsyncQuery, useLog } from "src/hooks";
import {
  GridActionButton,
  GridUploadButton,
} from "src/components/gridControls";
import FileUtils from "src/utils/file";
import assetAssessmentService from "src/services/assetAssessmentService";
import assetDocumentService from "src/services/assetDocumentService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";

interface Props {
  assetId: number;
  refresh: boolean;
  setEditMode: (val: boolean) => void;
  setRowEdit: (val: IAssementHistory) => void;
  setDeleteModal: (val: boolean) => void;
}

const AssesmentReport = ({
  assetId,
  refresh,
  setEditMode,
  setRowEdit,
  setDeleteModal,
}: Props) => {
  const { execute, data, isLoading, setData } = useAsyncQuery<
    IAssementHistory[]
  >(assetAssessmentService.getAssesmentHistory);
  const [refreshPage, setRefreshPage] = useState(false);
  const [deleteSubItemModal, setDeleteSubItemModal] = useState(false);
  const [idAsset, setIdAsset] = useState(0);

  const { log } = useLog();

  useEffect(() => {
    const getData = async () => {
      await execute(assetId);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, refreshPage]);

  useEffect(() => {
    const subItems = () => {
      if (data !== null) {
        const updatedData = data.map((item: IAssementHistory) => {
          if (
            item.assetDocument !== undefined &&
            item.assetDocument.length > 0
          ) {
            return {
              ...item,
              subItems: [
                ...(item.subItems || []),
                {
                  type: item?.assetDocument[0]?.name,
                  createdBy: {
                    name:
                      item?.assetDocument[0]?.createdBy?.name !== undefined
                        ? item?.assetDocument[0]?.createdBy?.name
                        : "",
                    lastName:
                      item?.assetDocument[0]?.createdBy?.lastName !== undefined
                        ? item?.assetDocument[0]?.createdBy?.lastName
                        : "",
                  },
                  createDate: item?.assetDocument[0]?.createdDate ?? "",
                  id: item?.assetDocument[0]?.id ?? 0,
                  name: item?.assetDocument[0]?.name ?? "",
                },
              ],
            };
          } else {
            return item;
          }
        });
        setData(updatedData);
      }
    };

    subItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const getColumns = (
    downloadDocument: (row: IAssementHistory) => void,
    saveFile: (e: ChangeEvent<HTMLInputElement>, row: IAssementHistory) => void,
    setRowEdit: (val: IAssementHistory) => void,
    setEditMode: (val: boolean) => void,
    setDeleteModal: (val: boolean) => void,
    setDeleteSubItemModal: (val: boolean) => void,
    setIdAsset: (val: number) => void
  ): ColumnType[] => {
    return [
      {
        id: "addedBy",
        label: "Added By",
        type: "custom",
        sort: true,

        callback: (row: IAssementHistory) => {
          return (
            <>
              <Typography>{`${row.createdBy?.name} ${row.createdBy?.lastName}`}</Typography>
            </>
          );
        },
      },
      {
        id: "createDate",
        label: "Added On",
        type: "utcDate",
        sort: false,
        format: "MM/DD/YYYY h:mma",
      },
      {
        id: "type",
        label: "Type",
        type: "custom",
        sort: false,
        callback: (row: IAssementHistory) => {
          const name = row?.type;
          return (
            <>
              <Typography>
                {row.type === 1 ? "Operational" : ""}
                {row.type === 2 ? "Out of Service" : ""}
                {row.type === 3 ? "Reactivated" : ""}
                {typeof row.type === "string" ? name : ""}
              </Typography>
            </>
          );
        },
      },
      {
        id: "description",
        label: "Description",
        type: "custom",
        sort: false,
        callback: (row: IAssementHistory) => {
          return (
            <>
              {row.description !== undefined ? row.description : ""}
              {row.description === undefined ? (
                <Stack direction="row">
                  <GridActionButton
                    type="picture"
                    onClick={() => {
                      downloadDocument(row);
                    }}
                    tooltip="Picture "
                  />
                  <GridActionButton
                    type="delete"
                    tooltip="Delete"
                    onClick={() => {
                      setIdAsset(row?.id ?? 0);
                      setDeleteSubItemModal(true);
                    }}
                    disabled={false}
                  />
                </Stack>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        id: "action",
        label: "",
        type: "custom",
        sort: false,
        callback: (row: IAssementHistory, index: number) => (
          <Stack direction="row">
            {!row.isBlocked && row.description !== undefined && (
              <>
                <GridUploadButton
                  accept="image/*"
                  onChange={(e: any) => {
                    saveFile(e, row);
                  }}
                  index={index}
                />
                <GridActionButton
                  type="edit"
                  tooltip="edit"
                  onClick={() => {
                    setRowEdit(row);
                    setEditMode(true);
                  }}
                />

                <GridActionButton
                  type="delete"
                  tooltip="Delete"
                  onClick={() => {
                    setDeleteModal(true);
                    setRowEdit(row);
                  }}
                />
              </>
            )}
          </Stack>
        ),
      },
    ];
  };

  const downloadDocument = async (row: IAssementHistory) => {
    if (row) {
      try {
        const response = await assetDocumentService.downloadDocumentAssets(
          Number(row.id)
        );

        let fileName = "";
        fileName = row.type?.toString() ?? "";
        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
    }
  };

  const saveFile = async (
    e: ChangeEvent<HTMLInputElement>,
    row: IAssementHistory
  ) => {
    try {
      if (!e.target.files) return;
      const file = e.target.files[0];
      await assetDocumentService.uploadUserFile({
        documentType: "photo",
        content: file,
        assetId: row?.companyAssetsId?.toString() ?? "0",
        companyId: row?.companyAssets?.companyId ?? "0",
        assetsAssessmentId: row?.id?.toString() ?? "",
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const deleteAssetDocument = async (id: number) => {
    try {
      await assetDocumentService.deleteDocumentAssets(id ?? 0);
      log.success("The item was deleted");
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const columns = getColumns(
    downloadDocument,
    saveFile,
    setRowEdit,
    setEditMode,
    setDeleteModal,
    setDeleteSubItemModal,
    setIdAsset
  );

  return (
    <>
      <LocalEnhancedTable<IAssementHistory>
        refreshGrid={true}
        columns={columns}
        data={data ?? []}
        showSkeleton={isLoading}
        defaultRowPerPage={5}
        orderColumn="desc"
        subItems="subItems"
      />
      <MatchWordConfirmModal
        onConfirm={() => {
          deleteAssetDocument(idAsset);
        }}
        text={`Are you certain you want to delete this item?`}
        matchWord={"delete"}
        isDialogOpen={deleteSubItemModal}
        setIsDialogOpen={setDeleteSubItemModal}
      />
    </>
  );
};

export default AssesmentReport;
