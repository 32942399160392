import React, { useState, useEffect } from "react";
import {
  ICountryResult,
  IKeyValue,
  IUserCountryAccess,
} from "src/ts/interfaces";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import userCountryAccess from "src/services/userCountryAccessService";
import { IconButton, Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormSelectCountry } from "src/components/formControls";
import { Add as AddIcon } from "@mui/icons-material";
import { useLog } from "src/hooks";
import userCountryAccessService from "src/services/userCountryAccessService";

interface IUserCountryAccessProps {
  userId?: number | undefined;
  userCountryAccessData?: IUserCountryAccess[];
  setUserCountryAccessData?: (userCountryAccess: IUserCountryAccess[]) => void;
  setDataCountryAccess: (userCountryAccess: IUserCountryAccess[]) => void;
  disabled?: boolean;
}

const getColumns = (
  handleDelete: (val: number) => void,
  disabled?: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IUserCountryAccess) => {
      return row.selectAll ? "All" : row.country.name;
    },
  },
  {
    id: "acton",
    label: "Actions",
    type: "custom",
    sort: true,
    callback: (row: IUserCountryAccess) => {
      return (
        !disabled && (
          <IconButton aria-label="delete" size="large">
            <DeleteIcon
              onClick={(e) => {
                handleDelete(row.id);
              }}
            />
          </IconButton>
        )
      );
    },
  },
];

const UserCountryAccessData = ({
  userId,
  setUserCountryAccessData,
  userCountryAccessData,
  setDataCountryAccess,
  disabled,
}: IUserCountryAccessProps) => {
  const [country, setCountry] = useState<any>(0);
  const [countryName, setCountryName] = useState<any>("(All)");
  const [, setIsCountryLoading] = useState(false);
  const [dataSet, setDataSet] = useState<IUserCountryAccess[]>([]);

  const [countries, setCountries] = useState<
    IKeyValue<number | null | undefined, string | null | undefined>[]
  >([]);
  const { log } = useLog();

  const newItem: IKeyValue<number, string> = { key: 0, value: "(All)" };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const request = await userCountryAccessService.getKeyValues();

        setCountries(request);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (userId === 0) getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        if (userId !== undefined && userId !== 0) {
          const request = await userCountryAccess.getByUser(userId);
          setDataSet(request.data);
          setDataCountryAccess(request.data);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDataSet]);

  const handleDelete = async (id: number) => {
    try {
      if (userId === 0) {
        setDataSet(
          dataSet.filter((item: any) => {
            return item.country.id !== id;
          })
        );
        setDataCountryAccess(
          dataSet.filter((item: any) => {
            return item.country.id !== id;
          })
        );
      } else {
        if (dataSet.length === 1) {
          log.warning("You can't delete the only country");
        } else {
          await userCountryAccess.deleteItem(id);
          setDataSet(
            dataSet.filter(function (access) {
              return access.id !== id;
            })
          );
        }

        setDataCountryAccess(
          dataSet.filter(function (access) {
            return access.id !== id;
          })
        );
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleInputChange = (e: any) => {
    if (userId === 0) {
      const dataCountry = countries.filter((item: any) => {
        return e.target.value === item.key;
      });

      setCountryName(e.target.value === 0 ? "(All)" : dataCountry[0].value);
      setCountry(e.target.value === 0 ? 0 : dataCountry[0].key);
    } else {
      setCountryName(e.target.name);
      setCountry(e.target.value);
    }
  };

  const handleAdd = async () => {
    if (userId !== undefined && userId !== 0) {
      var duplicateCountry = false;
      if (dataSet.length > 0) {
        for (var i = 0; i < dataSet.length; i++) {
          if (
            dataSet[i].country != null &&
            dataSet[i].country.id?.toString() === country.toString()
          ) {
            duplicateCountry = true;
            log.warning("This Country is already added");
          }
        }
      }

      if (!duplicateCountry) {
        if (country === 0) {
          try {
            const requestSelect = await userCountryAccess.selectAllCountries(
              userId
            );
            for (let i = 0; i < dataSet.length; i++) {
              if (dataSet[i].selectAll || country === 0) {
                dataSet.splice(i, 1);
                i--;
              }
            }

            setDataSet([...dataSet, requestSelect.data]);
            setDataCountryAccess([...dataSet, requestSelect.data]);
          } catch (error: any) {
            log.error(
              error?.message?.exceptionMessage ?? "Something went wrong"
            );
          }
        } else {
          try {
            const request = await userCountryAccess.addItem(userId, country);
            for (let i = 0; i < dataSet.length; i++) {
              if (dataSet[i].selectAll || country === 0) {
                dataSet.splice(i, 1);
                i--;
              }
            }

            setDataSet([...dataSet, request.data]);
            setDataCountryAccess([...dataSet, request.data]);
          } catch (error: any) {
            log.error(
              error?.message?.exceptionMessage ?? "Something went wrong"
            );
          }
        }
      }
    } else {
      var duplicateCountryUser = false;
      if (dataSet.length > 0) {
        for (var k = 0; k < dataSet.length; k++) {
          if (
            dataSet[k].country != null &&
            dataSet[k].country.id?.toString() === country.toString()
          ) {
            duplicateCountryUser = true;
            log.warning("This Country is already add");
          }
        }
      }

      if (!duplicateCountryUser) {
        for (let i = 0; i < dataSet.length; i++) {
          if (countryName === "All" || country === 0) {
            dataSet.splice(i, 1);
            i--;
          }
          if (dataSet.length > 0) {
            if (dataSet[i].country.id === 0) {
              dataSet.splice(i, 1);
            }
          }
        }
        var currentCountry: ICountryResult = { id: country, name: countryName };
        var customCountry: IUserCountryAccess = {
          id: country,
          country: currentCountry,
          selectAll: country === 0 ? true : false,
        };

        setDataSet([...dataSet, customCountry]);
        setUserCountryAccessData?.([...dataSet, customCountry]);
      }
    }
  };

  let columns = getColumns(handleDelete, disabled);

  return (
    <React.Fragment>
      <Grid item xs={12} sx={{ paddingBottom: 5 }}>
        <Typography variant="h5" gutterBottom display="inline">
          Country Access
        </Typography>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <FormSelectCountry
            value={country}
            onChange={handleInputChange}
            isLoading={(value: boolean) => setIsCountryLoading(value)}
            extraItem={newItem}
            disable={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          {!disabled && (
            <IconButton aria-label="add" size="medium">
              <AddIcon
                onClick={(e) => {
                  handleAdd();
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <LocalEnhancedTable<IUserCountryAccess>
        columns={columns}
        data={dataSet}
        defaultRowPerPage={5}
      />
    </React.Fragment>
  );
};

export default UserCountryAccessData;
