import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { COUNTRIES } from "src/constants";
import {
  CardContent,
  Grid,
  Link,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import {
  useForm,
  Form,
  useCountry,
  usePermissions,
  useAsyncMutation,
} from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
  FormSelectState,
  FormSelect,
  FormMaskText,
} from "src/components/formControls";
import { ICreditCard } from "src/ts/interfaces";
import useLoading from "src/hooks/useLoading";
import { Permission as PermissionTypes } from "src/ts/enums";
import HeaderPage from "src/components/page/HeaderPage";
import { ToolTip } from "src/components/others/ToolTip";
import { WalletService } from "src/services";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import useZipCode from "src/hooks/useZipCode";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: ICreditCard = {
  id: 0,
  companyId: 0,
  recurringId: null,
  cardNumber: "",
  name: "",
  lastName: "",
  expirationMonth: "",
  expirationYear: "",
  lastAmount: 1,
  email: "",
  phone: "",
  avsAddress: "",
  avsAddress2: "",
  city: "",
  avsZip: null,
  isActive: true,
  isDeleted: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  alias: "",
  serial: "",
  stateId: null,
  stateName: "",
  countryId: COUNTRIES.USA,
  cardType: "",
  default: false,
  billingCycle: "",
  nextPaymentDate: new Date(),
  cardCCV: "",
  token: "",
  originalAuthData: "",
  ps2000Data: "",
  demo: false,
};

const CardForm = () => {
  let navigate = useNavigate();
  let params = useParams<{
    companyId: string;
    cardId: string;
  }>();

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );

  const cardId = parseInt(params?.cardId === undefined ? "0" : params?.cardId);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.alias === "") {
      temp.alias = "This field is required";
    } else {
      temp.alias = "";
    }

    if (fieldValues.name === "") {
      temp.name = "This field is required";
    } else {
      temp.name = "";
    }

    if (fieldValues.lastName === "") {
      temp.lastName = "This field is required";
    } else {
      temp.lastName = "";
    }

    const regexCard =
      /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{13}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|(?:2131|1800|35\d{3})\d{11}|\d{2}[\s.*]{10}\d{4})$/;
    if (fieldValues.cardNumber === "") {
      temp.cardNumber = "The card is required";
    } else if (regexCard.test(fieldValues.cardNumber) === false) {
      temp.cardNumber = "The card is not valid";
    } else {
      temp.cardNumber = "";
    }

    if (fieldValues.expirationMonth === "") {
      temp.expirationMonth = "This field is required";
    } else {
      temp.expirationMonth = "";
    }

    if (fieldValues.expirationYear === "") {
      temp.expirationYear = "This field is required";
    } else {
      temp.expirationYear = "";
    }

    if (fieldValues.cardCCV === "" && cardId === 0) {
      temp.cardCCV = "This field is required";
    } else {
      temp.cardCCV = "";
    }

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
    if (fieldValues.email === "") {
      temp.email = "The email is required";
    } else if (regex.test(fieldValues.email) === false) {
      temp.email = "The email is not valid";
    } else {
      temp.email = "";
    }

    if (fieldValues.avsAddress === "") {
      temp.avsAddress = "This field is required";
    } else {
      temp.avsAddress = "";
    }

    if (fieldValues.avsZip === "") {
      temp.avsZip = "This field is required";
    } else {
      temp.avsZip = "";
    }

    if (fieldValues.city === "") {
      temp.city = "This field is required";
    } else {
      temp.city = "";
    }

    if (fieldValues.phone === "") {
      temp.phone = "This field is required";
    } else {
      temp.phone = "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Card);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { isLoading, startRequest, endRequest } = useLoading();
  const { countriesKeyValue, selectedCountry, setSelectedCountryById } =
    useCountry();
  const {
    execute: executeUpdateDaate,
    isSubmitting: isSubmittingUpdate,
    data: dataUpdated,
  } = useAsyncMutation(WalletService.updateCard, {
    successfulMessage: "Card Updated",
  });
  const {
    execute: executeCreateDaate,
    isSubmitting: isSubmittingCreate,
    data: dataCreated,
  } = useAsyncMutation(WalletService.createCard, {
    successfulMessage: "Card added successfully",
  });
  const hostname = window.location.hostname;
  const { zipValue } = useZipCode(values.avsZip);

  useEffect(() => {
    const getData = async () => {
      startRequest("card");
      const result = await WalletService.getCardById(Number(cardId));
      setValues(result.data);
      endRequest("card");
    };
    if (cardId !== 0) getData();
    else {
      setValues({ ...values, countryId: COUNTRIES.USA });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId]);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    setSelectedCountryById(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  useEffect(() => {
    if (dataUpdated) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdated]);

  useEffect(() => {
    if (dataCreated) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreated]);

  const handleCancel = () => {
    navigate(`/app/Wallet/${companyId}`);
  };

  const handleSaveCard = async () => {
    if (!validate()) return;
    if (cardId === 0) {
      setShowConfirmPopup(true);
    } else {
      await executeUpdateDaate(values, cardId);
    }
  };

  const handleConfirmSave = async () => {
    await executeCreateDaate(values);
  };

  const isDisable = () => {
    return isSubmittingUpdate || isSubmittingCreate || readOnly;
  };

  return (
    <>
      <HeaderPage
        title={"Card"}
        parentText={"Wallet"}
        parentLink={`/app/Wallet/${companyId}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Typography variant="h6">Card Information </Typography>
            <Divider my={3}></Divider>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <FormText
                  name="serial"
                  label="Serial number"
                  value={values.serial}
                  onChange={handleInputChange}
                  error={errors.serial}
                  disabled={true}
                  showSkeleton={isLoading}
                  placeholder="Wallet's serial number"
                />
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row">
                  <ToolTip title="Click to Verify Certificate">
                    <Link
                      target="_blank"
                      href={`https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC5&v_search=https://${hostname}/&x=6&y=5`}
                    >
                      <Box
                        component="img"
                        alt="InstantSSL"
                        src={"/static/img/instantSSL.png"}
                        sx={{}}
                      />
                    </Link>
                  </ToolTip>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="alias"
                  label="Card Name (Alias)"
                  value={values.alias}
                  onChange={handleInputChange}
                  error={errors.alias}
                  disabled={isDisable()}
                  showSkeleton={isLoading}
                  placeholder="Example: Significant other's card, Wife's card, Husband's card, etc."
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="name"
                  label="First Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  disabled={isDisable()}
                  showSkeleton={isLoading}
                  placeholder="Type the first name as it appears in the card"
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                  disabled={isDisable()}
                  showSkeleton={isLoading}
                  placeholder="Type the last name as it appears in the card"
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="cardNumber"
                  label="Card Number"
                  value={values.cardNumber}
                  onChange={handleInputChange}
                  error={errors.cardNumber}
                  disabled={isDisable() || cardId > 0}
                  showSkeleton={isLoading}
                  placeholder="Enter the card number"
                />
              </Grid>
              <Grid item xs={2}>
                <FormText
                  name="expirationMonth"
                  label="Expiration Month"
                  value={values.expirationMonth}
                  onChange={handleInputChange}
                  error={errors.expirationMonth}
                  disabled={isDisable() || cardId > 0}
                  showSkeleton={isLoading}
                  placeholder="MM"
                />
              </Grid>
              <Grid item xs={2}>
                <FormText
                  name="expirationYear"
                  label="Expiration Year"
                  value={values.expirationYear}
                  onChange={handleInputChange}
                  error={errors.expirationYear}
                  disabled={isDisable() || cardId > 0}
                  showSkeleton={isLoading}
                  placeholder="YY"
                />
              </Grid>
              {cardId === 0 && (
                <Grid item xs={2}>
                  <FormText
                    name="cardCCV"
                    label="CCV"
                    value={values.cardCCV}
                    onChange={handleInputChange}
                    error={errors.cardCCV}
                    disabled={isDisable()}
                    showSkeleton={isLoading}
                    placeholder="CCV"
                  />
                </Grid>
              )}
            </Grid>
            <Typography variant="h6" pt={6}>
              Billing Information{" "}
            </Typography>
            <Divider my={6}></Divider>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <FormText
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  disabled={isDisable()}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="avsAddress"
                  label="Address"
                  value={values.avsAddress}
                  onChange={handleInputChange}
                  error={errors.avsAddress}
                  disabled={isDisable()}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  name="countryId"
                  label="Country"
                  value={values.countryId}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  showSkeleton={isLoading}
                  disabled={isDisable()}
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="avsZip"
                  label="Zip Code"
                  value={values.avsZip}
                  onChange={handleInputChange}
                  error={errors.avsZip}
                  disabled={isDisable()}
                  maxLength={6}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  disabled={isDisable()}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                {values.countryId === COUNTRIES.USA ? (
                  <FormSelectState
                    stateId={values.stateId}
                    stateName={values.stateName}
                    countryId={values.countryId}
                    errorsStateId={errors.stateId}
                    errorsStateName={errors.stateName}
                    onChange={handleInputChange}
                    isLoading={(value: boolean) => setIsStateLoading(value)}
                    disable={isDisable()}
                  />
                ) : (
                  <FormText
                    name="stateName"
                    label="State"
                    value={values.stateName}
                    onChange={handleInputChange}
                    error={errors.stateName}
                    disabled={isDisable()}
                    showSkeleton={isLoading}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                  <FormText
                    name="countryCode"
                    label="Country Code"
                    value={selectedCountry?.countryCode?.toString() ?? "1"}
                    error={errors.countryCode}
                    disabled={true}
                    fullWidth={false}
                    showSkeleton={isLoading}
                  />
                  <FormMaskText
                    name="phone"
                    mask="(999) 999 - 9999"
                    label="Phone"
                    onChange={handleInputChange}
                    value={values.phone}
                    error={errors.phone}
                    showSkeleton={isLoading}
                    disabled={readOnly}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={isSubmittingCreate || isSubmittingUpdate}
                    disabled={isStateLoading}
                  />
                  {fullAccess && (
                    <FormSaveButton
                      onClick={handleSaveCard}
                      isSubmitting={isSubmittingCreate || isSubmittingUpdate}
                      disabled={isStateLoading}
                      showSkeleton={isLoading}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Information"}
        text={
          <>
            <Typography>
              We will withdraw $1.00 USD to validate your card.
            </Typography>
            <Typography>
              The funds will be returned to your account in the future days.
            </Typography>
            <Typography pt={5}>
              Are you certain you want to proceed to add the card?
            </Typography>
          </>
        }
        showPopup={showConfirmPopup}
        setShowPopup={setShowConfirmPopup}
        onSave={handleConfirmSave}
        isSubmitting={isSubmittingCreate || isSubmittingUpdate}
      />
    </>
  );
};

export default CardForm;
