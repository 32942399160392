import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import {
  IBuilding,
  IProjectContestOwner,
} from "src/ts/interfaces/projectOutToBid";

import moment from "moment";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

interface InformationProps {
  showDeclinedPopUp: boolean;
  setShowDeclinedPopUp: (val: boolean) => void;
  buildingInformation: IBuilding | undefined;
  projectSingle: ProjectDTO | undefined;
  projectContestSelected: IProjectContestOwner | undefined;
}

export default function DeclinedPopUp({
  showDeclinedPopUp,
  setShowDeclinedPopUp,
  buildingInformation,
  projectSingle,
  projectContestSelected,
}: InformationProps) {
  const handleClosePopUp = () => {
    setShowDeclinedPopUp(false);
  };

  return (
    <>
      <Popup
        title="Project Declined"
        openPopup={showDeclinedPopUp}
        setOpenPopup={setShowDeclinedPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Stack direction="row" spacing={2}>
            <Typography>
              <b>Name:</b>
            </Typography>
            <Typography>{buildingInformation?.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={3.2}>
            <Typography>
              <b>Code:</b>
            </Typography>
            <Typography>{projectSingle?.code}</Typography>
          </Stack>
          <Stack direction="row" spacing={6.6} mt={5}>
            <Typography>
              <b>Contractor:</b>
            </Typography>
            <Typography>{projectContestSelected?.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography>
              <b>Delinced Date:</b>
            </Typography>
            <Typography>
              {moment(projectContestSelected?.declinedDate).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </Typography>
          </Stack>
          <Stack mt={5} direction="column">
            <Typography>
              <b>Reason: </b>
            </Typography>
            <FormTextArea
              name="declineComments"
              value={projectContestSelected?.declineComments ?? ""}
              placeholder="Reason"
              onChange={() => {}}
              label=""
            />
          </Stack>
        </>

        <Stack>
          <FormButton
            text="Close"
            variant="outlined"
            onClick={() => {
              setShowDeclinedPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
