import React, { useEffect, useState } from "react";

import { Grid, Typography, Stack, Chip, Radio, Button } from "@mui/material";
import Popup from "src/components/Popup";
import { Form } from "src/hooks";
import FormNumeric from "src/components/formControls/FormNumeric";
import { IKeyValue, IUserPerson } from "src/ts/interfaces";
import moment from "moment";
import { CatalogService, localUnionsService } from "src/services";
import { FormSelect, FormText } from "src/components/formControls";
import { ILaborUnions } from "src/ts/interfaces/catalogs/laborUnions";
import { ILocalUnions } from "src/ts/interfaces/catalogs/localUnions";

interface ProfileFormProp {
  values: IUserPerson;
  setValues: (val: IUserPerson) => void;
  errors: any;
}

const UnionSection = (props: ProfileFormProp) => {
  const { values, setValues, errors } = props;

  const [informationShowPopUp, setInofrmationShowPopUp] = useState(false);
  const [dataLaborUnions, setDataLaborUnions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isLoadingLaborUnions, setIsLoadingLaborUnions] = useState(false);
  const [dataLocalUnions, setDataLocalUnions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isLoadingLocalUnions, setIsLoadingLaocalUnions] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingLaborUnions(true);
      var result = await CatalogService.getAllDropdown("LaborUnions");
      setDataLaborUnions(result);
      setIsLoadingLaborUnions(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingLaocalUnions(true);
      var result = await localUnionsService.getKeyValuesByLabor(
        values?.unionAffiliated?.laborUnionsId ?? 0
      );
      setDataLocalUnions(result);
      setIsLoadingLaocalUnions(false);
      if (result.length === 1) {
        setValues({
          ...values,
          unionAffiliated: {
            ...values.unionAffiliated,
            localUnionsId: result[0].key,
          },
        });
      }
    };

    if (
      values?.unionAffiliated?.laborUnionsId &&
      values?.unionAffiliated?.laborUnionsId > 0
    )
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.unionAffiliated?.laborUnionsId]);

  const isOtherLaborUnion = () => {
    if (!dataLaborUnions) return false;
    const found = dataLaborUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.unionAffiliated?.laborUnionsId === found?.key;
  };

  const isOtherLocalUnion = () => {
    if (!dataLocalUnions) return false;
    const found = dataLocalUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.unionAffiliated?.localUnionsId === found?.key;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      unionAffiliated: { ...values.unionAffiliated, [name]: value },
    });
    if (name === "laborUnionsId") {
      const found = dataLaborUnions.find(
        (element) => element.value === "SMWIA/SMART"
      );
      if (found?.key === value) setInofrmationShowPopUp(true);
      const labor: ILaborUnions = {
        id: found?.key ?? 0,
        name: found?.value ?? "",
        orderPosition: 0,
        createdById: 0,
        updatedId: 0,
        statusId: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
      };

      setValues({
        ...values,
        unionAffiliated: {
          ...values.unionAffiliated,
          laborUnions: labor,
          [name]: value,
        },
      });
    }

    if (name === "localUnionsId") {
      const found = dataLocalUnions.find(
        (element) => element.value === "SMWIA/SMART"
      );
      if (found?.key === value) setInofrmationShowPopUp(true);
      const local: ILocalUnions = {
        id: found?.key ?? 0,
        name: found?.value ?? "",
        orderPosition: 0,
        createdById: 0,
        updatedId: 0,
        statusId: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
        laborUnionsId: 0,
        laborUnionsName: "",
      };

      setValues({
        ...values,
        unionAffiliated: {
          ...values.unionAffiliated,
          localUnions: local,
          [name]: value,
        },
      });
    }
  };

  const handleClosePopUp = () => {
    setInofrmationShowPopUp(false);
  };

  const handleOnChangeText = (e: any) => {
    setValues({
      ...values,
      unionAffiliated: {
        ...values.unionAffiliated,
        [e.target.name]: e.target.value,
        laborUnionsId: values?.unionAffiliated?.laborUnionsId,
        localUnionsId: values?.unionAffiliated?.localUnionsId,
      },
    });
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    const answerValue = value === "true" ? false : true;

    if (name === "apprentice") {
      setValues({
        ...values,
        unionAffiliated: {
          ...values.unionAffiliated,
          apprentice: value === "true" ? false : true,
          laborUnionsId: values.unionAffiliated?.laborUnionsId,
          localUnionsId: values.unionAffiliated?.localUnionsId,
          localUnionsOther: "",
        },
      });
    } else {
      if (answerValue) {
        setValues({
          ...values,
          unionAffiliated: {
            ...values.unionAffiliated,
            [e.target.name]: value === "true" ? false : true,
            apprentice: false,
            smwiaMember: "",
            localUnionsOther: "",
            unionAffiliationNumbers: {
              localUnion: "",
            },
            laborUnionsId: 0,
            localUnionsId: 0,
          },
        });
      } else {
        setValues({
          ...values,
          unionAffiliated: null,
        });
      }

      if (values?.unionAffiliated?.unionAffiliatedAnswer === false) {
        setValues({
          ...values,
          unionAffiliated: {
            ...values.unionAffiliated,
            unionAffiliatedAnswer: true,
            apprentice: false,
            smwiaLocalUnion: "",
            smwiaMember: "",
            localUnionsOther: "",
            laborUnionsId: 0,
            localUnionsId: 0,
            unionAffiliationNumbers: {
              localUnion: "",
            },
          },
        });
      }
    }
  };
  const isUnderEvaluation =
    values.unionAffiliated != null &&
    values.unionAffiliated.code != null &&
    values.unionAffiliated.isAffiliated == null;

  const disableUnionNumber =
    values.unionAffiliated != null &&
    (values.unionAffiliated.isAffiliated === true || isUnderEvaluation);

  const commentsAcceptedRejected =
    values.unionAffiliated?.updatedAcceptRejectDate &&
    values.unionAffiliated.isAffiliated !== null &&
    (values.unionAffiliated.isAffiliated === true ||
      values.unionAffiliated.isAffiliated === false);

  const row = 12;
  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={row}>
            {values.unionAffiliated !== null && (
              <>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center">
                    {isUnderEvaluation ? (
                      <Chip
                        label="Under evaluation by Union administrator"
                        color="primary"
                        size="small"
                      />
                    ) : null}
                    {values?.unionAffiliated?.isAffiliated === true &&
                      values?.unionAffiliated?.isCanceled === false && (
                        <Chip label="VALIDATED" color="success" size="small" />
                      )}
                    {values?.unionAffiliated?.isAffiliated === false &&
                      values?.unionAffiliated?.isCanceled === false && (
                        <Chip
                          label="NOT APPROVED"
                          color="warning"
                          size="small"
                        />
                      )}
                    {values?.unionAffiliated?.isCanceled && (
                      <Chip label="CANCELED" color="error" size="small" />
                    )}
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={row}>
            <Grid container>
              <Grid item xs={5}>
                <Typography>Union Affiliated:</Typography>
                <Stack direction="row" alignContent="center">
                  <Typography mt={2}>Yes</Typography>
                  <Radio
                    checked={
                      values.unionAffiliated?.unionAffiliatedAnswer ?? false
                    }
                    onChange={handleOnChange}
                    value={
                      values.unionAffiliated?.unionAffiliatedAnswer ?? false
                    }
                    name="unionAffiliatedAnswer"
                    disabled={
                      values.unionAffiliated != null &&
                      (values.unionAffiliated.isAffiliated === true ||
                        isUnderEvaluation)
                    }
                  />
                  <Typography mt={2}>No</Typography>
                  <Radio
                    checked={
                      !values.unionAffiliated?.unionAffiliatedAnswer ?? true
                    }
                    onChange={handleOnChange}
                    value={
                      values.unionAffiliated?.unionAffiliatedAnswer ?? true
                    }
                    name="unionAffiliatedAnswer"
                    disabled={
                      values.unionAffiliated != null &&
                      (values.unionAffiliated.isAffiliated === true ||
                        isUnderEvaluation)
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                {values.unionAffiliated?.unionAffiliatedAnswer ? (
                  <>
                    <Stack>
                      <Typography>Apprentice:</Typography>
                      <Stack direction="row" alignContent="center">
                        <Typography mt={2}>Yes</Typography>
                        <Radio
                          checked={values.unionAffiliated.apprentice}
                          onChange={handleOnChange}
                          value={values.unionAffiliated.apprentice}
                          name="apprentice"
                          disabled={
                            values.unionAffiliated != null &&
                            (values.unionAffiliated.isAffiliated === true ||
                              isUnderEvaluation)
                          }
                        />
                        <Typography mt={2}>No</Typography>
                        <Radio
                          checked={!values.unionAffiliated.apprentice}
                          onChange={handleOnChange}
                          value={values.unionAffiliated.apprentice}
                          name="apprentice"
                          disabled={
                            values.unionAffiliated != null &&
                            (values.unionAffiliated.isAffiliated === true ||
                              isUnderEvaluation)
                          }
                        />
                      </Stack>
                    </Stack>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={row}>
                <Stack mt={5}></Stack>
              </Grid>
              {values.unionAffiliated !== null ? (
                <>
                  <Grid item xs={row}>
                    <Grid container>
                      <Grid item xs={5}>
                        {values?.unionAffiliated?.unionAffiliatedAnswer ? (
                          <Grid container spacing={5}>
                            <Grid item xs={12}>
                              <FormSelect
                                name="laborUnionsId"
                                label="Labor Unions"
                                value={values.unionAffiliated?.laborUnionsId?.toString()}
                                onChange={handleInputChange}
                                options={dataLaborUnions}
                                error={errors.laborUnionsId}
                                showSkeleton={isLoadingLaborUnions}
                                disable={disableUnionNumber}
                              />
                            </Grid>
                            {isOtherLaborUnion() && (
                              <Grid item xs={12}>
                                <FormText
                                  name={"laborUnionsOther"}
                                  label={"Labor Unions Other"}
                                  value={
                                    values.unionAffiliated?.laborUnionsOther
                                  }
                                  onChange={handleInputChange}
                                  disabled={disableUnionNumber}
                                ></FormText>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <FormSelect
                                name="localUnionsId"
                                label="Local Unions"
                                value={values.unionAffiliated?.localUnionsId?.toString()}
                                onChange={handleInputChange}
                                options={dataLocalUnions}
                                error={errors.localUnionsId}
                                showSkeleton={isLoadingLocalUnions}
                                disable={disableUnionNumber}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {isOtherLocalUnion() && (
                                <FormText
                                  name={"localUnionsOther"}
                                  label={"Local Unions Other"}
                                  value={
                                    values.unionAffiliated?.localUnionsOther
                                  }
                                  onChange={handleInputChange}
                                  disabled={disableUnionNumber}
                                  error={errors.localUnionsOther}
                                ></FormText>
                              )}
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={4}>
                        {values?.unionAffiliated?.unionAffiliatedAnswer ? (
                          <FormNumeric
                            name="smwiaMember"
                            value={values?.unionAffiliated?.smwiaMember ?? ""}
                            onChange={handleOnChangeText}
                            label="Member #:"
                            disabled={disableUnionNumber}
                            error={errors.smwiaMember}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={row}></Grid>
              {commentsAcceptedRejected ? (
                <>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <Typography mt={2}>
                        <b>
                          {values?.unionAffiliated?.isAffiliated
                            ? "Approved"
                            : "Rejected"}{" "}
                          Date:
                        </b>
                      </Typography>
                      <Typography mt={2} ml={2}>
                        {moment(
                          values?.unionAffiliated?.updatedAcceptRejectDate
                        ).format("MM/DD/yyyy h:mm a")}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <Typography mt={2}>
                        <b>
                          {values?.unionAffiliated?.isAffiliated
                            ? "Approved"
                            : "Rejected"}{" "}
                          By:
                        </b>
                      </Typography>
                      <Typography mt={2} ml={2}>
                        {values?.unionAffiliated?.updatedAcceptRejectBy?.name +
                          " " +
                          values?.unionAffiliated?.updatedAcceptRejectBy
                            ?.lastName}
                      </Typography>
                    </Stack>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                {commentsAcceptedRejected ? (
                  <Stack direction="row">
                    <Typography mt={2}>
                      <b> Comments:</b>{" "}
                    </Typography>
                    <Typography ml={2} mt={2}>
                      {values?.unionAffiliated?.affiliatedComments}
                    </Typography>
                  </Stack>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <Popup
        title="Information"
        openPopup={informationShowPopUp}
        setOpenPopup={setInofrmationShowPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Typography>
            <b>
              The acronym SMWIA specifically represents the Sheet Metal Workers
              International Association, which pertains to union affiliation.
            </b>
          </Typography>
          <br />
          <Typography>
            <b>
              Additionally, the acronym SMART represents Sheet Metal, Air, Rail,
              and Transportation workers.
            </b>
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={handleClosePopUp}
          >
            Close
          </Button>
        </Stack>
      </Popup>
    </>
  );
};

export default UnionSection;
