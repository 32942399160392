import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormLabel,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IProjectReportInfectiousComplianceControl,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import { CatalogService } from "src/services";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import { IGeneralMatrix } from "src/ts/interfaces/study/generalMatrix";
import generalMatrixService from "src/services/study/generalMatrixService";
import GeneralMatrixGrid from "./components/GeneralMatrixGrid";
import getProjectService from "src/services/project/getProjectService";
import SteadyStateDialog from "../components/SteadyState";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { HvacOutlined, ViewModuleOutlined } from "@mui/icons-material";
import { TYPE_DEVICE } from "src/constants";
import getProjectSingleService from "src/services/getProjectSingleService";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { FooterButtons } from "../components/FooterButtons";

const initialValues: IGeneralMatrix = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  isRectangleType: false,
  isRoundType: false,
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  comments: "",
  nocVisual: false,
  nocActual: false,
  numberOfDucts: 0,
  systemTotal: false,
  conditionId: 0,
  temperatureCompensated: false,
  barometricalyCompensated: false,
  centerlineStaticPressureId: 0,
  x: 0,
  y: 0,
  relativeHumidityBool: false,
  wetBulbFBool: false,
  dewPointBool: false,
  isActualLocalBarometricPressure: false,
  isComplete: false,
  generalReportTypeId: 0,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  isProjectDisabled: false,
  useSweepMethod: false,
  totalHeat: false,
  typeDevice: "0",
  nocRaNumber: 0,
  nocOaNumber: 0,
  nocMaNumber: 0,
  nocExhaustNumber: 0,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const typeOfTerminal = [
  {
    key: "0",
    labelCustom: (
      <>
        <Stack direction={"row"}>
          <ViewModuleOutlined />
          <Typography mt={0.2}>
            <b>Rectangular</b>
          </Typography>
        </Stack>
      </>
    ),
  },
  {
    key: "1",
    labelCustom: (
      <>
        <Stack direction={"row"} spacing={1}>
          <HvacOutlined />
          <Typography mt={0.2}>
            <b>Round</b>
          </Typography>
        </Stack>
      </>
    ),
  },
];

interface IMessagePsychrometric {
  text: string;
  color: "info" | "warning" | "success" | "error";
}

const GeneralMatrix = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const [mesurement, setMesurement] = useState("");
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string; code: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [loadingCorrect, setLoadingCorrect] = useState(false);
  const [titleReport, setTitleReport] = useState("");
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [selectRadioButton] = useState(initialValues.typeDevice);
  const [stepContinuos, setStepContinuos] = useState("step1");
  const [showSteady, setShowSteady] = useState(false);
  const [generalMatrix, setGeneralMatrix] = useState<IGeneralMatrix | null>();
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [saveItems, setSaveItems] = useState<boolean>(false);
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataX, setDataX] = useState(0);
  const [dataY, setDataY] = useState(0);
  const [messagePsychrometric, setMessagePsychrometric] =
    useState<IMessagePsychrometric>({ text: "", color: "info" });

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(generalMatrixService.update, {
      hideErrorMessage: false,
      onSuccess: async (result) => {
        const valuePrevious = values;
        setValues({
          ...result,
          nocOaNumber:
            valuePrevious?.nocOaNumber !== null
              ? valuePrevious?.nocOaNumber
              : 0,
          nocMaNumber:
            valuePrevious?.nocMaNumber !== null
              ? valuePrevious?.nocMaNumber
              : 0,
          nocRaNumber:
            valuePrevious?.nocRaNumber !== null
              ? valuePrevious?.nocRaNumber
              : 0,
          nocExhaustNumber:
            valuePrevious?.nocExhaustNumber !== null
              ? valuePrevious?.nocExhaustNumber
              : 0,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh, isLoading: isLoadingUpdate } = useAsyncQuery(
    generalMatrixService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
          typeDevice: values?.isRectangleType ? "0" : "1",
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    }
  );

  const { execute: executeRefreshPsyco, isLoading: isLoadingRefresh } =
    useAsyncQuery(generalMatrixService.update, {
      onSuccess: async (result) => {
        setValues({
          ...result,
        });
        await validateTotalCFM(result?.totalCFM ?? 0);
        await calcPsycometrics(result?.totalCFM!, result);
      },
    });

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (params.step?.toLowerCase() === "step2") {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .isRequired("Airflow Temperature For Energy Calculation Is Required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IGeneralMatrix>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IGeneralMatrix>(
    generalMatrixService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          let result = await formulaSystem.setSystem(
            dataResult?.projectId ?? values.projectId
          );
          setFormulas(result);
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult?.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = result.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }
          if (!dataResult.isRectangleType && !dataResult.isRoundType) {
            dataResult.isRectangleType = true;
            setValues({ ...values });
          }
          if (
            !dataResult.relativeHumidityBool &&
            !dataResult.wetBulbFBool &&
            !dataResult.dewPointBool
          ) {
            dataResult.relativeHumidityBool = true;
          }
          setDataX(dataResult.readingsX);
          setDataY(dataResult.readingsY);
          setValues({
            ...dataResult,
            typeDevice: dataResult.isRectangleType ? "0" : "1",
            nocOaNumber:
              dataResult?.nocOaNumber !== 0 ? dataResult?.nocOaNumber : 0,
            nocMaNumber:
              dataResult?.nocMaNumber !== 0 ? dataResult?.nocMaNumber : 0,
            nocRaNumber:
              dataResult?.nocRaNumber !== 0 ? dataResult?.nocRaNumber : 0,
            nocExhaustNumber:
              dataResult?.nocExhaustNumber !== 0
                ? dataResult?.nocExhaustNumber
                : 0,
          });
          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(dataResult?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
          setMesurement(measurementSystemResponse.data);
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [activeStep, setActiveStep] = useState<number>(
    stepContinuos === "step1" ? 0 : 1
  );

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeReport(id, params.code);
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    switch (params.code) {
      case "GEMA":
        setTitleReport("General Matrix Report");
        break;
      case "HOWI":
        setTitleReport("Hot Wire Anemometer Report");
        break;
      case "ROVA":
        setTitleReport("Rotating Vane Anemometer Report");
        break;
      case "AIFO":
        setTitleReport("Air Foil Report");
        break;
    }
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        const generalMatrixResponse = await generalMatrixService.getById(id);
        setGeneralMatrix(generalMatrixResponse.data);
        await getParentReportIsComplete(
          generalMatrixResponse.data.id,
          params.code ?? ""
        );
        await getProject(generalMatrixResponse.data.projectId);
        await getProjectReport(
          generalMatrixResponse.data.id,
          generalMatrixResponse.data.reportTypeId,
          generalMatrixResponse.data.projectId
        );
        await getInfectiousControlData(generalMatrixResponse.data.id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (values.dryBulbF && values?.dryBulbF > 0 && id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dryBulbF]);

  useEffect(() => {
    let dimensionTotal = undefined;
    if (values.isRectangleType) {
      dimensionTotal = formulas?.sqft(
        (values?.dimensionWidth ?? 0) * (values?.dimensionHeight ?? 0)
      );
    } else if (values.isRoundType) {
      dimensionTotal = formulas?.sqft(
        ((values?.dimensionWidth ?? 0) / 2) *
          ((values?.dimensionWidth ?? 0) / 2) *
          3.14159265359
      );
    }
    setValues({
      ...values,
      dimensionTotal: Number(dimensionTotal?.toFixed(3)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values.isRectangleType,
    values.isRoundType,
  ]);

  useEffect(() => {
    if (!disableStudy && dataReport) {
      onBlurCalcPsycometrics();
    }
    setLoadingCorrect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  useEffect(() => {
    if (values?.totalHeat && dataReport) {
      setLoadingCorrect(true);
      calcPsycometrics(values?.totalCFM!, values);
    } else {
      setLoadingCorrect(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.temperatureCompensated,
    values?.barometricalyCompensated,
    dataReport,
  ]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === "step1" ? 0 : 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const calcTotal = async (totalItems: number) => {
    if (values && values.id > 0) {
      let newValues = { ...values };
      newValues.totalVelocity = totalItems;
      newValues.avgVelocity =
        totalItems / ((newValues.readingsX ?? 0) * (newValues.readingsY ?? 0));
      setValues({
        ...newValues,
      });
      executeRefreshPsyco(newValues.id, newValues);
    }
  };

  const saveOnBlurHandler = async (e: any) => {
    const newValues = {
      ...values,
      [e.target.name]: e.target.value,
    };
    setValues({ ...newValues });
    executeRefresh(newValues.id, newValues);
    executeRefreshPsyco(values.id, values);
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6)) {
      setTotalCFM(totalCFM);
      if (totalCFM !== 0 && values?.totalCFM !== null) setLoadingCorrect(true);
    } else {
      setLoadingCorrect(false);
    }
  };

  const onBlurCalcPsycometrics = () => {
    calcPsycometrics(values?.totalCFM!, values);
  };

  const onBluChange = (e: any) => {
    calcPsycometrics(values?.totalCFM!, values, e, true);
  };

  const calcPsycometrics = async (
    total: number,
    newValues: any,
    e?: any,
    calc?: boolean
  ) => {
    setLoadingCorrect(true);
    if (disableStudy) return;
    let humidityMeasuredAs = "";
    let humidityValue = 0;
    if (newValues?.relativeHumidityBool) {
      humidityMeasuredAs = "RH";
      humidityValue = newValues?.relativeHumidity ?? 0;
    }
    if (newValues?.wetBulbFBool) {
      humidityMeasuredAs = "WB";
      humidityValue = newValues?.wetBulbF ?? 0;
    }
    if (newValues?.dewPointBool) {
      humidityMeasuredAs = "DP";
      humidityValue = newValues?.dewPoint ?? 0;
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: newValues.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: newValues.dryBulbF!,
      totalCFM: total,
    };
    const psychrometricRes = await psychrometric.getByProject(
      psychometricsArgs
    );
    setMessagePsychrometric({ text: "", color: "info" });
    if (stepContinuos !== "step1") {
      if (
        psychrometricRes.psychrometric.message.includes("must be between") ||
        psychrometricRes.psychrometric.message.includes("cannot exceed")
      )
        setMessagePsychrometric({
          text: psychrometricRes.psychrometric.message,
          color: "warning",
        });
      else
        setMessagePsychrometric({
          text: psychrometricRes.psychrometric.message,
          color: "info",
        });
    }

    if (e) {
      const { name, value } = e.target;
      newValues[name] = value;
      setValues({
        ...newValues,
        btuh: psychrometricRes.btuh!,
        sensibleHeat: psychrometricRes.sensibleHeat!,
        [name]: value,
      });
    } else {
      setValues({
        ...newValues,
        btuh: psychrometricRes.btuh!,
        sensibleHeat: psychrometricRes.sensibleHeat!,
      });
    }
    newValues.btuh = psychrometricRes.btuh!;
    newValues.sensibleHeat = psychrometricRes.sensibleHeat!;
    if (newValues.id > 0 && calc) await executeRefresh(newValues.id, newValues);
    else {
      setLoadingCorrect(false);
    }
  };

  const handleCheckNoc = (e: any) => {
    let nocActual = false;
    let nocVisual = false;
    switch (e.target.name) {
      case "nocActual": {
        nocActual = true;
        break;
      }
      case "nocVisual": {
        nocVisual = true;
        break;
      }
    }
    setValues({ ...values, nocActual: nocActual, nocVisual: nocVisual });
  };

  const handleBlurXY = (e: any) => {
    const { name, value } = e.target;
    const newValue = {
      ...values,
      [name]: Number(value),
    };
    setValues({
      ...values,
      [name]: Number(value),
    });
    validateXY(newValue);
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    return found?.key === values?.conditionId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  useEffect(() => {
    let temp: Record<string, string> = { ...errors };
    if ((values?.readingsY ?? 0) >= 20) {
      log.error("The item Y must be less than 20");
      temp.readingsY = new Validator(values, "readingsY")
        .lessThan(20, " ")
        .toString();
    } else {
      temp.readingsY = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.readingsY]);

  const isOther = useMemo(() => {
    const found = conditionOfTest.find((item) => item.value === "Other");
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
    await execute(id);
    setSaveItems(!saveItems);
    setShowSteady(true);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const handleChangeRadio = async (e: any) => {
    const newValues = { ...values };
    let relativeHumidityBool = false;
    let dewPointBool = false;
    let wetBulbFBool = false;
    switch (e.target.name) {
      case "relativeHumidityBool": {
        relativeHumidityBool = true;
        break;
      }
      case "dewPointBool": {
        dewPointBool = true;
        break;
      }
      case "wetBulbFBool": {
        wetBulbFBool = true;
        break;
      }
    }
    newValues.relativeHumidityBool = relativeHumidityBool;
    newValues.dewPointBool = dewPointBool;
    newValues.wetBulbFBool = wetBulbFBool;
    newValues.relativeHumidity = undefined;
    newValues.dewPoint = undefined;
    newValues.wetBulbF = undefined;
    await executeRefreshPsyco(newValues.id, newValues);
  };

  const showskeleton = useMemo(
    () => isLoading || isSubmittingUpdate,
    [isLoading, isSubmittingUpdate]
  );

  const showskeletonStep2 = useMemo(() => isLoading, [isLoading]);

  useMemo(() => {
    const validateStep2 = async () => {
      let active = false;
      if (values?.isRectangleType) {
        active =
          values?.dimensionWidth !== 0 &&
          values?.dimensionHeight !== 0 &&
          values?.readingsX !== 0 &&
          values?.readingsY !== 0 &&
          values?.conditionId !== 0;

        if (active) {
          setStepContinuos("step2");
          if (!disableStudy) await handleSave();
        }
      } else if (values?.isRoundType) {
        active =
          values?.dimensionWidth !== 0 &&
          values?.readingsX !== 0 &&
          values?.readingsY !== 0 &&
          values?.conditionId !== 0;

        if (active) {
          setStepContinuos("step2");
          if (!disableStudy) await handleSave();
        }
      }
    };
    validateStep2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.readingsX,
    values?.readingsY,
    values?.conditionId,
  ]);

  const handleInputChangeCompesation = (e: any) => {
    setLoadingCorrect(true);
    let temperatureCompensated = e.target.value;
    let dryBulbF = values?.dryBulbF;
    if (values.airflowTemperature === values.dryBulbF) {
      dryBulbF = formulas?.getAirflowTemperature();
    }
    let airflowTemperature = formulas?.getAirflowTemperature();
    if (values.temperatureCompensated === temperatureCompensated) {
      setLoadingCorrect(false);
    }

    setValues({
      ...values,
      temperatureCompensated: temperatureCompensated,
      dryBulbF: dryBulbF,
      airflowTemperature: airflowTemperature,
    });
  };

  const handleChangeSpecificDefault = async (e: any) => {
    const newValues = { ...values };
    let isActualLocalBarometricPressure = e.target.value;
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF: mesurement === "imperialSystem" ? 70 : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      newValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    } else {
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    }
    await executeRefreshPsyco(newValues.id, newValues);
  };

  const handleInputChangeConditionId = (e: any) => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === e.target.value) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        nocActual: false,
        nocVisual: true,
        nocOaNumber: 0,
        nocMaNumber: 0,
        nocRaNumber: 0,
        nocExhaustNumber: 0,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        nocOaNumber: 0,
        nocMaNumber: 0,
        nocRaNumber: 0,
        nocExhaustNumber: 0,
      });
    }
  };

  const handleInputChangePsycometric = async (e: any) => {
    setLoadingCorrect(true);
    const newValues = { ...values, [e.target.name]: e.target.value };
    await executeRefreshPsyco(newValues.id, newValues);
  };

  const onChangeTypeDevice = (e: any) => {
    const { value } = e.target;
    if (value === TYPE_DEVICE.RECTANGULAR) {
      setValues({
        ...values,
        isRectangleType: true,
        isRoundType: false,
        typeDevice: value,
        dimensionTotal: 0,
        headDiameterRVA: 0,
        dimensionHeight: 0,
        dimensionWidth: 0,
        readingsX: 0,
        readingsY: 0,
      });
    } else {
      setValues({
        ...values,
        isRectangleType: false,
        isRoundType: true,
        typeDevice: value,
        dimensionTotal: 0,
        headDiameterRVA: 0,
        dimensionHeight: 0,
        dimensionWidth: 0,
        readingsX: 0,
        readingsY: 0,
      });
    }
  };

  const handleRecreateMatrixFromRVAHeadDimension = () => {
    if (values.headDiameterRVA != null && values.headDiameterRVA > 0) {
      var newValues = { ...values };
      newValues.readingsX = Math.round(
        (newValues.dimensionWidth ?? 0) / (newValues?.headDiameterRVA ?? 0)
      );
      newValues.readingsX =
        newValues.readingsX === 0 &&
        newValues.dimensionWidth !== 0 &&
        newValues.dimensionWidth !== null
          ? 1
          : newValues.readingsX;
      newValues.readingsY = Math.round(
        (newValues.dimensionHeight ?? 0) / (newValues?.headDiameterRVA ?? 0)
      );
      newValues.readingsY =
        newValues.readingsY === 0 &&
        newValues.dimensionHeight !== 0 &&
        newValues.dimensionHeight !== null
          ? 1
          : newValues.readingsY;

      setValues({
        ...values,
        readingsX: newValues.readingsX,
        readingsY: newValues.readingsY,
      });
    }
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );

    setProjectReport(projectReportResposne.data);
  };

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      if (error?.message.title !== "Not Found") {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const refreshCompletedStudy = async () => {
    try {
      const generalMatrixResponse = await generalMatrixService.getById(id);
      setGeneralMatrix(generalMatrixResponse.data);
      const projectResponse = await getProjectSingleService.single(
        generalMatrixResponse.data.projectId
      );
      setProject(projectResponse.data);
      await execute(id);
      await getProjectReport(
        generalMatrixResponse?.data.id ?? 0,
        generalMatrixResponse?.data.reportTypeId ?? 0,
        generalMatrixResponse?.data.projectId ?? 0
      );
      setMessagePsychrometric({ text: "", color: "info" });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const validateXY = (newValue: IGeneralMatrix) => {
    let temp: Record<string, string> = { ...errors };

    if ((newValue?.readingsY ?? 0) === 0) {
      log.error("The item Y must be greater than 0");
      temp.readingsY = " ";
    } else {
      temp.readingsY = "";
    }
    if ((newValue?.readingsX ?? 0) === 0) {
      log.error("The item X must be greater than 0");
      temp.readingsX = " ";
    } else {
      temp.readingsX = "";
    }

    setErrors({
      ...temp,
    });
  };

  const studieROVA = params.code === "ROVA";

  const disableStep1 = disableStudy || stepContinuos === "step1";

  let showSteadyPopUp =
    showSteady && stepContinuos !== "step1" && values && values?.projectId > 0;

  const disableCompleteStudy = values?.dryBulbF === 0;

  const porcentajeDesign =
    values &&
    values?.percentajeOfDesign &&
    (values?.percentajeOfDesign >
      (values?.requiredCFMSystemToleranceTop
        ? values?.requiredCFMSystemToleranceTop
        : 0) +
        100 ||
      values?.percentajeOfDesign <
        100 -
          (values?.requiredCFMSystemToleranceBottom
            ? values?.requiredCFMSystemToleranceBottom
            : 0));

  const mode = stepContinuos === "step2" ? "read" : "read&Write";
  const modeReadWrite = disableStudy ? "read" : "read&Write";

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: params.code ?? "",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <h3>System Configuration</h3>
              <Grid container spacing={5}>
                <Grid item xs={12} md={3}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showskeleton}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showskeleton}
                    mode={mode}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormText
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showskeleton}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <h3>Type of Terminal Device being tested</h3>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name="typeDevice"
                        value={values?.typeDevice}
                        items={typeOfTerminal}
                        row
                        onChange={onChangeTypeDevice}
                        defaultValue={selectRadioButton}
                        showSkeleton={showskeleton}
                        disabled={disableStudy || stepContinuos === "step2"}
                      />
                    </Grid>
                    {values?.isRectangleType && (
                      <Grid container>
                        <Grid item xs={12} md={studieROVA ? 4 : 5}>
                          <Stack mb={1}>
                            <FormLabel
                              text="Rectangular Dimension"
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                marginBottom: "3px",
                              }}
                            />
                          </Stack>
                          <Grid container direction={"row"}>
                            <Grid item xs={disableStudy ? 1.5 : 5}>
                              <FormNumeric2
                                name={"dimensionWidth"}
                                label={"Width"}
                                value={
                                  values.isRoundType
                                    ? 0
                                    : values?.dimensionWidth?.toString() ?? ""
                                }
                                onChange={handleInputChange}
                                fullWidth="150px"
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                                suffix={` ${systemOfMeasurement.get("in")}`}
                                size="small"
                                disabled={disableStudy}
                                mode={modeReadWrite}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                variant="subtitle2"
                                mt={2}
                                textAlign={disableStudy ? "left" : "center"}
                              >
                                by
                              </Typography>
                            </Grid>
                            <Grid item xs={disableStudy ? 2 : 5}>
                              <FormNumeric2
                                name={"dimensionHeight"}
                                label={"Heigth"}
                                value={
                                  values?.dimensionHeight?.toString() ?? ""
                                }
                                onChange={handleInputChange}
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                                suffix={` ${systemOfMeasurement.get("in")}`}
                                size="small"
                                disabled={disableStudy}
                                mode={modeReadWrite}
                              />
                            </Grid>
                            {disableStudy && (
                              <Grid item xs={12} md={5} ml={10}>
                                <>
                                  <FormNumeric2
                                    name={"dimensionTotal"}
                                    label={"Dimension Total"}
                                    value={
                                      values?.dimensionTotal?.toFixed(3) ?? ""
                                    }
                                    onChange={() => {}}
                                    showSkeleton={showskeleton}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                    suffix={` ${systemOfMeasurement.get("in")}`}
                                    size="small"
                                    mode={"read"}
                                  />
                                </>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        {!disableStudy && (
                          <Grid item xs={12} md={studieROVA ? 2 : 2}>
                            <>
                              <Stack mt={1}>
                                <FormLabel
                                  text="Dimension Total "
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    marginBottom: "3px",
                                  }}
                                />
                              </Stack>
                              <Stack mt={2}>
                                <FormLabel
                                  text={`${
                                    values?.dimensionTotal?.toFixed(3) ?? ""
                                  } Sq/Ft`}
                                  sx={{
                                    marginBottom: "3px",
                                    textAlign: "left",
                                  }}
                                />
                              </Stack>
                            </>
                          </Grid>
                        )}

                        <Grid item xs={12} md={studieROVA ? 4 : 5}>
                          <Grid item xs={12}>
                            <Stack mb={1}>
                              <FormLabel
                                text="Number of readings"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginBottom: "3px",
                                }}
                              />
                            </Stack>
                            <Grid container direction={"row"}>
                              <Grid item xs={disableStudy ? 3 : 5}>
                                <FormNumeric2
                                  name={"readingsX"}
                                  label={"X axis ↔"}
                                  value={
                                    values.isRoundType
                                      ? 0
                                      : values?.readingsX?.toString() ?? ""
                                  }
                                  onBlur={handleBlurXY}
                                  fullWidth="150px"
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  disabled={disableStudy}
                                  error={errors.readingsX}
                                  mode={modeReadWrite}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  mt={2}
                                  textAlign={disableStudy ? "left" : "center"}
                                >
                                  by
                                </Typography>
                              </Grid>
                              <Grid item xs={disableStudy ? 3 : 5}>
                                <FormNumeric2
                                  name={"readingsY"}
                                  label={"Y axis ↕"}
                                  value={
                                    values.isRoundType
                                      ? 0
                                      : values?.readingsY?.toString() ?? ""
                                  }
                                  onBlur={handleBlurXY}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  error={errors.readingsY}
                                  disabled={disableStudy}
                                  mode={modeReadWrite}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {studieROVA && (
                          <Grid item xs={12} md={2}>
                            <Stack mb={1}>
                              <FormLabel
                                text={`RVA Head Diameter (${systemOfMeasurement.get(
                                  "in"
                                )})`}
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginBottom: "3px",
                                }}
                              />
                            </Stack>
                            <Grid container>
                              <Grid item xs={8}>
                                <FormNumeric2
                                  name={"headDiameterRVA"}
                                  label={"Diameter"}
                                  value={
                                    values?.headDiameterRVA?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  onBlur={
                                    handleRecreateMatrixFromRVAHeadDimension
                                  }
                                  disabled={disableStudy}
                                  mode={modeReadWrite}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {values?.isRoundType && (
                      <Grid container>
                        <Grid item xs={12} md={2}>
                          <Stack mb={1}>
                            <FormLabel
                              text="Round duct diameter ID"
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                marginBottom: "3px",
                              }}
                            />
                          </Stack>
                          <Grid container direction={"row"}>
                            <Grid item xs={6}>
                              <FormNumeric2
                                name={"dimensionWidth"}
                                label={"Width"}
                                value={
                                  values.isRectangleType
                                    ? 0
                                    : values?.dimensionWidth?.toString() ?? ""
                                }
                                onChange={handleInputChange}
                                fullWidth="150px"
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                                suffix={` ${systemOfMeasurement.get("in")}`}
                                size="small"
                                disabled={disableStudy}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={studieROVA ? 2 : 2}>
                          <Stack mt={1}>
                            <FormLabel
                              text="Dimension Total "
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                marginBottom: "3px",
                              }}
                            />
                          </Stack>
                          <Stack mt={2}>
                            <FormLabel
                              text={`${
                                values?.dimensionTotal?.toFixed(3) ?? ""
                              } Sq/Ft`}
                              sx={{
                                marginBottom: "3px",
                                textAlign: "left",
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={studieROVA ? 4 : 5}>
                          <Grid item xs={12}>
                            <Stack mb={1}>
                              <FormLabel
                                text="Number of readings"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginBottom: "3px",
                                }}
                              />
                            </Stack>
                            <Grid container direction={"row"}>
                              <Grid item xs={5}>
                                <FormNumeric2
                                  name={"readingsX"}
                                  label={"X axis ↔"}
                                  value={
                                    values.isRectangleType
                                      ? 0
                                      : values?.readingsX?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  fullWidth="150px"
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  disabled={disableStudy}
                                  error={errors.readingsX}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  mt={2}
                                  textAlign={"center"}
                                >
                                  by
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <FormNumeric2
                                  name={"readingsY"}
                                  label={"Y axis ↕"}
                                  value={
                                    values.isRectangleType
                                      ? 0
                                      : values?.readingsY?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  error={errors.readingsY}
                                  disabled={disableStudy}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {studieROVA && (
                          <Grid item xs={12} md={2}>
                            <Stack mb={1}>
                              <FormLabel
                                text={`RVA Head Diameter (${systemOfMeasurement.get(
                                  "in"
                                )})`}
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginBottom: "3px",
                                }}
                              />
                            </Stack>
                            <Grid container>
                              <Grid item xs={8}>
                                <FormNumeric2
                                  name={"headDiameterRVA"}
                                  label={"Diameter"}
                                  value={
                                    values?.headDiameterRVA?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  onBlur={
                                    handleRecreateMatrixFromRVAHeadDimension
                                  }
                                  disabled={disableStudy}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Divider mt={3} />
                  <h3>Condition of Test</h3>
                  <Grid container direction={"row"}>
                    <Grid item xs={12} md={2.5}>
                      <FormSelect
                        name={"conditionId"}
                        label={"Condition"}
                        onChange={handleInputChangeConditionId}
                        showSkeleton={showskeleton}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test",
                        }}
                        disabled={disableStudy}
                        mode={modeReadWrite}
                      />
                    </Grid>
                    <Grid item xs={12} md={8.5}>
                      {isNOC && (
                        <Grid container>
                          {!disableStudy && (
                            <>
                              <Grid item xs={12} md={4} pr={3}>
                                <Typography
                                  variant="subtitle2"
                                  mt={2}
                                  textAlign={"center"}
                                >
                                  Normal Operating Condition
                                </Typography>
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12} md={3.5}>
                            <FormCheckBox
                              name={"nocVisual"}
                              onChange={handleCheckNoc}
                              label={"Visual Damper Position"}
                              showSkeleton={showskeletonStep2}
                              value={values?.nocVisual}
                              disabled={disableStudy}
                              mode={modeReadWrite}
                            ></FormCheckBox>
                          </Grid>

                          <Grid item xs={12} md={3.5}>
                            <FormCheckBox
                              name={"nocActual"}
                              onChange={handleCheckNoc}
                              label={"Actual Damper Position"}
                              showSkeleton={showskeletonStep2}
                              value={values?.nocActual}
                              disabled={disableStudy}
                              mode={modeReadWrite}
                            ></FormCheckBox>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isOther && (
                    <Grid container mt={5}>
                      <Grid item xs={12}>
                        <FormTextArea
                          name={"comments"}
                          label={"Comments"}
                          value={values?.comments}
                          onChange={handleInputChange}
                          mode={modeReadWrite}
                        ></FormTextArea>
                      </Grid>
                    </Grid>
                  )}
                  {isNOC && (
                    <Grid
                      container
                      mt={3}
                      justifyContent={"center"}
                      spacing={2}
                    >
                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocOaNumber"}
                          label={""}
                          value={values?.nocOaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % O/A"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocRaNumber"}
                          label={""}
                          value={values?.nocRaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % R/A"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocMaNumber"}
                          label={""}
                          value={values?.nocMaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % M/A"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocExhaustNumber"}
                          label={""}
                          value={values?.nocExhaustNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % Exhaust"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid container mt={4}>
                      <Grid item xs={12} md={3} textAlign={"left"}>
                        <FormCheckSwitch
                          name={"totalHeat"}
                          label={"Total Heat"}
                          showSkeleton={showskeleton}
                          onChange={handleInputChange}
                          disabled={
                            isLoadingRefresh ||
                            isLoadingUpdate ||
                            loadingCorrect ||
                            disableStudy
                          }
                          // mode={values?.isComplete ? mode : "read&Write"}
                          value={values?.totalHeat}
                          mode={modeReadWrite}
                        ></FormCheckSwitch>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={disableStudy ? 4 : 3}
                        textAlign={"left"}
                      >
                        <FormCheckSwitch
                          name={"temperatureCompensated"}
                          label={"Correct For Temperature Compensation"}
                          showSkeleton={showskeleton}
                          onChange={handleInputChangeCompesation}
                          disabled={
                            isLoadingRefresh ||
                            isLoadingUpdate ||
                            loadingCorrect ||
                            disableStudy
                          }
                          value={values?.temperatureCompensated}
                          mode={modeReadWrite}
                        ></FormCheckSwitch>
                      </Grid>
                      <Grid item xs={12} md={3} textAlign={"left"}>
                        <FormCheckSwitch
                          name={"barometricalyCompensated"}
                          label={"Correct For Barometric Compensation"}
                          showSkeleton={showskeleton}
                          onChange={handleInputChangePsycometric}
                          disabled={
                            isLoadingRefresh ||
                            isLoadingUpdate ||
                            loadingCorrect ||
                            disableStudy
                          }
                          value={values?.barometricalyCompensated}
                          mode={modeReadWrite}
                        ></FormCheckSwitch>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider mt={3} mb={3} />
                  {values && (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <h3>Readings</h3>
                        </Grid>
                      </Grid>
                      <Grid container pt={2}>
                        {values?.barometricalyCompensated && (
                          <>
                            <Grid item xs={6} md={disableStudy ? 4 : 3}>
                              {values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"useSiteSpecificDefault"}
                                  label={"Local Barometric Pressure Default"}
                                  value={
                                    values?.useSiteSpecificDefault?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  mode="read"
                                  thousandSeparator={true}
                                  disabled={disableStep1 || disableStudy}
                                ></FormNumeric2>
                              )}
                              {!values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"localBarometricPressure"}
                                  label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                  value={
                                    values?.localBarometricPressure?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  disabled={disableStep1 || disableStudy}
                                  mode={modeReadWrite}
                                ></FormNumeric2>
                              )}
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Stack ml={2}>
                                <FormCheckSwitch
                                  name={"isActualLocalBarometricPressure"}
                                  label={"Use Site Specific Default"}
                                  showSkeleton={showskeleton}
                                  onChange={handleChangeSpecificDefault}
                                  disabled={disableStep1 || disableStudy}
                                  value={
                                    values?.isActualLocalBarometricPressure
                                  }
                                  mode={modeReadWrite}
                                />
                              </Stack>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          {params.code === "ROVA" && (
                            <Grid item xs={12}>
                              <Typography>
                                <Typography
                                  variant="subtitle2"
                                  display={"inline-flex"}
                                  mt={2}
                                  pr={2}
                                >
                                  Use Sweep method:
                                </Typography>
                                <FormCheckBox
                                  name={"useSweepMethod"}
                                  value={values?.useSweepMethod}
                                  onChange={handleInputChange}
                                  label={`Enter your total value for "Sweep Method" in the first cell of the matrix, cells will auto populate.`}
                                  disabled={disableStudy}
                                  mode={modeReadWrite}
                                ></FormCheckBox>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12} pb={3}>
                          {values && id && (
                            <GeneralMatrixGrid
                              id={id}
                              x={values?.readingsX ?? 0}
                              y={values?.readingsY ?? 0}
                              saveItems={saveItems}
                              disabled={disableStep1}
                              showSkeleton={showskeleton}
                              onBlur={calcTotal}
                              useSweepMethod={values?.useSweepMethod}
                              systemOfMeasurement={systemOfMeasurement}
                              values={values}
                              setDataX={setDataX}
                              setDataY={setDataY}
                              dataY={dataY}
                              dataX={dataX}
                            />
                          )}
                        </Grid>
                      </Grid>
                      {messagePsychrometric.text !== "" && (
                        <Grid item xs={12} justifyContent={"center"}>
                          <Stack mb={2}>
                            <Alert severity={messagePsychrometric.color}>
                              {messagePsychrometric.text}
                            </Alert>
                          </Stack>
                        </Grid>
                      )}
                      <Grid container spacing={3}>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"totalVelocity"}
                            label={"Total Velocity"}
                            value={values?.totalVelocity?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"avgVelocity"}
                            label={"Avg Velocity"}
                            value={values?.avgVelocity?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"trueCorectedAirVelocity"}
                            label={"True Corrected Average Velocity"}
                            value={
                              values?.trueCorectedAirVelocity?.toString() ?? ""
                            }
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"totalCFM"}
                            label={`Actual ${systemOfMeasurement.get("acfm")}`}
                            value={values?.totalCFM?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"required"}
                                label={`Required ${systemOfMeasurement.get(
                                  "cfm"
                                )}`}
                                value={values?.required?.toString() ?? ""}
                                onChange={handleInputChange}
                                disabled={disableStep1 || disableStudy}
                                onBlur={saveOnBlurHandler}
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                                mode={modeReadWrite}
                              ></FormNumeric2>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"akFactor"}
                                label={"AK Factor"}
                                value={values?.akFactor?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={saveOnBlurHandler}
                                disabled={disableStep1 || disableStudy}
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                                mode={modeReadWrite}
                              ></FormNumeric2>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"percentajeOfDesign"}
                            label={"% of Design"}
                            value={values?.percentajeOfDesign?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            color={porcentajeDesign ? "red" : undefined}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}></Grid>
                        <Grid item md={3} xs={6}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"dryBulbF"}
                                label={
                                  "Airflow Temperature For Energy Calculation"
                                }
                                value={values?.dryBulbF?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={saveOnBlurHandler}
                                disabled={disableStep1 || disableStudy}
                                showSkeleton={showskeleton}
                                error={errors.dryBulbF}
                                decimalScale={3}
                                thousandSeparator={true}
                                mode={modeReadWrite}
                                suffix={disableStudy ? " DB" : ""}
                              ></FormNumeric2>
                            </Grid>
                            <Grid item xs={2}>
                              {!disableStudy && (
                                <Typography pl={2} pt={1}>
                                  DB
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {values.totalHeat && (
                          <>
                            <Grid item md={3} xs={6}>
                              <Grid container>
                                <Grid item xs={10}>
                                  {values?.relativeHumidityBool === true && (
                                    <FormNumeric2
                                      name={"relativeHumidity"}
                                      label={"Relative Humidity"}
                                      value={
                                        values?.relativeHumidity?.toString() ??
                                        ""
                                      }
                                      onBlur={onBluChange}
                                      onChange={handleInputChange}
                                      disabled={disableStep1}
                                      showSkeleton={showskeleton}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                      mode={modeReadWrite}
                                    ></FormNumeric2>
                                  )}
                                  {values?.wetBulbFBool === true && (
                                    <FormNumeric2
                                      name={"wetBulbF"}
                                      label={"Wet bulb F"}
                                      value={values?.wetBulbF?.toString() ?? ""}
                                      onBlur={onBluChange}
                                      onChange={handleInputChange}
                                      disabled={disableStep1}
                                      showSkeleton={showskeleton}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                      mode={modeReadWrite}
                                    ></FormNumeric2>
                                  )}
                                  {values?.dewPointBool === true && (
                                    <FormNumeric2
                                      name={"dewPoint"}
                                      label={"Dew Point"}
                                      value={values?.dewPoint?.toString() ?? ""}
                                      onBlur={onBluChange}
                                      onChange={handleInputChange}
                                      disabled={disableStep1}
                                      showSkeleton={showskeleton}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                      mode={modeReadWrite}
                                    ></FormNumeric2>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <RadioGroup aria-label="" name="" row>
                                <FormControlLabel
                                  checked={
                                    values?.relativeHumidityBool === true
                                  }
                                  control={<Radio />}
                                  label="Relative Humidity"
                                  onChange={handleChangeRadio}
                                  name={"relativeHumidityBool"}
                                  disabled={disableStep1}
                                />
                                <FormControlLabel
                                  checked={values?.wetBulbFBool === true}
                                  control={<Radio />}
                                  label="Wet bulb F"
                                  onChange={handleChangeRadio}
                                  name={"wetBulbFBool"}
                                  disabled={disableStep1}
                                />
                                <FormControlLabel
                                  checked={values?.dewPointBool === true}
                                  control={<Radio />}
                                  label="Dew Point"
                                  onChange={handleChangeRadio}
                                  name={"dewPointBool"}
                                  disabled={disableStep1}
                                />
                              </RadioGroup>
                            </Grid>
                          </>
                        )}

                        {values.totalHeat && (
                          <Grid item md={3} xs={6}>
                            <FormNumeric2
                              name={"btuh"}
                              label={`Total ${systemOfMeasurement.get("btuh")}`}
                              value={values?.btuh?.toString() ?? ""}
                              onChange={handleInputChange}
                              mode="read"
                              showSkeleton={showskeleton || isLoadingUpdate}
                              decimalScale={3}
                              thousandSeparator={true}
                              disabled={disableStep1}
                            ></FormNumeric2>
                          </Grid>
                        )}
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"sensibleHeat"}
                            label={`Sensible ${systemOfMeasurement.get(
                              "btuh"
                            )}`}
                            value={values?.sensibleHeat}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={1}
                      stepsCount={3}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName={titleReport}
                      saveHandler={handleSave}
                      nextStepHandler={() => {}}
                      disabled={disableStudy || (values?.readingsY ?? 0) >= 20}
                      previousStepHandler={() => {}}
                      isComplete={values?.isComplete ?? true}
                      disableNext={(values?.readingsY ?? 0) >= 20}
                      singleStep={true}
                      hideSaveButton={disableStudy}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {project && projectReport && generalMatrix && (
                      <CompleteStudyPopup
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        project={project}
                        projectReport={projectReport}
                        completed={generalMatrix?.isComplete}
                        infectiousComplianceControl={
                          infectiousComplianceControl
                        }
                        parentStudy={parentStudy ?? null}
                        singleStep={disableCompleteStudy}
                        handlerCustomAccept={refreshCompletedStudy}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showSteadyPopUp && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={values?.reportTypeId}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
          setShowSteady={setShowSteady}
        />
      )}
    </>
  );
};

export default GeneralMatrix;
