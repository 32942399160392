import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
  Box,
  Chip as MuiChip,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import { ICatalogType } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useLog } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import { FormSelect } from "src/components/formControls";
import FormSaveButton from "src/components/formControls/FormSaveButton";
import FormCancelButton from "src/components/formControls/FormCancelButton";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import CatalogService from "src/services/catalogService";
import { COUNTRIES, STATUSES } from "src/constants";
import { Validator } from "src/ts/types/Validator";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Chip = styled(MuiChip)<ChipProps>(spacing);
const Card = styled(MuiCard)(spacing);

let scores: Record<string, string> = {};
scores.SafetyEquipment = "Safety Equipment";
scores.BuildingType = "Building Type";

scores.FanManufacturer = "Fan Manufacturer";
scores.FanType = "Fan Type";
scores.FanSubType = "Fan SubType";
scores.BeltType = "Belt Type";
scores.FanSheave = "FanSheave";

scores.FanMotorManufacturer = "Fan Motor Manufacturer";
scores.MotorManufacturer = "Motor Manufacturer";
scores.MotorSheaveMfg = "Motor Sheave Mfg.";
scores.MotorFrameCategory = "Motor Frame Category";

scores.PumpType = "Pump Type";
scores.PumpSvc = "Pump Svc";
scores.PumpManufacturer = "Pump Manufacturer";
scores.FlowDesign = "Flow Design";

scores.TicketClassification = "Ticket Classification";
scores.TechnicianQuizCategory = "Quiz Category";
scores.TrainingCourses = "Training Courses";
scores.LaborUnions = "Labor Unions";

const initialValues = {
  id: 0,
  name: "",
  statusId: 1,
};

const Catalog = () => {
  let { catalog } = useParams<{ catalog: string }>();

  let safeCatalog: string = "";
  if (catalog !== undefined) safeCatalog = catalog;

  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataSet, setDataSet] = useState<ICatalogType[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<ICatalogType>();
  const [showSkeleton, setShowSkeleton] = useState(false);

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.statusId = new Validator(fieldValues, "statusId")
      .selectedOption("", "The status is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);
  const { log } = useLog();
  useEffect(() => {
    const getData = async () => {
      if (!catalog) return;
      setShowSkeleton(true);
      const response = await CatalogService.getAll(catalog);
      setDataSet(response.data);
      setShowSkeleton(false);
    };

    getData();
  }, [catalog, refreshGrid]);

  const handleOpen = () => {
    resetForm();
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleEdit = (row: ICatalogType) => {
    setValues(row);
    setIsDialogOpen(true);
  };

  const handleSave = async () => {
    if (!validate()) return;

    setIsSubmitting(true);

    if (values.id === 0) {
      await CatalogService.add(catalog!, values)
        .then((response) => {
          setIsDialogOpen(false);
          setRefreshGrid(!refreshGrid);
          setIsSubmitting(false);
          resetForm();
        })
        .catch((error: any) => {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        });
    } else {
      await CatalogService.update(catalog!, values.id, values)
        .then((response) => {
          setIsDialogOpen(false);
          setRefreshGrid(!refreshGrid);
          setIsSubmitting(false);
          resetForm();
        })
        .catch((error: any) => {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
          setIsSubmitting(false);
          setIsDialogOpen(false);
        });
    }
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await CatalogService.deleteById(catalog!, currentRow.id);
        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const { fullAccess, readOnly } = usePermissions(
    catalog === "TechnicianQuizCategory"
      ? PermissionTypes.Quiz
      : PermissionTypes.Catalogs
  );

  const columns = (catalog: string): ColumnType[] => [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "location",
      label: "Location",
      type: "custom",
      sort: true,
      hide: catalog !== "TrainingCourses",
      callback: (row: ICatalogType) => {
        return (
          <>
            {row.unionAffiliationNumber &&
              `${row.unionAffiliationNumber?.localUnion} - ${
                row.unionAffiliationNumber?.city
              }, ${
                row.unionAffiliationNumber?.countryId === COUNTRIES.USA
                  ? row.unionAffiliationNumber?.state.name
                  : row.unionAffiliationNumber?.stateName
              }`}
          </>
        );
      },
    },
    {
      id: "beginDate",
      label: "Begin Date",
      type: "date",
      sort: true,
      hide: catalog !== "TrainingCourses",
    },
    {
      id: "endDate",
      label: "End Date",
      type: "date",
      sort: true,
      hide: catalog !== "TrainingCourses",
    },
    {
      id: "statusId",
      label: "Status",
      type: "custom",
      sort: true,
      callback: (row: ICatalogType) => {
        return (
          <>
            {row.statusId === 1 ? (
              <Chip label="Enabled" color="primary" m={1} size="small" />
            ) : (
              <Chip
                label="Disabled"
                color="primary"
                variant="outlined"
                m={1}
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ICatalogType) => {
        return (
          <div>
            <Box mr={2}>
              <GridActionButton
                type={fullAccess ? "edit" : "view"}
                onClick={() => handleEdit(row)}
                tooltip={fullAccess ? "Edit" : "View"}
              />
              {fullAccess && (
                <GridActionButton
                  type="delete"
                  onClick={() => handleDeleteDocument(row)}
                  tooltip="Delete"
                />
              )}
            </Box>
          </div>
        );
      },
    },
  ];

  const handleDeleteDocument = (row: ICatalogType) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  return (
    <React.Fragment>
      <Helmet title={scores[safeCatalog]} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {scores[safeCatalog]}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>{scores[safeCatalog]}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={handleOpen} variant="contained" color="primary">
                <AddIcon />
                {`New ${scores[safeCatalog]}`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ICatalogType>
                refreshGrid={refreshGrid}
                columns={columns(catalog ?? "")}
                data={dataSet}
                query={filterValues.name}
                showSkeleton={showSkeleton}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Add New ${scores[safeCatalog]}`}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                disabled={readOnly}
              />
            </Grid>
            {catalog === "TrainingCourses" && (
              <Grid item xs={12}>
                <FormText
                  name="description"
                  label="Description"
                  value={values.description}
                  onChange={handleInputChange}
                  disabled={readOnly}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormSelect
                name="statusId"
                label="Status"
                value={values.statusId}
                onChange={handleInputChange}
                options={STATUSES}
                error={errors.statusId}
                defaultValue={{ key: -1, value: "Select a value" }}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
          {fullAccess && (
            <FormSaveButton onClick={handleSave} isSubmitting={isSubmitting} />
          )}
        </>
      </Popup>
      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
        text={"Are you certain you want to delete this item?"}
        onConfirm={deleteDocument}
      ></MatchWordConfirmModal>
    </React.Fragment>
  );
};

export default Catalog;
