import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useState } from "react";
import { useAuth, useEffectOnce } from "src/hooks";
import { IProjectReportInfectiousComplianceControl } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/getProjectSingleService";
import { IStudyStep } from "src/ts/interfaces/study/common";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { IVelgridMatrix } from "src/ts/interfaces/study/velgridMatrix";
import velgridMatrixService from "src/services/study/velgridMatrixService";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const VelgridStep3 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();
  const { user } = useAuth();
  const steps: IStudyStep[] = [
    {
      label: "Step 1",
      key: 0,
      description: "System configuration",
      url: "/studies/VelgridMatrix/step1/:id",
    },
    {
      label: "Step 2",
      key: 1,
      description: "Readings",
      url: "/studies/VelgridMatrix/step2/:id",
    },
    {
      label: "Step 3",
      key: 2,
      description: "Report",
      url: "/studies/VelgridMatrix/step3/:id",
    },
  ];
  const activeStep = 2;

  const [velgridMatrix, setVelgridMatrix] = useState<IVelgridMatrix | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const velgridMatrixResponse = await velgridMatrixService.getById(id);
      setVelgridMatrix(velgridMatrixResponse.data);
      await getParentReportIsComplete(velgridMatrixResponse.data.id, "VEMR");
      await getProject(velgridMatrixResponse.data.projectId);
      await getProjectReport(
        velgridMatrixResponse.data.id,
        velgridMatrixResponse.data.reportTypeId,
        velgridMatrixResponse.data.projectId
      );
      await getInfectiousControlData(velgridMatrixResponse.data.id);
    };
    if (id > 0) getData();
  });

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const previousStepHandler = async () => {
    navigate(`/studies/VelgridMatrix/step2/${id}/`);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "VEMR",
          system: velgridMatrix?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={velgridMatrix?.projectId ?? 0}
            reportId={id}
            reportTypeId={6}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && velgridMatrix && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={velgridMatrix?.isComplete}
                      infectiousComplianceControl={infectiousComplianceControl}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={activeStep}
                      stepsCount={steps.length}
                      projectId={velgridMatrix?.projectId ?? 0}
                      companyId={user?.companyId as number}
                      isSaving={false}
                      reportName="Velgrid Matrix Test Report"
                      saveHandler={() => {}}
                      disabled={false}
                      previousStepHandler={previousStepHandler}
                      isComplete={velgridMatrix?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default VelgridStep3;
