/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import projectReportService from "src/services/study/projectReportService";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useFormulas,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEETReportServiceModelDTO,
  IEnergyStudyAirHydronicsDTO,
  IFormulaSystemService,
  IHeadSheetActualDTO,
  IHeadSheetActualFormulaDTO,
  IHeadSheetActualSpacePressurizationDTO,
  IProjectPurchase,
  IProjectReportInfectiousComplianceControl,
  IReportType,
  IStaticIdentDTO,
  IStaticReportDTO,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import projectService from "src/services/project/projectService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import staticReportService from "src/services/study/staticReportService";
import Popup from "src/components/Popup";
import { FormButton, FormCheckBox } from "src/components/formControls";
import { StaticComponentsGrid } from "../../static/step2/components/ComponentsGrid";
import headSheetService from "src/services/study/headSheetService";
import headSheetActualSpacePressurizationService from "src/services/study/headSheetActualSpacePressurizationService";
import { SpacePressurization } from "../../headsheet/step5/components/SpacePressurization";
import StudyWizard from "../../components/StudyWizard";
import { borderRadius } from "polished";
import { useTheme } from "@mui/system";
import RetestReport from "../components/RetestReport";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const EnergyHydronicsStep7 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [openNoHeatTransferDeviceWarning, setOpenNoHeatTransferDeviceWarning] =
    useState(false);
  const [
    openNoHeatTransferDeviceSelectedWarning,
    setOpenNoHeatTransferDeviceSelectedWarning,
  ] = useState(false);
  const [proceedWithoutCoil, setProceedWithoutCoil] = useState(false);
  const [staticValues, setStaticValues] = useState<IStaticReportDTO>();
  const [filters, setFilters] = useState<IStaticIdentDTO[]>();
  const [headSheetActualValues, setHeadSheetActualValues] =
    useState<IHeadSheetActualDTO>();
  const [spacePressurizationValues, setSpacePressurizationValues] =
    useState<IHeadSheetActualSpacePressurizationDTO[]>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [projectReportPurchase, setProjectReportPurchase] =
    useState<IProjectPurchase | null>(null);
  const isDarkTheme = useTheme().palette.mode === "dark";

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 6;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(dataResult.projectId);
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          setValues({ ...dataResult });
          executeProject(dataResult.projectId);
          executeStatic(dataResult.finalStaticReportId || 0);
          if (dataResult.headSheetId)
            await executeHeadSheet(dataResult.headSheetId);
        };
        getData();
      },
    }
  );

  const { execute: executeStatic, isLoading: isLoadingStatic } =
    useAsyncQuery<IStaticReportDTO>(staticReportService.getById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setStaticValues({ ...dataResult });
          if (dataResult?.staticIdent?.length > 0) {
            const filtersTemp = dataResult?.staticIdent?.filter(
              (x: IStaticIdentDTO) => x.upStaticRow?.category === "Filters"
            );
            setFilters(filtersTemp);
          }
        };
        getData();
      },
    });

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectSingle, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
        };
        getData();
      },
    });

  const { execute: executeParent, isLoading: isLoadingParentReport } =
    useAsyncQuery<IProjectReport>(projectReportService.GetParentReport, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setParentStudy(dataResult);
        };
        getData();
      },
    });

  const { execute: executeEndFinalTest } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endFinalTest,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              fInalTestTimeAir: dataResult,
            });
            if (staticValues) {
              await staticReportService.updateIdent(
                staticValues.id,
                staticValues.staticIdent as IStaticIdentDTO[]
              );
            }
          };
          getData();
        },
      }
    );

  const { execute: executeHeadSheet, isLoading: isLoadingHeadSheet } =
    useAsyncQuery<IEETReportServiceModelDTO>(headSheetService.getStepFour, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          if (dataResult.fans)
            executeFanMotor(dataResult.id, dataResult.fans[0].key);
        };
        getData();
      },
    });

  const { execute: executeFanMotor, isLoading: isLoadingFanMotor } =
    useAsyncQuery<IHeadSheetActualFormulaDTO>(headSheetService.getActuals, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const { actual } = dataResult;
          setHeadSheetActualValues(actual);
          executeSpacePressurization(actual.id);
          window.scrollTo(0, 0);
        };
        getData();
      },
    });

  const {
    execute: executeSpacePressurization,
    isLoading: isLoadingSpacePressurization,
  } = useAsyncQuery<IHeadSheetActualSpacePressurizationDTO[]>(
    headSheetActualSpacePressurizationService.getByHeadSheetActualId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setSpacePressurizationValues(dataResult);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) {
      executeReportType(values?.reportTypeId);
      executeParent(id, values?.reportTypeId);
    }
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    const getData = async () => {
      await getInfectiousControlData(dataReport.id);
      await getProjectReportPurchaseData(dataReport.id);
    };

    if (dataReport?.id) getData();
  }, [dataReport?.id, values?.isComplete]);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    if (!isDialogOpen) getData();
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      if (showSecondPart()) await saveSpacePressurizationHandle();
    } catch (error) {}
  };

  const saveEnergyHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        if (staticValues) {
          await staticReportService.updateIdent(
            staticValues.id,
            staticValues.staticIdent as IStaticIdentDTO[]
          );
        }
        await energyStudyAirHydronicsPutService.update(id, values);
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveSpacePressurizationHandle = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        spacePressurizationValues?.forEach(async (spacePressurization) => {
          await headSheetActualSpacePressurizationService.update(
            spacePressurization.id,
            spacePressurization
          );
        });
        log.success("Space pressurization data was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/EnergyStudyAirHydronics/step7/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/EnergyStudyAirHydronics/step5/${id}`);
    } catch (error) {}
  };

  const showFirstPart = () => !!values?.finalTestTimeH2O;
  const showSecondPart = () => showFirstPart() && !!values?.fInalTestTimeAir;

  const handleNoHeatTransferDeviceWarningClose = () =>
    setOpenNoHeatTransferDeviceWarning(false);

  const handleNoHeatTransferDeviceWarningOk = async () => {
    setOpenNoHeatTransferDeviceWarning(false);
    await executeEndFinalTest(id, values);
  };

  const handleNoHeatTransferDeviceSelectedWarningClose = () =>
    setOpenNoHeatTransferDeviceSelectedWarning(false);

  const handleNoHeatTransferDeviceSelectedWarningOk = async () => {
    setOpenNoHeatTransferDeviceSelectedWarning(false);
    await executeEndFinalTest(id, values);
  };

  const handleProceedWithoutCoil = (e: any) => {
    setProceedWithoutCoil(e.target.value);
    if (e.target.vale) {
      const now = new Date(Date.now());
      setValues({
        ...values,
        noCoilAgreementSignature: `${user?.userName} - ${now.toLocaleString()}`,
      });
    }
  };

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReportPurchaseData = async (reportId: number) => {
    try {
      const res = await projectReportService.getProjectReportPurchase(reportId);
      setProjectReportPurchase(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#0288d1", // Change to your desired color
    color: "white",
    borderRadius: 5,
  };

  const leftStyle = {
    fontWeight: "bold",
    fontSize: "18px",
  };

  const rightStyle = {
    fontSize: "16px",
  };

  const showSummary = useMemo(
    () =>
      //true,
      values?.isComplete &&
      ((infectiousComplianceControl?.projectPurchase != null &&
        infectiousComplianceControl?.projectPurchase?.paymentStatus ===
          "Paid") ||
        (!project?.isInfectiousControlProject &&
          projectReportPurchase?.paymentStatus === "Paid")),
    [
      project?.isInfectiousControlProject,
      infectiousComplianceControl?.projectPurchase,
      infectiousComplianceControl?.projectPurchase?.paymentStatus,
      projectReportPurchase?.paymentStatus,
      values?.isComplete,
    ]
  );

  const airSummaryText = useMemo(() => {
    if (values?.systemIsMA)
      return `System is at ${(values?.oaPercentResult || 0).toLocaleString(
        "en-US",
        {
          useGrouping: true,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}% O/A and ${(values?.raPercentResult || 0).toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}% RA`;
    else if (values?.systemIs100OA) return "System is 100% OA";
    return "System is 100% RA";
  }, [values?.systemIs100OA, values?.systemIs100RA, values?.systemIsMA]);

  const labelColor = (
    value: number,
    lowerRange = 97.5,
    higherRange = 102.5
  ) => {
    if (value < lowerRange || value > higherRange) return "red";
    if (value > 0) return "green";
    return "inherit";
  };

  const summaryDataBlock = (
    title: string,
    attribute: keyof IEnergyStudyAirHydronicsDTO | undefined,
    validateShowSummary?: boolean,
    colorAttr?: keyof IEnergyStudyAirHydronicsDTO | undefined,
    helperText?: string,
    factor = 1,
    colorValidatorLower = 97.5,
    colorValidatorHigher = 102.5,
    customContent?: string,
    customColor?: string
  ) => {
    if (values) {
      if (showSummary || !validateShowSummary) {
        const value = !!attribute
          ? ((values[attribute] as number) || 0) * factor
          : 0;
        return (
          <>
            <Grid item xs={6} style={{ paddingLeft: 40 }}>
              <p style={{ fontWeight: "bold" }}>{title}</p>
            </Grid>
            {!!customContent ? (
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <p
                  style={{
                    color: !!customColor
                      ? customColor
                      : !!colorAttr
                      ? labelColor(
                          (values[colorAttr] as unknown as number) || 0,
                          colorValidatorLower,
                          colorValidatorHigher
                        )
                      : "inherit",
                  }}
                >
                  {customContent}
                </p>
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                style={{ textAlign: "right", paddingRight: 200 }}
              >
                <p
                  style={{
                    color: !!customColor
                      ? customColor
                      : !!colorAttr
                      ? labelColor(
                          (values[colorAttr] as unknown as number) || 0,
                          colorValidatorLower,
                          colorValidatorHigher
                        )
                      : "inherit",
                  }}
                >
                  {value.toLocaleString("en-US", {
                    useGrouping: true,
                    minimumFractionDigits: 3,
                  })}
                </p>
              </Grid>
            )}
            {!!helperText && (
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: 40,
                  paddingRight: 200,
                }}
              >
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  {helperText}
                </p>
              </Grid>
            )}
          </>
        );
      }
      return (
        <>
          <Grid item xs={6} style={{ paddingLeft: 40 }}>
            <p style={{ fontWeight: "bold" }}>{title}</p>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Chip
              color="default"
              label="Data will be available when payment is received"
            />
          </Grid>
        </>
      );
    }
  };

  const designGPM = useMemo(() => {
    if (values?.testMode === "Cooling") return values?.coolingCoilGPM;
    return values?.heatingCoilGPM;
  }, [values?.testMode]);

  return (
    <>
      <Popup
        title="Important"
        openPopup={openNoHeatTransferDeviceWarning}
        setOpenPopup={setOpenNoHeatTransferDeviceWarning}
        onClose={handleNoHeatTransferDeviceWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            You have not added a heat transfer device to perform a static
            pressure drop across the coil being tested.
            <br />
            Please return and add the heat transfer device being tested.
          </p>
          <FormCheckBox
            name="proceedWithoutCoil"
            label="I understand that moving forward without adding a Heat Transfer Device will cause the results of this Report to be incomplete."
            size="medium"
            onChange={handleProceedWithoutCoil}
            value={proceedWithoutCoil}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleNoHeatTransferDeviceWarningOk}
            autoFocus
            disabled={!proceedWithoutCoil}
          >
            Keep moving forward
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleNoHeatTransferDeviceWarningClose}
            autoFocus
          >
            Close to add a Coil
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openNoHeatTransferDeviceSelectedWarning}
        setOpenPopup={setOpenNoHeatTransferDeviceSelectedWarning}
        onClose={handleNoHeatTransferDeviceSelectedWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            Prior to moving forward, please select the coil that needs to be
            tested.
          </p>
          <FormCheckBox
            name="proceedWithoutCoil"
            label="I understand that moving forward without adding a Heat Transfer Device will cause the results of this Report to be incomplete."
            size="medium"
            onChange={handleProceedWithoutCoil}
            value={proceedWithoutCoil}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleNoHeatTransferDeviceSelectedWarningOk}
            autoFocus
            disabled={!proceedWithoutCoil}
          >
            Keep moving forward
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleNoHeatTransferDeviceSelectedWarningClose}
            autoFocus
          >
            Close to select a Coil
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <h2 style={{ textAlign: "center" }}>SUMMARY OF RESULTS</h2>
              {values?.soaNeeded && showSummary && (
                <Alert severity="error">
                  During the "Energy Off" testing phase, the Mixed Air (M/A)
                  temperature was calculated and found to be lower than the
                  combined temperatures of the Outside Air (O/A) and Return Air
                  (R/A). This is typically an indication of bleed-through in the
                  chilled water coil. AMP establishes its baseline temperature
                  using the lower of the recorded O/A or R/A temperature.
                </Alert>
              )}
              <br />
              <Grid
                container
                spacing={0}
                alignItems="flex-start"
                alignContent="flex-start"
              >
                {/**OA */}
                <Grid item xs={12}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>System OA/RA</div>
                    <div style={rightStyle}>{airSummaryText}</div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      `(Required) O/A ${systemOfMeasurement.get("cfm")}:`,
                      "outsideAirflow"
                    )}
                    {summaryDataBlock(
                      `(Actual) O/A ${systemOfMeasurement.get("cfm")}:`,
                      "oaResultantCFM",
                      true,
                      "oacfmPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) O/A ${systemOfMeasurement.get(
                        "cfm"
                      )} %:`,
                      "oacfmPercentageOfDesign",
                      true,
                      "oacfmPercentageOfDesign"
                    )}
                    {summaryDataBlock("(%) O/A:", "oaPercentResult", true)}
                  </Grid>
                  {(values?.oacfmPercentageOfDesign || 0) < 100 &&
                    values?.recordOA &&
                    showSummary && (
                      <Alert severity="warning">
                        Adjust {systemOfMeasurement.get("cfm")} Outside Airiflow
                        Up to{" "}
                        {(values?.outsideAirflow || 0).toLocaleString("en-US", {
                          useGrouping: true,
                          minimumFractionDigits: 2,
                        })}{" "}
                        to Maximize Energy Efficiency and Heat Trnasfer
                      </Alert>
                    )}
                  {(values?.oacfmPercentageOfDesign || 0) > 100 &&
                    values?.recordOA &&
                    showSummary && (
                      <Alert severity="warning">
                        Adjust {systemOfMeasurement.get("cfm")} Outside Airiflow
                        Down to{" "}
                        {(values?.outsideAirflow || 0).toLocaleString("en-US", {
                          useGrouping: true,
                          minimumFractionDigits: 2,
                        })}{" "}
                        to Maximize Energy Efficiency and Heat Trnasfer
                      </Alert>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      `(Required) R/A ${systemOfMeasurement.get("cfm")}:`,
                      "returnAirflow"
                    )}
                    {summaryDataBlock(
                      `(Actual) R/A ${systemOfMeasurement.get("cfm")}:`,
                      "raResultantCFM",
                      true,
                      "racfmPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) R/A ${systemOfMeasurement.get(
                        "cfm"
                      )} %:`,
                      "racfmPercentageOfDesign",
                      true,
                      "racfmPercentageOfDesign"
                    )}
                    {summaryDataBlock("(%) R/A:", "raPercentResult", true)}
                  </Grid>
                </Grid>
                {/**System CFM */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>
                      System {systemOfMeasurement.get("cfm")}
                    </div>
                    {values?.testMode === "Cooling" && (
                      <div style={rightStyle}>
                        Coil rated for a {systemOfMeasurement.get("cfm")} range
                        of
                      </div>
                    )}
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      `(Required) System ${systemOfMeasurement.get("cfm")}:`,
                      "systemCFM"
                    )}
                    {summaryDataBlock(
                      `(Actual) System ${systemOfMeasurement.get("cfm")}:`,
                      "calculatedCFM",
                      true,
                      "cfmPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) System ${systemOfMeasurement.get(
                        "cfm"
                      )} %:`,
                      "cfmPercentageOfDesign",
                      true,
                      "cfmPercentageOfDesign"
                    )}
                    {!!values?.coilFoulingFactorAir &&
                      summaryDataBlock(
                        "(Air) Coil Fouling Factor %:",
                        "coilFoulingFactorAir",
                        true,
                        undefined,
                        "Coil Fouling: Energy Heat Transfer is below design"
                      )}
                    {!!values?.energyWasteFactorAir &&
                      summaryDataBlock(
                        "(Air) Energy Waste Factor %:",
                        "energyWasteFactorAir",
                        true,
                        "energyWasteFactorAir",
                        "Energy Waste: Fluid Flow exceeds design",
                        100,
                        0,
                        0.035
                      )}
                    {!!values?.bhpReductionFactorAir &&
                      summaryDataBlock(
                        `(Air) Operating ${systemOfMeasurement.get(
                          "bhp"
                        )} can drop %:`,
                        "bhpReductionFactorAir",
                        true,
                        undefined,
                        "",
                        100
                      )}
                    {!!values?.spReductionFactorAir &&
                      summaryDataBlock(
                        "(Air) Pressure can drop %",
                        "spReductionFactorAir",
                        true,
                        undefined,
                        "",
                        100
                      )}
                  </Grid>
                  {(values?.cfmPercentageOfDesign || 0) < 100 && showSummary && (
                    <Alert severity="warning">
                      Adjust {systemOfMeasurement.get("cfm")} Airiflow Up to{" "}
                      {(values?.systemCFM || 0).toLocaleString("en-US", {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                      })}{" "}
                      to Maximize Energy Efficiency and Heat Trnasfer
                    </Alert>
                  )}
                  {(values?.cfmPercentageOfDesign || 0) > 100 && showSummary && (
                    <Alert severity="warning">
                      Adjust {systemOfMeasurement.get("cfm")} Airiflow Down to{" "}
                      {(values?.systemCFM || 0).toLocaleString("en-US", {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                      })}{" "}
                      to Maximize Energy Efficiency and Heat Trnasfer
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {values?.testMode === "Cooling" && (
                    <>
                      <Grid container spacing={0}>
                        {summaryDataBlock(
                          `(Standard airflow @ 500 ${systemOfMeasurement.get(
                            "fpm"
                          )}) ${systemOfMeasurement.get("cfm")}:`,
                          "cfmRangeLower"
                        )}
                        {summaryDataBlock(
                          `(Maximum airflow @ 550 ${systemOfMeasurement.get(
                            "fpm"
                          )}) ${systemOfMeasurement.get("cfm")}:`,
                          "cfmRangeHigher",
                          true
                        )}
                      </Grid>
                      {(values?.calculatedCFM || 0) >
                        (values?.cfmRangeHigher || 0) && (
                        <Alert severity="error">
                          Warning: Water Coil carry over and water damage
                          potential may occur beyond this{" "}
                          {systemOfMeasurement.get("cfm")}
                        </Alert>
                      )}
                    </>
                  )}
                </Grid>
                {/**System GPM */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>
                      System {systemOfMeasurement.get("gpm")}
                    </div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {values?.testMode === "Cooling" &&
                      summaryDataBlock(
                        `(Required) System ${systemOfMeasurement.get("gpm")}:`,
                        "coolingCoilGPM"
                      )}
                    {values?.testMode === "Heating" &&
                      summaryDataBlock(
                        `(Required) System ${systemOfMeasurement.get("gpm")}:`,
                        "heatingCoilGPM"
                      )}
                    {summaryDataBlock(
                      `(Actual) System ${systemOfMeasurement.get("gpm")}:`,
                      "gpms",
                      true,
                      "gpmPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) System ${systemOfMeasurement.get(
                        "gpm"
                      )} %:`,
                      "gpmPercentageOfDesign",
                      true,
                      "gpmPercentageOfDesign"
                    )}
                  </Grid>
                  {(values?.gpmPercentageOfDesign || 0) < 100 && showSummary && (
                    <Alert severity="warning">
                      Adjust {systemOfMeasurement.get("gpm")} Flow Up to{" "}
                      {(designGPM || 0).toLocaleString("en-US", {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                      })}{" "}
                      to Maximize Energy Efficiency and Heat Trnasfer
                    </Alert>
                  )}
                  {(values?.gpmPercentageOfDesign || 0) > 100 && showSummary && (
                    <Alert severity="warning">
                      Adjust {systemOfMeasurement.get("gpm")} Airiflow Down to{" "}
                      {(designGPM || 0).toLocaleString("en-US", {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                      })}{" "}
                      to Maximize Energy Efficiency and Heat Trnasfer
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {!!values?.coilFoulingFactorWater &&
                      summaryDataBlock(
                        "(Water) Coil Fouling Factor %:",
                        "coilFoulingFactorWater",
                        true
                      )}
                    {!!values?.btUhDeficitWater &&
                      summaryDataBlock(
                        `(Water) ${systemOfMeasurement.get("btuh")} Deficit:`,
                        "btUhDeficitWater",
                        true
                      )}
                    {!!values?.energyWasteFactorWater &&
                      summaryDataBlock(
                        "(Water) Energy Waste Factor %:",
                        "energyWasteFactorWater",
                        true,
                        "energyWasteFactorWater",
                        "",
                        100,
                        0,
                        0.035
                      )}
                    {!!values?.bhpReductionFactorWater &&
                      summaryDataBlock(
                        `(Water) Operating ${systemOfMeasurement.get(
                          "bhp"
                        )} can drop %:`,
                        "bhpReductionFactorWater",
                        true,
                        undefined,
                        "",
                        100
                      )}
                    {!!values?.spReductionFactorWater &&
                      summaryDataBlock(
                        "(Water) Pressure can drop %",
                        "spReductionFactorWater",
                        true,
                        undefined,
                        "",
                        100
                      )}
                  </Grid>
                </Grid>
                {/**Chilled Water T */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>
                      System Chilled Water - ΔT{" "}
                      {systemOfMeasurement.get("temperature")}
                    </div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {values?.testMode === "Cooling" &&
                      summaryDataBlock(
                        `(Required) Chilled Water ΔT ${systemOfMeasurement.get(
                          "temperature"
                        )}:`,
                        "hydronicFluidFlowDeltaTemperatureCooling"
                      )}
                    {values?.testMode === "Heating" &&
                      summaryDataBlock(
                        `(Required) Chilled Water ΔT ${systemOfMeasurement.get(
                          "temperature"
                        )}:`,
                        "hydronicFluidFlowDeltaTemperatureHeating"
                      )}
                    {summaryDataBlock(
                      `(Actual) Chilled Water ΔT ${systemOfMeasurement.get(
                        "temperature"
                      )}:`,
                      "waterDeltaT",
                      true,
                      "atfPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) Chilled Water ΔT ${systemOfMeasurement.get(
                        "temperature"
                      )} %:`,
                      "atfPercentageOfDesign",
                      true,
                      "atfPercentageOfDesign"
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {values?.testMode === "Cooling" &&
                      summaryDataBlock(
                        "(Design) Entering Water Temperature:",
                        "enteringHydronicCoolingCoilTemperature",
                        true
                      )}
                    {values?.testMode === "Heating" &&
                      summaryDataBlock(
                        "(Design) Entering Water Temperature:",
                        "enteringHydronicHeatingCoilTemperature",
                        true
                      )}
                    {values?.testMode === "Cooling" &&
                      summaryDataBlock(
                        "(Actual) Entering Water Temperature:",
                        "enteringWaterTempToCoil",
                        true,
                        "enteringWaterTempToCoil",
                        "",
                        1,
                        0,
                        (values?.enteringHydronicCoolingCoilTemperature || 0) +
                          0.5
                      )}
                    {values?.testMode === "Heating" &&
                      summaryDataBlock(
                        "(Actual) Entering Water Temperature:",
                        "enteringWaterTempToCoil",
                        true,
                        "enteringWaterTempToCoil",
                        "",
                        100,
                        (values?.enteringHydronicHeatingCoilTemperature || 0) -
                          0.5,
                        999999999
                      )}
                  </Grid>
                  {(values?.enteringWaterTempToCoil || 0) >
                    (values?.enteringHydronicCoolingCoilTemperature || 0) &&
                    values?.testMode === "Cooling" &&
                    showSummary && (
                      <Alert severity="warning">
                        <span style={{ color: "red" }}>WARNING:</span> Actual
                        Water Temprerature Delivery exceeds design
                      </Alert>
                    )}
                  {(values?.enteringWaterTempToCoil || 0) <
                    (values?.enteringHydronicCoolingCoilTemperature || 0) &&
                    values?.testMode === "Heating" &&
                    showSummary && (
                      <Alert severity="warning">
                        <span style={{ color: "red" }}>WARNING:</span> Actual
                        Actual Water Temprerature Delivery is below design
                      </Alert>
                    )}
                </Grid>
                {/**System BTUH */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>
                      System {systemOfMeasurement.get("btuh")}
                    </div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {values?.testMode === "Cooling" &&
                      values?.referenceTotalBTUH !== "AHU" &&
                      summaryDataBlock(
                        `(Required) System Total ${systemOfMeasurement.get(
                          "btuh"
                        )}:`,
                        "totalBTUHCooling"
                      )}
                    {values?.testMode === "Cooling" &&
                      values?.referenceTotalBTUH === "AHU" &&
                      summaryDataBlock(
                        `(Required) System Total ${systemOfMeasurement.get(
                          "btuh"
                        )}:`,
                        "sytemTotalBTUH"
                      )}
                    {values?.testMode === "Heating" &&
                      values?.referenceTotalBTUH !== "AHU" &&
                      summaryDataBlock(
                        `(Required) System Total ${systemOfMeasurement.get(
                          "btuh"
                        )}:`,
                        "totalBTUHHeating"
                      )}
                    {values?.testMode === "Heating" &&
                      values?.referenceTotalBTUH === "AHU" &&
                      summaryDataBlock(
                        `(Required) System Total ${systemOfMeasurement.get(
                          "btuh"
                        )}:`,
                        "heatingSytemTotalBTUH"
                      )}
                    {summaryDataBlock(
                      `(Actual) System Total ${systemOfMeasurement.get(
                        "btuh"
                      )}:`,
                      "systemBTUHActual",
                      true,
                      "systemBTUHPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) ${systemOfMeasurement.get(
                        "btuh"
                      )} %:`,
                      "systemBTUHPercentageOfDesign",
                      true,
                      "systemBTUHPercentageOfDesign"
                    )}
                    {values?.motorAirstream &&
                      summaryDataBlock(
                        `(Actual) Motor Heat Added to Air ${systemOfMeasurement.get(
                          "btuh"
                        )}:`,
                        "motorHeatAddedToAir",
                        true
                      )}
                    {values?.motorAirstream &&
                      values?.testMode === "Cooling" &&
                      summaryDataBlock(
                        `(Actual) ${systemOfMeasurement.get(
                          "btuh"
                        )} Cooling Summer Deficit Tons:`,
                        "toonsCoolingSummerDeficit",
                        true
                      )}
                  </Grid>
                </Grid>
                {/**Coil Delta T Air DB Temperature */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>Coil Delta T Air DB Temperature</div>
                    <div style={rightStyle}>
                      NOTE:{" "}
                      {values?.motorAirstream ? "HOC and Motor Heat" : "HOC"}{" "}
                      considered for calculation
                    </div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {values?.testMode === "Cooling" &&
                      summaryDataBlock(
                        `(Required) ΔT Air DB ${systemOfMeasurement.get(
                          "temperature"
                        )}:`,
                        "deltaAirTemperatureDBCooling"
                      )}
                    {values?.testMode === "Heating" &&
                      summaryDataBlock(
                        `(Required) ΔT Air DB ${systemOfMeasurement.get(
                          "temperature"
                        )}:`,
                        "deltaAirTemperatureDBHeating"
                      )}
                    {summaryDataBlock(
                      `(Actual) System ${systemOfMeasurement.get("cfm")}:`,
                      "atfdb",
                      true,
                      "atfdbPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) ΔT Air DB ${systemOfMeasurement.get(
                        "temperature"
                      )} %:`,
                      "atfdbPercentageOfDesign",
                      true,
                      "atfdbPercentageOfDesign"
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      "Air Upstream Coil DB :",
                      "upStreamCoilAirTemperatureDryBulb",
                      true
                    )}
                    {summaryDataBlock(
                      "Air Downstream Coil DB",
                      "downStreamCoilAirTemperatureDryBulb",
                      true
                    )}
                  </Grid>
                  {(values?.calculatedCFM || 0) >
                    (values?.cfmRangeHigher || 0) &&
                    values?.testMode === "Cooling" && (
                      <Alert severity="error">
                        Warning: Water Coil Carryover May Occur
                      </Alert>
                    )}
                </Grid>
                {/**Upstream Coil Entering Enthalpy */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>Upstream Coil Entering Enthalpy</div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      "(Required) Enthalpy / h:",
                      "oaDesignEnthlpy"
                    )}
                    {summaryDataBlock(
                      "(Actual) Upstream Entering Coil Air Enthalpy / h:",
                      "maEnthalpyEnergyOff",
                      true,
                      "enthalpyPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      "(Percentage of design) Enthalpy / h %:",
                      "enthalpyPercentageOfDesign",
                      true,
                      "enthalpyPercentageOfDesign"
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {(values?.enthalpyPercentageOfDesign || 0) < 100 &&
                    showSummary && (
                      <Alert severity="warning">
                        <span style={{ fontWeight: "bolder", color: "blue" }}>
                          Note:
                        </span>{" "}
                        At time of current testing the actual upstream caloric
                        Enthalpy value was at{" "}
                        <span style={{ color: "red" }}>
                          {(
                            values?.enthalpyPercentageOfDesign || 0
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          %
                        </span>{" "}
                        of the systems designed capacity of Heat Transfer.
                        During the test the total caloric Enthalpy value was{" "}
                        <span style={{ color: "red" }}>
                          {(
                            100 - (values?.enthalpyPercentageOfDesign || 0)
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          %
                        </span>{" "}
                        <span style={{ fontWeight: "bold", color: "blue" }}>
                          below
                        </span>{" "}
                        the full design Heat Transfer Challenge for this coil.
                        Expect the other test results to be not greater than{" "}
                        {(
                          values?.enthalpyPercentageOfDesign || 0
                        ).toLocaleString("en-US", {
                          useGrouping: true,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        % of design heat transfer values.
                      </Alert>
                    )}
                  {(values?.enthalpyPercentageOfDesign || 0) > 100 &&
                    showSummary && (
                      <Alert severity="warning">
                        <span style={{ fontWeight: "bolder", color: "blue" }}>
                          Note:
                        </span>{" "}
                        At time of current testing the actual upstream caloric
                        Enthalpy value was at{" "}
                        <span style={{ color: "red" }}>
                          {(
                            values?.enthalpyPercentageOfDesign || 0
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          %
                        </span>{" "}
                        of the systems designed capacity of Heat Transfer.
                        During the test the total caloric Enthalpy value was{" "}
                        <span style={{ color: "red" }}>
                          {(
                            (100 - (values?.enthalpyPercentageOfDesign || 0)) *
                            -1
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          %
                        </span>{" "}
                        <span style={{ fontWeight: "bold", color: "blue" }}>
                          above
                        </span>{" "}
                        the full design Heat Transfer Challenge for this coil.
                        Expect the other test results to be approximately @ 100%
                        or greater than the design heat transfer values.
                      </Alert>
                    )}
                </Grid>
                {/**System Coil Static Pressure */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>System Coil Static Pressure</div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      "(Required) Coil Static Pressure Factor:",
                      "acutalTestedCoilStaticPressureFactor",
                      true
                    )}
                    {summaryDataBlock(
                      "(Actual) Coil Static Pressure:",
                      "actualTestedCoilSP",
                      true,
                      "actualTestedCoilSP",
                      "",
                      1,
                      0,
                      values?.acutalTestedCoilStaticPressureFactor || 0
                    )}
                  </Grid>
                  {(values?.actualTestedCoilSP || 0) >=
                    (values?.acutalTestedCoilStaticPressureFactor || 0) &&
                    values?.recordOA &&
                    showSummary && (
                      <Alert severity="warning">
                        WARNING: This coil has potential surface blockage, we
                        recommend you to identifying if this coil needs
                        cleaning.
                      </Alert>
                    )}
                  {(values?.actualTestedCoilSP || 0) <
                    (values?.acutalTestedCoilStaticPressureFactor || 0) &&
                    values?.recordOA &&
                    showSummary && (
                      <Alert severity="info">
                        This coil appears to be clean on the surface
                      </Alert>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      "Fluid flow (air) loss % based on Actual Coil Pressure Drop:",
                      "spAcrossCoil",
                      true,
                      "spAcrossCoil",
                      "",
                      100,
                      0.05,
                      0.125
                    )}
                    {summaryDataBlock(
                      "Fluid flow (water) loss % based on Actual Pressure Drop:",
                      "spThroughCoil",
                      true,
                      "spThroughCoil",
                      "",
                      100,
                      0.05,
                      0.125
                    )}
                  </Grid>
                </Grid>
                {/**Filters Schedule */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>System Filters Schedule</div>
                  </header>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer sx={{ maxHeight: 480 }} component={Paper}>
                    <Table stickyHeader aria-label="static components">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ minWidth: 300 }} />
                          <TableCell style={{ textAlign: "center" }}>
                            Expect Energy Consumption (Chillers, Boilers, HVAC
                            Fans, Pumps, etc.) to Increase Energy Consumption by
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center", minWidth: 600 }}
                          >
                            Expect Heat Transfer and Efficiency to drop
                          </TableCell>
                          {values?.isComplete &&
                            filters?.map((row, index, staticIdents) => (
                              <TableCell
                                key={`filters-schedule-header-${row.id}`}
                                style={{ textAlign: "center" }}
                              >
                                {row.upStaticRow?.component} Filter - Change @
                                indicated SP {systemOfMeasurement.get("inwc")}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <strong>Filters Changed @ 5% Loaded:</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>105.26%</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>5%</strong>
                          </TableCell>
                          {values?.isComplete &&
                            filters?.map((row, index, staticIdents) => (
                              <TableCell
                                key={`filters-schedule-header-${row.id}`}
                                style={{ textAlign: "center" }}
                              >
                                <Chip
                                  color="success"
                                  label={(
                                    row.filterChangeScheduleSP5 || 0
                                  ).toLocaleString("en-US", {
                                    useGrouping: true,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                        <TableRow
                          style={{
                            backgroundColor: isDarkTheme
                              ? "#243549"
                              : "#f3f3f3",
                          }}
                        >
                          <TableCell
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <strong>Filters Changed @ 10% Loaded:</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>111.11%</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>10%</strong>
                          </TableCell>
                          {values?.isComplete &&
                            filters?.map((row, index, staticIdents) => (
                              <TableCell
                                key={`filters-schedule-header-${row.id}`}
                                style={{ textAlign: "center" }}
                              >
                                <Chip
                                  color="info"
                                  label={(
                                    row.filterChangeScheduleSP10 || 0
                                  ).toLocaleString("en-US", {
                                    useGrouping: true,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <strong>Filters Changed @ 15% Loaded:</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>117.65%</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>15%</strong>
                          </TableCell>
                          {values?.isComplete &&
                            filters?.map((row, index, staticIdents) => (
                              <TableCell
                                key={`filters-schedule-header-${row.id}`}
                                style={{ textAlign: "center" }}
                              >
                                <Chip
                                  color="warning"
                                  label={(
                                    row.filterChangeScheduleSP15 || 0
                                  ).toLocaleString("en-US", {
                                    useGrouping: true,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                        <TableRow
                          style={{
                            backgroundColor: isDarkTheme
                              ? "#243549"
                              : "#f3f3f3",
                          }}
                        >
                          <TableCell
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <strong>Filters Changed @ 20% Loaded:</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>125.00%</strong>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <strong style={{ color: "red" }}>20%</strong>
                          </TableCell>
                          {values?.isComplete &&
                            filters?.map((row, index, staticIdents) => (
                              <TableCell
                                key={`filters-schedule-header-${row.id}`}
                                style={{ textAlign: "center" }}
                              >
                                <Chip
                                  color="error"
                                  label={(
                                    row.filterChangeScheduleSP20 || 0
                                  ).toLocaleString("en-US", {
                                    useGrouping: true,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/**Building Pressure */}
                <Grid item xs={12} marginTop={5}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>Building Pressure</div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {project?.buildingPressurizationId === 1 &&
                      summaryDataBlock(
                        "(Design) Positive Building Pressure Minimum:",
                        undefined,
                        true,
                        undefined,
                        "",
                        1,
                        97.5,
                        102.5,
                        '+ 0.02" WC to + 0.05" WC',
                        "darkgreen"
                      )}
                    {project?.buildingPressurizationId === 2 &&
                      summaryDataBlock(
                        "(Design) Negative Building Pressure Minimum:",
                        undefined,
                        true,
                        undefined,
                        "",
                        1,
                        97.5,
                        102.5,
                        '- 0.02" WC to - 0.05" WC',
                        "red"
                      )}
                    {project?.buildingPressurizationId !== 1 &&
                      project?.buildingPressurizationId !== 2 &&
                      summaryDataBlock(
                        "(Design) Combination Building Pressure Minimum:",
                        undefined,
                        true,
                        undefined,
                        "",
                        1,
                        97.5,
                        102.5,
                        '+- 0.02" WC to +- 0.05" WC',
                        "darkorange"
                      )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {headSheetActualValues?.id && spacePressurizationValues && (
                    <>
                      <h4 style={{ marginLeft: 40 }}>
                        (Actual) Building Pressure Data
                      </h4>
                      <SpacePressurization
                        headSheetActualId={headSheetActualValues?.id}
                        disableStudy={disableStudy()}
                        isLoading={isLoading}
                        isLoadingSpacePressurization={
                          isLoadingSpacePressurization
                        }
                        systemOfMeasurement={systemOfMeasurement}
                        spacePressurizationValues={spacePressurizationValues}
                        setSpacePressurizationValues={
                          setSpacePressurizationValues
                        }
                        readOnly
                      />
                    </>
                  )}
                </Grid>
                {/**AMP Factor */}
                <Grid item xs={12}>
                  <header style={headerStyle}>
                    <div style={leftStyle}>AMP Factor</div>
                  </header>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      "This systems specific AMP Outside Air (O/A) Factor:",
                      "ampFactorSteadyOff",
                      true
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {showSummary && (
                    <Alert severity="info">
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        WARNING:
                      </span>{" "}
                      We recommend setting up your O/A prior to making any
                      prescribed changes or finalizing this system. This factor
                      provided is a precision factor specific to this systems
                      current operation.
                      <span style={{ fontWeight: "bold" }}>
                        Your AMP factor can be used at different O/A and Return
                        Air (R/A) temperatures prior to changing any motor
                        speeds or Motor Horse Power/(
                        {systemOfMeasurement.get("bhp")}).
                      </span>
                      Go to your AMP built in calculator tab "E=mc2" and click
                      on "% O/A {systemOfMeasurement.get("temperature")}" and
                      enter your AMP system specific O/A Factor.
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: 20 }} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  {project && dataReport && values && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={dataReport}
                      completed={values?.isComplete}
                      infectiousComplianceControl={infectiousComplianceControl}
                      parentStudy={parentStudy ?? null}
                      hideReopenStudyButton
                    />
                  )}
                </Grid>
                {showSummary && (
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <RetestReport
                      reportId={id}
                      projectId={values?.projectId}
                      reportTypeId={values?.reportTypeId || 18}
                      isInfectiousControlProject={
                        project?.isInfectiousControlProject || false
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={false}
                disabled={false}
                reportName={title as string}
                previousStepHandler={previousStepHandler}
                nextStepHandler={nextStepHandler}
                disableNext={true || !showSecondPart()}
                saveHandler={saveHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyHydronicsStep7;
