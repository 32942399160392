import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import {
  useForm,
  Form,
  useLog,
  usePermissions,
  useAppSelector,
} from "src/hooks";
import {
  FormButton,
  FormCheckBox,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { CompanyService, WalletService } from "src/services";
import { ICompany, IWalletList } from "src/ts/interfaces";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import HeaderPage from "src/components/page/HeaderPage";
import CreditCardIconSelector from "src/components/icons/CreditCardIcon";
import {
  AddIcon,
  StarBorderIcon,
  StarIcon,
  ViewListIcon,
} from "src/components/icons";
import SatellitePopup from "../company/components/SatellitePopup";
import FormNumeric from "src/components/formControls/FormNumeric";
import FormTooltip from "src/components/formControls/FormTooltip";
import AddFundsPopUp from "./AddFundsPopUp";
import CurrencyFormat from "src/components/format/CurrencyFormat";
import CompanyInformation from "../project/createEditProject/components/CompanyInformation";
import { Permission as PermissionTypes } from "src/ts/enums";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
  deactivationReason: "",
};

const columns = (
  handleEdit: (cardId: number) => void,
  HandleAddFunds: (cardId: number) => void,
  isWalletBlocked: boolean,
  handleDisable: (cardId: number, isActive: boolean) => void,
  HandleDefault: (cardId: number) => void,
  handleDelete: (cardId: number) => void,
  isActive: boolean,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "alias",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "cardNumber",
    label: "Card Number",
    type: "string",
    sort: true,
  },
  {
    id: "cardType",
    label: "Type",
    type: "custom",
    sort: false,
    callback: (row: IWalletList) => {
      return <CreditCardIconSelector typeCreditCard={row.cardType} />;
    },
  },
  {
    id: "name",
    label: "Name on Card",
    type: "custom",
    sort: true,
    callback: (row: IWalletList) => {
      return (
        <>
          {row.name} {row.lastName}
        </>
      );
    },
  },
  {
    id: "updatedDate",
    label: "Deactivated Date",
    type: "utcDate",
    sort: true,
    hide: isActive,
    format: "MM/DD/yyyy",
  },
  {
    id: "default",
    label: "Default",
    type: "custom",
    sort: true,
    align: "center",
    hide: !isActive,
    callback: (row: IWalletList) => {
      return (
        <div style={{ textAlign: "center" }}>
          {!row.default && <StarBorderIcon />}
          {row.default && <StarIcon color="success" />}
        </div>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    width: "300px",
    align: "left",
    callback: (row: IWalletList) => {
      return (
        <div style={{ textAlign: "left" }}>
          {!isWalletBlocked && (
            <>
              {row.isActive && (
                <GridActionButton
                  type="money"
                  onClick={() => {
                    HandleAddFunds(row.id);
                  }}
                  tooltip="Add funds to wallet with this card"
                  disabled={!fullAccess}
                />
              )}
              <GridActionButton
                type={fullAccess ? "edit" : "view"}
                onClick={() => {
                  handleEdit(row.id);
                }}
                tooltip={fullAccess ? "Edit" : "View"}
              />
              {!row.isActive && !row.default && (
                <GridActionButton
                  type="enable"
                  onClick={() => {
                    handleDisable(row.id, row.isActive);
                  }}
                  tooltip="Enable"
                  disabled={!fullAccess}
                />
              )}
              {row.isActive && !row.default && (
                <GridActionButton
                  type="disable"
                  onClick={() => {
                    handleDisable(row.id, row.isActive);
                  }}
                  tooltip="Disable"
                  disabled={!fullAccess}
                />
              )}
              {row.isActive && !row.default && (
                <GridActionButton
                  type="star"
                  onClick={() => {
                    HandleDefault(row.id);
                  }}
                  tooltip="Make default card"
                  disabled={!fullAccess}
                />
              )}
              {!row.default && row.isActive && (
                <GridActionButton
                  type="delete"
                  onClick={() => {
                    handleDelete(row.id);
                  }}
                  tooltip="Delete"
                  disabled={!fullAccess}
                />
              )}
            </>
          )}
        </div>
      );
    },
  },
];

const WalletList = () => {
  let params = useParams<{
    companyId: string;
  }>();

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );
  const { fullAccess } = usePermissions(PermissionTypes.Wallet);
  const { fullAccess: fullAccessCard } = usePermissions(PermissionTypes.Card);
  const { execute, data, isLoading } = useAsyncQuery<IWalletList[]>(
    WalletService.getWalletList
  );
  const { execute: executeBalance, data: dataBalance } = useAsyncQuery<number>(
    WalletService.getWalletBalance
  );
  const {
    execute: executeCompany,
    data: dataCompany,
    setData: setDataCompany,
  } = useAsyncQuery<ICompany>(CompanyService.getById);
  const [isActive, setIsActive] = useState(true);
  const [showAddFundsPopup, setShowAddFundsPopup] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [selectedStatusCardId, setSelectedStatusCardId] = useState(0);
  const [isActiveCard, setIsActiveCard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [showConfirmDefault, setShowConfirmDefault] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const navigate = useNavigate();
  const { log } = useLog();
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const signalRStatus = useAppSelector((state) => state.signalR);

  useEffect(() => {
    if (signalRStatus.walletList > 0) {
      execute(companyId ?? 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRStatus.walletList]);

  useEffect(() => {
    const getData = async () => {
      await execute(companyId ?? 0);
      await executeCompany(companyId ?? 0);
      getWalletBalance();
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, companyId]);

  useEffect(() => {
    if (selectedCardId !== 0) setShowAddFundsPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCardId]);

  useEffect(() => {
    if (!showAddFundsPopup) setSelectedCardId(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddFundsPopup]);

  const getWalletBalance = () => {
    executeBalance(companyId ?? 0);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: boolean) => {
    setIsActive(newValue);
  };

  const handleEdit = (cardId: number) => {
    navigate(`/app/Wallet/Card/${cardId}/${companyId}`);
  };

  const showSatelliteHandler = async () => {
    setShowSatelliteModal(true);
  };

  const handleInputChangeCheck = (e: any) => {
    const str: keyof ICompany = e.target.name;
    if (e.target.name === "walletAdminBlocked" && !dataCompany?.[str]) {
      setDataCompany({
        ...dataCompany,
        [str]: !dataCompany?.[str],
        walletAutoReload: false,
        walletAutoWithdraw: false,
      });
    } else {
      setDataCompany({
        ...dataCompany,
        [str]: !dataCompany?.[str],
      });
    }
  };

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    if (e.target.name === "walletAutoReloadAmountList") {
      name = "walletAutoReloadAmount";
      if (e.target.value === 0) value = null;
    }

    setDataCompany({
      ...dataCompany,
      [name]: value,
    });
  };

  const handleSave = () => {
    setShowConfirmPopup(true);
  };

  const handleConfirmSave = async () => {
    setIsSubmitting(true);
    await CompanyService.updateWallerAutoReload(dataCompany);
    setIsSubmitting(false);
    log.success("Your AMP Wallet configuration was successfully updated.");
    getWalletBalance();
  };

  const reloadAmount = [
    { key: 50, value: "50.00" },
    { key: 100, value: "100.00" },
    { key: 250, value: "250.00" },
    { key: 500, value: "500.00" },
    { key: 1000, value: "1000.00" },
    { key: 1500, value: "1500.00" },
    { key: 2000, value: "2000.00" },
    { key: 2500, value: "2500.00" },
    { key: 3000, value: "3000.00" },
    { key: 5000, value: "5000.00" },
    { key: 7500, value: "7500.00" },
    { key: 10000, value: "10000.00" },
    { key: 0, value: "Other amount" },
  ];

  const isNotDropdown = (amount: number) => {
    return !reloadAmount.find(
      (element) => element.key === amount && amount !== 0
    );
  };

  const HandleAddFunds = (cardId: number) => {
    setSelectedCardId(cardId);
  };

  const handleConfirmDisable = async () => {
    setIsSubmitting(true);
    await WalletService.changeStatusCard(selectedStatusCardId, !isActiveCard);
    setIsSubmitting(false);
    setShowConfirmDisable(false);
    setRefreshPage(!refreshPage);
    log.success("Card status has been changed");
  };
  const handleDisable = (cardId: number, isActive: boolean) => {
    setSelectedStatusCardId(cardId);
    setIsActiveCard(isActive);
    setShowConfirmDisable(true);
  };

  const handleConfirmDefault = async () => {
    setIsSubmitting(true);
    await WalletService.changeDefaultCard(selectedStatusCardId);
    setIsSubmitting(false);
    setShowConfirmDefault(false);
    setRefreshPage(!refreshPage);
    log.success("Card status has been changed");
  };
  const handleDefault = (cardId: number) => {
    setSelectedStatusCardId(cardId);
    setShowConfirmDefault(true);
  };

  const handleConfirmDelete = async () => {
    setIsSubmitting(true);
    await WalletService.deleteCard(selectedStatusCardId);
    setIsSubmitting(false);
    setShowConfirmDelete(false);
    setRefreshPage(!refreshPage);
    log.success("Card was deleted");
  };

  const handleDelete = (cardId: number) => {
    setSelectedStatusCardId(cardId);
    setShowConfirmDelete(true);
  };

  const handleViewHistory = () => {
    navigate(`/app/WalletHistory/${companyId}`);
  };

  return (
    <>
      <HeaderPage
        title={"Wallet"}
        parentText={"Dashboard"}
        parentLink={`/`}
        pageYoutube="Wallet"
        actionSection={
          <Grid container>
            {fullAccessCard && (
              <Grid item p={1}>
                <FormButton
                  text={"Add New Card"}
                  onClick={() => {
                    handleEdit(0);
                  }}
                  startIcon={<AddIcon />}
                  size="small"
                  color="primary"
                  disabled={!fullAccess}
                />
              </Grid>
            )}
            {dataCompany?.headquarters &&
              dataCompany?.satelliteCount !== undefined &&
              dataCompany?.satelliteCount > 0 && (
                <Grid item p={1}>
                  <FormButton
                    text={"View Satellites"}
                    onClick={showSatelliteHandler}
                    startIcon={<ViewListIcon />}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              )}
          </Grid>
        }
        infoSection={
          !dataCompany?.headquarters && (
            <CompanyInformation company={dataCompany} />
          )
        }
      ></HeaderPage>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} textAlign={"center"}>
                  <Typography variant="h4">Actual Balance: </Typography>
                  <Typography variant="h6">
                    <CurrencyFormat value={dataBalance}></CurrencyFormat>
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} textAlign={"center"}>
                  <Typography variant="h4">Wallet Serial Number: </Typography>
                  <Typography variant="h6">
                    {dataCompany?.walletSerial}
                  </Typography>
                  {dataCompany?.walletBlocked && (
                    <Typography variant="h4">
                      Your AMP Wallet is blocked.
                    </Typography>
                  )}
                  {dataCompany?.walletAdminBlocked && (
                    <Typography variant="h4">
                      Your AMP Wallet has an Administrative Blockage.
                    </Typography>
                  )}
                  {dataCompany?.walletDemoEndpointEnabled && (
                    <Typography variant="h4">DEMO Mode.</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider my={3}></Divider>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                pt={"10px"}
                display={"inline-block"}
                textAlign={"right"}
              >
                <Typography variant="subtitle2" display={"inline-block"} pr={3}>
                  Auto reload my AMP wallet:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display={"inline"}>
                  <FormCheckBox
                    label="Automatically reload your balance when your secured funds drop to a minimum threshold. Applies to your selected default card. "
                    name="walletAutoReload"
                    value={dataCompany?.walletAutoReload ?? false}
                    onChange={handleInputChangeCheck}
                    disabled={dataCompany?.walletAdminBlocked || !fullAccess}
                  />
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FormTooltip titleName={"Auto reload wallet"} />
              </Grid>
            </Grid>
            {dataCompany?.walletAutoReload && (
              <Grid container>
                <Grid item xs={3}></Grid>
                <Grid item xs={5} pb={3} pt={2}>
                  <FormSelect
                    name="walletAutoReloadAmountList"
                    label="Amount"
                    value={
                      isNotDropdown(dataCompany?.walletAutoReloadAmount ?? 0)
                        ? "0"
                        : Number(dataCompany?.walletAutoReloadAmount)
                            ?.toFixed(0)
                            ?.toString()
                    }
                    onChange={handleInputChange}
                    options={reloadAmount}
                    showSkeleton={isLoading}
                    disabled={dataCompany?.walletAdminBlocked || !fullAccess}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {isNotDropdown(dataCompany?.walletAutoReloadAmount ?? 0) && (
                  <>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={5} pb={3}>
                      <FormNumeric
                        name={"walletAutoReloadAmount"}
                        label={"Enter Reload amount"}
                        value={
                          dataCompany?.walletAutoReloadAmount?.toString() ?? ""
                        }
                        onChange={handleInputChange}
                        disabled={
                          dataCompany?.walletAdminBlocked || !fullAccess
                        }
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </>
                )}
                <Grid item xs={3}></Grid>
                <Grid item xs={5} pb={3}>
                  <FormNumeric
                    name={"walletMinimumBalance"}
                    label={"When your balance drops to"}
                    value={dataCompany?.walletMinimumBalance?.toString() ?? ""}
                    onChange={handleInputChange}
                    disabled={dataCompany?.walletAdminBlocked || !fullAccess}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid
                pt={"10px"}
                display={"inline-block"}
                item
                textAlign={"right"}
                xs={3}
              >
                <Typography variant="subtitle2" display={"inline-block"} pr={3}>
                  Enable Auto-Withdrawals:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display={"inline"}>
                  <FormCheckBox
                    label="Automatically withdraw money from my default card when the purchase exceeds my AMP Wallet balance. "
                    name="walletAutoWithdraw"
                    value={dataCompany?.walletAutoWithdraw ?? false}
                    onChange={handleInputChangeCheck}
                    disabled={dataCompany?.walletAdminBlocked || !fullAccess}
                  />
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FormTooltip titleName={"Auto withdraw wallet"} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                pt={"10px"}
                display={"inline-block"}
                item
                textAlign={"right"}
                xs={3}
              >
                <Typography variant="subtitle2" display={"inline-block"} pr={3}>
                  Block my Wallet:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display={"inline"}>
                  <FormCheckBox
                    label="All the purchases in the system will be blocked for all the users in the Company. "
                    name="walletAdminBlocked"
                    value={dataCompany?.walletAdminBlocked ?? false}
                    onChange={handleInputChangeCheck}
                    disabled={!fullAccess}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <FormSaveButton
                onClick={handleSave}
                isSubmitting={isSubmitting}
                disabled={!fullAccess}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider my={3}></Divider>
            </Grid>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={isActive}
                onChange={handleChangeTab}
                aria-label="CreditCards"
              >
                <Tab label="Active" id="true" value={true} />
                <Tab label="Inactive" id="false" value={false} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IWalletList>
                refreshGrid={true}
                columns={columns(
                  handleEdit,
                  HandleAddFunds,
                  dataCompany?.walletAdminBlocked ?? false,
                  handleDisable,
                  handleDefault,
                  handleDelete,
                  isActive,
                  fullAccess
                )}
                data={Object.values(data ?? []).filter((x) => {
                  return x.isActive === isActive;
                })}
                query={filterValues.name}
                showSkeleton={isLoading}
                subItems={"subBuilding"}
                orderColumn="desc"
                defaultSortColumn="createdDate"
                filterCustom={["name", "lastName", "alias"]}
              />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <FormButton
                text={"View Wallet History"}
                onClick={handleViewHistory}
                size="small"
              ></FormButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Information"}
        text={
          "Are you certain you want to save this configuration in your AMP Wallet?"
        }
        showPopup={showConfirmPopup}
        setShowPopup={setShowConfirmPopup}
        onSave={handleConfirmSave}
        isSubmitting={isSubmitting}
      />
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to ${
          isActiveCard ? "inactive" : "active"
        } this card?`}
        showPopup={showConfirmDisable}
        setShowPopup={setShowConfirmDisable}
        onSave={handleConfirmDisable}
        isSubmitting={isSubmitting}
      />
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to make this card default?`}
        showPopup={showConfirmDefault}
        setShowPopup={setShowConfirmDefault}
        onSave={handleConfirmDefault}
        isSubmitting={isSubmitting}
      />
      <MatchWordConfirmModal
        onConfirm={handleConfirmDelete}
        text={`Are you certain you want to delete this card?`}
        matchWord={"DELETE"}
        isDialogOpen={showConfirmDelete}
        setIsDialogOpen={setShowConfirmDelete}
      />
      {dataCompany && (
        <SatellitePopup
          showSatellitePopup={showSatelliteModal}
          setShowSatellitePopup={setShowSatelliteModal}
          companyId={Number(companyId) ?? 0}
          historyView={"Wallet"}
          companyName={dataCompany?.name}
        />
      )}
      <AddFundsPopUp
        showModal={showAddFundsPopup}
        setShowModal={setShowAddFundsPopup}
        cardId={selectedCardId}
        companyId={Number(companyId)}
        getWalletBalance={getWalletBalance}
      ></AddFundsPopUp>
    </>
  );
};

export default WalletList;
