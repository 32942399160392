import React, { useState } from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "src/hooks/useAuth";
import EmergencyButton from "../ticketSupport/components/EmergencyButton";
import ResetPasswordModal from "./components/ResetPasswordModal";
import { useNavigate } from "react-router-dom";
import { NotificationService } from "src/services";
import { useAppDispatch } from "src/hooks";
import { signalrActions } from "src/redux/slices/signalrActions";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const { signIn, initialize } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showForgotPasswordPopUp, setShowForgotPasswordPopUp] = useState(false);
  const handleOnClick = () => {
    setShowForgotPasswordPopUp(true);
  };
  const handleOnClickSingUp = () => {
    navigate("/auth/sign-up");
  };
  const changeValues = () => {
    initialize();
    const getNotifications = async () => {
      var countTickets = await NotificationService.getByType(2);
      dispatch(signalrActions.coutNewTicket(Number(countTickets.data ?? 0)));
      var newTechnician = await NotificationService.getByType(1);
      dispatch(signalrActions.newTechnician(Number(newTechnician.data ?? 0)));
      var newOutToBid = await NotificationService.getByType(13);
      dispatch(signalrActions.newOutToBid(Number(newOutToBid.data ?? 0)));
    };
    getNotifications();
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().max(255).required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signIn(values.email, values.password);
          } catch (error: any) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              name="email"
              label="Email Address"
              value={values.email || ""}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={(e: any) => {
                handleChange(e);
                changeValues();
              }}
              my={2}
            />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="password"
              name="password"
              label="Password"
              value={values.password || ""}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={(e: any) => {
                handleChange(e);
                changeValues();
              }}
              my={2}
            />

            <Stack direction="column" justifyContent="center" spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Sign in
              </Button>

              <Button variant="text" color="info" onClick={handleOnClick}>
                Forgot password
              </Button>
              <Button variant="text" color="info" onClick={handleOnClickSingUp}>
                Create an account
              </Button>
              <EmergencyButton emailValue={values.email} />

              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  variant="text"
                  color="info"
                  href="https://auditmaster.pro/legal?id=privacy_statement"
                  target="_blank"
                >
                  Privacy Statement
                </Button>
              </Stack>
            </Stack>
            <ResetPasswordModal
              showForgotPasswordPopUp={showForgotPasswordPopUp}
              setShowForgotPasswordPopUp={setShowForgotPasswordPopUp}
            />
          </form>
        )}
      </Formik>
    </>
  );
}

export default SignIn;
