import axios from "src/utils/axios";
import { IPitotTraverseTest } from "src/ts/interfaces/study/pitot";

const getById = (id: number) => {
  return axios.get<IPitotTraverseTest>(`PitotTraverseTest/${id}`);
};

const update = (id: number, pitot: IPitotTraverseTest) => {
  return axios.put<IPitotTraverseTest>(
    `PitotTraverseTest?recalculate=true`,
    pitot
  );
};

const pitotTraverseTestService = {
  getById,
  update,
};

export default pitotTraverseTestService;
