import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FormCancelButton, FormSaveButton } from "src/components/formControls";
import {
  Avatar as MuiAvatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Stack,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { FormActionButton } from "src/components/formControls";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { userService } from "src/services";
import { IUserPerson, ICurrentCertificate } from "src/ts/interfaces";
import ProfileForm from "./ProfileForm";
import AccountSettings from "./AccountSettingsForm";
import ConfigureNotificationPopup from "./ConfigureNotificationPopup";
import FileUtils from "src/utils/file";
import {
  useAsyncMutation,
  useForm,
  useTimeZone,
  useCountry,
  useLog,
  useAuth,
} from "src/hooks";
import UnionSection from "./UnionSection";
import { useNavigate } from "react-router-dom";
import { Validator } from "src/ts/types";
import { COUNTRIES, ROLES } from "src/constants";
import Certification from "./Certification";
import IdentityVerificationPopUp from "./IdentityVerificationPopUp";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Spacer = styled.div(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(MuiAvatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface DetailsProps {
  enCodeImg: string;
}

const Details = (props: DetailsProps) => {
  const { enCodeImg } = props;
  const [image, setImage] = useState(`data:image/png;base64, ${enCodeImg}`);

  const { execute: uploadProfilePic, isSubmitting } = useAsyncMutation(
    userService.uploadProfilePic
  );

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const bsc = await FileUtils.file2Base64(file);

    setImage(bsc);

    await uploadProfilePic({
      documentType: "",
      content: file,
      name: "",
    });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile Picture
        </Typography>

        <Spacer mb={4} />

        <CenteredContent>
          <BigAvatar
            alt="Remy Sharp"
            //src={`data:image/png;base64, ${image}`}
            src={image}
          />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            onChange={handleFileUpload}
            type="file"
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={isSubmitting}
            >
              <CloudUpload mr={2} /> Upload
            </Button>
          </label>
        </CenteredContent>
      </CardContent>
    </Card>
  );
};

const initialValues: IUserPerson = {
  id: 0,
  name: "",
  lastName: "",
  address: "",
  address2: "",
  countryId: -1,
  zip: "",
  city: "",
  stateId: -1,
  stateName: "",
  phone: null,
  fixedPhone: "",
  timeZoneId: -1,
  isTwoFactorAuthenticationEnable: false,
  confirmIdentityBySms: false,
  countryCode: "",
  unionAffiliated: {
    unionAffiliatedAnswer: false,
    smwiaLocalUnion: "",
    smwiaMember: "",
    apprentice: false,
    unionAffiliationNumbers: {
      localUnion: "",
    },
    localUnionsId: 0,
    laborUnionsId: 0,
  },
  hasSheduler: false,
  roleName: "",
  textColor: "",
};

const Profile = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const [userData, setUserData] = useState<IUserPerson | null>(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [confirmIdentityBySms, setConfirmIdentityBySms] = useState(false);
  const [phone, setPhone] = useState<string | null>(null);
  const [countryId, setCountryId] = useState<string | number>(0);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [certificationData, setCertificationData] = useState<
    ICurrentCertificate[]
  >([]);
  let navigate = useNavigate();

  const { execute, isSubmitting } = useAsyncMutation(
    userService.updateCurrent,
    {
      successfulMessage: "User was saved",
    }
  );

  useEffect(() => {
    const getCuerrent = async () => {
      const response = await userService.getCurrent();
      setUserData(response.data);
      setValues(response.data);
      setConfirmIdentityBySms(response.data?.confirmIdentityBySms ?? false);
      setPhone(response.data?.phone);
      setCountryId(response.data?.countryId);
      const responseCertification = await userService.getCurrentCertification();
      setCertificationData(responseCertification.data);
    };

    getCuerrent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The User's first name is required")
      .toString();

    temp.lastName = new Validator(fieldValues, "lastName")
      .isRequired("The User's last name is required")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .isRequired("The User's address is required.")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "The Provider's country is required.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("The User's zipcode is required.")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The User's city is required")
      .toString();

    temp.stateId = new Validator(fieldValues, "stateId")
      .selectedOption("", "The User's state is required.")
      .toString();

    temp.stateName = new Validator(fieldValues, "stateName")
      .validateIf(values.countryId !== COUNTRIES.USA)
      .toString();

    temp.phone = new Validator(fieldValues, "phone")
      .isRequired("The User's cell phone is required")
      .toString();

    temp.timeZoneId = new Validator(fieldValues, "timeZoneId")
      .selectedOption("", "The Time zone is required.")
      .toString();

    if (
      (temp.unionAffiliated !== null || temp.unionAffiliated !== undefined) &&
      values.unionAffiliated?.unionAffiliatedAnswer
    ) {
      temp.laborUnionsId = new Validator(
        fieldValues.unionAffiliated,
        "laborUnionsId"
      )
        .selectedOption(0, "The Labor Union required.")
        .toString();

      if (
        fieldValues.unionAffiliated.laborUnions !== null &&
        fieldValues.unionAffiliated.laborUnions.name === "Other"
      ) {
        temp.laborUnionsOther = new Validator(
          fieldValues.unionAffiliated,
          "laborUnionsOther"
        )
          .isRequired("The Labor Union Other required.")
          .toString();
      }

      temp.localUnionsId = new Validator(
        fieldValues.unionAffiliated,
        "localUnionsId"
      )
        .selectedOption(0, "The Local Union required.")
        .toString();

      if (
        fieldValues.unionAffiliated.localUnions !== null &&
        fieldValues.unionAffiliated.localUnions.name === "Other"
      ) {
        temp.localUnionsOther = new Validator(
          fieldValues.unionAffiliated,
          "localUnionsOther"
        )
          .isRequired("The Local Union Other required.")
          .toString();
      }

      temp.smwiaMember = new Validator(
        fieldValues.unionAffiliated,
        "smwiaMember"
      )
        .isRequired("The Member # union is required.")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const { getCountryById } = useCountry(values.countryId as number);
  const { getTimeZoneById } = useTimeZone();

  const showNotificationModalHandler = (show: boolean) => {
    setShowNotificationModal(show);
  };

  const cancelHandler = () => {
    switch (user?.role) {
      case ROLES.Auditor:
        navigate("/app/auditorDashboard");
        break;
      case ROLES.SysAdmin:
        navigate("/app/sysAdminDashboard");
        break;
      case ROLES.EnergyAuditor:
        navigate("/app/energyAuditorDashboard");
        break;
      case ROLES.TemporalEnergyAuditor:
        navigate("/app/temporalEnergyAuditorDashboard");
        break;
      case ROLES.TemporalAuditor:
        navigate("/app/temporalAuditorDashboard");
        break;
      case ROLES.Architect:
        navigate("/app/architectDashboard");
        break;
      case ROLES.CompanyOwner:
        navigate("/app/companyAdministratorsDashboard");
        break;
      case ROLES.SuperAdministrator:
        navigate("/app/superAdministratorDashboard");
        break;
      case ROLES.UnionAdministrator:
        navigate("/app/unionAdministratorDashboard");
        break;
      case ROLES.Trainer:
        navigate("/app/trainerDashboard");
        break;
      case ROLES.Training:
        navigate("/app/trainingDashboard");
        break;
      case ROLES.Administrator:
        navigate("/app/companyAdministratorsDashboard");
        break;
      case ROLES.ProjectManager:
        navigate("/app/companyAdministratorsDashboard");
        break;
      case ROLES.ArchitectAux:
        navigate("/app/architectDashboard");
        break;
      default:
        navigate("/app/default");
        break;
    }
  };

  const saveHandler = async () => {
    if (!validate()) return;

    if (
      user &&
      user?.role !== ROLES.Architect &&
      user?.role !== ROLES.ArchitectAux
    ) {
      if (
        values?.unionAffiliated?.unionAffiliatedAnswer &&
        (values?.unionAffiliated?.laborUnionsId === null ||
          values?.unionAffiliated?.localUnionsId === null)
      )
        return log.warning("Union data is required");
    }

    if (
      values.confirmIdentityBySms &&
      (values.confirmIdentityBySms !== confirmIdentityBySms ||
        values.phone !== phone ||
        values.countryId !== countryId)
    ) {
      setShowVerificationModal(true);
      return;
    }

    await confirmSave();
  };

  const confirmSave = async () => {
    values.timeZone = getTimeZoneById(values.timeZoneId);
    values.country = getCountryById(values.countryId);

    await execute(values);
    const response = await userService.getCurrent();
    setUserData(response.data);
    setValues(response.data);
    setConfirmIdentityBySms(response.data?.confirmIdentityBySms ?? false);
    setPhone(response.data?.phone);
    setCountryId(response.data?.countryId);
  };

  return (
    <React.Fragment>
      <ConfigureNotificationPopup
        showModal={showNotificationModal}
        showModalHandler={showNotificationModalHandler}
      />
      <Helmet title="Profile" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            User Profile
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>User Profile</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <FormActionButton
              mr={2}
              text="Configure Notifications"
              size="medium"
              onClick={() => showNotificationModalHandler(true)}
              type="settings"
            />
          </Stack>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={5} xl={6}>
          {userData?.photo !== undefined && (
            <Details enCodeImg={userData?.photo} />
          )}
          <AccountSettings email={userData?.email ?? ""} />
        </Grid>
        <Grid item xs={12} lg={7} xl={6}>
          <Card mb={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Personal Information
              </Typography>
              <Spacer mb={2} />
              {userData && (
                <ProfileForm
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  handleInputChange={handleInputChange}
                  isSubmitting={isSubmitting}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        {user &&
          user?.role !== ROLES.Architect &&
          user?.role !== ROLES.ArchitectAux && (
            <Grid item xs={12}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Union Section
                  </Typography>
                  <Spacer mb={2} />
                  {userData && (
                    <UnionSection
                      values={values}
                      setValues={setValues}
                      errors={errors}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <FormCancelButton
              onClick={cancelHandler}
              isSubmitting={isSubmitting}
            />
            <FormSaveButton onClick={saveHandler} isSubmitting={isSubmitting} />
          </Stack>
        </Grid>
        {certificationData.length > 0 && (
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Certifications
                </Typography>
                <Spacer mb={2} />
                <Certification certificationData={certificationData} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <IdentityVerificationPopUp
        showVerificationModal={showVerificationModal}
        setSowVerificationModal={setShowVerificationModal}
        userPerson={values}
        confirmSave={confirmSave}
      ></IdentityVerificationPopUp>
    </React.Fragment>
  );
};

export default Profile;
