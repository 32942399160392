import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AMP_API,
  timeout: 40000,
  headers: { seckey: "4ee0843ecc4cf0c169eacece4dd4fec1" },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject({
      message:
        (error.response && error.response.data) || "Something went wrong",
    });
  }
);

export default axiosInstance;
