import { ICredentials, ICredentialsWithCode } from "src/ts/interfaces";
import axios from "src/utils/axios";

const signIn = async ({ userName, password, role }: ICredentials) => {
  let bodyFormData = new FormData();
  bodyFormData.append("userName", userName);
  bodyFormData.append("password", password);
  bodyFormData.append("appType", "web");

  return await axios.post("/token", bodyFormData);
};

// 'api/'
const requestVerificationCode = async ({
  userName,
  password,
  role,
  notificationType,
}: ICredentials) => {
  let bodyFormData = new FormData();
  bodyFormData.append("userName", userName);
  bodyFormData.append("password", password);
  bodyFormData.append("notificationType", notificationType ?? "SMS");

  return await axios.post("/token/email", bodyFormData);
};

const verifyCode = async (credentialsWithCode: ICredentialsWithCode) => {
  let bodyFormData = new FormData();
  bodyFormData.append("userName", credentialsWithCode.userName);
  bodyFormData.append("password", credentialsWithCode.password);
  bodyFormData.append("code", credentialsWithCode.code);
  bodyFormData.append("appType", "web");

  return await axios.post("/token/verifyCode", bodyFormData);
};

const signInWithRole = async ({ userName, password, role }: ICredentials) => {
  let bodyFormData = new FormData();
  bodyFormData.append("userName", userName);
  bodyFormData.append("password", password);
  bodyFormData.append("role", role);
  bodyFormData.append("appType", "web");
  bodyFormData.append("grant_type", "password");

  return await axios.post("/token/role", bodyFormData);
};

const checkSystemPassword = async (password: string) => {
  let bodyFormData = new FormData();
  bodyFormData.append("password", password);

  return await axios.post("/token/checkSystemPassword", bodyFormData);
};

const signOut = async () => {
  return await axios.post("/token/logout");
};

const changeCompany = async (companyId: number) => {
  return await axios.get(`/token/changeCompany?companyId=${companyId}`);
};

const AuthenticationService = {
  signIn,
  verificationCode: requestVerificationCode,
  verifyCode,
  signInWithRole,
  signOut,
  checkSystemPassword,
  changeCompany,
};

export default AuthenticationService;
