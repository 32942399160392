import { Grid, Typography, Stack } from "@mui/material";
import { Form } from "src/hooks/useForm";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
} from "src/components/formControls";

interface MatchWordConfirmModalProps {
  matchWord: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
}

const ChangeReadingsPopUp = (props: MatchWordConfirmModalProps) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    text,
    onConfirm,
    isSubmitting,
    onCancel,
  } = props;

  const confirmHandler = async () => {
    onConfirm();

    setIsDialogOpen(false);
  };

  const handlerCancel = () => {
    onCancel();
  };

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Warning`}
        onClose={handlerCancel}
        disableClickOutside={true}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <Typography variant="subtitle2" gutterBottom>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack spacing={2} direction="row">
            <FormCancelButton onClick={handlerCancel} isSubmitting={false} />
            <FormAcceptButton
              text={`Yes,Change`}
              onClick={confirmHandler}
              isSubmitting={isSubmitting ?? false}
              color="primary"
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default ChangeReadingsPopUp;
