import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Checkbox,
  Stack,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import DropZone from "src/components/DropZone";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IAssetDocument } from "src/ts/interfaces/companyAssets";

import { ColumnType } from "src/types/enhancedTable";
import FileUtils from "src/utils/file";
import useLog from "src/hooks/useLog";

import { GridActionButton } from "src/components/gridControls";
import { useParams } from "react-router-dom";
import { FormButton, FormText } from "src/components/formControls";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useAsyncQuery } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import { TableData } from "src/ts/interfaces";
import assetDocumentService from "src/services/assetDocumentService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const getColumns = (
  handleDeleteDocument: (row: IAssetDocument) => void,
  downloadDocument: (row: IAssetDocument) => void,
  handleOnChangeCheck: (row: IAssetDocument) => void
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    width: "75%",
    callback: (row: IAssetDocument) => {
      return (
        <>
          <Stack direction="row">
            {row.type === "photo" ? (
              <ImageOutlinedIcon />
            ) : (
              <PictureAsPdfOutlinedIcon />
            )}{" "}
            {row.name}
          </Stack>
        </>
      );
    },
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IAssetDocument) => {
      return (
        <div>
          <Box>
            <GridActionButton
              type="download"
              onClick={() => downloadDocument(row)}
              tooltip="Download"
            />
            <GridActionButton
              type="delete"
              onClick={() => handleDeleteDocument(row)}
              tooltip="Delete"
            />
          </Box>
        </div>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: false,
    callback: (row: IAssetDocument) => {
      return (
        <div>
          <Box mr={2}>
            <Checkbox
              onChange={() => {
                handleOnChangeCheck(row);
              }}
            />
          </Box>
        </div>
      );
    },
  },
];

enum SysAdminDocumentTypeEnum {
  Photo = "photo",
  Document = "document",
}

type AssetDocumentParams = {
  assetId: string;
  companyId: string;
  asset: string;
};

const AssetDocumentation = () => {
  const { log } = useLog();
  let { assetId, companyId, asset } = useParams<AssetDocumentParams>();
  const [currentRow, setCurrentRow] = useState<IAssetDocument>();
  const [currentRowTechnician] = useState<IAssetDocument>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const [photo, setPhoto] = useState<IAssetDocument[]>([]);
  const [documents, setDocuments] = useState<IAssetDocument[]>([]);

  const [sendbyEmailShow, setSendbyEmailShow] = useState(false);

  const [emailSelected, setEmailSelected] = useState<Array<number>>([]);

  const [disableTemplateUploader] = useState(false);

  const title = asset === "VEHI" ? " Vehicle" : "Equipment";

  let navigate = useNavigate();

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const responseDocuments =
          await assetDocumentService.getAllAssetDocuments(Number(assetId));

        const photoData = responseDocuments.data.filter(
          (item: any) => item.type === SysAdminDocumentTypeEnum.Photo
        );
        setPhoto(photoData.length === 0 ? [] : photoData);

        const documentData = responseDocuments.data.filter(
          (item: any) => item.type === SysAdminDocumentTypeEnum.Document
        );

        setDocuments(documentData.length === 0 ? [] : documentData);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleDeleteDocument = (row: IAssetDocument) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const downloadDocument = async (row: IAssetDocument) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);

        const response = await assetDocumentService.downloadDocumentAssets(
          Number(row.id)
        );

        let fileName = row.name;
        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleDocumentFileAcceptedPhoto = async (file: File) => {
    var assetID = assetId;
    await saveFile(
      file,
      SysAdminDocumentTypeEnum.Photo,
      assetID ?? "",
      companyId ?? ""
    );
  };

  const handleDocumentFileAcceptedDocument = async (file: File) => {
    var assetID = assetId;

    await saveFile(
      file,
      SysAdminDocumentTypeEnum.Document,
      assetID ?? "",
      companyId ?? ""
    );
  };

  const saveFile = async (
    file: File,
    fileType: string,
    assetID: string,
    companyId: string
  ) => {
    try {
      setIsSubmitting(true);
      await assetDocumentService.uploadUserFile({
        documentType: fileType,
        content: file,
        assetId: assetID,
        companyId: companyId,
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteDocument = async () => {
    if (
      (currentRow && currentRow.id) ||
      (currentRowTechnician && currentRowTechnician.id)
    ) {
      try {
        await assetDocumentService.deleteDocumentAssets(currentRow?.id ?? 0);
        log.success("Data was deleted succesfully");
        setRefreshPage(!refreshPage);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const handleOnChangeCheck = (row: IAssetDocument) => {
    if (emailSelected.length === 0) {
      setEmailSelected([...emailSelected, row.id]);
    } else {
      const emailSelectedFilter = emailSelected.filter((item: number) => {
        return item === row.id;
      });
      if (emailSelectedFilter[0] === row.id) {
        setEmailSelected(
          emailSelected.filter((item: number) => item !== row.id)
        );
      } else {
        setEmailSelected([...emailSelected, row.id]);
      }
    }
  };

  const columns = getColumns(
    handleDeleteDocument,
    downloadDocument,
    handleOnChangeCheck
  );

  const handleSendEmail = async (dataTable: TableData[]) => {
    const teamMemberIds = dataTable
      .filter((item) => item.userType === "TeamMember")
      .map((item) => item.id) as number[];

    const userCompanyIds = dataTable
      .filter((item) => item.userType === "LicenseedUser")
      .map((item) => item.id) as number[];

    await assetDocumentService.sendEmailDocuments({
      documents: emailSelected,
      id: assetId ?? "0",
      teamMemberEmails: teamMemberIds,
      userEmails: userCompanyIds,
    });
  };

  return (
    <>
      <HeaderPage
        title={"Vendor Documentation"}
        parentText={title}
        parentLink={`/app/companyAssets/${companyId}/${asset}`}
        actionSection={undefined}
      ></HeaderPage>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Photo
                </Typography>
              </Stack>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<IAssetDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={photo}
                    hidePagination={true}
                  />
                  <DropZone
                    onFileAccepted={handleDocumentFileAcceptedPhoto}
                    accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                    disabled={disableTemplateUploader}
                    disableMessage="File must be in PDF format"
                    submiting={isSubmitting}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1">
                    <b>*File must be in PNG, JPG, JPEG, GIF or BMP format*</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Document(s)
                </Typography>
              </Stack>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<IAssetDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={documents}
                    hidePagination={true}
                  />

                  <DropZone
                    onFileAccepted={handleDocumentFileAcceptedDocument}
                    accept={{ "application/pdf": [".pdf"] }}
                    disabled={disableTemplateUploader}
                    disableMessage="File must be in PDF format"
                    submiting={isSubmitting}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle1">
                    <b>*File must be in PDF format*</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Stack>
        <Grid container>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <FormButton
                text="Close"
                onClick={() => {
                  navigate(`/app/companyAssets/${companyId}/${asset}`);
                }}
                size="small"
                variant="outlined"
              />
              <FormButton
                text="Send by email"
                onClick={() => {
                  if (emailSelected.length > 0) {
                    setSendbyEmailShow(true);
                  } else {
                    log.error("Select a document before sending");
                  }
                }}
                size="small"
                color="primary"
              />
            </Stack>
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>
      </Stack>
      {sendbyEmailShow && (
        <ShareByEmailPopUpComponent
          sendbyEmailShow={sendbyEmailShow}
          setSendbyEmailShow={setSendbyEmailShow}
          sendEmailFunction={handleSendEmail}
        />
      )}

      <MatchWordConfirmModal
        onConfirm={deleteDocument}
        text={`Are you certain you want to delete this item?`}
        matchWord={"delete"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
      />
    </>
  );
};

export default AssetDocumentation;
