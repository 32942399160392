import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import notificationReducer from "./slices/notificationsSlice";
import signalrSlice from "./slices/signalrSlice";
import floatingBarSlice from "./slices/floatingBarSlice";
import floatingBarPdfGeneration from "./slices/flotatingBarPdfGeneration";
import flotatingBarCouponBar from "./slices/flotatingBarCouponBar";

export const store = configureStore({
  reducer: combineReducers({
    counter: counterReducer,
    notification: notificationReducer.reducer,
    signalR: signalrSlice.reducer,
    floatingBar: floatingBarSlice.reducer,
    pdfGeneration: floatingBarPdfGeneration.reducer,
    couponBar: flotatingBarCouponBar.reducer,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
