import React, { useEffect, useState, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Typography,
  Alert,
  AlertTitle,
  Stack,
  Checkbox,
} from "@mui/material";
import DropZone from "src/components/DropZone";
import { Helmet } from "react-helmet-async";
import { Box, spacing } from "@mui/system";
import styled from "@emotion/styled";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ICompany, ICompanyDocument, TableData } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { FileDownloadIcon, DeleteIcon, InfoIcon } from "src/components/icons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FileUtils from "src/utils/file";
import { ToolTip } from "src/components/others/ToolTip";
import { FormButton, FormText } from "src/components/formControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useAsyncQuery, useLog, usePermissions } from "src/hooks";
import companyDocumentService from "src/services/companyDocumentService";
import FormCropImage from "src/components/formControls/FormCropImage";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import HeaderPage from "src/components/page/HeaderPage";
import { CompanyService } from "src/services";
import CompanyInformation from "../project/createEditProject/components/CompanyInformation";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const getColumns = (
  handleDeleteDocument: (row: ICompanyDocument) => void,
  downloadDocument: (row: ICompanyDocument) => void,
  handleOnChangeCheck: (row: ICompanyDocument) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyDocument) => {
      return (
        <div>
          <Box mr={2}>
            <IconButton
              aria-label="download"
              size="large"
              onClick={() => downloadDocument(row)}
            >
              <FileDownloadIcon />
            </IconButton>

            {fullAccess && (
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => handleDeleteDocument(row)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </div>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: false,
    callback: (row: ICompanyDocument) => {
      return (
        <div>
          <Box mr={2}>
            <Checkbox
              disabled={row.name === "" ? true : false}
              onChange={() => {
                handleOnChangeCheck(row);
              }}
            />
          </Box>
        </div>
      );
    },
  },
];

type CompanyParams = {
  id: string;
};

const initialValues = {
  code: "",
};

const CompanyDocumentPage = () => {
  let { id } = useParams<CompanyParams>();

  const [refreshPage, setRefreshPage] = useState(false);
  const [profileDocuments, setProfileDocuments] = useState<ICompanyDocument[]>(
    []
  );
  const [certificationDocuments, setCertificationDocuments] = useState<
    ICompanyDocument[]
  >([]);
  const [logoDocuments, setLogoDocuments] = useState<ICompanyDocument[]>([]);

  const [disableProfileUploader, setDisableProfileUploader] = useState(false);
  const [disableCertificationUploader, setDisableCertificationUploader] =
    useState(false);
  const [disableLogoUploader, setDisableLogoUploader] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<ICompanyDocument>();
  const [values, setValues] = useState(initialValues);
  const [disabledButton, setDisabledButton] = useState(false);
  const [emailSelected, setEmailSelected] = useState<Array<number>>([]);
  const [sendbyEmailShow, setSendbyEmailShow] = useState(false);
  const { log } = useLog();
  const [imgSrc, setImgSrc] = useState("");
  const { fullAccess } = usePermissions(PermissionTypes.Company_Documents);
  let navigate = useNavigate();

  const { execute, data } = useAsyncQuery<ICompany>(CompanyService.getById);

  useEffect(() => {
    const getCompanyDocuments = async (companyId: number) => {
      try {
        setIsSubmitting(true);
        const request = await companyDocumentService.companyDocuments(
          companyId
        );

        const profileData = request.data.filter(
          (item: ICompanyDocument) => item.type === "profile"
        );
        setDisableProfileUploader(profileData.length >= 1);
        setProfileDocuments(profileData);

        const certificationData = request.data.filter(
          (item: ICompanyDocument) => item.type === "certification"
        );
        setCertificationDocuments(certificationData);
        setDisableCertificationUploader(certificationData.length >= 5);

        const logoData = request.data.filter(
          (item: ICompanyDocument) => item.type === "logo"
        );
        setDisableLogoUploader(logoData.length >= 1);
        setLogoDocuments(logoData);
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getCompanyDocuments(Number(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refreshPage]);

  useEffect(() => {
    setValues({
      code: "",
    });
  }, [showDeleteModal]);

  const saveFile = async (companyId: number, file: File, fileType: string) => {
    try {
      setIsSubmitting(true);
      await companyDocumentService.uploadFile({
        companyId: companyId,
        documentType: fileType, //"profile",
        content: file,
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await companyDocumentService.deleteDocument(currentRow.id);
        setRefreshPage(!refreshPage);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
      setValues({ code: "" });
    } else {
      setValues({ code: "" });
    }
  };

  const downloadDocument = async (row: ICompanyDocument) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);
        const response = await companyDocumentService.downloadFile(row.id);
        FileUtils.downloadFile(response.data, row.name);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleDeleteDocument = (row: ICompanyDocument) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const handleProfileFileAccepted = async (file: File) => {
    await saveFile(Number(id), file, "profile");
  };

  const handleCertificationFileAccepted = async (file: File) => {
    await saveFile(Number(id), file, "certification");
  };

  const handleLogoFileAccepted = async (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(file);
    }

    //await saveFile(Number(id), file, "logo");
  };

  const handleSaveLogo = async (file: string) => {
    try {
      setIsSubmitting(true);
      await companyDocumentService.uploadFileLogo({
        companyId: Number(id),
        documentType: "logo",
        content: file,
      });
      setRefreshPage(!refreshPage);
      setImgSrc("");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    value === "delete" ? setDisabledButton(true) : setDisabledButton(false);
  };

  const handleOnChangeCheck = (row: ICompanyDocument) => {
    if (emailSelected.length === 0) {
      setEmailSelected([...emailSelected, row.id]);
    } else {
      const emailSelectedFilter = emailSelected.filter((item: number) => {
        return item === row.id;
      });
      if (emailSelectedFilter[0] === row.id) {
        setEmailSelected(
          emailSelected.filter((item: number) => item !== row.id)
        );
      } else {
        setEmailSelected([...emailSelected, row.id]);
      }
    }
  };

  const handleSendEmail = async (dataTable: TableData[]) => {
    const teamMemberIds = dataTable
      .filter((item) => item.userType === "TeamMember")
      .map((item) => item.id) as number[];

    const userCompanyIds = dataTable
      .filter((item) => item.userType === "LicenseedUser")
      .map((item) => item.id) as number[];

    await companyDocumentService.sendEmailDocuments({
      documents: emailSelected,
      teamMemberEmails: teamMemberIds,
      userEmails: userCompanyIds,
    });
  };

  const columns = getColumns(
    handleDeleteDocument,
    downloadDocument,
    handleOnChangeCheck,
    fullAccess
  );

  return (
    <React.Fragment>
      <HeaderPage
        title={"  Company Documents"}
        parentText={"Company"}
        parentLink={`/app/company/${id}`}
        infoSection={
          !data?.headquarters && data && <CompanyInformation company={data} />
        }
      ></HeaderPage>
      <Helmet title="Company Documents" />

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                LOGO (This will be your company logo minimally displayed on
                every page of all AMP reports you generate)
              </Typography>
              <Alert
                icon={
                  <ToolTip
                    sx={{ cursor: "pointer" }}
                    title="Image size determines an image's actual width and height in pixel. Image Size is dimension of an image"
                  >
                    <InfoIcon />
                  </ToolTip>
                }
                severity="info"
              >
                <AlertTitle>Important</AlertTitle>
                We strongly recommend using a high-quality image for your
                company logo. The picture you submit will become part of every
                report submitted. Your image must keep within the following
                boundaries: 394 x 139 <strong>pixels</strong>
              </Alert>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ICompanyDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={logoDocuments}
                    hidePagination={true}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleLogoFileAccepted}
                      accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                      disabled={disableLogoUploader}
                      disableMessage="Only one logo is allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={12}>
                  <FormCropImage
                    save={handleSaveLogo}
                    imgSrc={imgSrc}
                    isSubmitting={isSubmitting}
                    aspect={2.83}
                  ></FormCropImage>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                PROFILE (This will be your primary 1-page company profile page,
                personally designed and created by you)
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ICompanyDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={profileDocuments}
                    hidePagination={true}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleProfileFileAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={disableProfileUploader}
                      disableMessage="Only one profile is allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                CERTIFICATIONS (This is for any certifications awarded to your
                company that you would like to have added to every report)
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ICompanyDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={certificationDocuments}
                    hidePagination={true}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleCertificationFileAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={disableCertificationUploader}
                      disableMessage="Only five certifications are allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <FormButton
              text="Close"
              onClick={() => {
                navigate(`/app/company/${id}`);
              }}
              color="primary"
              variant="outlined"
              size="medium"
            />
            <FormButton
              text="Send by email"
              onClick={() => {
                if (emailSelected.length > 0) {
                  setSendbyEmailShow(true);
                } else {
                  log.error("Select a document before sending");
                }
              }}
              color="primary"
              size="medium"
            />
          </Stack>
        </Grid>
      </Grid>
      {sendbyEmailShow && (
        <ShareByEmailPopUpComponent
          sendbyEmailShow={sendbyEmailShow}
          setSendbyEmailShow={setSendbyEmailShow}
          sendEmailFunction={handleSendEmail}
        />
      )}
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteDocument}
        isSubmitting={false}
        disabled={!disabledButton}
      >
        <Stack>
          <Typography mb={2} mt={5}>
            Type "delete" to confirm
          </Typography>
          <FormText
            label=""
            name="code"
            value={values.code}
            onChange={handleOnChange}
          />
        </Stack>
      </DialogMessagePopup>
    </React.Fragment>
  );
};

export default CompanyDocumentPage;
