import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "../project/types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getQueryString } from "../project/utils/common";
import { GridActionButton } from "src/components/gridControls";
import { useAuth, useLog } from "src/hooks";
import DeclineProject from "./Components/DeclineProject";
import getProjectService from "src/services/project/getProjectService";
import { FormLabel } from "src/components/formControls";
import AwardedProjectPopUp from "./Components/AwardedProjectPopUp";

const getColumns = (
  navigate: NavigateFunction,
  companyId: number,
  setCurrentRow: (val: IProjectOutForBidData) => void,
  setAwarededShowPopUp: (val: boolean) => void,
  setDeclineProjectShow: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => (
      <FormLabel text={row.projectContractor?.code} />
    ),
  },
  {
    id: "name",
    label: "Project",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => (
      <FormLabel text={row.projectContractor?.name} />
    ),
  },
  {
    id: "ownerName",
    label: "Proposed By",
    type: "custom",
    sort: true,
    align: "center",
    callback: (row: IProjectOutForBidData) => (
      <FormLabel text={row.projectContractor?.companyOwner.name} />
    ),
  },
  {
    id: "building",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => {
      return (
        <Typography>
          {" "}
          <b>{row.projectContractor?.buildingName}</b> <br />
          {row.projectContractor?.buildingAddress}{" "}
        </Typography>
      );
    },
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => (
      <FormLabel text={row.projectContractor?.projectDescription} />
    ),
  },

  {
    id: "winingDate",
    label: "Date Awarded",
    type: "utcDate",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              if (row.codeUsed) {
                navigate(
                  `/app/Project/Edit/${row?.projectContractor?.id}/${companyId}`
                );
              } else {
                setCurrentRow(row);
                setAwarededShowPopUp(true);
              }
            }}
            tooltip="Review Project"
          />

          {!row.codeUsed && (
            <GridActionButton
              type="close"
              onClick={() => {
                setCurrentRow(row);
                setDeclineProjectShow(true);
              }}
              tooltip="Decline"
            />
          )}
        </Stack>
      </>
    ),
  },
];

interface projectsAvailableProps {
  filters: FilterType;
  refresh: boolean;
}

export default function ProjectAwarded({
  filters,
  refresh,
}: projectsAvailableProps) {
  const { log } = useLog();
  const { user } = useAuth();
  const [currentRow, setCurrentRow] = useState<IProjectOutForBidData>();
  const [awarededShowPopUp, setAwarededShowPopUp] = useState(false);
  const [declineProjectShow, setDeclineProjectShow] = useState(false);

  let navigate = useNavigate();
  let columns = getColumns(
    navigate,
    user?.companyId ?? 0,
    setCurrentRow,
    setAwarededShowPopUp,
    setDeclineProjectShow
  );
  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    startRequest,
    endRequest,
    refreshGrid,
    setRefreshGrid,
  } = useTable(columns, {
    defaultOrderBy: "createdDate",
    defaultOrder: "desc",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("dataLoading");
        const request = await getProjectService.getProjectAwareded(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            search: filters.search,
            countryId: filters.countryId,
            stateId: filters.stateId | 0,
            stateName: filters.stateName,
            zipCode: filters.zipCode,
            projectCode: filters.projectCode,
            hasAaacepted: true,
            buildingName: filters.buildingName,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.getAll);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("dataLoading");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    setCount,
    setDataSet,
    setRowsPerPage,
    refreshGrid,
    refresh,
  ]);

  return (
    <>
      <Table />

      {awarededShowPopUp && (
        <AwardedProjectPopUp
          setAwarededShowPopUp={setAwarededShowPopUp}
          awarededShowPopUp={awarededShowPopUp}
          currentRow={currentRow}
        />
      )}
      {declineProjectShow && (
        <DeclineProject
          declineProjectShow={declineProjectShow}
          setDeclineProject={setDeclineProjectShow}
          dataRow={currentRow}
          refreshPage={refreshGrid}
          setRefreshPage={setRefreshGrid}
        />
      )}
    </>
  );
}
