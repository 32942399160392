import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, CardContent, styled, Card as MuiCard } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

import { useAuth } from "src/hooks";
import { IAssets, ITypeAsset } from "src/ts/interfaces/companyAssets";
import { spacing } from "@mui/system";
import SatellitePopUp from "./SatellitePopUp";
import AssesmentReport from "./AssesmentReport";

import { AssetOutOfServiceVehicleColumns } from "./columnsTable/VehicleAdminColumns";
import { AssetOutOfServiceEquipmentColumns } from "./columnsTable/EquipmentAdminColumns";
import OutofServicePopUp from "./OutofServicePopUp";
import ReactiveAsset from "./ReactiveAsset";
import { ASSETSTATUS, ASSET_TILE, AssetsConstant } from "src/constants";

const Card = styled(MuiCard)(spacing);

interface Props {
  data: IAssets[];
  isLoading: boolean;
  asset: string;
  companyId: string;
  setRefreshGrid: (value: boolean) => void;
  refreshGrid: boolean;
  filterValues: string;
  information: ITypeAsset;
  permissionRole: boolean;
}

const OutOfServiceGrid = (props: Props) => {
  const {
    data,
    isLoading,
    asset,
    companyId,
    filterValues,
    information,
    permissionRole,
    setRefreshGrid,
    refreshGrid,
  } = props;
  const [dataTable, setDataTable] = useState<IAssets[]>([]);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const [showAssessmentHistory, setShowAssessmentHistory] = useState(false);
  const [trasnferAssigType, setTransferAssigType] = useState("");
  const [currentRow, setCurrentRow] = useState<IAssets>();
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [outOfServiceShowPopUp, setOutOfServiceShowPopUp] = useState(false);
  const [reactiveAssetShowPopUp, setReactiveAssetShowPopUp] = useState(false);

  const { user } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const getData = () => {
      if (asset === AssetsConstant.VEHICLE) {
        if (data !== null) {
          const filter = data.filter((item: IAssets) => {
            return item.assignedToId !== user?.userId;
          });
          setDataTable(filter);
        }
      } else {
        const filter = data.filter((item: IAssets) => {
          return item.assignedToId !== user?.userId;
        });
        setDataTable(filter);
      }
    };

    if (data !== null) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setColumns(
      asset === AssetsConstant.VEHICLE
        ? AssetOutOfServiceVehicleColumns(
            setCurrentRow,
            setShowSatelliteModal,
            setShowAssessmentHistory,
            setTransferAssigType,
            navigate,
            companyId,
            setOutOfServiceShowPopUp,
            setReactiveAssetShowPopUp
          )
        : AssetOutOfServiceEquipmentColumns(
            setCurrentRow,
            setShowSatelliteModal,
            setShowAssessmentHistory,
            setTransferAssigType,
            navigate,
            companyId,
            setOutOfServiceShowPopUp,
            setReactiveAssetShowPopUp
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const filterType =
    information.name === "Vehicle"
      ? ["vehicle.make", "vehicle.year", "vehicle.model"]
      : [
          "equipment.name",
          "equipment.description",
          "equipment.serialNumber",
          "equipment.model",
        ];

  const assetTitle =
    asset === AssetsConstant.EQUIPMENT
      ? `Test ${ASSET_TILE.EQUIPMENT}`
      : ASSET_TILE.VEHICLE;

  const assetData =
    asset === AssetsConstant.EQUIPMENT
      ? ASSET_TILE.EQUIPMENT
      : ASSET_TILE.VEHICLE;
  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IAssets>
                refreshGrid={true}
                columns={columns}
                data={dataTable}
                showSkeleton={isLoading}
                skeletonRows={1}
                hidePagination={false}
                defaultRowPerPage={10}
                query={filterValues}
                filterCustom={filterType}
                defaultSortColumn={assetData.toLocaleLowerCase()}
                secondLevelSort="id"
                orderBySecondLevel={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showSatelliteModal && (
        <SatellitePopUp
          showSatelliteModal={showSatelliteModal}
          setShowSatelliteModal={setShowSatelliteModal}
          currentRow={currentRow ?? {}}
          transactionType={trasnferAssigType}
        />
      )}
      {showAssessmentHistory && (
        <AssesmentReport
          showAssessmentHistory={showAssessmentHistory}
          setShowAssessmentHistory={setShowAssessmentHistory}
          currentRow={currentRow ?? {}}
          asset={asset}
          permissionRole={permissionRole}
          assetStatus={ASSETSTATUS.OUTOFSERVICE}
        />
      )}

      {outOfServiceShowPopUp && (
        <OutofServicePopUp
          outofServiceShowPop={outOfServiceShowPopUp}
          setOutofServiceShowPop={setOutOfServiceShowPopUp}
          assetTitle={assetTitle}
          assetId={currentRow?.id?.toString() ?? ""}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
          readonly={true}
          dataOutOfService={currentRow?.assetOutOfService}
        />
      )}
      {reactiveAssetShowPopUp && (
        <ReactiveAsset
          reactiveAssetShowPopUp={reactiveAssetShowPopUp}
          setReactiveAssetShowPopUp={setReactiveAssetShowPopUp}
          assetType={asset}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
        />
      )}
    </>
  );
};

export default OutOfServiceGrid;
