import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ASSET_TILE, AssetsConstant, COUNTRIES } from "src/constants";
import { useForm, useCountry } from "src/hooks";
import { FormCancelButton, FormButton } from "src/components/formControls";
import { Validator } from "src/ts/types";
import useLoading from "src/hooks/useLoading";
import useZipCode from "src/hooks/useZipCode";
import EditUpdateForm from "./addUpdateComponents/EditUpdateForm";
import { IAssets } from "src/ts/interfaces/companyAssets";
import companyAssetsService from "src/services/companyAssetsService";
import InformationPopUp from "./addUpdateComponents/InformationPopUp";
import EditUpdateFormVehicles from "./addUpdateComponents/EditUpdateFormVehicles";
import { SaveIcon } from "src/components/icons";
import { IKeyValue } from "src/ts/interfaces";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export type AudiTechniParams = {
  companyId: string;
  asset: string;
  assetId: string;
  type: string;
};

const initialValuesEquipment: IAssets = {
  id: 0,
  officeShop: true,
  vehicle: {},
  equipment: {
    name: "",
    description: "",
    serialNumber: "",
    calibrationDate: "",
    model: "",
    id: 0,
    dateOfPurchase: "",
    companyPurchasedFrom: "",
    companyAddress: "",
    companyAddress2: "",
    companyCountry: {
      id: "235",
      name: "",
    },
    companyCity: "",
    companyZip: "",
    companyStateId: null,
    companyPhoneNumber: "",
    companyStateName: "",
    purchasingPo: "",
    costOfFullPurchase: 0,
    companyCountryId: 235,
  },
};

const initialValuesVehicle: IAssets = {
  id: 0,
  officeShop: true,
  vehicle: {
    make: "",
    model: "",
    year: "",
    vehicleIdNumber: "",
    licensePlate: "",
    countryOfRegistrationId: 235,
    stateId: null,
    stateName: "",
    firstDataEntryMiles: "",
    keylessCode: "",
    optFuelBurnRate: "",
    keysIssuedToTechnician: "",
    insurancePolicyNumber: "",
    economicNumber: "",
  },
  equipment: {},
};

const AddUpdateEquipmentVehicle = () => {
  const params = useParams<AudiTechniParams>();
  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );
  const assetId = parseInt(
    params?.assetId === undefined ? "0" : params?.assetId
  );
  const { asset, type } = params;
  const navigate = useNavigate();
  const { log } = useLog();

  const [informationShowPopUp, setInformationShowPopUp] = useState(false);
  const [selectPusache, setSelectPusache] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const { setSelectedCountryById, selectedCountry, countriesKeyValue } =
    useCountry();

  const { isLoading, startRequest, endRequest } = useLoading();

  const [value, setValue] = useState(0);

  const title =
    asset === AssetsConstant.EQUIPMENT
      ? ASSET_TILE.EQUIPMENT
      : ASSET_TILE.VEHICLE;
  const read = type === "1";

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (title === "Equipment") {
      temp.name = new Validator(fieldValues.equipment, "name")
        .isRequired("The Equipment name is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.description = new Validator(fieldValues.equipment, "description")
        .isRequired("The Equipment description is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.serialNumber = new Validator(fieldValues.equipment, "serialNumber")
        .isRequired("The Equipment Serial Number is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.model = new Validator(fieldValues.equipment, "model")
        .isRequired("The Equipment Model is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.calibrationDate = new Validator(
        fieldValues.equipment,
        "calibrationDate"
      )
        .isRequired("The Calibration Date is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();
    } else {
      temp.make = new Validator(fieldValues.vehicle, "make")
        .isRequired("The Vehicle make is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();
      temp.model = new Validator(fieldValues.vehicle, "model")
        .isRequired("The Vehicle model is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();
      temp.year = new Validator(fieldValues.vehicle, "year")
        .greatThan(0, "The Vehicle year is required.")
        .toString();
      temp.vehicleIdNumber = new Validator(
        fieldValues.vehicle,
        "vehicleIdNumber"
      )
        .isRequired("The Vehicle ID number is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();
      temp.licensePlate = new Validator(fieldValues.vehicle, "licensePlate")
        .isRequired("The Vehicle license plate is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.licensePlate = new Validator(fieldValues.vehicle, "licensePlate")
        .isRequired("The Vehicle license plate is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.countryOfRegistrationId = new Validator(
        fieldValues.vehicle,
        "countryOfRegistrationId"
      )
        .selectedOption(-1, "Select a country.")
        .toString();

      temp.stateId = new Validator(fieldValues.vehicle, "stateId")
        .selectedOption("", "The state is required.")
        .toString();

      temp.stateName = new Validator(fieldValues.vehicle, "stateName")
        .validateIf(
          fieldValues.vehicle.countryOfRegistrationId !== COUNTRIES.USA
        )
        .isRequired("The state is required.")
        .maxLength(50, "Only 50 character are allowed")
        .toString();
      temp.firstDataEntryMiles = new Validator(
        fieldValues.vehicle,
        "firstDataEntryMiles"
      )
        .greatThan(0, "The Vehicle first data entry miles is required.")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useForm(
    asset === AssetsConstant.EQUIPMENT
      ? initialValuesEquipment
      : initialValuesVehicle,
    true,
    validate
  );

  const { zipValue } = useZipCode(
    values.equipment ? values.equipment.companyZip : 0
  );

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.equipment.companyCountryId === COUNTRIES.USA &&
      asset === AssetsConstant.EQUIPMENT
    ) {
      setValues({
        ...values,
        equipment: {
          ...values.equipment,
          companyCity: zipValue.city,
          companyStateId: zipValue.stateId,
        },
      });
    } else if (
      zipValue?.countryId !== undefined &&
      values.equipment.companyCountryId === COUNTRIES.USA &&
      asset === AssetsConstant.VEHICLE
    ) {
      setValues({
        ...values,
        vehicle: {
          ...values.equipment,
          companyCity: zipValue.city,
          companyStateId: zipValue.stateId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    const getInformation = async () => {
      try {
        startRequest("assetData");
        if (assetId === 0) {
          log.error("Incorrect company");
          return;
        }
        const response = await companyAssetsService.getAssetsEditUpdate(
          assetId.toString()
        );

        setValues(response.data);

        if (asset === AssetsConstant.EQUIPMENT) {
          setCountryId(response.data.equipment?.companyCountryId ?? 0);
        } else {
          setCountryId(response.data.vehicle?.countryOfRegistrationId ?? 0);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("assetData");
      } finally {
        endRequest("assetData");
      }
    };

    if (assetId !== 0) getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedCountryById(countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 1) {
      setSelectPusache(true);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (asset === AssetsConstant.EQUIPMENT) {
      setValues({
        ...values,
        equipment: {
          ...values.equipment,
          [name]: value,
        },
      });
      if (name === "companyCountryId") {
        setSelectedCountryById(value);
      }
    } else {
      setValues({
        ...values,
        vehicle: {
          ...values.vehicle,
          [name]: value,
        },
      });
    }
  };

  const handleInputChangeCountry = (e: any) => {
    const { name, value } = e.target;
    if (name === "id") {
      setValues({
        ...values,
        equipment: {
          ...values.equipment,
          companyCountry: {
            ...values.companyCountry,
            [name]: value,
          },
        },
      });
    } else {
      setValues({
        ...values,
        equipment: {
          ...values.equipment,
          companyCountry: {
            ...values.companyCountry,
            [name]: value,
          },
        },
      });
    }
  };

  const handlerAddUpdate = async () => {
    if (!validate()) return;

    if (assetId === 0) {
      if (!selectPusache && title === ASSET_TILE.EQUIPMENT) {
        setInformationShowPopUp(true);
      } else {
        handlerAccept();
      }
    } else {
      try {
        await companyAssetsService.putAssetsUpdateEdit(
          assetId.toString(),
          values
        );
        log.success(`The ${title} was"updated`);
        navigate(`/app/companyAssets/${companyId}/${asset}`);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const handlerAccept = async () => {
    try {
      await companyAssetsService.postAssetsEditUpdate({
        companyAssetTypeId: title === ASSET_TILE.EQUIPMENT ? 1 : 2,
        companyId: companyId.toString(),
        equipment: values.equipment,
        officeShop: true,
        vehicle: values.vehicle,
      });
      log.success(`The ${title} was created`);
      navigate(`/app/companyAssets/${companyId}/${asset}`);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Helmet title="Requests" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {`Recommended Test ${title}`}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to={`/app/TechnicianModify/${companyId}`}>
              Requests
            </Link>
            <Typography>{"New"}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 5,
            }}
          >
            <Grid item xs={6}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="General" id="1" />
                {asset === AssetsConstant.EQUIPMENT && (
                  <Tab label="Purchase" id="2" />
                )}
              </Tabs>
            </Grid>
          </Grid>
          <Grid container>
            {asset === AssetsConstant.EQUIPMENT ? (
              <EditUpdateForm
                values={values}
                setValues={setValues}
                errors={errors}
                handleInputChange={handleInputChange}
                isLoading={isLoading}
                countriesKeyValue={
                  countriesKeyValue as IKeyValue<number, string>[]
                }
                assetType={title}
                tabValue={value}
                handleInputChangeCountry={handleInputChangeCountry}
                selectedCountry={selectedCountry}
                readOnly={read}
              />
            ) : (
              <EditUpdateFormVehicles
                values={values}
                setValues={setValues}
                errors={errors}
                handleInputChange={handleInputChange}
                isLoading={isLoading}
                countriesKeyValue={
                  countriesKeyValue as IKeyValue<number, string>[]
                }
                assetType={title}
                tabValue={value}
                handleInputChangeCountry={handleInputChangeCountry}
                selectedCountry={selectedCountry}
                readOnly={read}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              mt={12}
            >
              <FormCancelButton
                onClick={() => {
                  navigate(`/app/companyAssets/${companyId}/${asset}`);
                }}
                isSubmitting={false}
              />
              {!read && (
                <FormButton
                  text={assetId === 0 ? "Add" : "Save"}
                  onClick={() => {
                    handlerAddUpdate();
                  }}
                  isSubmitting={false}
                  size="small"
                  startIcon={<SaveIcon />}
                />
              )}
            </Stack>
          </Grid>
        </CardContent>
      </Card>
      {informationShowPopUp && (
        <InformationPopUp
          informationShowPopUp={informationShowPopUp}
          setInformationShowPopUp={setInformationShowPopUp}
          handlerAccept={handlerAccept}
        />
      )}
    </>
  );
};

export default AddUpdateEquipmentVehicle;
