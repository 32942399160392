import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAsyncQuery, useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import projectService from "src/services/project/projectService";
import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import CloseProjectPopUp from "../componentsArchitectProject/components/CloseProjectPopUp";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import projectPutService from "src/services/project/projectPutService";

const getColumns = (
  navigate: NavigateFunction,
  companyID: number,
  setCurrentRow: (val: IGetProjectInfectious) => void,
  setCloseProjectShowPopUp: (val: boolean) => void,
  setDeleteProjectModal: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },

  {
    id: "building",
    label: "Building Address",
    type: "custom",
    sort: true,
    callback: (row: IGetProjectInfectious) => {
      return <Typography> {row.buildingAddress} </Typography>;
    },
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    sort: true,
    format: "MM/DD/YYYY",
  },

  {
    id: "action",
    label: "",
    type: "custom",
    sort: false,
    width: "150p",
    callback: (row: IGetProjectInfectious) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              navigate(`/app/ProjectWizard/Edit/${row.id}/${companyID}`);
            }}
            tooltip="Review Project"
          />
          <GridActionButton
            type="close"
            onClick={() => {
              setCurrentRow(row);
              setCloseProjectShowPopUp(true);
            }}
            tooltip="Close Project"
          />
          <GridActionButton
            type="delete"
            onClick={() => {
              setCurrentRow(row);
              setDeleteProjectModal(true);
            }}
            tooltip="Delete Project"
          />
          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/DocumentLibrary/${row.id}/${companyID}/Project`);
            }}
            tooltip="Project's Document Library"
          />
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(`/app/PhotoLibrary/${row.id}/${companyID}/Project`);
            }}
            tooltip="Project's Picture Library"
          />
        </Stack>
      </>
    ),
  },
];

interface NewProjectInfectiousProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IGetProjectInfectious[];
  isLoading: boolean;
  refreshGrid: boolean;
  setDataTable: (val: IGetProjectInfectious[]) => void;
}

export type ProjectsParams = {
  companyId: string;
};

export default function NewProjectInfectious({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  setDataTable,
}: NewProjectInfectiousProps) {
  const [currentRow, setCurrentRow] = useState<IGetProjectInfectious>();
  const [closeProjectShowPopUp, setCloseProjectShowPopUp] = useState(false);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);

  let navigate = useNavigate();
  const { user } = useAuth();

  const { execute: executeDelete } = useAsyncQuery(
    projectService.getDeleteProject
  );

  const { execute: executeProjectSingle, data: dataProjectSingle } =
    useAsyncQuery<ProjectDTO>(getProjectSingleService.getById);

  const { execute: executeUpdateProjectSingle } = useAsyncQuery<ProjectDTO>(
    projectPutService.update
  );

  useEffect(() => {
    const updateProject = async () => {
      await executeUpdateProjectSingle(currentRow?.id ?? 0, {
        ...dataProjectSingle,
        projectStatus: {
          id: 4,
          name: "Removed",
        },
      });
    };

    if (dataProjectSingle) updateProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectSingle]);

  let columns = getColumns(
    navigate,
    user?.companyId,
    setCurrentRow,
    setCloseProjectShowPopUp,
    setDeleteProjectModal
  );

  const deleteProject = async () => {
    await executeDelete(currentRow?.id);

    if (dataTable.length >= 0) {
      const dataSplice = dataTable.filter((item: IGetProjectInfectious) => {
        return item.id !== currentRow?.id;
      });
      setDataTable(dataSplice);
      executeProjectSingle(currentRow?.id);
      setRefreshGrid(!refreshGrid);
    }
  };

  return (
    <>
      <LocalEnhancedTable<IGetProjectInfectious>
        refreshGrid={false}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="asc"
        defaultSortColumn="id"
      />
      {closeProjectShowPopUp && (
        <CloseProjectPopUp
          setCloseProjectShowPopUp={setCloseProjectShowPopUp}
          closeProjectShowPopUp={closeProjectShowPopUp}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
        />
      )}
      <MatchWordConfirmModal
        onConfirm={deleteProject}
        text={`Are you certain you want to delete permanently this project?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteProjectModal}
        setIsDialogOpen={setDeleteProjectModal}
      />
    </>
  );
}
