import { Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  FormCheckBox,
  FormDatePicker,
  FormMaskText,
  FormNumeric2,
  FormSelect,
  FormSelectState,
  FormText,
} from "src/components/formControls";
import { ADDUPDATEASSET, ASSET_TILE } from "src/constants";
import { Form } from "src/hooks";
import { ICountry, IKeyValue } from "src/ts/interfaces";
import { IAssets } from "src/ts/interfaces/companyAssets";

interface IEdiiUpdateGeneralProps {
  values: IAssets;
  setValues: (val: IAssets) => void;
  errors: Record<string, string>;
  handleInputChange: (e: any) => void;
  isLoading: boolean;
  countriesKeyValue: IKeyValue<number, string>[];
  assetType: string;
  tabValue: number;
  handleInputChangeCountry: (e: any) => void;
  selectedCountry: ICountry | null;
  readOnly: boolean;
}

const EditUpdateForm = ({
  values,
  setValues,
  errors,
  handleInputChange,
  isLoading,
  countriesKeyValue,
  assetType,
  tabValue,
  handleInputChangeCountry,
  selectedCountry,
  readOnly,
}: IEdiiUpdateGeneralProps) => {
  const [isStateLoading, setIsStateLoading] = useState(false);
  const row = 6;

  return (
    <>
      <Form>
        {tabValue === ADDUPDATEASSET.GENERAL && assetType === "Equipment" && (
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label={`${assetType} Name`}
                value={values?.equipment?.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="description"
                label={`${assetType} Description`}
                value={values?.equipment?.description}
                onChange={handleInputChange}
                error={errors.description}
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="serialNumber"
                label="Serial Number"
                value={values?.equipment?.serialNumber}
                onChange={handleInputChange}
                error={errors.serialNumber}
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="model"
                label="Model Number"
                value={values?.equipment?.model}
                onChange={handleInputChange}
                error={errors.model}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <Stack direction="row">
                <FormDatePicker
                  name="calibrationDate"
                  label="Calibration Due Date"
                  onChange={handleInputChange}
                  value={values?.equipment?.calibrationDate ?? ""}
                  error={errors.calibrationDate}
                  readOnlyText={readOnly}
                />
                <Typography m={2}>
                  <b> Assign to</b>
                </Typography>
                <FormCheckBox
                  label="Office/Shop"
                  name={""}
                  value={values.officeShop}
                  disabled={true}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
        {tabValue === ADDUPDATEASSET.PURCHASE &&
          assetType === ASSET_TILE.EQUIPMENT && (
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormDatePicker
                  name="dateOfPurchase"
                  label="Date of Purchase"
                  onChange={handleInputChange}
                  value={values?.equipment?.dateOfPurchase}
                  fullWidth={true}
                  readOnlyText={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="companyPurchasedFrom"
                  label={`Company purchased from`}
                  value={values?.equipment?.companyPurchasedFrom}
                  onChange={handleInputChange}
                  error={errors.companyPurchasedFrom}
                  showSkeleton={isLoading}
                  readOnlyText={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="companyAddress"
                  label="Company Address"
                  value={values?.equipment?.companyAddress}
                  onChange={handleInputChange}
                  error={errors.companyAddress}
                  showSkeleton={isLoading}
                  readOnlyText={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="companyAddress2"
                  label="Company Address 2"
                  value={values?.equipment?.companyAddress2}
                  onChange={handleInputChange}
                  error={errors.companyAddress2}
                  placeholder="Suite, Floor, etc."
                  showSkeleton={isLoading}
                  readOnlyText={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="companyCountryId"
                  label="Country"
                  value={values.equipment?.companyCountryId ?? ""}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  showSkeleton={isLoading}
                  defaultValue={{ key: 235, value: "United States (USA)" }}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="companyZip"
                  label="Company Zip code"
                  value={values.equipment?.companyZip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  showSkeleton={isLoading}
                  readOnlyText={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="companyCity"
                  label="Company City"
                  value={values.equipment?.companyCity}
                  onChange={handleInputChange}
                  error={errors.zip}
                  showSkeleton={isLoading}
                  readOnlyText={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelectState
                  stateId={values?.equipment?.companyStateId?.toString() ?? ""}
                  stateName={values?.equipment?.companyStateName ?? ""}
                  countryId={Number(values.equipment?.companyCountryId)}
                  errorsStateId={errors.stateId}
                  errorsStateName={errors.stateName}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsStateLoading(value)}
                  showSkeleton={isLoading}
                  otherNameStateName="companyStateName"
                  otherValuerStateName={values.equipment?.companyStateName}
                  otherNameStateId="companyStateId"
                  otherValuerStateId={values.equipment?.companyStateId}
                  readonly={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row" spacing={2}>
                  <FormText
                    name="countryCode"
                    label="Country Code"
                    value={selectedCountry?.countryCode?.toString() ?? "1"}
                    error={errors.countryCode}
                    fullWidth={false}
                    showSkeleton={isLoading}
                    readOnlyText={readOnly}
                  />
                  <FormMaskText
                    name="companyPhoneNumber"
                    mask="(999) 999 - 9999"
                    label="Company Phone Number"
                    onChange={handleInputChange}
                    value={values?.equipment?.companyPhoneNumber}
                    error={errors.companyPhoneNumber}
                    showSkeleton={isLoading}
                    disabled={readOnly}
                  />
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormNumeric2
                  name="costOfFullPurchase"
                  label="Cost of full purchase with/including tax"
                  value={values?.equipment?.costOfFullPurchase?.toString()}
                  onChange={handleInputChange}
                  error={errors.costOfFullPurchase}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="purchasingPo"
                  label="Purchasing PO #"
                  value={values?.equipment?.purchasingPo}
                  onChange={handleInputChange}
                  error={errors.purchasingPo}
                  showSkeleton={isLoading}
                  readOnlyText={readOnly}
                />
              </Grid>
            </Grid>
          )}
      </Form>
    </>
  );
};

export default EditUpdateForm;
