import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  IconButton,
  CardContent,
  Card as MuiCard,
  Stack,
  Typography as MuiTypography,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Edit as EditIcon } from "@mui/icons-material";
import { IAdministratorsData, IUserPersonRole } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useAuth, useLog, usePermissions } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { userService, CompanyService } from "src/services";
import { CompanyParams } from "src/ts/types";
import { formatPhoneNumber } from "src/utils/format";
import { GridActionButton } from "src/components/gridControls";
import { rgba } from "polished";
import { css } from "@emotion/react";
import { green, red } from "@mui/material/colors";
import useAsyncMutation from "src/hooks/useAsyncMutation";
import { EmailIcon } from "src/components/icons";
import DisabledUserPopUp from "./components/DisabledUserPopUp";
import LockOutPopUp from "./components/LockOutPopUp";
import { ToolTip } from "src/components/others/ToolTip";
import { ICompany } from "src/ts/interfaces/company";
import useLoading from "src/hooks/useLoading";
import { Permission as PermissionTypes } from "src/ts/enums";
import { ROLES } from "src/constants";
import PermissionsPopup from "./components/PermissionsPopup";

interface activeInactiveUserPromt {
  status: string;
  filterValues: any;
  setSatelliteUser: (val: ICompany) => void;
  setCompanyName: (val: string) => void;
}
const illustrationPercentageStyle = (props: any) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    color: ${rgba(props.theme.palette.primary.main, 0.85)};
  `}
`;

const Percentage = styled(MuiTypography)<{
  percentagecolor: string;
  illustration?: string;
}>`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    /* background: ${(props) => rgba(props.percentagecolor, 0.1)}; */
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${illustrationPercentageStyle}
`;

const Card = styled(MuiCard)(spacing);

function UserStatus(props: activeInactiveUserPromt) {
  let { id } = useParams<CompanyParams>();
  const { log } = useLog();
  const { user } = useAuth();
  const companyId = Number(id);
  let navigate = useNavigate();
  const [disablePopup, setDisablePopup] = useState(false);
  const [permissions, setPermissions] = useState<IAdministratorsData>();
  const [showPopUp, setShowPopUp] = useState(false);
  const { fullAccess: companyUsersFullAccess } = usePermissions(
    PermissionTypes.Companys_Users
  );
  const {
    readOnly: userSuperAdminReadOnly,
    fullAccess: userSuperAdminFullAccess,
  } = usePermissions(PermissionTypes.Users_SuperAdmin);

  const [name, setName] = useState("");
  const [userID, setUserId] = useState(0);
  const [enableUser, setEnableUser] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { execute: sendEmail } = useAsyncMutation(userService.sendEmail, {
    successfulMessage: "Account confirmation email was successfully sent",
  });
  const [lockOutModal, setLockOutModal] = useState(false);
  const [dataUser, setDataUser] = useState<IUserPersonRole>();

  const sendEmailHandler = (userId: number) => {
    sendEmail(userId);
  };

  const getColumns = (
    handleClickDisable: (id: number) => void,
    setName: (val: string) => void,
    setEnableUser: (val: boolean) => void,
    setUserId: (val: number) => void,
    setLockOutModal: (val: boolean) => void,
    setDataUser: (val: IUserPersonRole) => void,
    handlePermissions: (row: IUserPersonRole) => void
  ): ColumnType[] => [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
      hide: true,
    },
    {
      id: "name",
      label: "Name ",
      type: "custom",
      sort: true,
      callback: (row: IUserPersonRole) => {
        return `${row.name} ${row.lastName}`;
      },
    },
    {
      id: "phone",
      label: "Cell Phone",
      type: "custom",
      sort: true,
      callback: (row: IUserPersonRole) => formatPhoneNumber(row.phone),
      minWidth: "130px",
    },
    {
      id: "email",
      label: "Email Address",
      type: "custom",
      sort: true,
      callback: (row: IUserPersonRole) => {
        var input = row.email;
        var fields = input.split("@");
        return (
          <>
            <Stack>{fields[0]}@</Stack>
            <Stack>{fields[1]}</Stack>
          </>
        );
      },
    },

    {
      id: "role",
      label: "Role",
      type: "string",
      sort: true,
      hide: user?.role === ROLES.Architect,
    },

    {
      id: "isEmailConfirmed",
      label: "Email Confirmation Status ",
      type: "custom",
      sort: true,
      callback: (row: IUserPersonRole) => (
        <Percentage
          variant="subtitle2"
          color="textSecondary"
          percentagecolor={row.isEmailConfirmed ? green[500] : red[500]}
        >
          <span>{row.isEmailConfirmed ? "Confirmed" : "Pending"}</span>
        </Percentage>
      ),
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IUserPersonRole) => {
        return (
          <div>
            <Stack direction="row">
              {(companyUsersFullAccess || userSuperAdminReadOnly) && (
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      navigate(`/app/user/${row.id}/${companyId}`);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {props.status === "Active" &&
                (userSuperAdminReadOnly || userSuperAdminFullAccess) && (
                  <GridActionButton
                    type="disable"
                    onClick={(e: any) => {
                      handleClickDisable(row.id);
                      setName(
                        `${row.name} ${
                          row.lastName !== null ? row.lastName : ""
                        }`
                      );
                      setEnableUser(row.unibesEnabled);
                      setUserId(row.id);
                    }}
                    tooltip="AMP's Admin - Disabled User"
                  />
                )}

              {props.status === "Inactive" &&
                (userSuperAdminReadOnly || userSuperAdminFullAccess) &&
                row.unibesEnabled && (
                  <GridActionButton
                    type="disable"
                    onClick={(e: any) => {
                      handleClickDisable(row.id);
                      setName(
                        `${row.name} ${
                          row.lastName !== null ? row.lastName : ""
                        }`
                      );
                      setEnableUser(row.unibesEnabled);
                      setUserId(row.id);
                    }}
                    tooltip="AMP's Admin - Disabled User"
                  />
                )}
              {props.status === "Inactive" &&
                (userSuperAdminReadOnly || userSuperAdminFullAccess) &&
                !row.unibesEnabled && (
                  <GridActionButton
                    type="enable"
                    onClick={(e: any) => {
                      handleClickDisable(row.id);
                      setName(
                        `${row.name} ${
                          row.lastName !== null ? row.lastName : ""
                        }`
                      );
                      setEnableUser(row.unibesEnabled);
                      setUserId(row.id);
                    }}
                    tooltip="AMP's Admin - Enable User"
                  />
                )}

              <GridActionButton
                type="folder"
                onClick={(e: any) => {
                  navigate(`/app/UserDocuments/${row.id}/${companyId}/users`);
                }}
                tooltip="Upload Documents"
              />
              <GridActionButton
                type="info"
                onClick={(e: any) => {
                  setLockOutModal(true);
                  setDataUser(row);
                }}
                tooltip="Lockout"
              />
              {(userSuperAdminReadOnly ||
                userSuperAdminFullAccess ||
                user?.role === ROLES.Architect) && (
                <GridActionButton
                  type="key"
                  onClick={() => {
                    handlePermissions(row);
                  }}
                  tooltip="Permissions"
                />
              )}
              {(row.showRoleName.includes("TemporalAuditor") ||
                row.showRoleName.includes("Auditor") ||
                row.showRoleName.includes("EnergyAuditor") ||
                row.showRoleName.includes("TemporalEnergyAuditor")) && (
                <GridActionButton
                  type="key"
                  onClick={() => {
                    navigate(
                      `/app/UserPermissions/${row.id}/${companyId}/users`
                    );
                  }}
                  tooltip="Permissions"
                />
              )}
              {!row.isEmailConfirmed && props.status !== "Inactive" && (
                <ToolTip title="Resend Invitation">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      if (row.id !== null) sendEmailHandler(row.id);
                    }}
                  >
                    <EmailIcon />
                  </IconButton>
                </ToolTip>
              )}
            </Stack>
          </div>
        );
      },
    },
  ];
  const [dataSet, setDataSet] = useState<IUserPersonRole[] | null>(null);
  const [dataSetInfo, setDataSetInfo] = useState<IUserPersonRole[] | null>(
    null
  );

  const { isLoading, startRequest, endRequest } = useLoading();

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("companyLoading");
        const response = await userService.getUsersByCompany(companyId);

        setDataSetInfo(response.data);
        if (props.status === "Active") {
          const newValue = response.data.filter((item: any) => {
            return item.statusId === 1;
          });
          setDataSet(newValue);
          if (props.filterValues.unionAffilated === true) {
            const newArray = newValue?.filter((item: any) => {
              return item.isUnionAffiliated === true;
            });
            setDataSet(newArray ?? []);
          }
        } else if (props.status === "Inactive") {
          const newValue = response.data.filter((item: any) => {
            return item.statusId === 2 || item.statusId === null;
          });
          setDataSet(newValue);
          if (props.filterValues.unionAffilated === true) {
            const newArray = newValue?.filter((item: any) => {
              return item.isUnionAffiliated === true;
            });
            setDataSet(newArray ?? []);
          }
        }
        endRequest("companyLoading");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const getCompany = async () => {
      const response = await CompanyService.getById(companyId);
      props.setSatelliteUser(response.data);
      props.setCompanyName(response.data.name);
    };

    getData();
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("companyLoading");
        const response = await userService.getUsersByCompany(companyId);
        setDataSetInfo(response.data);
        if (props.status === "Active") {
          const newValue = response.data.filter((item: any) => {
            return item.statusId === 1;
          });

          setDataSet(newValue);
        } else if (props.status === "Inactive") {
          const newValue = response.data.filter((item: any) => {
            return item.statusId === 2 || item.statusId === null;
          });

          setDataSet(newValue);
        }

        endRequest("companyLoading");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (props.filterValues.unionAffilated === true) {
      const newArray = dataSetInfo?.filter((item: any) => {
        return item.isUnionAffiliated === true;
      });
      setDataSet(newArray ?? []);
    } else {
      if (props.status === "Active") {
        const newValue = dataSetInfo?.filter((item: any) => {
          return item.statusId === 1;
        });

        setDataSet(newValue ?? []);
      } else if (props.status === "Inactive") {
        const newValue = dataSetInfo?.filter((item: any) => {
          return item.statusId === 2 || item.statusId === null;
        });

        setDataSet(newValue ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterValues.unionAffilated, props.filterValues.statusId]);

  const handleClickDisable = async (id: number) => {
    try {
      await userService.validateUser(id);
      setShowPopUp(true);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handlePermissions = (row: IUserPersonRole) => {
    let initialValues: IAdministratorsData = {
      id: row.id,
      certification: "",
      name: row.name,
      lastName: row.lastName,
      phone: row.phone,
      email: row.email,
      userCountryAccess: "",
      statusId: 0,
      role: row.showRoleName,
      administratorType: 0,
      country: {
        id: 0,
        countryCode: 0,
        name: "",
      },
    };

    setPermissions(initialValues);
    setDisablePopup(true);
  };

  const handleSave = () => {};

  let columns = getColumns(
    handleClickDisable,
    setName,
    setEnableUser,
    setUserId,
    setLockOutModal,
    setDataUser,
    handlePermissions
  );

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <LocalEnhancedTable<IUserPersonRole>
                refreshGrid={true}
                columns={columns}
                data={dataSet ?? []}
                defaultSortColumn="id"
                defaultRowPerPage={10}
                query={props.filterValues.name}
                orderColumn="desc"
                filterCustom={["name", "lastName", "email", "role"]}
                showSkeleton={isLoading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DisabledUserPopUp
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
        name={name}
        enableUser={enableUser}
        userID={userID}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      {lockOutModal && dataUser ? (
        <LockOutPopUp
          setLockOutModal={setLockOutModal}
          lockOutModal={lockOutModal}
          dataUser={dataUser}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}
      <PermissionsPopup
        currentValues={permissions}
        disablePopup={disablePopup}
        setDisablePopup={setDisablePopup}
        onSave={handleSave}
        onClick={() => null}
        role={permissions?.role ?? ""}
        showInUi={false}
      />
    </React.Fragment>
  );
}

export default UserStatus;
