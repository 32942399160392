import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";

import { Stack, spacing } from "@mui/system";
import {
  Card as MuiCard,
  Grid,
  CardContent,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import HeaderPage from "src/components/page/HeaderPage";
import { ProjectParams } from "../createEditProject/components/ProjectForm";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAppDispatch,
  useAuth,
  useAppSelector,
} from "src/hooks";
import { buildingService } from "src/services";
import { IBuilding } from "src/ts/interfaces";
import {
  ARCHITECTDATA,
  PROJECTMENU_ALL,
  PROJECT_INITIAL_VALUES,
  ROLES,
} from "src/constants";
import useFormTyped from "src/hooks/useFormTyped";
import { Validator } from "src/ts/types";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectService from "src/services/project/projectService";
import Tolerances from "./components/Tolerances";
import Studies from "./components/Studies";
import EquipmentCalibration from "./components/EquipmentCalibration";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { FormButton } from "src/components/formControls";
import projectAuditorService from "src/services/project/projectAuditorService";
import FormBackButton from "src/components/formControls/FormBackButton";
import { convertUTCDateToLocalDate } from "src/utils/utils";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues: ProjectDTO = {
  ...PROJECT_INITIAL_VALUES,
  diffuserRegisterGrilleToleranceTop: null,
  diffuserRegisterGrilleToleranceBottom: null,
  requiredCFMSystemToleranceTop: null,
  requiredCFMSystemToleranceBottom: null,
  hydronicTerminalDevicesToleranceTop: null,
  hydronicTerminalDevicesToleranceBottom: null,
  requiredGPMSystemToleranceTop: null,
  requiredGPMSystemToleranceBottom: null,

  energyDiffuserRegisterGrilleToleranceTop: null,
  energyDiffuserRegisterGrilleToleranceBottom: null,
  energyRequiredCFMSystemToleranceTop: null,
  energyRequiredCFMSystemToleranceBottom: null,
  energyHydronicTerminalDevicesToleranceTop: null,
  energyHydronicTerminalDevicesToleranceBottom: null,
  energyRequiredGPMSystemToleranceTop: null,
  energyRequiredGPMSystemToleranceBottom: null,
  energyOutsideAirVentilationRateTop: null,
  energyOutsideAirVentilationRateBottom: null,
};

const ProjectStudies = () => {
  let params = useParams<ProjectParams>();

  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );
  const dispatch = useAppDispatch();

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );

  const { user } = useAuth();
  const navigate = useNavigate();
  const [isPartOfProject, setIsPartOfProject] = useState(false);
  const [showSaveTolerancesModal, setShowSaveTolerancesModal] = useState(false);
  const { execute: getBuilding, data: building } = useAsyncQuery<IBuilding>(
    buildingService.getById
  );
  const [refresgProject, setRefreshProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const signalRStatus = useAppSelector((state) => state.signalR);

  useEffect(() => {
    const getProjectID = () => {
      dispatch(floatingBarActions.setChangeProjectId(projectId));
      dispatch(floatingBarActions.setOptionMenu(PROJECTMENU_ALL));
    };
    getProjectID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signalRStatus.paidId !== 0) {
      setValues({ ...values, isPaid: signalRStatus.paidId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRStatus.paidId]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await getProjectSingleService.getById(projectId);
      if (response.data === null) return;
      setValues({ ...response.data });
      await getBuilding(response.data.buildingId);
      setIsLoading(false);
    };

    if (projectId > 0) {
      getData();
      getIsPartOfProject(projectId);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refresgProject]);

  const getIsPartOfProject = async (projectId: number) => {
    const response = await projectAuditorService.getIsPartOfProject(projectId);
    setIsPartOfProject(response.data);
  };

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.energyDiffuserRegisterGrilleToleranceTop = new Validator(
      fieldValues,
      "energyDiffuserRegisterGrilleToleranceTop"
    )
      .isRequired("Value is required")
      .toString();

    temp.energyDiffuserRegisterGrilleToleranceBottom = new Validator(
      fieldValues,
      "energyDiffuserRegisterGrilleToleranceBottom"
    )
      .isRequired("Value is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //query
  const { values, setValues, errors, setErrors } = useFormTyped<ProjectDTO>(
    initialValues,
    false,
    validate
  );

  const { execute: saveTolerances, isSubmitting: savingTolerances } =
    useAsyncMutation(projectService.saveTolerances, {
      successfulMessage: "Tolerances were saved",
      errorMessage: "An error was ocurred",
    });

  const confirmSaveTolerancesHandler = async () => {
    const projectWithTolerances = {
      ...values,
      tolerancesWereSaved: true,
    };

    setValues({
      ...values,
      tolerancesWereSaved: true,
    });

    await saveTolerances(projectId, projectWithTolerances);
  };

  const tolerancesWidth = values.isInfectiousControlProject ? 6 : 8;
  const projectDataWidth = values.isInfectiousControlProject ? 6 : 4;

  const refreshProjectHandler = () => {
    setRefreshProject(!refresgProject);
  };

  const previousStepHandler = () => {
    navigate(`/app/Project/Edit/${projectId}/${companyId}`);
  };

  const filterByTab = useMemo(() => {
    for (const key in ARCHITECTDATA) {
      if (ARCHITECTDATA[key].queryData === values.projectStatusId) {
        return ARCHITECTDATA[key].name;
      }
    }
    return "all";
  }, [values.projectStatusId]);

  const closeHandler = () => {
    if (params?.navRoute === "ProjectEvaluation")
      navigate(`/app/ProjectEvaluation`);
    else if (
      user?.role !== ROLES.CompanyOwner &&
      user?.role !== ROLES.Administrator &&
      user?.role !== ROLES.ProjectManager &&
      user?.role !== ROLES.UnionAdministrator &&
      user?.role !== ROLES.SuperAdministrator &&
      user?.role !== ROLES.Architect &&
      user?.role !== ROLES.ArchitectAux
    )
      navigate("/app/Working");
    else if (
      user?.role === ROLES.Architect ||
      user?.role === ROLES.ArchitectAux ||
      user?.subscriptionId === 1
    )
      navigate(`/app/ProjectArchitect/${user?.companyId}/${filterByTab}`);
    else navigate(`/app/ProjectList/${user?.companyId}`);
  };

  return (
    <>
      <HeaderPage title="Project" parentText="Dashboard" parentLink="/" />
      <Divider my={6} />

      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={tolerancesWidth} lg={tolerancesWidth}>
          <Card mb={6}>
            <CardContent>
              <Tolerances
                project={values}
                setProject={setValues}
                isLoading={isLoading}
                isPartOfProject={isPartOfProject}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={projectDataWidth} lg={projectDataWidth}>
          <Card
            mb={6}
            sx={{
              height: "95%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Project
              </Typography>

              <FormReadOnlyField
                label="Code"
                value={values?.code}
                showSkeleton={isLoading}
              />
              <FormReadOnlyField
                label="Project"
                value={values?.name}
                showSkeleton={isLoading}
              />
              <FormReadOnlyField
                label="Description"
                value={values?.projectDescription ?? "-"}
                showSkeleton={isLoading}
              />
              <FormReadOnlyField
                label="Building"
                value={building?.name}
                showSkeleton={isLoading}
              />
              <FormReadOnlyField
                label=""
                value={`${building?.address}, ${
                  building?.address2 ? building?.address2 : ""
                } ${building?.address2 ? ", " : ""}${
                  building?.country?.name
                }, ${
                  building?.state ? building?.state?.name : building?.stateName
                }, ${building?.city}, Zip: ${building?.zip}`}
                showSkeleton={isLoading}
              />
              {values.projectStatusId === 3 && (
                <>
                  <FormReadOnlyField
                    label="Completed By"
                    value={values?.completedByName}
                    showSkeleton={isLoading}
                  />
                  <FormReadOnlyField
                    label="Completed On"
                    value={convertUTCDateToLocalDate(
                      values?.completedDate?.toString() || ""
                    )?.format("MM/DD/YYYY hh:mm A")}
                    showSkeleton={isLoading}
                  />
                  {values.superAdminCompletedComments && (
                    <FormReadOnlyField
                      label="System Admin Comments"
                      value={values.superAdminCompletedComments}
                      showSkeleton={isLoading}
                    />
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        {values?.projectBomaTypeId !== null && (
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <EquipmentCalibration project={values} />
              </CardContent>
            </Card>
          </Grid>
        )}

        {values?.tolerancesWereSaved && (
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Studies
                </Typography>
                {values.id > 0 && (
                  <Studies
                    project={values}
                    refreshProject={refreshProjectHandler}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormBackButton
            onClick={previousStepHandler}
            isSubmitting={false}
            size="small"
          />
          <FormButton
            onClick={closeHandler}
            isSubmitting={false}
            variant="outlined"
            size="small"
            text="Close"
          />
        </Stack>
      </Grid>

      <DialogMessagePopup
        title="Are you sure?"
        text="Tolerances can't be changed once you select the accept button"
        showPopup={showSaveTolerancesModal}
        setShowPopup={setShowSaveTolerancesModal}
        onSave={confirmSaveTolerancesHandler}
        isSubmitting={savingTolerances}
      />
      {/* <FloatingBar projectId={projectId} /> */}
    </>
  );
};

export default ProjectStudies;
