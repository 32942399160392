import { useAsyncQuery, useTheme } from "src/hooks";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import { Grid, Skeleton, Table, TableBody, Typography } from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import {
  IPitotMatrixItems,
  IPitotMatrixTitle,
} from "src/ts/interfaces/study/pitot";
import pitotMatrixTitleService from "src/services/study/pitotMatrixTitleService";
import pitotMatrixItemsService from "src/services/study/pitotMatrixItemsService";

interface Props {
  id: number;
  saveItems: boolean;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number, totalItems: number) => void;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
}

const PitotFanTraverseGrid = ({
  id,
  saveItems,
  disabled,
  showSkeleton,
  onBlur,
  systemOfMeasurement,
}: Props) => {
  const [items, setItems] = useState<IPitotMatrixItems[][]>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<IPitotMatrixItems>();
  const { theme } = useTheme();
  const { execute, data } = useAsyncQuery<IPitotMatrixItems[]>(
    pitotMatrixItemsService.getByPitotId,
    {
      onSuccess: (result) => {
        const matrix = Array(Math.ceil(result.length / dataX?.length))
          .fill("")
          .reduce((acc, cur, index) => {
            return [
              ...acc,
              [...result].splice(index * dataX?.length, dataX?.length),
            ];
          }, []);
        setItems(matrix);
      },
    }
  );

  const { execute: executeX, data: dataX } = useAsyncQuery<IPitotMatrixTitle[]>(
    pitotMatrixTitleService.getByPitotId
  );

  const { execute: executeY, data: dataY } = useAsyncQuery<IPitotMatrixTitle[]>(
    pitotMatrixTitleService.getByPitotId
  );

  const { execute: executeSave } = useAsyncQuery(
    pitotMatrixItemsService.saveItems
  );

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };
    if (dataX && dataX.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataX]);

  useEffect(() => {
    const getData = async () => {
      await executeY(id, 2);
      await executeX(id, 1);
    };
    if (id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      await executeSave(data);
    };
    if (data && data.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    let total = 0;
    total = sumItems();
    onBlur(total, data?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, data]);

  const setItemsCalc = async (element: IPitotMatrixItems, e: any) => {
    element.item = Number(e.target.value);
    await setItem({ ...element });
  };

  const sumItems = () => {
    const sum = data
      ? data.reduce((sum, current) => sum + (current?.item ?? 0), 0)
      : 0;
    return sum;
  };

  const width = useMemo(() => `${89 / dataX?.length}%`, [dataX?.length]);

  return (
    <>
      <Table>
        <TableBody>
          <SpreadSheetRow>
            <SpreadSheetCell key={`number`} width={"11%"}>
              <Typography
                sx={{
                  background: `linear-gradient(to right top, ${
                    theme === "DARK" ? "#233044" : "#FFFFFF"
                  } 0%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 48%,${
                    theme === "DARK" ? "#FFFFFF50" : "#0000001f"
                  } 50%,${theme === "DARK" ? "#FFFFFF50" : "#0000001f"} 50%,${
                    theme === "DARK" ? "#233044" : "#FFFFFF"
                  } 52%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 100%)`,
                  padding: "2px",
                }}
              >
                <Grid container>
                  <Grid item xs={6} sx={{ padding: "0px", marginTop: "15px" }}>
                    Point Side
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: "right",
                      padding: "0px",
                      paddingBottom: "15px",
                    }}
                  >
                    Hole Side
                  </Grid>
                </Grid>
              </Typography>
            </SpreadSheetCell>
            {dataX && (
              <>
                {Array.from({ length: dataX?.length }, (_, i) => (
                  <SpreadSheetCell key={`${i + 1}`} width={width} minWidth={95}>
                    <Typography mt={0.8} padding={1.8} fontWeight="bold">
                      {dataX[i].title.toFixed(2)}
                    </Typography>
                  </SpreadSheetCell>
                ))}
              </>
            )}
          </SpreadSheetRow>
          {items &&
            items?.length > 0 &&
            Object.values(items ?? []).map(
              (item: IPitotMatrixItems[], index: number) => (
                <SpreadSheetRow>
                  <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                    <Typography
                      m={2}
                      mt={0.8}
                      paddingLeft={1.8}
                      fontWeight="bold"
                    >
                      {dataY[index]?.title.toFixed(2)}
                    </Typography>
                  </SpreadSheetCell>
                  <>
                    {item.map((element: IPitotMatrixItems, indexy) => (
                      <SpreadSheetCell key={`${element.id}`} width={width}>
                        {showSkeleton ? (
                          <Skeleton height={50} />
                        ) : (
                          <>
                            <SpreadsheetNumericInput2
                              name={element.id.toString()}
                              value={element.item}
                              onChange={(e: any) => {
                                setItemsCalc(element, e);
                              }}
                              decimalScale={3}
                              maxValue={1000000}
                              disabled={disabled}
                              mode={disabled ? "read" : undefined}
                              placeholder={systemOfMeasurement.get("phfpm")}
                            ></SpreadsheetNumericInput2>
                          </>
                        )}
                      </SpreadSheetCell>
                    ))}
                  </>
                </SpreadSheetRow>
              )
            )}
        </TableBody>
      </Table>
    </>
  );
};

export default PitotFanTraverseGrid;
