import { useAsyncQuery, useTheme } from "src/hooks";
import { IVelgridMatrixItems } from "src/ts/interfaces/study/velgridMatrix";
import velgridMatrixItemsService from "src/services/study/velgridMatrixItemsService";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import { Grid, Skeleton, Table, TableBody, Typography } from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";

interface Props {
  id: number;
  x: number;
  saveItems: boolean;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number) => void;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
}

const VelgridGrid = ({
  id,
  x,
  saveItems,
  disabled,
  showSkeleton,
  onBlur,
  systemOfMeasurement,
}: Props) => {
  const [items, setItems] = useState<IVelgridMatrixItems[][]>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<IVelgridMatrixItems>();
  const { theme } = useTheme();
  const { execute, data } = useAsyncQuery<IVelgridMatrixItems[]>(
    velgridMatrixItemsService.getByVelgridId,
    {
      onSuccess: (result) => {
        const matrix = Array(Math.ceil(result.length / x))
          .fill("")
          .reduce((acc, cur, index) => {
            return [...acc, [...result].splice(index * x, x)];
          }, []);
        setItems(matrix);
      },
    }
  );

  const { execute: executeSave } = useAsyncQuery(
    velgridMatrixItemsService.saveItems
  );

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };
    if (id > 0 && x > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, x]);

  useEffect(() => {
    const getData = async () => {
      await executeSave(data);
    };
    if (data && data.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    const sum = data
      ? data.reduce(
          (sum, current) => Number(sum) + Number(current?.item ?? 0),
          0
        )
      : 0;

    if (item) onBlur(sum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleSetItems = async (element: IVelgridMatrixItems, e: any) => {
    element.item = e.target.value;
    setItem({ ...element });
  };

  const width = useMemo(() => `${95 / x}%`, [x]);

  return (
    <>
      <Table>
        <TableBody>
          <SpreadSheetRow>
            <SpreadSheetCell key={`number`} width={"5%"}>
              <Typography
                sx={{
                  background: `linear-gradient(to right top, ${
                    theme === "DARK" ? "#233044" : "#FFFFFF"
                  } 0%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 48%,${
                    theme === "DARK" ? "#FFFFFF50" : "#0000001f"
                  } 50%,${theme === "DARK" ? "#FFFFFF50" : "#0000001f"} 50%,${
                    theme === "DARK" ? "#233044" : "#FFFFFF"
                  } 52%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 100%)`,
                  padding: "2px",
                }}
              >
                <Grid container>
                  <Grid item xs={6} sx={{ padding: "0px", marginTop: "15px" }}>
                    Y
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: "right",
                      padding: "0px",
                      paddingBottom: "15px",
                    }}
                  >
                    X
                  </Grid>
                </Grid>
              </Typography>
            </SpreadSheetCell>
            <>
              {Array.from({ length: x }, (_, i) => (
                <SpreadSheetCell key={`${i + 1}`} width={width} minWidth={95}>
                  <Typography mt={0.8} padding={1.8} fontWeight="bold">
                    {i + 1}
                  </Typography>
                </SpreadSheetCell>
              ))}
            </>
          </SpreadSheetRow>
          {items &&
            items?.length > 0 &&
            Object.values(items ?? []).map(
              (item: IVelgridMatrixItems[], index: number) => (
                <SpreadSheetRow>
                  <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                    <Typography
                      m={2}
                      mt={0.8}
                      paddingLeft={1.8}
                      fontWeight="bold"
                    >
                      {index + 1}
                    </Typography>
                  </SpreadSheetCell>
                  <>
                    {item.map((element: IVelgridMatrixItems, index) => (
                      <SpreadSheetCell
                        key={`${element.id}`}
                        width={width}
                        minWidth={95}
                      >
                        {showSkeleton ? (
                          <Skeleton height={50} />
                        ) : (
                          <SpreadsheetNumericInput2
                            name={element.id.toString()}
                            value={element.item}
                            onChange={(e: any) => {
                              handleSetItems(element, e);
                            }}
                            decimalScale={3}
                            maxValue={1000000}
                            disabled={disabled}
                            mode={disabled ? "read" : undefined}
                            placeholder={systemOfMeasurement.get("phfpm")}
                          ></SpreadsheetNumericInput2>
                        )}
                      </SpreadSheetCell>
                    ))}
                  </>
                </SpreadSheetRow>
              )
            )}
        </TableBody>
      </Table>
    </>
  );
};

export default VelgridGrid;
