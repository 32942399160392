import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Grid,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CssBaseline,
} from "@mui/material";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
  useTheme,
} from "src/hooks";
import { THEMES } from "src/constants";
import signUpService from "src/services/signUpService";
import { IWizard } from "src/ts/interfaces/wizard";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormButton,
  FormCancelButton,
} from "src/components/formControls";
import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import GlobalStyle from "src/components/GlobalStyle";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import IdentityConfirmation from "./steps/IdentityConfirmation";
import ContactInformation from "./steps/ContactInformation";
import ConfirmCreditCard from "./steps/ConfirmCreditCard";
import ConfirmFree from "./steps/ConfirmFree";
import ConfirmPay from "./steps/ConfirmPay";
import Redirect from "./steps/Redirect";
import { CompanyService } from "src/services";

const Card = styled(MuiCard)(spacing);

const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function ContactInformationMain() {
  const { theme } = useTheme();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const { log } = useLog();
  const { execute, data, setData } = useAsyncQuery<IWizard>(
    signUpService.start,
    {
      hideSuccessfulMessage: true,
      onSuccess: async (result) => {
        if (result.actualStep) setActiveStep(result.actualStep.key);
        else {
          setModalConfirm(true);
        }
      },
    }
  );
  const { execute: executeNext } = useAsyncMutation(signUpService.nextStep, {
    hideSuccessfulMessage: true,
    onSuccess: (result) => {
      setActiveStep(result.key);
      setData({ ...data, actualStep: result });
    },
  });

  const { execute: executeRestart } = useAsyncMutation(signUpService.restart, {
    hideSuccessfulMessage: true,
    onSuccess: (result) => {
      setActiveStep(result.key);
      setData({ ...data, actualStep: result });
    },
  });

  const { execute: executeResend } = useAsyncQuery(CompanyService.resendEmail, {
    onSuccess: () => {
      log.success("Confirmation email was sent");
    },
  });

  useEffect(() => {
    const data = async () => {
      await execute();
    };

    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = async (cardConfirm: boolean) => {
    await executeNext(cardConfirm, data.signUpWizard.id);
  };

  const handleBack = async () => {
    await executeRestart(data.signUpWizard.id);
  };

  const handleValidate = async () => {
    setModalConfirm(false);
    await execute();
  };

  const handleCancel = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleResendEmail = async () => {
    await executeResend();
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Create a new account
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Sign Up" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "42.5%",
                    marginRight: "42.5%",
                    width: "15%",
                    marginBottom: "10px",
                  }}
                />
              </Link>
              <Stepper alternativeLabel activeStep={data?.actualStep?.step - 1}>
                {data?.steps.map((step) => (
                  <Step key={step.key}>
                    <StepLabel style={{ textTransform: "capitalize" }}>
                      {step.name}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Grid p={4}>
                {activeStep === "ContactInformation" && (
                  <ContactInformation
                    setModalCancel={setModalCancel}
                    handleNext={handleNext}
                  />
                )}
                {activeStep === "ConfirmCreditCard" && (
                  <ConfirmCreditCard
                    setModalCancel={setModalCancel}
                    handleNext={handleNext}
                  />
                )}
                {activeStep === "IdentityConfirmation" && (
                  <IdentityConfirmation
                    setModalCancel={setModalCancel}
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                )}
                {activeStep === "ConfirmFree" && (
                  <ConfirmFree wizardId={data.signUpWizard.id} />
                )}
                {activeStep === "ConfirmPay" && (
                  <ConfirmPay
                    setModalCancel={setModalCancel}
                    wizard={data}
                    handleNext={handleNext}
                  />
                )}
                {activeStep === "Redirect" && (
                  <Redirect wizardId={data.signUpWizard.id} />
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Popup
        title={"Information"}
        openPopup={modalConfirm}
        setOpenPopup={setModalConfirm}
        onClose={undefined}
        disableClickOutside={true}
      >
        <>
          We have sent you a message to confirm your email address. After
          receiving your email, please follow the link provided and continue
          with your registration.
        </>
        <>
          <Stack spacing={2} direction="row">
            <FormCancelButton
              onClick={handleCancel}
              isSubmitting={false}
            ></FormCancelButton>
            <FormButton
              text={"Resend Email"}
              size={"medium"}
              variant="outlined"
              onClick={handleResendEmail}
            ></FormButton>
            <FormAcceptButton
              text="Validate & Continue"
              onClick={handleValidate}
              isSubmitting={false}
            ></FormAcceptButton>
          </Stack>
        </>
      </Popup>
      <DialogMessagePopup
        title={"Information"}
        text={
          "After canceling you can continue with the process by entering your credentials "
        }
        showPopup={modalCancel}
        setShowPopup={setModalCancel}
        onSave={handleCancel}
        isSubmitting={false}
      ></DialogMessagePopup>
    </Root>
  );
}

export default ContactInformationMain;
