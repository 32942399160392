import { useState } from "react";
import { Stack, Typography } from "@mui/material";

import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import CloseProjectPopUp from "../componentsArchitectProject/components/CloseProjectPopUp";

const getColumns = (
  navigate: NavigateFunction,
  role: string,
  setCurrentRow: (val: IGetProjectInfectious) => void,
  companyId: number,
  setCloseProjectShowPopUp: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },

  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "buildingAddress",
    label: "Building",
    type: "string",
    sort: true,
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "companyName",
    label: "Contractor Assigned",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IGetProjectInfectious) => (
      <>
        <Stack direction={"column"}>
          <Stack direction="row">
            <GridActionButton
              type="gauge"
              onClick={() => {
                navigate(`/app/projectDashboard/${row.id}`);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(`/app/Project/Edit/${row.id}/${companyId}`);
              }}
              tooltip="Review Project"
            />

            <GridActionButton
              type="forum"
              onClick={() => {
                navigate(`/app/dispute/${row.id}`);
              }}
              tooltip="Dispute"
            />

            <GridActionButton
              type="close"
              onClick={() => {
                setCurrentRow(row);
                setCloseProjectShowPopUp(true);
              }}
              tooltip="Close Project"
            />

            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(`/app/DocumentLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                navigate(`/app/PhotoLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Picture Library"
            />
          </Stack>
          <Stack></Stack>
        </Stack>
      </>
    ),
  },
];

interface AwarededInfectiousProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IGetProjectInfectious[];
  isLoading: boolean;
  refreshGrid: boolean;
  setDataTable: (val: IGetProjectInfectious[]) => void;
}

export default function AwarededInfectious({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  setDataTable,
}: AwarededInfectiousProps) {
  const [currentRow, setCurrentRow] = useState<IGetProjectInfectious>();
  const [closeProjectShowPopUp, setCloseProjectShowPopUp] = useState(false);

  let navigate = useNavigate();
  const { user } = useAuth();

  let columns = getColumns(
    navigate,
    user?.role,
    setCurrentRow,
    user?.companyId,
    setCloseProjectShowPopUp
  );

  return (
    <>
      <LocalEnhancedTable<IGetProjectInfectious>
        refreshGrid={false}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="desc"
        defaultSortColumn="id"
      />
      {closeProjectShowPopUp && (
        <CloseProjectPopUp
          setCloseProjectShowPopUp={setCloseProjectShowPopUp}
          closeProjectShowPopUp={closeProjectShowPopUp}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
          isInfectious={true}
        />
      )}
    </>
  );
}
