import React, { useEffect, useState } from "react";
import { Grid, Alert, Typography, Button } from "@mui/material";
import { Stack } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import {
  ICompany,
  ICountAvailableCoupon,
  IProjectPurchaseDetail,
} from "src/ts/interfaces";
import {
  FormButton,
  FormLabel,
  FormRadioGroup,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { useAppDispatch, useAsyncQuery, useAuth, useLog } from "src/hooks";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import floatingBarPdfGeneration from "src/redux/slices/flotatingBarPdfGeneration";
import projectReportService from "src/services/study/projectReportService";
import { CompanyService } from "src/services";
import flotatingBarCouponBar from "src/redux/slices/flotatingBarCouponBar";
import { currencyformatter } from "src/utils/format";
import couponsWalletMovementService from "src/services/couponsWalletMovement";

enum PaymentOptionEnum {
  PayNow,
  SendToCart,
}

interface ICompletePayment {
  optionComplete: PaymentOptionEnum;
  couponCode: string;
}

const initialValues: ICompletePayment = {
  optionComplete: 0,
  couponCode: "",
};

const paymentTypeDataSource = [
  {
    key: PaymentOptionEnum.PayNow,
    value: "Pay now",
  },
  {
    key: PaymentOptionEnum.SendToCart,
    value: "Send to cart",
  },
];

const getColumns = (): ColumnType[] => [
  { id: "code", label: "Code", type: "string", sort: true },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
    totalLabel: "Total",
  },
  {
    id: "quantity",
    label: "Qty",
    type: "string",
    sort: false,
    align: "center",
    contentAlign: "center",
  },
  {
    id: "price",
    label: "Unit Price",
    type: "custom",
    sort: true,
    total: "price",
    align: "right",
    contentAlign: "right",
    totalLabelAlign: "right",
    callback: (row: IProjectPurchaseDetail) =>
      currencyformatter.format(row.unitaryCost),
  },
  {
    id: "discounts",
    label: "Discount",
    type: "custom",
    sort: true,
    total: "discounts",
    align: "right",
    contentAlign: "right",
    totalLabelAlign: "right",
    callback: (row: IProjectPurchaseDetail) =>
      currencyformatter.format(row.discount),
  },
  {
    id: "total",
    label: "Total",
    type: "custom",
    sort: true,
    total: "total",
    align: "right",
    contentAlign: "right",
    totalLabelAlign: "right",
    callback: (row: IProjectPurchaseDetail) =>
      currencyformatter.format(row.totalCost - row.discount),
  },
];

interface Props {
  projectId: number;
  typeId: number;
  onCompleteProject: (sendToCart: boolean, coupons: string) => Promise<void>;
  isInfect: boolean;
}

const CompletePaymentStudy = ({
  projectId,
  typeId,
  onCompleteProject,
  isInfect,
}: Props) => {
  const { values, handleInputChange, setValues } =
    useFormTyped<ICompletePayment>(initialValues, true, []);

  const { log } = useLog();
  const { user } = useAuth();
  const [results, setResults] = useState<IProjectPurchaseDetail[]>([]);
  const dispatch = useAppDispatch();
  const [showCouponText, setShowCouponText] = useState(false);
  const [coupons, setCoupons] = useState<string>();
  const [companyData, setCompanyData] = useState<ICompany | any>(null);

  const { execute, data } = useAsyncQuery<ICountAvailableCoupon>(
    couponsWalletMovementService.CountAvailableCoupons
  );

  const ShowCouponText = () => {
    setShowCouponText(!showCouponText);
  };

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      let company = await CompanyService.getById(user?.companyId);
      setCompanyData(company);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.companyId]);

  useEffect(() => {
    const getData = async () => {
      await validateCoupon(false, "");
      dispatch(floatingBarPdfGeneration.actions.setComplete(false));
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    console.log(coupons);
    const getData = async () => {
      await validateCoupon(true, coupons ?? "");
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupons]);

  const validateCoupon = async (messageCoupon: boolean, coupons: string) => {
    try {
      let discountResponse = await projectReportService.GetPurchaseDetail(
        typeId,
        projectId,
        coupons === "" ? "0" : coupons
      );
      discountResponse.data.code = "EARF";
      setResults([...[], discountResponse.data]);
    } catch (error: any) {
      setValues({ ...values, couponCode: "" });
      log.error(error?.message?.exceptionMessage ?? "Invalid Coupon");
    }
  };

  const validateCouponHandler = async () => {
    setCoupons(values.couponCode);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const completePayment = async (sendToCart: boolean) => {
    setIsSubmitting(true);
    await onCompleteProject(sendToCart, values.couponCode);
    setIsSubmitting(false);
    dispatch(flotatingBarCouponBar.actions.setRefresh());
  };

  const columns = getColumns();

  return (
    <Grid container spacing={6}>
      {companyData?.walletAdminBlocked && (
        <Grid item xs={12}>
          <Alert severity="error">
            Your AMP Wallet has and Administrative Blockage.
          </Alert>
        </Grid>
      )}
      <Grid item xs={2}>
        <Typography pt="10px">Select an option:</Typography>
      </Grid>
      <Grid item xs={10}>
        <FormRadioGroup
          name="optionComplete"
          label=""
          value={values.optionComplete}
          items={paymentTypeDataSource}
          onChange={handleInputChange}
          row={true}
        />
        {
          // eslint-disable-next-line eqeqeq
          values.optionComplete == PaymentOptionEnum.SendToCart && (
            <Typography>
              You wont have permission to get PDF Reports until the Project's
              Completion Fee is covered
              <br />
              <br />
              Are you certain you want to continue?
            </Typography>
          )
        }
        {
          // eslint-disable-next-line eqeqeq
          values.optionComplete == PaymentOptionEnum.PayNow && (
            <Typography>
              Your decision will withdraw funds from your Company's Wallet.
              <br />
              <br />
              Are you certain you want to continue?
            </Typography>
          )
        }
      </Grid>

      <Grid item xs={2}>
        <FormLabel text="Promotional code:" />
      </Grid>
      <Grid item xs={7}>
        <Stack direction="row">
          <FormText
            name="couponCode"
            label="Enter your code:"
            value={values.couponCode}
            onChange={handleInputChange}
            type={showCouponText ? "text" : "password"}
          />
          {/* Coupon was successfully applied */}
        </Stack>
      </Grid>

      <Grid item xs={3}>
        <Button
          type="submit"
          size="small"
          variant="outlined"
          color="primary"
          disabled={false}
          onClick={ShowCouponText}
          style={{ minWidth: "30px", marginRight: "10px" }}
        >
          {showCouponText ? (
            <>
              <VisibilityIcon style={{ fontSize: "21px" }} />
            </>
          ) : (
            <>
              <VisibilityOffIcon style={{ fontSize: "21px" }} />
            </>
          )}
        </Button>
        <FormButton
          text="Validate Coupon"
          onClick={validateCouponHandler}
          size="small"
          variant="outlined"
          isSubmitting={isSubmitting}
        />
      </Grid>

      <Grid item xs={12}>
        <LocalEnhancedTable<IProjectPurchaseDetail>
          columns={columns}
          data={results}
          showSkeleton={false}
          hidePagination={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="center">
          {
            // eslint-disable-next-line eqeqeq
            values.optionComplete == PaymentOptionEnum.PayNow && (
              <FormButton
                text="Yes → Pay Now"
                onClick={() => completePayment(false)}
                size="small"
                startIcon={<AttachMoneyIcon />}
                isSubmitting={isSubmitting}
              />
            )
          }

          {
            // eslint-disable-next-line eqeqeq
            values.optionComplete == PaymentOptionEnum.SendToCart && (
              <FormButton
                text="Yes → Send to Cart"
                onClick={() => completePayment(true)}
                size="small"
                startIcon={<ShoppingCartIcon />}
              />
            )
          }
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CompletePaymentStudy;
