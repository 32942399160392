import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { IBuildingSubType } from "src/ts/interfaces/catalogs";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import { buildingSubType, buildingType } from "src/services";
import { IKeyValue } from "src/ts/interfaces";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface BuildingSubTypeFormProps {
  selectedValue?: IBuildingSubType;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onUpdate: () => void;
}

const initialValues: IBuildingSubType = {
  id: 0,
  name: "",
  buildingTypeId: "",
  orderPosition: 0,
  buildingTypeName: "",
};

const BuildingSubtypeForm = (props: BuildingSubTypeFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen, onUpdate } = props;
  const { log } = useLog();
  const [buildingTypeKeyValue, setBuildingTypeKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The SystemCostsCatalog's name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.buildingTypeId = new Validator(fieldValues, "buildingTypeId")
      .selectedOption("", "The Building Type's is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    if (isDialogOpen) setValues(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  useEffect(() => {
    const getBuildingSubType = async () => {
      const response = await buildingType.getKeyValues();
      setBuildingTypeKeyValue(response);
    };

    getBuildingSubType();
  }, []);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      if (values.id === 0) {
        await buildingSubType.add(values);
        log.success("The building subtype was added succesfully");
      } else {
        await buildingSubType.update(values);
        log.success("The building subtype was updated succesfully");
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    setIsDialogOpen(false);
    onUpdate();
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={fullAccess ? (values.id === 0 ? "New" : "Edit") : "view"}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="buildingTypeId"
                label="Building Type"
                value={values.buildingTypeId}
                onChange={handleInputChange}
                options={buildingTypeKeyValue}
                error={errors.buildingTypeId}
                disable={readOnly}
                showSkeleton={false}
                defaultValue={{ key: -1, value: "Select a value" }}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <FormCancelButton onClick={handleClose} isSubmitting={false} />
          {fullAccess && (
            <FormSaveButton onClick={handleSave} isSubmitting={false} />
          )}
        </>
      </Popup>
    </>
  );
};

export default BuildingSubtypeForm;
