import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useForm, Form, useCountry, useStateCatalog, useLog } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
  FormMaskText,
  FormTextArea,
} from "src/components/formControls";
import { IPlaqueProviders } from "src/ts/interfaces";
import { UserParams, Validator } from "src/ts/types";
import { COUNTRIES } from "src/constants";
import { plaqueProviderService } from "src/services";
import { ProviderTypeEnum } from "src/ts/enums/plaquesAndDecals";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import useZipCode from "src/hooks/useZipCode";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: IPlaqueProviders = {
  id: 0,
  name: "",
  providerType: 1,
  email: "",
  address: "",
  address2: "",
  stateId: 0,
  stateName: "",
  countryId: 235,
  zip: "",
  city: "",
  countryCode: "",
  phone: "",
  extension: "",
  additional: "",
  contactName: "",
  title: "",
  isActive: false,
  isDeleted: false,
  createdDate: "",
  createdBy: "",
  updatedDate: "",
  updatedBy: "",
  plaques: [],
  getFullAddress: "",
};

const providerTypesEnum = [
  { key: ProviderTypeEnum.Plaque, value: "Plaque" },
  { key: ProviderTypeEnum.Decals, value: "Decals" },
];

const Provider = () => {
  let { id } = useParams<UserParams>();
  const providerId = parseInt(id === undefined ? "0" : id);

  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshCountryCode, setRefreshCountryCode] = useState(false);
  let navigate = useNavigate();

  const { countriesKeyValue, countries } = useCountry();
  const { states, setCountryId, isLoading } = useStateCatalog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Provider's name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.providerType = new Validator(fieldValues, "providerType")
      .selectedOption("", "The Type is required.")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .isRequired("The Provider's Address is required.")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "The Provider's country is required.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("The Provider's zipcode is required.")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The Provider's city is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.stateId = new Validator(fieldValues, "stateId")
      .selectedOption("", "The Provider's state is required.")
      .toString();

    temp.stateName = new Validator(fieldValues, "stateName")
      .validateIf(values.countryId !== COUNTRIES.USA)
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.extension = new Validator(fieldValues, "extension")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.email = new Validator(fieldValues, "email")
      .maxLength(50, "Only 50 character are allowed")
      .emailFormat("Insert a correct email. demo@bootlab.io")
      .toString();

    temp.additional = new Validator(fieldValues, "additional")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const { zipValue } = useZipCode(values.zip);

  useEffect(() => {
    const getUser = async (userId: number) => {
      const response = await plaqueProviderService.getById(userId);
      setValues(response.data);
      setRefreshCountryCode(!refreshCountryCode);
    };

    if (providerId > 0) {
      getUser(providerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCountryId(values.countryId);

    if (countries.length > 0) {
      const countryCodevalue = countries.filter((item: any) => {
        return item.id === values.countryId;
      });

      let newValues = { ...values };
      newValues.countryId = countryCodevalue[0].id;
      newValues.countryCode = countryCodevalue[0].countryCode;
      newValues.country = {
        id: countryCodevalue[0].id,
        name: countryCodevalue[0].name,
      };

      setValues({
        ...newValues,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId, countries, refreshCountryCode]);

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      if (providerId > 0) {
        await plaqueProviderService.update(values);
      } else {
        await plaqueProviderService.add(values);
      }
      log.info(`Provider was ${providerId > 0 ? "saved" : "added"}`);
      setIsSubmitting(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelHandler = () => {
    navigate("/app/plaqueProviders");
  };

  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Plaque_Decals
  );

  useEffect(() => {
    if (zipValue?.countryId !== undefined) {
      if (values.countryId === COUNTRIES.USA) {
        setValues({
          ...values,
          city: zipValue.city,
          stateId: zipValue.stateId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  const row = 6;

  return (
    <>
      <Helmet title="Company" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Provider
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/app/plaqueProviders">
              Providers
            </Link>
            <Typography>Provider</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormText
                  name="name"
                  label="Provider Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="providerType"
                  label="Provider Type"
                  value={values.providerType}
                  onChange={handleInputChange}
                  options={providerTypesEnum}
                  error={errors.providerType}
                  disable={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address2"
                  label="Address 2"
                  value={values.address2}
                  onChange={handleInputChange}
                  error={errors.address2}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="countryId"
                  label="Country"
                  value={values.countryId}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  disable={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="zip"
                  label="Zip Code"
                  value={values.zip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              {values.countryId !== Number.MIN_SAFE_INTEGER &&
              values.countryId === COUNTRIES.USA ? (
                <Grid item xs={row}>
                  <FormSelect
                    name="stateId"
                    label="State"
                    value={values.stateId}
                    onChange={handleInputChange}
                    options={states}
                    error={errors.stateId}
                    disable={isSubmitting || readOnly}
                    showSkeleton={isLoading}
                  />
                </Grid>
              ) : (
                <Grid item xs={row}>
                  <FormText
                    name="stateName"
                    label="State"
                    value={values.stateName}
                    onChange={handleInputChange}
                    error={errors.stateName}
                    showSkeleton={isLoading}
                    disabled={readOnly}
                  />
                </Grid>
              )}
              <Grid item xs={row}>
                <Stack direction="row" spacing={2}>
                  <FormText
                    name="countryCode"
                    label="Country Code"
                    value={values.countryCode}
                    error={errors.countryCode}
                    disabled={true}
                    fullWidth={false}
                  />
                  <FormMaskText
                    name="phone"
                    mask="(999) 999 - 9999"
                    label="Contact's Phone Number"
                    onChange={handleInputChange}
                    value={values.phone}
                    error={errors.phone}
                    disabled={readOnly}
                  />
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="extension"
                  label="Extension Number"
                  value={values.extension}
                  onChange={handleInputChange}
                  error={errors.extension}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormTextArea
                  name="additional"
                  label="Additional Information"
                  value={values.additional}
                  onChange={handleInputChange}
                  error={errors.additional}
                  placeholder=""
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={cancelHandler}
                    isSubmitting={isSubmitting}
                  />
                  {fullAccess && (
                    <FormSaveButton
                      onClick={saveHandler}
                      isSubmitting={isSubmitting}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

export default Provider;
