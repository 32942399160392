import Popup from "src/components/Popup";
import { FormButton, FormRadioGroup } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import FormNumeric from "src/components/formControls/FormNumeric";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import { estimatedTime } from "src/constants";
import {
  IEstimatedTime,
  IProposalProject,
} from "src/ts/interfaces/projectEstimatedTime";
import { Validator } from "src/ts/types";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import projectContestService from "src/services/projectContestService";
import { useEffect } from "react";
import getProjectService from "src/services/project/getProjectService";

interface TimeDurationProps {
  timeDurationEnteredShowPopUp: boolean;
  setTimeDurationEntered: (val: boolean) => void;
  currentRow: IProjectOutForBidData | undefined;
  setRefreshPage: (val: boolean) => void;
  refreshPage: boolean;
}

const initialValues: IProposalProject = {
  estimatedTime: 0,
  isDays: false,
};

export default function TimeDurationEnteredPopUp({
  timeDurationEnteredShowPopUp,
  setTimeDurationEntered,
  currentRow,
  setRefreshPage,
  refreshPage,
}: TimeDurationProps) {
  const { log } = useLog();
  const { execute, data } = useAsyncQuery<IEstimatedTime>(
    getProjectService.getEstimatedTime
  );
  const validate: any = (fieldValues: IProposalProject = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.estimatedTime = new Validator(fieldValues, "estimatedTime")
      .isRequired("The time duration is required.")
      .greatThan(0, "Must be great than 0")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, setErrors, errors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const handlerAccept = async () => {
    if (!validate()) return;

    try {
      await projectContestService.putEstimatedTime({
        estimatedTimeDuration: Number(values.estimatedTime),
        estimatedTimeDurationUnit: values.isDays ? 2 : 1,
        id: currentRow?.projectContestId ?? 0,
      });
      setRefreshPage(!refreshPage);
      setTimeDurationEntered(false);
      log.success("Estimated time was updated.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    const dataInfo = async () => {
      execute(currentRow?.id);
    };
    dataInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.estimatedTimeDuration !== null) {
      setValues({
        ...values,
        estimatedTime: data.estimatedTimeDuration,
        isDays: data.estimatedTimeDurationUnit !== 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={timeDurationEnteredShowPopUp}
        setOpenPopup={setTimeDurationEntered}
        onClose={() => {
          setTimeDurationEntered(false);
        }}
        size="sm"
      >
        <>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={5}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>
                    For Project Award - Owner Request You Enter a Project Time
                    Duration
                  </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormNumeric
                      name="estimatedTime"
                      label="Time"
                      value={values.estimatedTime.toString()}
                      onChange={handleInputChange}
                      error={errors.estimatedTime}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormRadioGroup
                      name="isDays"
                      label=""
                      row={true}
                      value={values?.isDays ? 1 : 0}
                      onChange={(e) => {
                        values.isDays = e.target.value === "1" ? true : false;
                        setValues({ ...values });
                      }}
                      items={estimatedTime}
                      showSkeleton={false}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setTimeDurationEntered(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              handlerAccept();
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
