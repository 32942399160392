import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Chip,
  InputLabel,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useForm, Form, useCountry } from "src/hooks";
import {
  FormCancelButton,
  FormSelect,
  FormText,
  FormSelectState,
  FormCheckBox,
  FormMaskText,
  FormButton,
} from "src/components/formControls";
import CompanyService from "src/services/companyService";
import SubscriptionService from "src/services/subscriptionService";
import {
  ICompany,
  IKeyValue,
  ISystemCostsCatalog,
  IWeekDays,
} from "src/ts/interfaces";
import CatalogService from "src/services/catalogService";
import { CompanyTypeEnum, subscriptionEnum } from "src/ts/enums";
import WeekEndingPayPeriods from "../components/WeekEndingPayPeriods";
import { CompanyParams, Validator } from "src/ts/types";
import PayPopup from "../components/PayPopup";
import SatellitePopup from "../components/SatellitePopup";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: ICompany = {
  id: 0,
  name: "",
  subscriptionId: 0,
  statusId: 1,
  isActive: true,
  companyTypeId: -1,
  otherCompanyType: "",
  address: "",
  address2: "",
  countryId: 0,
  stateId: 0,
  zip: "",
  city: "",
  phone: null,
  extension: "",
  email: "",
  weekStartDay: 2,
  weekEndDay: 1,
  walletDemoEndpointEnabled: false,
  headquarters: false,
  parentCompanyId: 0,
  walletAutoReload: false,
  walletAutoWithdraw: false,
  walletBlocked: false,
  walletAdminBlocked: false,
  storageSize: 0,
  signupDone: false,
  isDeleted: false,
  elevation: 0,
  unionAffiliated: false,
  answerAfiliated: null,
  profileCompleted: false,
  isNotCurrentlyEmployed: false,
  isPendingToBeApproved: false,
};

const CompanyView = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  const { setSelectedCountryById, selectedCountry, countriesKeyValue } =
    useCountry();

  let { id } = useParams<CompanyParams>();
  const companyId = Number(id);
  const isNewCompany = id === "0";

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("This field is required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.subscriptionId = new Validator(fieldValues, "subscriptionId")
      .selectedOption("", "Subscription is required.")
      .toString();

    temp.companyTypeId = new Validator(fieldValues, "companyTypeId")
      .selectedOption("", "Company type is required.")
      .toString();

    temp.otherCompanyType = new Validator(fieldValues, "otherCompanyType")
      .validateIf(
        // eslint-disable-next-line eqeqeq
        Number(fieldValues.companyTypeId) == CompanyTypeEnum.Other &&
          // eslint-disable-next-line eqeqeq
          Number(fieldValues.subscriptionId) ==
            subscriptionEnum.CertifiedSoftwareSubscription
      )
      .isRequired("This field is required.")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("The Company's zipcode is required")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The Company's city is required.")
      .toString();

    temp.phone = new Validator(fieldValues, "phone")
      .maxLength(10, "Incorrect phone format.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActiveParentCompany, setIsActiveParentCompany] = useState(true);

  const [subcriptionData, setSubcriptionData] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);

  const [companyTypes, setCompanyTypes] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);

  const [isStateLoading, setIsStateLoading] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    const loadCatalogs = async () => {
      try {
        const responses = await Promise.all([
          SubscriptionService.getKeyValues(),
          CatalogService.getCompanyTypeKeyValues(),
        ]);

        setSubcriptionData(responses[0]);
        setCompanyTypes(responses[1]);

        if (companyId > 0 && !isStateLoading) {
          const request = await CompanyService.getIsActiveParentCompany(
            companyId
          );
          setIsActiveParentCompany(request.data);
          const response = await CompanyService.getById(companyId);
          setValues(response.data);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    loadCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, isStateLoading, setValues]);

  const handleCancel = () => {
    navigate("/app/companies");
  };

  useEffect(() => {
    setSelectedCountryById(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  const handleWeekEndingPayChange = (weekDays: IWeekDays) => {
    setValues({ ...values, ...weekDays });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itemCostCatalog, setItemCostCatalog] =
    useState<ISystemCostsCatalog | null>(null);
  const [showBuyModal, setShowBuyModal] = useState(false);

  const [showSatelliteModal, setShowSatelliteModal] = useState(false);

  const showSatelliteHandler = async () => {
    setShowSatelliteModal(true);
  };

  const closeBuyModalHandler = (e: boolean) => {
    setShowBuyModal(e);
  };

  const row = 6;

  return (
    <>
      <Helmet title="Company" />
      <PayPopup
        itemCostCatalog={itemCostCatalog!}
        showBuyModal={showBuyModal}
        onChangeShowBuyModal={closeBuyModalHandler}
        companyId={companyId}
        companyName={""}
      />

      <SatellitePopup
        showSatellitePopup={showSatelliteModal}
        setShowSatellitePopup={setShowSatelliteModal}
        companyId={companyId}
      />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography variant="h3" gutterBottom display="inline">
              Company
            </Typography>

            {values.headquarters ? (
              <Chip label="Headquarters" color="primary" size="small" />
            ) : (
              <Chip
                label="Satellite"
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
          </Stack>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/app/companies">
              Companies
            </Link>
            <Typography>Company</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            {values?.headquarters &&
              values.satelliteCount !== undefined &&
              values.satelliteCount > 0 && (
                <FormButton
                  onClick={showSatelliteHandler}
                  isSubmitting={false}
                  text="View Satellite(s)"
                  size="medium"
                  variant="outlined"
                />
              )}
          </Stack>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormText
                  name="name"
                  label="Company Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="subscriptionId"
                  label="Subscription"
                  value={values.subscriptionId}
                  onChange={handleInputChange}
                  options={subcriptionData}
                  error={errors.subscriptionId}
                  defaultValue={{ key: -1, value: "Select one" }}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="companyTypeId"
                  label="Company type"
                  value={values.companyTypeId}
                  onChange={handleInputChange}
                  options={companyTypes}
                  error={errors.companyTypeId}
                  defaultValue={{ key: -1, value: "Select one" }}
                  disabled={true}
                />
              </Grid>
              {values.companyTypeId === CompanyTypeEnum.Other &&
                values.subscriptionId ===
                  subscriptionEnum.CertifiedSoftwareSubscription && (
                  <Grid item xs={row}>
                    <FormText
                      name="otherCompanyType"
                      label="Other Type"
                      value={values.otherCompanyType}
                      onChange={handleInputChange}
                      error={errors.otherCompanyType}
                      disabled={true}
                    />
                  </Grid>
                )}
              <Grid item xs={row}>
                <FormText
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address2"
                  label="Address 2"
                  value={values.address2}
                  onChange={handleInputChange}
                  error={errors.address2}
                  disabled={true}
                  placeholder="Suite, Floor, etc."
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="countryId"
                  label="Country"
                  value={values.countryId}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="zip"
                  label="Zip Code"
                  value={values.zip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelectState
                  stateId={values.stateId}
                  stateName={values.stateName}
                  countryId={values.countryId}
                  errorsStateId={errors.stateId}
                  errorsStateName={errors.stateName}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsStateLoading(value)}
                  disable={true}
                />
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row" spacing={2}>
                  <FormText
                    name="countryCode"
                    label="Country Code"
                    value={selectedCountry?.countryCode?.toString() ?? ""}
                    error={errors.countryCode}
                    disabled={true}
                    fullWidth={false}
                  />
                  <FormMaskText
                    name="phone"
                    mask="(999) 999 - 9999"
                    label="Phone"
                    onChange={handleInputChange}
                    value={values.phone}
                    error={errors.phone}
                    disabled={true}
                  />
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="extension"
                  label="Company Extension #"
                  value={values.extension}
                  onChange={handleInputChange}
                  error={errors.extension}
                  disabled={true}
                />
              </Grid>
              {isNewCompany && (
                <Grid item xs={row}>
                  <FormText
                    name="email"
                    label="Company Email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    disabled={true}
                  />
                </Grid>
              )}
              <Grid item xs={row}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <InputLabel>Company Status</InputLabel>
                  {values.isActive ? (
                    <Chip label="Active" color="success" />
                  ) : (
                    <Chip label="Inactive" color="error" />
                  )}
                  {!values.headquarters && values.id !== 0 ? (
                    <FormButton
                      onClick={() => {}}
                      text="History"
                      size="small"
                    />
                  ) : null}
                </Stack>

                {/* <Typography variant="subtitle2" gutterBottom>
                  Main Administrator:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <b>Name:</b>AMP Test Contractor account
                  <br />
                  <b>User:</b>unibescontractor@gmail.com
                </Typography> */}
              </Grid>
              <Grid item xs={row}>
                <WeekEndingPayPeriods
                  label="Week ending pay periods"
                  name="weekDay"
                  onChange={handleWeekEndingPayChange}
                  startDay={values.weekStartDay}
                  endDay={values.weekEndDay}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}></Grid>
              <Grid item xs={row}>
                {values.headquarters ? (
                  <FormCheckBox
                    label="Enable demo endpoint for Wallet transactions"
                    name="walletDemoEndpointEnabled"
                    value={values.walletDemoEndpointEnabled}
                    onChange={handleInputChange}
                    disabled={true}
                  />
                ) : null}

                <Typography
                  visibility={
                    values.walletDemoEndpointEnabled ? "visible" : "hidden"
                  }
                  variant="subtitle2"
                  gutterBottom
                >
                  All the transactions in the wallet will be done through the
                  Elavon Demo endpoint.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={false}
                    disabled={isStateLoading}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

export default CompanyView;
