import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import permissionUsersService from "src/services/permissionUsersService";
import { IPermissionUsersType } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import useAsyncMutation from "src/hooks/useAsyncMutation";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { useAuth, useLog, usePermissions } from "src/hooks";
import { FormCheckSwitch } from "src/components/formControls";
interface SystemAdministratorGridProps {
  userId: number;
  role: string;
  permissionType: string;
  showInUi: boolean;
}

const PermissionsSwitchDialog = (props: SystemAdministratorGridProps) => {
  const { log } = useLog();
  const { user } = useAuth();
  const [typeList, setTypeList] = useState<IPermissionUsersType[]>([]);
  const { readOnly } = usePermissions(props.permissionType);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedElement, setSelectedElement] =
    useState<IPermissionUsersType | null>(null);
  const [viewHide, setViewHide] = useState<boolean>(false);
  const {
    execute: updatePermission,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: updatedPermissionId,
    isSubmitting: updateSubmitting,
  } = useAsyncMutation<number>(permissionUsersService.saveItem, {
    successfulMessage: "The permission was updated",
  });

  const [isOpenCollapse, setIsOpenCollapse] = useState(-1);

  const handleOpen = (clickedIndex: number) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(-1);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        if (props.userId !== undefined) {
          const request = await permissionUsersService.getByUser(
            props.userId,
            props.role
          );
          setTypeList(request.data);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTypeList]);

  const handleSwicthChange = async (
    element: IPermissionUsersType,
    type: number
  ) => {
    if (type === 1) {
      element.fullAccess = true;
      element.readOnly = false;
    } else if (type === 0) {
      element.readOnly = true;
      element.fullAccess = false;
      element.childs &&
        element.childs
          .filter((element) => props.showInUi || element.showInUi === true)
          .forEach((child) => {
            if (child.showInUi && child.showReadOnly && child.fullAccess) {
              child.readOnly = true;
              child.fullAccess = false;
              child.parentReadDisable = true;
              child.parentDisable = false;
            }
          });
    } else {
      element.fullAccess = false;
      element.readOnly = false;
      element.childs &&
        element.childs
          .filter((element) => props.showInUi || element.showInUi === true)
          .forEach((child) => {
            if (child.showInUi && (child.fullAccess || child.readOnly)) {
              child.fullAccess = false;
              child.readOnly = false;
              child.parentDisable = true;
            }
          });
    }

    setSelectedElement({ ...element });

    await updatePermission({
      fullAccess: element.fullAccess,
      id: element.id,
      name: element.name,
      order: element.order,
      permissionTypeId: Number(element.permissionTypeId),
      readOnly: element.readOnly,
      type: element.type.toString(),
      role: element.role,
      userPersonId: props.userId,
    });
  };

  const columnsPermission = (
    readOnly: boolean,
    updateSubmitting: boolean
  ): ColumnType[] => [
    {
      id: "option",
      label: "Option",
      type: "custom",
      sort: false,
      callback: (row: IPermissionUsersType) => {
        return <div>{row.name}</div>;
      },
    },
    {
      id: "deny",
      label: "Deny",
      type: "custom",
      sort: false,
      callback: (row: IPermissionUsersType) => {
        return (
          <>
            <Switch
              checked={!row.readOnly && !row.fullAccess}
              onChange={(e) => {
                handleSwicthChange(row, 3);
              }}
              value={true}
              disabled={
                !row.showInUi ||
                !row.showDeny ||
                row.parentDisable ||
                readOnly ||
                updateSubmitting
              }
            />
          </>
        );
      },
    },
    {
      id: "readOnly",
      label: "Read Only",
      type: "custom",
      sort: false,
      callback: (row: IPermissionUsersType) => {
        return (
          <>
            <Switch
              checked={row.readOnly}
              onChange={(e) => {
                handleSwicthChange(row, 0);
              }}
              value={true}
              disabled={
                !row.showInUi ||
                !row.showReadOnly ||
                row.parentDisable ||
                readOnly ||
                updateSubmitting
              }
            />
          </>
        );
      },
    },
    {
      id: "fullAccess",
      label: "Full Access",
      type: "custom",
      sort: false,
      callback: (row: IPermissionUsersType) => {
        return (
          <>
            <Switch
              checked={row.fullAccess}
              onChange={(e) => {
                handleSwicthChange(row, 1);
              }}
              value={true}
              disabled={
                !row.showInUi ||
                !row.showFullAccess ||
                row.parentDisable ||
                row.parentReadDisable ||
                readOnly ||
                updateSubmitting
              }
            />
          </>
        );
      },
    },
  ];

  return (
    <Grid>
      {user?.userName === "LilySysAdmin" && (
        <Grid container pb={5}>
          <FormCheckSwitch
            name={"viewHide"}
            value={viewHide}
            label={"View hide items"}
            onChange={() => {
              setViewHide(!viewHide);
            }}
          ></FormCheckSwitch>
        </Grid>
      )}

      <Grid container>
        <Grid xs={5} spacing={5}>
          Option
        </Grid>
        <Grid xs={2}>Deny</Grid>
        <Grid xs={2}>Read Only</Grid>
        <Grid xs={2}>Full Access</Grid>
      </Grid>
      <List>
        {typeList
          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
          .filter((element) => element.showInUi === true || viewHide)
          .map((type, index) => (
            <Grid>
              <ListItemButton onClick={() => handleOpen(index)}>
                <ListItemText>
                  <Grid container>
                    <Grid xs={5}>{type.name}</Grid>
                    <Grid xs={2}>
                      <Switch
                        checked={!type.readOnly && !type.fullAccess}
                        onChange={(e) => {
                          handleSwicthChange(type, 3);
                        }}
                        value={true}
                        disabled={
                          !type.showInUi ||
                          !type.showDeny ||
                          readOnly ||
                          updateSubmitting
                        }
                      />
                    </Grid>
                    <Grid xs={2}>
                      <Switch
                        checked={type.readOnly}
                        onChange={(e) => {
                          handleSwicthChange(type, 0);
                        }}
                        value={true}
                        disabled={
                          !type.showInUi ||
                          !type.showReadOnly ||
                          readOnly ||
                          updateSubmitting
                        }
                      />
                    </Grid>
                    <Grid xs={2}>
                      <Switch
                        checked={type.fullAccess}
                        onChange={(e) => {
                          handleSwicthChange(type, 1);
                        }}
                        value={true}
                        disabled={
                          !type.showInUi ||
                          !type.showFullAccess ||
                          readOnly ||
                          updateSubmitting
                        }
                      />
                    </Grid>
                    <Grid xs={1}>
                      {type.childs.filter(
                        (element) => element.showInUi === true || viewHide
                      ).length > 0 &&
                        (isOpenCollapse === index ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        ))}
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItemButton>
              {type.childs.filter(
                (element) => element.showInUi === true || viewHide
              ).length > 0 && (
                <Collapse
                  in={isOpenCollapse === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid>
                    <LocalEnhancedTable<IPermissionUsersType>
                      refreshGrid={true}
                      columns={columnsPermission(
                        readOnly ?? false,
                        updateSubmitting
                      )}
                      data={
                        type.childs.filter(
                          (element) => element.showInUi === true || viewHide
                        ) ?? []
                      }
                      hidePagination={false}
                      defaultRowPerPage={10}
                      defaultSortColumn={"order"}
                    />
                  </Grid>
                </Collapse>
              )}
            </Grid>
          ))}
      </List>
    </Grid>
  );
};

export default PermissionsSwitchDialog;
