import { useAsyncQuery, useLoading } from "src/hooks";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import { Skeleton, Table, TableBody, Typography } from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import {
  IGeneralMatrix,
  IGeneralMatrixItems,
} from "src/ts/interfaces/study/generalMatrix";
import generalMatrixItemsService from "src/services/study/generalMatrixItemsService";
import generalMatrixService from "src/services/study/generalMatrixService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import ChangeReadingsPopUp from "./ChangeReadingsPopUp ";

interface Props {
  id: number;
  x: number;
  y: number;
  saveItems: boolean;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number) => void;
  useSweepMethod: boolean;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };

  values: IGeneralMatrix;
  setDataX: (val: number) => void;
  setDataY: (val: number) => void;
  dataX: number;
  dataY: number;
}

const GeneralMatrixGrid = ({
  id,
  x,
  y,
  saveItems,
  disabled,
  showSkeleton,
  onBlur,
  useSweepMethod,
  systemOfMeasurement,
  values,
  setDataX,
  setDataY,
  dataX,
  dataY,
}: Props) => {
  const [items, setItems] = useState<IGeneralMatrixItems[][]>();
  const [item, setItem] = useState<IGeneralMatrixItems>();
  const [showChangeXYModal, setShowChangeXYModal] = useState(false);

  const { startRequest, endRequest, isLoading } = useLoading();

  const { execute, data, setData } = useAsyncQuery<IGeneralMatrixItems[]>(
    generalMatrixItemsService.getByGeneralId
  );

  const { execute: executeSaveItem } = useAsyncQuery(
    generalMatrixItemsService.saveItems
  );

  const { execute: executeUpdateMatrix } = useAsyncQuery(
    generalMatrixService.update
  );

  useEffect(() => {
    const runProcess = () => {
      const matrix = Array(Math.ceil(data.length / x))
        .fill("")
        .reduce((acc, cur, index) => {
          return [...acc, [...data].splice(index * x, x)];
        }, []);
      setItems(matrix);
    };

    if (data && data.length > 0 && x > 0 && y > 0) runProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      setShowChangeXYModal(true);
    };

    const getInfo = async () => {
      await execute(id);
    };

    const getDataNew = async () => {
      await executeUpdateMatrix(id, values);
      await execute(id);
    };

    if (
      data &&
      data.length > 0 &&
      ((x > 0 && x !== dataX) || (y > 0 && y !== dataY)) &&
      y < 20
    ) {
      getData();
    } else if (data && data.length === 0 && x > 0 && y > 0 && y < 20) {
      getDataNew();
    } else {
      if (id > 0) getInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y]);

  useEffect(() => {
    const blurHandler = async () => {
      const matrixItems = items?.flat();

      await executeSaveItem(matrixItems);
    };
    if (item) {
      let total = sumItems();
      onBlur(total);
      blurHandler();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (useSweepMethod) {
      let total = 0;
      if (data) {
        let newValue = Number(data[0].item);
        data.forEach((element) => (element.item = newValue));
        total = data.length * (newValue ?? 0);
        setData(data);
        onBlur(total);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSweepMethod]);

  const setItemsCalc = async (element: IGeneralMatrixItems, e: any) => {
    let total = 0;
    let newValue = Number(e.target.value);
    if (useSweepMethod) {
      data.forEach((element) => (element.item = newValue));
      total = data.length * newValue;
      setData(data);
      onBlur(total);
    } else {
      element.item = newValue;
      setItem({ ...element });
    }
  };

  const sumItems = () => {
    const sum = data
      ? data.reduce((sum, current) => sum + (current?.item ?? 0), 0)
      : 0;
    return sum;
  };

  const width = useMemo(() => `${95 / x}%`, [x]);

  const changeMatrixXY = async () => {
    startRequest("deleteMatrix");
    await executeUpdateMatrix(id, values);
    await execute(id);

    endRequest("deleteMatrix");
  };

  const cancelModalXY = async () => {
    await executeUpdateMatrix(id, {
      ...values,
      readingsX: dataX,
      readingsY: dataY,
    });
    setShowChangeXYModal(false);
  };

  return (
    <>
      <Table>
        <TableBody>
          <SpreadSheetRow>
            <SpreadSheetCell key={`number`} width={"5%"}>
              <Typography mt={0.8} padding={1.8} fontWeight="bold">
                #
              </Typography>
            </SpreadSheetCell>
            <>
              {Array.from({ length: x }, (_, i) => (
                <SpreadSheetCell key={`${i + 1}`} width={width} minWidth={95}>
                  <Typography mt={0.8} padding={1.8} fontWeight="bold">
                    {i + 1}
                  </Typography>
                </SpreadSheetCell>
              ))}
            </>
          </SpreadSheetRow>
          {items &&
            items?.length > 0 &&
            Object.values(items ?? []).map(
              (item: IGeneralMatrixItems[], index: number) => (
                <SpreadSheetRow>
                  <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                    <Typography
                      m={2}
                      mt={0.8}
                      paddingLeft={1.8}
                      fontWeight="bold"
                    >
                      {index + 1}
                    </Typography>
                  </SpreadSheetCell>
                  <>
                    {item.map((element: IGeneralMatrixItems, indexy) => (
                      <SpreadSheetCell
                        key={`${element.id}`}
                        width={width}
                        minWidth={95}
                      >
                        {showSkeleton || isLoading ? (
                          <Skeleton height={50} />
                        ) : (
                          <>
                            <SpreadsheetNumericInput2
                              name={element.id.toString()}
                              value={element.item}
                              onChange={(e: any) => {
                                setItemsCalc(element, e);
                              }}
                              decimalScale={3}
                              maxValue={1000000}
                              disabled={
                                disabled ||
                                (useSweepMethod === true &&
                                  !(index === 0 && indexy === 0))
                              }
                              mode={
                                disabled ||
                                (useSweepMethod === true &&
                                  !(index === 0 && indexy === 0))
                                  ? "read"
                                  : undefined
                              }
                              placeholder={systemOfMeasurement.get("phfpm")}
                            ></SpreadsheetNumericInput2>
                          </>
                        )}
                      </SpreadSheetCell>
                    ))}
                  </>
                </SpreadSheetRow>
              )
            )}
        </TableBody>
      </Table>
      <ChangeReadingsPopUp
        onConfirm={changeMatrixXY}
        text={`Are you certain you want to change the values of the readings (X,Y)?`}
        matchWord={"CHANGE"}
        isDialogOpen={showChangeXYModal}
        setIsDialogOpen={setShowChangeXYModal}
        onCancel={cancelModalXY}
      />
    </>
  );
};

export default GeneralMatrixGrid;
