import axios from "src/utils/axios";
import { IPitotMatrixTitle } from "src/ts/interfaces/study/pitot";

const getByPitotId = (id: number, orientation: number) => {
  return axios.get<IPitotMatrixTitle[]>(
    `PitotMatrixTitle?pitotMatrixId=${id}&orientation=${orientation}`
  );
};

const pitotMatrixTitleService = {
  getByPitotId,
};

export default pitotMatrixTitleService;
