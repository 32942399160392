import { IRoleAlias } from "../ts/interfaces";
import axios from "../utils/axios";

const getRolesCanView = () => {
  return axios.get<IRoleAlias[]>("role/rolesCanView");
};

const roleService = {
  getRolesCanView,
};

export default roleService;
