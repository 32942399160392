import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../../project/types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAsyncQuery, useAuth } from "src/hooks";

import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import projectService from "src/services/project/projectService";
import moment from "moment";
import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import CloseProjectPopUp from "../componentsArchitectProject/components/CloseProjectPopUp";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import projectContestService from "src/services/projectContestService";
import { TableData } from "src/ts/interfaces";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { projectPutService } from "src/services";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

const getColumns = (
  navigate: NavigateFunction,
  companyID: number,
  setCurrentRow: (val: IGetProjectInfectious) => void,
  setCloseProjectShowPopUp: (val: boolean) => void,
  setDeleteProjectModal: (val: boolean) => void,
  setSendbyEmailShow: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "contestDueDate",
    label: "Proposal Due Date",
    type: "custom",
    sort: true,
    callback: (row: IArchitectProjects) => {
      const dateRow = new Date(row.contestDueDate);
      const currentDate = new Date();
      const pastDate = currentDate > dateRow;

      return (
        <Typography sx={{ color: pastDate ? "red" : "" }}>
          {moment(row.contestDueDate).format("MM/DD/YYYY hh:mm A")}
        </Typography>
      );
    },
  },

  {
    id: "buildingName",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IArchitectProjects) => {
      return (
        <Typography>
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },

  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IGetProjectInfectious) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              navigate(`/app/ProjectContractors/${row.id}/${companyID}`);
            }}
            tooltip="Review Project"
          />
          <GridActionButton
            type="close"
            onClick={() => {
              setCurrentRow(row);
              setCloseProjectShowPopUp(true);
            }}
            tooltip="Close Project"
          />
          <GridActionButton
            type="delete"
            onClick={() => {
              setCurrentRow(row);
              setDeleteProjectModal(true);
            }}
            tooltip="Delete Project"
          />
          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/DocumentLibrary/${row.id}/${companyID}/Project`);
            }}
            tooltip="Project's Document Library"
          />
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(`/app/PhotoLibrary/${row.id}/${companyID}/Project`);
            }}
            tooltip="Project's Picture Library"
          />
          <GridActionButton
            type="email"
            onClick={() => {
              setCurrentRow(row);
              setSendbyEmailShow(true);
            }}
            tooltip="Forward Project"
          />
        </Stack>
      </>
    ),
  },
];

interface OutForBidProjectInfectiousProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IGetProjectInfectious[];
  isLoading: boolean;
  refreshGrid: boolean;
  setDataTable: (val: IGetProjectInfectious[]) => void;
}

export default function OutForBidInfectious({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  setDataTable,
}: OutForBidProjectInfectiousProps) {
  const [currentRow, setCurrentRow] = useState<IGetProjectInfectious>();
  const [closeProjectShowPopUp, setCloseProjectShowPopUp] = useState(false);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [sendbyEmailShow, setSendbyEmailShow] = useState(false);

  let navigate = useNavigate();
  const { user } = useAuth();

  const { execute: executeDelete } = useAsyncQuery(
    projectService.getDeleteProject
  );

  const { execute: executeSendMembers } = useAsyncQuery(
    projectContestService.postSendMembers
  );

  const { execute: executeProjectSingle, data: dataProjectSingle } =
    useAsyncQuery<ProjectDTO>(getProjectSingleService.getById);

  const { execute: executeUpdateProjectSingle } = useAsyncQuery<ProjectDTO>(
    projectPutService.update
  );

  let columns = getColumns(
    navigate,
    user?.companyId,
    setCurrentRow,
    setCloseProjectShowPopUp,
    setDeleteProjectModal,
    setSendbyEmailShow
  );

  useEffect(() => {
    const updateProject = async () => {
      await executeUpdateProjectSingle(currentRow?.id ?? 0, {
        ...dataProjectSingle,
        projectStatus: {
          id: 4,
          name: "Removed",
        },
      });
    };

    if (dataProjectSingle) updateProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectSingle]);

  const deleteProject = async () => {
    await executeDelete(currentRow?.id);

    if (dataTable.length >= 0) {
      const dataSplice = dataTable.filter((item: IGetProjectInfectious) => {
        return item.id !== currentRow?.id;
      });
      setDataTable(dataSplice);
      executeProjectSingle(currentRow?.id);
      setRefreshGrid(!refreshGrid);
    }
  };

  const handleSendEmail = async (dataTable: TableData[]) => {
    const teamMemberIds = dataTable
      .filter((item) => item.userType === "TeamMember")
      .map((item) => item.id) as number[];

    await executeSendMembers(currentRow?.id, teamMemberIds);
  };

  return (
    <>
      <LocalEnhancedTable<IGetProjectInfectious>
        refreshGrid={false}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="asc"
        defaultSortColumn="id"
      />
      {closeProjectShowPopUp && (
        <CloseProjectPopUp
          setCloseProjectShowPopUp={setCloseProjectShowPopUp}
          closeProjectShowPopUp={closeProjectShowPopUp}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
          isInfectious={true}
        />
      )}
      <MatchWordConfirmModal
        onConfirm={deleteProject}
        text={`Are you certain you want to delete permanently this project?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteProjectModal}
        setIsDialogOpen={setDeleteProjectModal}
      />
      {sendbyEmailShow && (
        <ShareByEmailPopUpComponent
          sendEmailFunction={handleSendEmail}
          setSendbyEmailShow={setSendbyEmailShow}
          sendbyEmailShow={sendbyEmailShow}
          onlyTeamMember={true}
        />
      )}
    </>
  );
}
