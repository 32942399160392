import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import { IVelgridMatrix } from "src/ts/interfaces/study/velgridMatrix";
import velgridMatrixService from "src/services/study/velgridMatrixService";
import { DuctType } from "src/constants";
import { CatalogService } from "src/services";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import VelgridGrid from "./components/VelgridGrid";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import getProjectService from "src/services/project/getProjectService";
import SteadyStateDialog from "../components/SteadyState";
import { IStudyStep } from "src/ts/interfaces";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";
const initialValues: IVelgridMatrix = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  numberOfDucts: 0,
  systemTotal: false,
  outletTotal: false,
  conditionId: 0,
  temperatureCompensated: false,
  barometricalyCompensated: false,
  centerlineStaticPressureId: 0,
  x: 0,
  y: 0,
  relativeHumidityBool: false,
  wetBulbFBool: false,
  dewPointBool: false,
  ductSize: 0,
  ductSizeBase: 0,
  ductSizeHeight: 0,
  comments: "",
  nocVisual: false,
  nocActual: false,
  ductTypeId: DuctType.Rectangular,
  outsideAir: false,
  isActualLocalBarometricPressure: false,
  step: 0,
  isComplete: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  totalHeat: false,
  customMatrix: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const VelgridReport = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  let navigate = useNavigate();
  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(velgridMatrixService.update, {
      successfulMessage: "Report was updated successfully",
      onSuccess: (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh } = useAsyncQuery(
    velgridMatrixService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    }
  );

  const { execute: executeRefreshPsyco } = useAsyncQuery(
    velgridMatrixService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
        calcPsycometrics(result?.totalCFM!, result);
      },
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (params.step === "step2") {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .isRequired("Airflow Temperature For Energy Calculation Is Required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IVelgridMatrix>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IVelgridMatrix>(
    velgridMatrixService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          if (!dataResult.ductTypeId)
            dataResult.ductTypeId = DuctType.Rectangular;
          if (
            !dataResult.relativeHumidityBool &&
            !dataResult.wetBulbFBool &&
            !dataResult.dewPointBool
          ) {
            dataResult.relativeHumidityBool = true;
          }
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = formulas.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }
          setValues({ ...dataResult });
          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(
              dataResult.projectId ?? values.projectId
            );
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const steps: IStudyStep[] = [
    {
      label: "Step 1",
      key: 0,
      description: "System configuration",
      url: "/studies/VelgridMatrix/step1/:id",
    },
    {
      label: "Step 2",
      key: 1,
      description: "Readings",
      url: "/studies/VelgridMatrix/step2/:id",
    },
    {
      label: "Step 3",
      key: 2,
      description: "Report",
      url: "/studies/VelgridMatrix/step3/:id",
    },
  ];

  const [activeStep, setActiveStep] = useState<number>(
    params.step === "step1" ? 0 : 1
  );
  const [saveItems, setSaveItems] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeReport(id, "VEMR");
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let dimensionTotal = formulas?.sqft(
      ((values?.dimensionWidth ?? 0) - (values?.insulationThickness ?? 0) * 2) *
        ((values?.dimensionHeight ?? 0) -
          (values?.insulationThickness ?? 0) * 2)
    );

    setValues({
      ...values,
      dimensionTotal: dimensionTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.insulationThickness,
  ]);

  useEffect(() => {
    let ductSizeBaseCalc =
      values?.ductSizeBase - (values?.insulationThickness ?? 0) * 2;

    let ductSize = formulas?.sqft(
      (ductSizeBaseCalc / 2) * (ductSizeBaseCalc / 2) * 3.14159265359
    );

    setValues({
      ...values,
      ductSize: ductSize ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.ductSizeBase, values?.insulationThickness]);

  useEffect(() => {
    if (!disableStudy && dataReport)
      calcPsycometrics(values?.totalCFM!, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === "step1" ? 0 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const calcTotal = async (totalItems: number) => {
    if (values && values.id > 0) {
      values.totalVelocity = totalItems;
      values.avgVelocity = totalItems / (values.x * values.y);
      setValues({
        ...values,
      });
      executeRefresh(values.id, values);
    }
  };

  const saveOnBlurHandler = async (e: any, ductType?: number) => {
    if (e.target.name === "ductTypeId") e.target.value = ductType;
    const newValues = { ...values, [e.target.name]: e.target.value };
    executeRefresh(newValues.id, newValues);
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6))
      setTotalCFM(totalCFM);
  };

  const onBlurCalcPsycometrics = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    calcPsycometrics(newValues?.totalCFM!, newValues);
  };

  const calcPsycometrics = async (total: number, newValues: any) => {
    let humidityMeasuredAs = "";
    let humidityValue = 0;
    if (newValues?.relativeHumidityBool) {
      humidityMeasuredAs = "RH";
      humidityValue = newValues?.relativeHumidity ?? 0;
    }
    if (newValues?.wetBulbFBool) {
      humidityMeasuredAs = "WB";
      humidityValue = newValues?.wetBulbF ?? 0;
    }
    if (newValues?.dewPointBool) {
      humidityMeasuredAs = "DP";
      humidityValue = newValues?.dewPoint ?? 0;
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: newValues.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: newValues.dryBulbF!,
      totalCFM: total,
    };
    if (newValues.dryBulbF && total) {
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );

      if (
        psychrometricRes.psychrometric.message.includes("must be between") ||
        psychrometricRes.psychrometric.message.includes("cannot exceed")
      )
        log.warning(psychrometricRes.psychrometric.message);
      else log.info(psychrometricRes.psychrometric.message);
      newValues.btuh = psychrometricRes.btuh!;
      newValues.sensibleHeat = psychrometricRes.sensibleHeat!;
      setValues({
        ...newValues,
      });
      if (newValues.id > 0) await executeRefresh(newValues.id, newValues);
    }
  };

  const handleChangeSpecificDefault = async (e: any) => {
    let isActualLocalBarometricPressure = e.target.value;
    const copyValues = { ...values };
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF:
          systemOfMeasurement.measurementSystem === "imperialSystem"
            ? 70
            : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      setValues({
        ...values,
        useSiteSpecificDefault:
          psychrometricRes.psychrometric?.atmosphericPress,
        isActualLocalBarometricPressure: isActualLocalBarometricPressure,
      });
      copyValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      copyValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    } else {
      setValues({
        ...values,
        isActualLocalBarometricPressure: isActualLocalBarometricPressure,
      });
      copyValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    }
    await executeRefreshPsyco(copyValues.id, copyValues);
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      await executeUpdate(values.id, values);
      setSaveItems(!saveItems);
    }
    if (params.step === "step1") navigate(`/studies/VelgridMatrix/step2/${id}`);
    else navigate(`/studies/VelgridMatrix/step3/${id}`);
    setActiveStep(1);
  };

  const handleCheckNoc = (e: any) => {
    values.nocActual = false;
    values.nocVisual = false;
    switch (e.target.name) {
      case "nocActual": {
        values.nocActual = true;
        break;
      }
      case "nocVisual": {
        values.nocVisual = true;
        break;
      }
    }
    setValues({ ...values });
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
    setSaveItems(!saveItems);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const mode = params.step === "step2" ? "read" : "read&Write";

  const handleInputChangeBarometric = async (e: any) => {
    const copyValues = { ...values };
    setValues({
      ...values,
      barometricalyCompensated: e.target.value,
    });
    copyValues.barometricalyCompensated = e.target.value;
    await executeRefreshPsyco(copyValues.id, copyValues);
  };

  const handleInputChangeCompesation = (e: any) => {
    let temperatureCompensated = e.target.value;
    let dryBulbF = values?.dryBulbF;
    if (values.airflowTemperature === values.dryBulbF) {
      dryBulbF = formulas?.getAirflowTemperature();
    }
    let airflowTemperature = formulas?.getAirflowTemperature();
    setValues({
      ...values,
      temperatureCompensated: temperatureCompensated,
      dryBulbF: dryBulbF,
      airflowTemperature: airflowTemperature,
    });
  };

  const handleBackStep = async () => {
    if (!disableStudy) await handleSave();
    navigate(`/studies/VelgridMatrix/step1/${id}`);
    setActiveStep(0);
  };

  const handleChangeRadio = (e: any) => {
    const copyvalues = { ...values };
    copyvalues.relativeHumidityBool = false;
    copyvalues.dewPointBool = false;
    copyvalues.wetBulbFBool = false;
    copyvalues.relativeHumidity = undefined;
    copyvalues.wetBulbF = undefined;
    copyvalues.dewPoint = undefined;
    switch (e.target.name) {
      case "relativeHumidityBool": {
        copyvalues.relativeHumidityBool = true;
        break;
      }
      case "dewPointBool": {
        copyvalues.dewPointBool = true;
        break;
      }
      case "wetBulbFBool": {
        copyvalues.wetBulbFBool = true;
        break;
      }
    }
    calcPsycometrics(copyvalues?.totalCFM!, copyvalues);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "VEMR",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={values?.projectId ?? 0}
            reportId={id}
            reportTypeId={6}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading || isSubmittingUpdate}
                    disabled={disableStudy}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={isLoading || isSubmittingUpdate}
                    disabled={disableStudy}
                    mode={mode}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextArea
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={isLoading || isSubmittingUpdate}
                    disabled={disableStudy}
                    mode={mode}
                  ></FormTextArea>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <Grid container mt={4}>
                    <Grid item xs={4} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Type of Terminal Device being tested
                      </Typography>
                    </Grid>
                    {params.step === "step2" && (
                      <Grid item xs={6}>
                        <Typography mt={2}>
                          {values?.ductTypeId === DuctType.Round
                            ? "Round"
                            : "Rectangular"}
                        </Typography>
                      </Grid>
                    )}
                    {params.step === "step1" && (
                      <Grid item xs={3}>
                        <FormCheckBox
                          name={"ductTypeId"}
                          onChange={(e: any) => {
                            setValues({
                              ...values,
                              ductTypeId: DuctType.Rectangular,
                            });
                            saveOnBlurHandler(e, DuctType.Rectangular);
                          }}
                          label={"Rectangular"}
                          showSkeleton={isLoading || isSubmittingUpdate}
                          value={values?.ductTypeId === DuctType.Rectangular}
                          disabled={disableStudy}
                          mode={mode}
                        ></FormCheckBox>
                      </Grid>
                    )}
                    {params.step === "step1" && (
                      <Grid item xs={3}>
                        <FormCheckBox
                          name={"ductTypeId"}
                          onChange={(e: any) => {
                            setValues({
                              ...values,
                              ductTypeId: DuctType.Round,
                            });
                            saveOnBlurHandler(e, DuctType.Round);
                          }}
                          label={"Round"}
                          showSkeleton={isLoading || isSubmittingUpdate}
                          value={values?.ductTypeId === DuctType.Round}
                          disabled={disableStudy}
                          mode={mode}
                        ></FormCheckBox>
                      </Grid>
                    )}
                  </Grid>
                  {values?.ductTypeId === DuctType.Rectangular && (
                    <Grid container mt={4}>
                      <Grid item xs={4} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Rectangular Dimension
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <FormNumeric2
                          name={"dimensionWidth"}
                          label={"Width"}
                          value={values?.dimensionWidth?.toString() ?? ""}
                          onChange={handleInputChange}
                          fullWidth="150px"
                          showSkeleton={isLoading || isSubmittingUpdate}
                          disabled={disableStudy}
                          mode={mode}
                          onBlur={saveOnBlurHandler}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid container>
                          <Grid
                            item
                            width={"20px"}
                            textAlign={"left"}
                            ml={2}
                            mt={2}
                          >
                            <Typography display={"inline"}>
                              {systemOfMeasurement.get("in")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            width={"40px"}
                            textAlign={"center"}
                            ml={2}
                            mt={2}
                          >
                            <Typography
                              display={"inline"}
                              variant="subtitle2"
                              ml={2}
                              mt={2}
                            >
                              by
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <FormNumeric2
                          name={"dimensionHeight"}
                          label={"Heigth"}
                          value={values?.dimensionHeight?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading || isSubmittingUpdate}
                          disabled={disableStudy}
                          mode={mode}
                          onBlur={saveOnBlurHandler}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid
                        item
                        width={"20px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      >
                        <Typography display={"inline"}>
                          {systemOfMeasurement.get("in")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"} ml={4}>
                        <FormNumeric2
                          name={"dimensionTotal"}
                          label={systemOfMeasurement.get("sqft")}
                          value={values?.dimensionTotal?.toString() ?? ""}
                          mode="read"
                          showSkeleton={isLoading || isSubmittingUpdate}
                          disabled={disableStudy}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid
                        item
                        width={"40px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      ></Grid>
                    </Grid>
                  )}
                  {values?.ductTypeId === DuctType.Round && (
                    <Grid container mt={4}>
                      <Grid item xs={4} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Round duct diameter ID
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <FormNumeric2
                          name={"ductSizeBase"}
                          label={"Diameter"}
                          value={values?.ductSizeBase?.toString() ?? ""}
                          onChange={handleInputChange}
                          fullWidth="150px"
                          showSkeleton={isLoading || isSubmittingUpdate}
                          disabled={disableStudy}
                          mode={mode}
                          onBlur={saveOnBlurHandler}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid
                        item
                        width={"20px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      >
                        <Typography display={"inline"}>
                          {systemOfMeasurement.get("in")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"} ml={4}>
                        <FormNumeric2
                          name={"ductSize"}
                          label={systemOfMeasurement.get("sqft")}
                          value={values?.ductSize?.toString() ?? ""}
                          mode="read"
                          showSkeleton={isLoading || isSubmittingUpdate}
                          disabled={disableStudy}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid
                        item
                        width={"40px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      ></Grid>
                    </Grid>
                  )}
                  <Grid container mt={4}>
                    <Grid item xs={4} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Number of readings
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"x"}
                        label={"X axis ↔"}
                        value={values?.x?.toString() ?? ""}
                        onChange={handleInputChange}
                        fullWidth="150px"
                        showSkeleton={isLoading || isSubmittingUpdate}
                        disabled={!values?.customMatrix || disableStudy}
                        mode={mode}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid container>
                        <Grid item xs={12} textAlign={"center"} ml={2} mt={2}>
                          <Typography
                            display={"inline"}
                            variant="subtitle2"
                            ml={2}
                            mt={2}
                          >
                            by
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"y"}
                        label={"Y axis ↕"}
                        value={values?.y?.toString() ?? ""}
                        onChange={handleInputChange}
                        showSkeleton={isLoading || isSubmittingUpdate}
                        disabled={!values?.customMatrix || disableStudy}
                        mode={mode}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item width={"20px"} textAlign={"left"} ml={2} mt={2}>
                      <Typography display={"inline"}></Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FormCheckBox
                        name={"customMatrix"}
                        label={"Custom Matrix"}
                        value={values?.customMatrix}
                        onChange={handleInputChange}
                        showSkeleton={isLoading || isSubmittingUpdate}
                        disabled={disableStudy}
                        mode={mode}
                      ></FormCheckBox>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={4} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Condition of Test
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelect
                        name={"conditionId"}
                        label={""}
                        onChange={handleInputChange}
                        showSkeleton={isLoading || isSubmittingUpdate}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        disabled={disableStudy}
                        mode={mode}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test",
                        }}
                      ></FormSelect>
                    </Grid>
                  </Grid>
                  {isNOC && (
                    <Grid container mt={4}>
                      <Grid item xs={4} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Normal Operating Condition
                        </Typography>
                      </Grid>
                      {params.step === "step2" && (
                        <Grid item xs={6}>
                          {values?.nocVisual && (
                            <Typography pt={2}>
                              Visual Damper Position
                            </Typography>
                          )}
                          {values?.nocActual && (
                            <Typography pt={2}>
                              Actual Damper Position
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {params.step === "step1" && (
                        <Grid item xs={3}>
                          <FormCheckBox
                            name={"nocVisual"}
                            onChange={handleCheckNoc}
                            label={"Visual Damper Position"}
                            showSkeleton={isLoading}
                            value={values?.nocVisual}
                            disabled={disableStudy}
                            mode={mode}
                          ></FormCheckBox>
                        </Grid>
                      )}
                      {params.step === "step1" && (
                        <Grid item xs={3}>
                          <FormCheckBox
                            name={"nocActual"}
                            onChange={handleCheckNoc}
                            label={"Actual Damper Position"}
                            showSkeleton={isLoading}
                            value={values?.nocActual}
                            disabled={disableStudy}
                            mode={mode}
                          ></FormCheckBox>
                        </Grid>
                      )}
                      <Grid item xs={4} pr={3}></Grid>
                      <Grid item xs={1}>
                        <FormNumeric2
                          name={"nocOaNumber"}
                          label={""}
                          value={values?.nocOaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography m={2}>% O/A</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <FormNumeric2
                          name={"nocRaNumber"}
                          label={""}
                          value={values?.nocRaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography m={2}>% R/A</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <FormNumeric2
                          name={"nocMaNumber"}
                          label={""}
                          value={values?.nocMaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography m={2}>% M/A</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <FormNumeric2
                          name={"nocExhaustNumber"}
                          label={""}
                          value={values?.nocExhaustNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography ml={2} mt={2}>
                          % Exhaust
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container mt={4}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"totalHeat"}
                        label={"Total Heat"}
                        showSkeleton={isLoading || isSubmittingUpdate}
                        onChange={handleInputChange}
                        disabled={disableStudy || params.step === "step2"}
                        mode={values?.isComplete ? mode : "read&Write"}
                        value={values?.totalHeat}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={3} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"temperatureCompensated"}
                        label={"Correct For Temperature Compensation"}
                        showSkeleton={isLoading || isSubmittingUpdate}
                        onChange={handleInputChangeCompesation}
                        disabled={disableStudy}
                        mode={values?.isComplete ? mode : "read&Write"}
                        value={values?.temperatureCompensated}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={3} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"barometricalyCompensated"}
                        label={"Correct For Barometric Compensation"}
                        showSkeleton={isLoading || isSubmittingUpdate}
                        onChange={handleInputChangeBarometric}
                        disabled={disableStudy}
                        value={values?.barometricalyCompensated}
                      ></FormCheckSwitch>
                    </Grid>
                  </Grid>
                  {params.step === "step2" && values && (
                    <>
                      <Grid container spacing={3} mt={3}>
                        {values?.barometricalyCompensated && (
                          <>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3} textAlign={"left"}>
                              <FormCheckSwitch
                                name={"isActualLocalBarometricPressure"}
                                label={"Use Site Specific Default"}
                                showSkeleton={isLoading || isSubmittingUpdate}
                                onChange={handleChangeSpecificDefault}
                                disabled={disableStudy}
                                value={values?.isActualLocalBarometricPressure}
                              ></FormCheckSwitch>
                            </Grid>
                            <Grid item xs={4}>
                              <>
                                {values?.isActualLocalBarometricPressure && (
                                  <FormNumeric2
                                    name={"useSiteSpecificDefault"}
                                    label={"Local Barometric Pressure Default"}
                                    value={
                                      values?.useSiteSpecificDefault?.toString() ??
                                      ""
                                    }
                                    onChange={handleInputChange}
                                    onBlur={saveOnBlurHandler}
                                    showSkeleton={
                                      isLoading || isSubmittingUpdate
                                    }
                                    mode={"read"}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  ></FormNumeric2>
                                )}
                                {!values?.isActualLocalBarometricPressure && (
                                  <FormNumeric2
                                    name={"localBarometricPressure"}
                                    label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                    value={
                                      values?.localBarometricPressure?.toString() ??
                                      ""
                                    }
                                    onChange={handleInputChange}
                                    onBlur={saveOnBlurHandler}
                                    showSkeleton={
                                      isLoading || isSubmittingUpdate
                                    }
                                    decimalScale={3}
                                    thousandSeparator={true}
                                  ></FormNumeric2>
                                )}
                              </>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} mt={6}>
                          <Divider my={3} m={0}></Divider>
                        </Grid>
                        <Grid item xs={12} pb={3}>
                          {values && id && (
                            <VelgridGrid
                              id={id}
                              x={values?.x}
                              saveItems={saveItems}
                              disabled={disableStudy}
                              showSkeleton={isLoading || isSubmittingUpdate}
                              onBlur={calcTotal}
                              systemOfMeasurement={systemOfMeasurement}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"totalVelocity"}
                            label={"Total Velocity"}
                            value={values?.totalVelocity?.toString() ?? ""}
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"avgVelocity"}
                            label={"Avg Velocity"}
                            value={values?.avgVelocity?.toString() ?? ""}
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"trueCorectedAirVelocity"}
                            label={"True Corrected Average Velocity"}
                            value={
                              values?.trueCorectedAirVelocity?.toString() ?? ""
                            }
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"totalCFM"}
                            label={`Actual ${systemOfMeasurement.get("acfm")}`}
                            value={values?.totalCFM?.toString() ?? ""}
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"required"}
                                label={`Required ${systemOfMeasurement.get(
                                  "cfm"
                                )}`}
                                value={values?.required?.toString() ?? ""}
                                onChange={handleInputChange}
                                disabled={disableStudy}
                                onBlur={saveOnBlurHandler}
                                showSkeleton={isLoading || isSubmittingUpdate}
                                decimalScale={3}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"akFactor"}
                                label={"AK Factor"}
                                value={values?.akFactor?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={saveOnBlurHandler}
                                disabled={disableStudy}
                                showSkeleton={isLoading || isSubmittingUpdate}
                                decimalScale={2}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"percentajeOfDesign"}
                            label={"% of Design"}
                            value={values?.percentajeOfDesign?.toString() ?? ""}
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            color={
                              values &&
                              ((values?.percentajeOfDesign ?? 0) >
                                (values?.requiredCFMSystemToleranceTop
                                  ? values?.requiredCFMSystemToleranceTop
                                  : 0) +
                                  100 ||
                                (values?.percentajeOfDesign ?? 0) <
                                  100 -
                                    (values?.requiredCFMSystemToleranceBottom
                                      ? values?.requiredCFMSystemToleranceBottom
                                      : 0))
                                ? "red"
                                : undefined
                            }
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"dryBulbF"}
                                label={
                                  "Airflow Temperature For Energy Calculation"
                                }
                                value={values?.dryBulbF?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={onBlurCalcPsycometrics}
                                disabled={disableStudy}
                                showSkeleton={isLoading || isSubmittingUpdate}
                                error={errors.dryBulbF}
                                decimalScale={2}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography pl={2} pt={1}>
                                DB
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {values.totalHeat && (
                          <>
                            <Grid item xs={3}>
                              <Grid container>
                                <Grid item xs={10}>
                                  {values?.relativeHumidityBool === true && (
                                    <FormNumeric2
                                      name={"relativeHumidity"}
                                      label={"Relative Humidity"}
                                      value={
                                        values?.relativeHumidity?.toString() ??
                                        ""
                                      }
                                      onChange={handleInputChange}
                                      onBlur={onBlurCalcPsycometrics}
                                      disabled={disableStudy}
                                      showSkeleton={
                                        isLoading || isSubmittingUpdate
                                      }
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                  {values?.wetBulbFBool === true && (
                                    <FormNumeric2
                                      name={"wetBulbF"}
                                      label={"Wet bulb F"}
                                      value={values?.wetBulbF?.toString() ?? ""}
                                      onChange={handleInputChange}
                                      onBlur={onBlurCalcPsycometrics}
                                      disabled={disableStudy}
                                      showSkeleton={
                                        isLoading || isSubmittingUpdate
                                      }
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                  {values?.dewPointBool === true && (
                                    <FormNumeric2
                                      name={"dewPoint"}
                                      label={"Dew Point"}
                                      value={values?.dewPoint?.toString() ?? ""}
                                      onChange={handleInputChange}
                                      onBlur={onBlurCalcPsycometrics}
                                      disabled={disableStudy}
                                      showSkeleton={
                                        isLoading || isSubmittingUpdate
                                      }
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <RadioGroup aria-label="" name="" row>
                                <FormControlLabel
                                  checked={
                                    values?.relativeHumidityBool === true
                                  }
                                  control={<Radio />}
                                  label="Relative Humidity"
                                  onChange={handleChangeRadio}
                                  name={"relativeHumidityBool"}
                                  disabled={disableStudy}
                                />
                                <FormControlLabel
                                  checked={values?.wetBulbFBool === true}
                                  control={<Radio />}
                                  label="Wet bulb F"
                                  onChange={handleChangeRadio}
                                  name={"wetBulbFBool"}
                                  disabled={disableStudy}
                                />
                                <FormControlLabel
                                  checked={values?.dewPointBool === true}
                                  control={<Radio />}
                                  label="Dew Point"
                                  onChange={handleChangeRadio}
                                  name={"dewPointBool"}
                                  disabled={disableStudy}
                                />
                              </RadioGroup>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"btuh"}
                            label={`Total ${systemOfMeasurement.get("btuh")}`}
                            value={values?.btuh?.toString() ?? ""}
                            onChange={handleInputChange}
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"sensibleHeat"}
                            label={`Sensible ${systemOfMeasurement.get(
                              "btuh"
                            )}`}
                            value={values?.sensibleHeat?.toString() ?? ""}
                            mode="read"
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={activeStep}
                      stepsCount={steps.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName="Velgrid Matrix Test Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      disabled={disableStudy}
                      previousStepHandler={handleBackStep}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {params.step !== "step1" && values && values?.projectId && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={6}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
        />
      )}
    </>
  );
};

export default VelgridReport;
