import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { IPlaqueOrder } from "src/ts/interfaces";
import { ProviderTypeEnum } from "src/ts/enums/plaquesAndDecals";
import plaqueOrderService from "src/services/plaqueOrderService";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

let providerTypes: Record<string, string> = {};
providerTypes[ProviderTypeEnum.Plaque] = "Plaque";
providerTypes[ProviderTypeEnum.Decals] = "Decals";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const Orders = () => {
  let navigate = useNavigate();
  const { execute, data, isLoading } = useAsyncQuery<IPlaqueOrder[]>(
    plaqueOrderService.getByStatus
  );
  const [tabStatus, setTabStatus] = useState(0);

  const { fullAccess } = usePermissions(PermissionTypes.PlaqueDecalsOrders);

  useEffect(() => {
    const getData = async () => {
      await execute(tabStatus + 1);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStatus]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabStatus(newValue);
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "companyName",
      label: "Company Name",
      type: "string",
      sort: true,
    },
    {
      id: "projectName",
      label: "Project Name",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date Ordered",
      type: "utcDate",
      format: "ll",
      sort: true,
    },
    {
      id: "total",
      label: "Total",
      type: "currency",
      sort: true,
    },
    {
      id: "updatedDate",
      label: "Payment Date",
      type: "utcDate",
      format: "ll",
      sort: true,
    },
    {
      id: "orderDetail",
      label: "Provider Type",
      type: "custom",
      sort: false,
      callback: (row: IPlaqueOrder) => {
        return row.plaqueOrderDetail.map((detail) => (
          <div>
            {detail.quantity} - {detail.plaques.name}
          </div>
        ));
      },
    },
    {
      id: "orderStatus",
      label: "Payment Status",
      type: "custom",
      sort: true,
      callback: (row: IPlaqueOrder) => {
        return (
          <Chip
            label={row.orderStatus > 0 ? "Paid" : "Pending"}
            color={row.orderStatus > 0 ? "primary" : "warning"}
            size="small"
          />
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IPlaqueOrder) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                navigate(`/app/plaqueOrdersModify/${row.id}`);
              }}
              tooltip={fullAccess ? "Edit" : "View"}
            />
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Plaque and Decals Order(s)" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Plaque and Decals Order(s)
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Plaque and Decals Order(s)</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Tabs
                value={tabStatus}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Recived" id="1" disabled={false} />
                <Tab label="Manufacturer" id="2" disabled={false} />
                <Tab label="Shipped" id="3" disabled={false} />
                <Tab label="Delivered" id="4" disabled={false} />
              </Tabs>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="flex-end"></Stack>
            </Grid>

            <Grid item xs={12}>
              <LocalEnhancedTable<IPlaqueOrder>
                refreshGrid={true}
                columns={columns}
                data={data}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Orders;
