import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
  Typography,
  Chip as MuiChip,
} from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { useAsyncQuery, useAsyncMutation } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { AddIcon } from "src/components/icons";
import { FormButton } from "src/components/formControls";
import HeaderPage from "src/components/page/HeaderPage";
import { ITemplateDefault, ITemplateList } from "src/ts/interfaces/template";
import templateServices from "src/services/templateServices";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import AddTemplatePopUp from "./AddTemplatePopUp";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Chip = styled(MuiChip)<ChipProps>(spacing);
const getColumns = (
  navigate: NavigateFunction,
  isDefault: boolean,
  setViewTemplatePopup: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      template: {
        name: string;
        description: string;
        additionalNotes: string;
      };
    }>
  >,
  handlePrefered: (id: number) => void,
  setDeletePopup: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      templateId: number;
    }>
  >,
  setAddTemplatePopup: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      templateId: number;
    }>
  >
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: false,
    callback: (row: ITemplateList) => {
      return (
        <>
          {row?.name === "Premium Savings" && isDefault && (
            <Chip label={row?.name} color="success" m={1} size="small" />
          )}
          {row?.name === "Elevated Savings" && isDefault && (
            <Chip label={row?.name} color="primary" m={1} size="small" />
          )}
          {row?.name === "Moderate Savings" && isDefault && (
            <Chip label={row?.name} color="warning" m={1} size="small" />
          )}
          {!isDefault && <Typography>{row?.name}</Typography>}
        </>
      );
    },
  },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "preferedTemplate",
    label: "Preferred Template",
    type: "custom",
    sort: true,
    callback: (row: ITemplateList) => {
      return (
        <>
          {row.preferredTemplate ? (
            <GridActionButton
              type="enable"
              color="success"
              tooltip=""
              onClick={() => {
                handlePrefered(row.id);
              }}
            />
          ) : (
            <GridActionButton
              type="emptyCircle"
              tooltip=""
              color="default"
              onClick={() => {
                handlePrefered(row.id);
              }}
            />
          )}
        </>
      );
    },
  },
  {
    id: "studiesIncluded",
    label: "Studies Included",
    type: "custom",
    sort: true,
    width: "300px",
    callback: (row: ITemplateList) => {
      return (
        <>
          <Typography color="warning.main">
            {row.airStudies} Configurable Air Studies
          </Typography>
          <Typography color="primary">
            {row.airHydronicWater} Configurable All Hydronic/Water Studies
          </Typography>
          <Typography>
            {row.nonConfigurable} Non-Configurable Studies
          </Typography>
        </>
      );
    },
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ITemplateList) => {
      return (
        <div>
          <Stack direction="row">
            {isDefault && (
              <GridActionButton
                type="view"
                onClick={() => {
                  setViewTemplatePopup({
                    show: true,
                    template: {
                      name: row.name,
                      description: row.description,
                      additionalNotes: row.additionalNotes,
                    },
                  });
                }}
                tooltip="View Template"
              />
            )}
            {!isDefault && (
              <>
                <GridActionButton
                  type="edit"
                  onClick={() => {
                    setAddTemplatePopup({ show: true, templateId: row.id });
                  }}
                  tooltip="Edit Template"
                />
                <GridActionButton
                  type="delete"
                  onClick={() => {
                    setDeletePopup({ show: true, templateId: row.id });
                  }}
                  tooltip="Delete Template"
                />
              </>
            )}
            <GridActionButton
              type="list"
              onClick={() => {
                navigate(`/app/tolerances/${row.id}`);
              }}
              tooltip="Configure Template"
            />
          </Stack>
        </div>
      );
    },
  },
];

const ProjectTemplate = () => {
  const navigate = useNavigate();
  const [refreshTable, setRefreshTable] = useState(false);
  const [showTempleteInfo, setShowTempleteInfo] = useState(false);
  const [viewTemplatePopup, setViewTemplatePopup] = useState({
    show: false,
    template: { name: "", description: "", additionalNotes: "" },
  });
  const [addTemplatePopup, setAddTemplatePopup] = useState({
    show: false,
    templateId: 0,
  });
  const [deletePopup, setDeletePopup] = useState({
    show: false,
    templateId: 0,
  });
  const { execute, data, isLoading } = useAsyncQuery<ITemplateDefault>(
    templateServices.getDefaults
  );
  const { execute: executePrefered, data: dataPrefered } = useAsyncMutation(
    templateServices.setPrefered,
    {
      successfulMessage: "Preferred template was saved.",
    }
  );

  const { execute: executeDelete, isSubmitting: isSubmittingDelete } =
    useAsyncMutation(templateServices.deleteTemplate, {
      successfulMessage: "Preferred template was saved.",
      onSuccess: () => {
        setRefreshTable(!refreshTable);
        setDeletePopup({
          templateId: 0,
          show: false,
        });
      },
    });

  useEffect(() => {
    const getData = async () => {
      execute();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (dataPrefered) setRefreshTable(!refreshTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrefered]);

  const handleViewTemplate = () => {
    setViewTemplatePopup({
      ...viewTemplatePopup,
      show: !viewTemplatePopup.show,
    });
  };

  const handlePrefered = async (id: number) => {
    await executePrefered(id);
  };

  const handleShowAddTemplate = () => {
    setAddTemplatePopup({ ...addTemplatePopup, show: !addTemplatePopup.show });
  };

  const handleAddTemplate = () => {
    setAddTemplatePopup({ templateId: 0, show: true });
  };

  const deleteModalHandler = async () => {
    await executeDelete(deletePopup.templateId);
  };

  return (
    <>
      <HeaderPage
        title="Templates"
        parentText="Dashboard"
        parentLink="/"
        actionSection={
          <Stack direction="row" spacing={2}>
            <FormButton
              text={"Add Custom Template"}
              onClick={handleAddTemplate}
              startIcon={<AddIcon />}
              size="medium"
              color="primary"
            />
            <FormButton
              onClick={() => {
                setShowTempleteInfo(true);
              }}
              variant="outlined"
              color="primary"
              text={"Templates Information"}
              size="medium"
            />
          </Stack>
        }
      />
      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Pre-Defined Templates:</Typography>
              <Typography>
                Select 1 of 3 fully formatted templates for your project or make
                your own custom template(s). The 3 Pre-Defined Templates fully
                cover each study with certain parameters selected for optimal
                HVAC Energy Savings and Heat Transfer Performance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ITemplateList>
                columns={getColumns(
                  navigate,
                  true,
                  setViewTemplatePopup,
                  handlePrefered,
                  setDeletePopup,
                  setAddTemplatePopup
                )}
                data={Object.values(data?.defaults ?? [])}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
                hidePagination={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Custom Templates:</Typography>
              <Typography>
                Develop your own custom template(s). Template(s) will be saved
                for any future projects you develop and wish to apply this
                template to.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ITemplateList>
                columns={getColumns(
                  navigate,
                  false,
                  setViewTemplatePopup,
                  handlePrefered,
                  setDeletePopup,
                  setAddTemplatePopup
                )}
                data={Object.values(data?.templates ?? [])}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"View template"}
        text={
          <>
            <Typography variant="subtitle2">Name</Typography>
            <Typography pb={4}>{viewTemplatePopup.template?.name}</Typography>
            <Typography variant="subtitle2">Description</Typography>
            <Typography pb={4}>
              {viewTemplatePopup.template?.description}
            </Typography>
            <Typography variant="subtitle2">Additional Notes</Typography>
            <Typography>
              {viewTemplatePopup.template?.additionalNotes}
            </Typography>
          </>
        }
        showPopup={viewTemplatePopup.show}
        setShowPopup={handleViewTemplate}
        onSave={handleViewTemplate}
        isSubmitting={false}
        hideCancel={true}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={"Templates Information"}
        size="md"
        text={
          <>
            <Typography pb={3}>
              <Typography variant="subtitle2" display={"inline"}>
                This Templates Selections & Options All Have the Ability to
                Assure:
              </Typography>
              All Fluid Flows and Temperatures are Analyzed, Normalized, and
              Compared with Like Data.
            </Typography>
            <Typography pb={3}>
              Recommended Energy Conservation Measures (ECM’s) to be
              Implemented.&nbsp;
            </Typography>
            <Typography pb={3}>
              Instant Comparison of Supply Diffuser, Return Register, Exhaust
              Grille Fluid Flows & Tolerances.&nbsp;
            </Typography>
            <Typography pb={3}>
              Instant Comparison of Total Air & Hydronic System
              Tolerances.&nbsp;
            </Typography>
            <Typography pb={3}>
              Instant Validation of Outside Air Ventilation Rates, Air Changes
              per Hour (ACH’s) & Tolerances.&nbsp;
            </Typography>
            <Typography pb={3}>
              Instant Comparison of Hydronic Terminal Device GPM’s &
              Tolerances.&nbsp;
            </Typography>
            <Typography pb={3}>
              Instant Comparison of Heat Transfer for all Fluid Flows Recorded
              with Latent and Sensible BTU/h Values.&nbsp;
            </Typography>
            <Typography pb={3}>
              <Typography variant="subtitle2" display={"inline"}>
                System Recommendations and Changes are calculated in terms of:
              </Typography>{" "}
              RPM’s, Motor Pulleys, Pump Impellers, Static Pressures (SP’s),
              Terminal Device Sizing Error Changes, Filter operating Cost vs
              Change Schedules, Coil Cleaning Issues and Service Required.&nbsp;
            </Typography>
            <Typography pb={3}>
              <Typography variant="subtitle2" display={"inline"}>
                Reporting Highlights Feature:
              </Typography>{" "}
              Any system or specific item deficiency found reports them in{" "}
              <Typography
                variant="subtitle2"
                display={"inline"}
                color={"error"}
              >
                Red text
              </Typography>{" "}
              for ease of identification. Any ignored or missed item that was
              requested and not found in a report is also identified with{" "}
              <Typography
                variant="subtitle2"
                display={"inline"}
                color={"error"}
              >
                Red text and/or Warnings.
              </Typography>
              &nbsp;
            </Typography>
            <Typography pb={3}>
              Reports generated and void of any{" "}
              <Typography
                variant="subtitle2"
                display={"inline"}
                color={"error"}
              >
                Red Warnings and/or Text
              </Typography>{" "}
              lets you be assured your systems fall within the prescribed
              tolerances required and requested for your project.
            </Typography>
          </>
        }
        showPopup={showTempleteInfo}
        setShowPopup={setShowTempleteInfo}
        onSave={() => {
          setShowTempleteInfo(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      ></DialogMessagePopup>
      <AddTemplatePopUp
        showTemplatePopUp={addTemplatePopup.show}
        setShowTemplatePopUp={handleShowAddTemplate}
        templateId={addTemplatePopup.templateId}
        setRefresh={() => {
          setRefreshTable(!refreshTable);
        }}
      ></AddTemplatePopUp>
      <MatchWordConfirmModal
        onConfirm={deleteModalHandler}
        text={`Are you certain you want to delete this item?`}
        matchWord={"DELETE"}
        isDialogOpen={deletePopup.show}
        setIsDialogOpen={() => {
          setDeletePopup({
            ...deletePopup,
            show: !deletePopup.show,
          });
        }}
        isSubmitting={isSubmittingDelete}
      />
    </>
  );
};

export default ProjectTemplate;
