import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import { useAsyncQuery, useLog } from "src/hooks";
import projectReportToRetestService from "src/services/study/projectReportToRetestService";
import { useNavigate } from "react-router-dom";
import Popup from "src/components/Popup";
import { Alert, Button, Stack } from "@mui/material";

interface RetestReportProps {
  reportId: number;
  projectId: number;
  reportTypeId: number;
  isInfectiousControlProject: boolean;
}

const RetestReport = (props: RetestReportProps) => {
  const { reportId, projectId, reportTypeId, isInfectiousControlProject } =
    props;
  const [openRetestWarning, setOpenRetestWarning] = useState(false);
  const { log } = useLog();
  const navigate = useNavigate();

  const { execute: executeRetest, isLoading: isLoadingRetest } = useAsyncQuery(
    projectReportToRetestService.retestEnergyReport,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          log.success(
            `${
              isInfectiousControlProject ? "Infectious Disease / " : ""
            }Energy study and all of its sub-studies were opened and a copy with all your previous information was created and is now available for review`
          );
          navigate(`/studies/EnergyStudyAirHydronics/step1/${reportId}`);
        };
        getData();
      },
    }
  );

  const handleRetestWarningClose = () => setOpenRetestWarning(false);
  const handleRetestWarningOk = () => {
    setOpenRetestWarning(false);
    executeRetest(reportId, projectId, reportTypeId);
  };

  return (
    <>
      <Popup
        title="Important"
        openPopup={openRetestWarning}
        setOpenPopup={setOpenRetestWarning}
        onClose={handleRetestWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="warning">
            Selecting the “Retest" option will open this{" "}
            {isInfectiousControlProject ? "Infectious Disease / " : ""}
            Energy study and all of its sub-studies that were previously
            selected, in addition to this, a copy with all your previous
            information will be created and available for review, a new and
            active data entry field copy will be generated with all of the
            studies actual values removed.
            <br />
            <br />
            Do you want to proceed?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleRetestWarningClose}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleRetestWarningOk}
            autoFocus
          >
            Accept
          </Button>
        </Stack>
      </Popup>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        pt={5}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isLoadingRetest}
          onClick={() => setOpenRetestWarning(true)}
          autoFocus
          startIcon={<RestartAltIcon />}
        >
          Retest this Individual Energy Study
        </Button>
      </Stack>
    </>
  );
};
export default RetestReport;
