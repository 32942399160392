import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import projectContestService from "src/services/projectContestService";

interface DeclineProjectProps {
  declineProjectShow: boolean;
  setDeclineProject: (val: boolean) => void;
  refreshPage: boolean;
  setRefreshPage: (val: boolean) => void;
  dataRow: IProjectOutForBidData | undefined;
}

const initialValues = {
  comments: "",
};

export default function DeclineProject({
  declineProjectShow,
  setDeclineProject,
  refreshPage,
  setRefreshPage,
  dataRow,
}: DeclineProjectProps) {
  const { log } = useLog();

  const { values, handleInputChange } = useForm(initialValues, true, []);

  const accepthandlerAll = async () => {
    try {
      await projectContestService.putDeclineProject(
        dataRow?.id ?? 0,
        values.comments
      );
      setDeclineProject(false);
      setRefreshPage(!refreshPage);
      log.success("Declined completed");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const buildName =
    dataRow?.buildingName === undefined
      ? dataRow?.projectContractor?.buildingName
      : dataRow?.buildingName;

  const buildingAddress =
    dataRow?.buildingAddress === undefined
      ? dataRow?.projectContractor?.buildingAddress
      : dataRow?.buildingAddress;

  return (
    <>
      <Popup
        title={"Decline Project"}
        openPopup={declineProjectShow}
        setOpenPopup={setDeclineProject}
        onClose={() => {
          setDeclineProject(false);
        }}
        size="sm"
      >
        <>
          <Grid container>
            <Grid item xs={2.5}>
              <Typography> Project Code: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{dataRow?.code} </Typography>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={2.5}>
              <Typography> Project Name: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                {" "}
                {dataRow?.name === undefined
                  ? dataRow?.projectContractor?.name
                  : dataRow.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={2.5}>
              <Typography ml={8.5}> Building: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                <b>{buildName} </b>
                {buildingAddress}
              </Typography>
            </Grid>
          </Grid>

          <Typography mb={1} mt={2}>
            <b>Reason(s) for declining this project:</b>
          </Typography>
          <FormTextArea
            name="comments"
            value={values.comments}
            onChange={handleInputChange}
            label=""
            placeholder="Be descriptive when entering reason(s) for declining this project"
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            variant="outlined"
            size="small"
            text="Close"
            onClick={() => {
              setDeclineProject(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              accepthandlerAll();
            }}
            disabled={!values.comments}
          />
        </Stack>
      </Popup>
    </>
  );
}
