import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "../project/types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getQueryString } from "../project/utils/common";
import { GridActionButton } from "src/components/gridControls";
import getProjectSingleService from "src/services/getProjectSingleService";
import { useAuth, useLog } from "src/hooks";
import ContestTermsPopUp from "./Components/ContestTermsPopUp";
import { ROLES } from "src/constants";

const getColumns = (
  navigate: NavigateFunction,
  role: string,
  setContestTermsShowPopUpShowPopUp: (val: boolean) => void,
  setCurrentRow: (val: IProjectOutForBidData) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "ownerName",
    label: "Proposed By",
    type: "string",
    sort: true,
  },
  {
    id: "building",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => {
      return (
        <Typography>
          {" "}
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "contestDueDate",
    label: "Proposal Due Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IProjectOutForBidData) => (
      <>
        <Stack direction="row">
          {role === ROLES.SysAdmin && (
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(`/app/projectsOutForBid/${row.id}`);
              }}
              tooltip="Go to Project"
            />
          )}

          {role !== ROLES.SysAdmin && role !== ROLES.SuperAdministrator && (
            <GridActionButton
              type="check"
              onClick={() => {
                setCurrentRow(row);
                setContestTermsShowPopUpShowPopUp(true);
              }}
              tooltip="Apply for this project"
            />
          )}
        </Stack>
      </>
    ),
  },
];

interface projectsAvailableProps {
  filters: FilterType;
  refresh: boolean;
}

export default function ProjectsAvailable({
  filters,
  refresh,
}: projectsAvailableProps) {
  const [contestTermsShowPopUp, setContestTermsShowPopUpShowPopUp] =
    useState(false);

  const [currentRow, setCurrentRow] = useState<IProjectOutForBidData>();
  const { log } = useLog();
  let navigate = useNavigate();
  const { user } = useAuth();
  let columns = getColumns(
    navigate,
    user?.role,
    setContestTermsShowPopUpShowPopUp,
    setCurrentRow
  );

  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    startRequest,
    endRequest,
  } = useTable(columns, {
    defaultOrderBy: "createdDate",
    defaultOrder: "desc",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("dataLoading");
        if (user?.role === "SysAdmin") {
          const request = await getProjectSingleService.getAll(
            {
              page: page + 1,
              sortColumn: orderBy,
              sortDec: order === "desc" ? true : false,
              search: filters.search,
              countryId: filters.countryId,
              stateId: filters.stateId | 0,
              stateName: filters.stateName,
              zipCode: filters.zipCode,
              projectCode: filters.projectCode,
              hasAaacepted: false,
              buildingName: filters.buildingName,
            },
            getQueryString
          );

          setCount(request.data.count);
          setDataSet(request.data.getAll);
          setRowsPerPage(10);
        } else {
          const request = await getProjectSingleService.getAllProjects(
            {
              page: page + 1,
              sortColumn: orderBy,
              sortDec: order === "desc" ? true : false,
              search: filters.search,
              countryId: filters.countryId,
              stateId: filters.stateId | 0,
              stateName: filters.stateName,
              zipCode: filters.zipCode,
              projectCode: filters.projectCode,
              hasAaacepted: false,
              buildingName: filters.buildingName,
            },
            getQueryString
          );

          setCount(request.data.count);
          setDataSet(request.data.getAll);
          setRowsPerPage(10);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("dataLoading");
      } finally {
        endRequest("dataLoading");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, setCount, setDataSet, setRowsPerPage, refresh]);

  return (
    <>
      <Table />
      {contestTermsShowPopUp && (
        <ContestTermsPopUp
          contestTermsShowPopUp={contestTermsShowPopUp}
          setContestTermsShowPopUpShowPopUp={setContestTermsShowPopUpShowPopUp}
          currentRow={currentRow}
        />
      )}
    </>
  );
}
