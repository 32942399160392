import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Box,
  IconButton,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import { IAdministratorsData } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form } from "src/hooks";
import { FormText, FormButton } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { getFullName } from "src/utils/utils";
import { Key as KeyIcon } from "@mui/icons-material";
import PermissionsSwitchDialog from "../auth/Components/PermissionsSwitchDialog";
import { formatPhoneNumber } from "src/utils/format";
import { GridChipStatus } from "src/components/gridControls";
import { ToolTip } from "src/components/others/ToolTip";
import { userService } from "src/services";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const SystemAdministratorsPage = () => {
  const { execute, data, isLoading } = useAsyncQuery<IAdministratorsData[]>(
    userService.getAllSysadmins
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<IAdministratorsData>();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      await execute("SysAdmin");
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleEdit = (row: IAdministratorsData) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const columns: ColumnType[] = [
    {
      id: "fullName",
      label: "Name",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => {
        return <div>{getFullName(row.name ?? "", row.lastName ?? "")}</div>;
      },
    },
    {
      id: "user",
      label: "User",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => {
        return <div>{row.email}</div>;
      },
    },
    {
      id: "email",
      label: "Email",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => {
        return <div>{row.email}</div>;
      },
    },
    {
      id: "telephone",
      label: "Telephone",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) =>
        formatPhoneNumber(row.phone?.toString() ?? ""),
    },
    {
      id: "country",
      label: "Country",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => {
        return <div>{row.country?.name}</div>;
      },
    },
    {
      id: "status",
      label: "Status",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => (
        <GridChipStatus value={row.statusId} />
      ),
    },
    {
      id: "action1",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => {
        return (
          <div>
            <Box mr={2}>
              <ToolTip title={"Permissions"}>
                <IconButton
                  aria-label="VpnKey"
                  size="large"
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  <KeyIcon />
                </IconButton>
              </ToolTip>
            </Box>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Helmet title="System Administrators Permissions" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            System Administrators Permissions
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>System Administrators Permissions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IAdministratorsData>
                refreshGrid={true}
                columns={columns}
                data={data}
                query={filterValues.name}
                showSkeleton={isLoading}
              />
              <Popup
                openPopup={isDialogOpen}
                setOpenPopup={setIsDialogOpen}
                title="Permissions"
                onClose={() => setIsDialogOpen(false)}
              >
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <PermissionsSwitchDialog
                      userId={currentRow?.id ?? 0}
                      role={"SysAdmin"}
                      permissionType="System_Administrators"
                      showInUi={true}
                    />
                  </Grid>
                </Grid>
                <>
                  <FormButton
                    text="Close"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </>
              </Popup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SystemAdministratorsPage;
