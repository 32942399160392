import axios from "src/utils/axios";
import { IPitotTraverseActualTest } from "src/ts/interfaces/study/pitot";

const getByPitotId = (id: number) => {
  return axios.get<IPitotTraverseActualTest[]>(
    `PitotTraverseActualTest/Company/${id}`
  );
};

const saveItems = (items: IPitotTraverseActualTest[]) => {
  return axios.post(`PitotTraverseActualTest`, items);
};

const pitotTraverseActualTestService = {
  getByPitotId,
  saveItems,
};

export default pitotTraverseActualTestService;
