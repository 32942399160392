import { useEffect, useState } from "react";
import Popup from "../Popup";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Info } from "react-feather";
import { FormButton } from "../formControls";
import releaseVersion from "src/services/releaseVersionService";
import { IRelease } from "src/ts/interfaces/releaseVersion";

function NavBarVersion() {
  const [isOpenModal, setOpenModal] = useState(false);
  const [dataVersion, setDataVersion] = useState<IRelease>();
  const [body, setBody] = useState("");

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const getReleaseVersions = async () => {
      const response = await releaseVersion.getReleaseVersion();
      setDataVersion(response.data);
      setBody(response.data.description);
    };

    getReleaseVersions();
  }, [isOpenModal]);

  return (
    <>
      <Tooltip title="Version">
        <IconButton
          color="inherit"
          onClick={() => {
            setOpenModal(true);
          }}
          size="large"
        >
          <Info />
        </IconButton>
      </Tooltip>
      <Popup
        openPopup={isOpenModal}
        setOpenPopup={setOpenModal}
        title="AMP Software"
        onClose={handleClose}
      >
        <>
          <Typography>
            <b>AMP software version is up to date.</b>
          </Typography>
          <Typography mt={1}>
            <b>{`Release Notes: ${dataVersion?.name}`} </b>
          </Typography>
          <Typography mt={1} mb={5}>
            <b>{`Version: ${dataVersion?.version}`} </b>
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: body ?? "" }} />
        </>
        <>
          <FormButton
            text="Close"
            size="medium"
            onClick={handleClose}
            isSubmitting={false}
          />
        </>
      </Popup>
    </>
  );
}

export default NavBarVersion;
