import useAuth from "./useAuth";
import { Permissions } from "../types/authorizationData";
import { DemoOptions } from "src/constants";

const usePermissions = (permission: string) => {
  const { user } = useAuth();

  const result = user
    ? (user?.permissions as Permissions[]).find(
        (x: Permissions) => x.code === permission
      )
    : ({} as Permissions);

  return {
    fullAccess: (result && result.fullAccess) ?? false,
    readOnly: result && result.readOnly,
    read:
      result &&
      (result.readOnly || result.fullAccess) &&
      (!result.demo || result.demo !== DemoOptions.Hide),
    demo: result?.demo === DemoOptions.Disabled,
  };
};

export default usePermissions;
