import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip as MuiChip,
} from "@mui/material";

import { SpacingProps, spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  CenterlineStaticPressure,
  DryBulbF,
  DuctType,
  MethodMeasurement,
} from "src/constants";
import { CatalogService } from "src/services";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import getProjectService from "src/services/project/getProjectService";
import { IPitotTraverseTest } from "src/ts/interfaces/study/pitot";
import pitotTraverseTestService from "src/services/study/pitotTraverseTestService";
import PitotFanTraverseGrid from "./components/PitotFanTraverseGrid";
import PitotFanTraverseTTGrid from "./components/PitotFanTraverseTTGrid";
import { STEPS } from "./components/constants";
import SteadyStateDialog from "../components/SteadyState";
import { FooterButtons } from "../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: IPitotTraverseTest = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  step: 0,
  systemTotal: false,
  outletTotal: false,
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  systemField: "",
  description: "",
  comments: "",
  minDucts: 0,
  location: "",
  areaServed: "",
  system: "",
  ductTypeId: 0,
  methodMeasurementsId: 0,
  centerlineStaticPressureId: 0,
  conditionId: 0,
  isVFD: false,
  numberOfDuctsId: 0,
  nocVisual: false,
  nocActual: false,
  vfdHzIsSelect: false,
  vfdPercentageIsSelect: false,
  vfdOtherName: "",
  vfdOtherIsSelect: false,
  isActualLocalBarometricPressure: false,
  barometricalyCompensated: false,
  velocityReadingTemperatureCompensated: false,
  isComplete: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  statusId: 0,
  isProjectDisabled: false,
  totalHeat: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PitotFanTraverse = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [amcaAlertModal, setAmcaAlertModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalTT, setTotalTT] = useState(0);
  const [totalItemsTT, setTotalItemsTT] = useState(0);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  let navigate = useNavigate();
  const holePointSide = [
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
    { key: 7, value: 7 },
    { key: 8, value: 8 },
  ];
  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(pitotTraverseTestService.update, {
      successfulMessage: "Report was updated successfully",
      onSuccess: async (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh, isLoading: isLoadingUpdate } = useAsyncQuery(
    pitotTraverseTestService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (params.step === "step2") {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .isRequired("Airflow Temperature For Energy Calculation Is Required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IPitotTraverseTest>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IPitotTraverseTest>(
    pitotTraverseTestService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          let result = await formulaSystem.setSystem(dataResult?.projectId);
          setFormulas(result);
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = result.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }
          if (!dataResult.ductTypeId) {
            dataResult.ductTypeId = DuctType.Rectangular;
          }
          if (!dataResult.methodMeasurementsId) {
            dataResult.methodMeasurementsId = MethodMeasurement.AABC;
          }
          setValues({ ...dataResult });
          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(dataResult?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
          await executeReport(
            id,
            dataResult?.reportTypeId === 5 ? "PITT" : "PTFR"
          );
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [activeStep, setActiveStep] = useState<number>(
    params.step?.toLowerCase() === "step1" ? 0 : 1
  );
  const [saveItems, setSaveItems] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      execute(id);
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let dimensionTotal = undefined;
    if (values.ductTypeId === DuctType.Rectangular) {
      dimensionTotal = formulas?.sqft(
        ((values?.ductSizeHeight ?? 0) -
          (values?.internalInsulation ?? 0) * 2) *
          ((values?.ductSizeBase ?? 0) - (values?.internalInsulation ?? 0) * 2)
      );
    } else if (values.ductTypeId === DuctType.Round) {
      dimensionTotal = formulas?.sqft(
        (((values?.ductSizeBase ?? 0) - (values?.internalInsulation ?? 0) * 2) /
          2) *
          (((values?.ductSizeBase ?? 0) -
            (values?.internalInsulation ?? 0) * 2) /
            2) *
          3.14159265359
      );
    } else if (values.ductTypeId === DuctType.Oval) {
      let pi = 3.14159265359;
      let ovalRectWidth =
        (values?.ductSizeBase ?? 0) - (values?.ductSizeHeight ?? 0);
      let ovalCircleRadius =
        ((values?.ductSizeHeight ?? 0) -
          (values?.internalInsulation ?? 0) * 2) /
        2;
      let ovalRoundArea = pi * Math.pow(ovalCircleRadius, 2);
      let ovalRectArea =
        ovalRectWidth *
        ((values?.ductSizeHeight ?? 0) - (values?.internalInsulation ?? 0) * 2);
      dimensionTotal = formulas?.sqft(ovalRectArea + ovalRoundArea);
    }
    setValues({
      ...values,
      ductSize: dimensionTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.ductSizeBase,
    values?.ductSizeHeight,
    values.ductTypeId,
    values?.internalInsulation,
    values.methodMeasurementsId,
  ]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === "step1" ? 0 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  useEffect(() => {
    if (!disableStudy && dataReport) onBlurCalcPsycometrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  const validateAmcaAlert = () => {
    if (
      values?.ductTypeId === DuctType.Rectangular &&
      values?.methodMeasurementsId === MethodMeasurement.AMCA &&
      values?.ductSize &&
      values?.ductSize < 1
    ) {
      setAmcaAlertModal(true);
    }
  };

  const refreshCalcValue = (
    ductTypeId?: number,
    methodMeasurementsId?: number
  ) => {
    let newValues = { ...values };
    if (ductTypeId) {
      newValues.ductTypeId = ductTypeId;
      newValues.ductSizeBase = undefined;
      newValues.ductSizeHeight = undefined;
      newValues.ductSize = undefined;
      newValues.internalInsulation = undefined;
    }
    if (methodMeasurementsId)
      newValues.methodMeasurementsId = methodMeasurementsId;
    if (newValues.ductTypeId === DuctType.Oval) {
      newValues.methodMeasurementsId = MethodMeasurement.AABC;
    }
    setValues({ ...newValues });
  };

  const calcTotal = async (totalItem: number, numberItems: number) => {
    if (disableStudy) return;
    setTotal(totalItem);
    setTotalItems(numberItems);
    if (values && values.id > 0 && values?.ductTypeId !== DuctType.Oval) {
      const newValues = { ...values };
      newValues.avgVelocity = totalItem / numberItems;
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    } else {
      const newValues = { ...values };
      newValues.avgVelocity =
        (totalItem + totalTT) / (numberItems + totalItemsTT);
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    }
  };

  const calcTotalTT = async (totalItem: number, numberItems: number) => {
    if (disableStudy) return;
    setTotalTT(totalItem);
    setTotalItemsTT(numberItems);
    if (values && values.id > 0 && values?.ductTypeId !== DuctType.Oval) {
      const newValues = { ...values };
      newValues.avgVelocity = totalItem / numberItems;
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    } else {
      const newValues = { ...values };
      newValues.avgVelocity = (totalItem + total) / (numberItems + totalItems);
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    }
  };

  const saveOnBlurHandler = async () => {
    if (disableStudy) return;
    executeRefresh(values.id, values);
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6))
      setTotalCFM(totalCFM);
  };

  const onBlurCalcPsycometrics = () => {
    calcPsycometrics(values?.totalCFM!);
  };

  const calcPsycometrics = async (
    total: number,
    dryBulb?: number,
    newValues?: IPitotTraverseTest
  ) => {
    if (disableStudy) return;
    let humidityMeasuredAs = "";
    if (values?.dryBulbFId === DryBulbF.Relativeumidity) {
      humidityMeasuredAs = "RH";
    }
    if (values?.dryBulbFId === DryBulbF.DewPoint) {
      humidityMeasuredAs = "DP";
    }
    if (values?.dryBulbFId === DryBulbF.WetBulbF) {
      humidityMeasuredAs = "WB";
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: values.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: values?.humidityValue ?? 0,
      dryBulbF: values.dryBulbF!,
      totalCFM: total,
    };
    const psychrometricRes = await psychrometric.getByProject(
      psychometricsArgs
    );

    if (
      psychrometricRes.psychrometric.message.includes("must be between") ||
      psychrometricRes.psychrometric.message.includes("cannot exceed")
    )
      log.warning(psychrometricRes.psychrometric.message);
    else log.info(psychrometricRes.psychrometric.message);
    let oldValues: any = { ...values };
    if (newValues) oldValues = { ...newValues };
    oldValues.btuh = psychrometricRes.btuh!;
    oldValues.sensibleHeat = psychrometricRes.sensibleHeat!;
    if (dryBulb) {
      oldValues.dryBulbFId = dryBulb;
      oldValues.humidityValue = undefined;
      setValues({
        ...oldValues,
        btuh: psychrometricRes.btuh!,
        sensibleHeat: psychrometricRes.sensibleHeat!,
        dryBulbFId: dryBulb,
        humidityValue: undefined,
      });
    } else {
      setValues({
        ...oldValues,
        btuh: psychrometricRes.btuh!,
        sensibleHeat: psychrometricRes.sensibleHeat!,
      });
    }
    if (oldValues.id > 0) await executeRefresh(oldValues.id, oldValues);
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      setSaveItems(!saveItems);
      await executeUpdate(values.id, values);
    }
    if (params.step?.toLowerCase() === "step1")
      navigate(
        `/studies/PitotTraverseStart${
          values?.reportTypeId === 5 ? "" : "TT"
        }/Step2/${id}`
      );
    else
      navigate(
        `/studies/PitotTraverseStart${
          values?.reportTypeId === 5 ? "" : "TT"
        }/Step3/${id}`
      );
    setActiveStep(1);
  };

  const handleCheckNoc = (e: any) => {
    values.nocActual = false;
    values.nocVisual = false;
    switch (e.target.name) {
      case "nocActual": {
        values.nocActual = true;
        break;
      }
      case "nocVisual": {
        values.nocVisual = true;
        break;
      }
    }
    setValues({ ...values });
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const isOther = useMemo(() => {
    const found = conditionOfTest.find((item) => item.value === "Other");
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (!validate()) return;
    setSaveItems(!saveItems);
    await executeUpdate(values.id, values);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const mode = params.step?.toLowerCase() === "step2" ? "read" : "read&Write";

  const handleBackStep = async () => {
    if (!disableStudy) {
      setSaveItems(!saveItems);
      await handleSave();
    }
    navigate(
      `/studies/PitotTraverseStart${
        values?.reportTypeId === 5 ? "" : "TT"
      }/Step1/${id}`
    );
    setActiveStep(0);
  };

  const handleChangeRadio = (dryBulb: number) => {
    calcPsycometrics(values?.totalCFM!, dryBulb);
  };

  const showskeleton = useMemo(
    () => isLoading || isSubmittingUpdate,
    [isLoading, isSubmittingUpdate]
  );

  const showskeletonStep2 = useMemo(() => isLoading, [isLoading]);

  const handleInputChangeCompesation = (e: any) => {
    values.velocityReadingTemperatureCompensated = e.target.value;
    if (values.airflowTemperature === values.dryBulbF) {
      values.dryBulbF = 70;
    }
    values.airflowTemperature = 70;
    setValues({ ...values });
  };

  const handleChangeSpecificDefault = async (e: any) => {
    let isActualLocalBarometricPressure = e.target.value;
    let newValues = { ...values };
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF:
          systemOfMeasurement.measurementSystem === "imperialSystem"
            ? 70
            : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      newValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;

      calcPsycometrics(newValues?.totalCFM!, undefined, newValues);
    } else {
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;

      calcPsycometrics(newValues?.totalCFM!, undefined, newValues);
    }
  };
  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: values?.reportTypeId === 5 ? "PITT" : "PTFR",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={STEPS}
            activeStep={activeStep}
            projectId={values?.projectId ?? 0}
            reportId={id}
            reportTypeId={values?.reportTypeId ?? 0}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                    mode={mode}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextArea
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                    mode={mode}
                  ></FormTextArea>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <Grid container mt={4}>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Condition of Test
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelect
                        name={"conditionId"}
                        label={""}
                        onChange={handleInputChange}
                        showSkeleton={showskeleton}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        disabled={disableStudy}
                        mode={mode}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test",
                        }}
                      ></FormSelect>
                    </Grid>
                  </Grid>
                  {isOther && (
                    <Grid container mt={4}>
                      <Grid item xs={4} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Comments
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormTextArea
                          name={"comments"}
                          label={"Comments"}
                          value={values?.comments}
                          onChange={handleInputChange}
                        ></FormTextArea>
                      </Grid>
                    </Grid>
                  )}
                  {isNOC && (
                    <Grid container mt={4}>
                      <Grid item xs={3} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Normal Operating Condition
                        </Typography>
                      </Grid>
                      {params.step?.toLowerCase() === "step2" && (
                        <Grid item xs={6}>
                          {values?.nocVisual && (
                            <Typography pt={2}>
                              Visual Damper Position
                            </Typography>
                          )}
                          {values?.nocActual && (
                            <Typography pt={2}>
                              Actual Damper Position
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {params.step?.toLowerCase() === "step1" && (
                        <Grid item xs={3}>
                          <FormCheckBox
                            name={"nocVisual"}
                            onChange={handleCheckNoc}
                            label={"Visual Damper Position"}
                            showSkeleton={showskeletonStep2}
                            value={values?.nocVisual}
                            disabled={disableStudy}
                            mode={mode}
                          ></FormCheckBox>
                        </Grid>
                      )}
                      {params.step?.toLowerCase() === "step1" && (
                        <Grid item xs={3}>
                          <FormCheckBox
                            name={"nocActual"}
                            onChange={handleCheckNoc}
                            label={"Actual Damper Position"}
                            showSkeleton={showskeletonStep2}
                            value={values?.nocActual}
                            disabled={disableStudy}
                            mode={mode}
                          ></FormCheckBox>
                        </Grid>
                      )}
                      <Grid item xs={3} pr={3}></Grid>
                      <Grid item xs={3} pr={3}></Grid>
                      <Grid item xs={1} pt={1}>
                        <FormNumeric2
                          name={"nocOaFirstNumber"}
                          label={""}
                          value={values?.nocOaFirstNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <Typography m={2}>% O/A</Typography>
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <FormNumeric2
                          name={"nocRaFirstNumber"}
                          label={""}
                          value={values?.nocRaFirstNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <Typography m={2}>% R/A</Typography>
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <FormNumeric2
                          name={"nocOaSecondNumber"}
                          label={""}
                          value={values?.nocOaSecondNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <Typography m={2}>% M/A</Typography>
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <FormNumeric2
                          name={"nocExhaustNumber"}
                          label={""}
                          value={values?.nocExhaustNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1} pt={1}>
                        <Typography ml={2} mt={2}>
                          % Exhaust
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={4}>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        System operated from a VFD?
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FormCheckSwitch
                        name={"isVFD"}
                        label={""}
                        value={values?.isVFD}
                        onChange={handleInputChange}
                        disabled={mode === "read" ? true : false}
                      ></FormCheckSwitch>
                    </Grid>
                    {values?.isVFD && (
                      <>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              <FormCheckBox
                                name={"vfdHzIsSelect"}
                                label={"Hz"}
                                value={values?.vfdHzIsSelect}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    vfdHzIsSelect: true,
                                    vfdPercentageIsSelect: false,
                                    vfdOtherIsSelect: false,
                                  });
                                }}
                                disabled={mode === "read" ? true : false}
                              ></FormCheckBox>
                            </Grid>
                            <Grid item xs={4}>
                              <FormCheckBox
                                name={"vfdPercentageIsSelect"}
                                label={"%"}
                                value={values?.vfdPercentageIsSelect}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    vfdHzIsSelect: false,
                                    vfdPercentageIsSelect: true,
                                    vfdOtherIsSelect: false,
                                  });
                                }}
                                disabled={mode === "read" ? true : false}
                              ></FormCheckBox>
                            </Grid>
                            <Grid item xs={4}>
                              <FormCheckBox
                                name={"vfdOtherIsSelect"}
                                label={"Other"}
                                value={values?.vfdOtherIsSelect}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    vfdHzIsSelect: false,
                                    vfdPercentageIsSelect: false,
                                    vfdOtherIsSelect: true,
                                  });
                                }}
                                disabled={mode === "read" ? true : false}
                              ></FormCheckBox>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5}>
                              {values?.vfdOtherIsSelect && (
                                <FormText
                                  name={"vfdOtherName"}
                                  label={"Other name"}
                                  value={values?.vfdOtherName}
                                  onChange={handleInputChange}
                                  mode={mode}
                                ></FormText>
                              )}
                            </Grid>
                            <Grid item xs={5}>
                              {values?.vfdHzIsSelect && (
                                <FormNumeric2
                                  name={"vfdHz"}
                                  label={"Hz"}
                                  value={values?.vfdHz}
                                  onChange={handleInputChange}
                                  mode={mode}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                              {values?.vfdPercentageIsSelect && (
                                <FormNumeric2
                                  name={"vfdPercentage"}
                                  label={"%"}
                                  value={values?.vfdPercentage}
                                  onChange={handleInputChange}
                                  mode={mode}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                              {values?.vfdOtherIsSelect && (
                                <FormNumeric2
                                  name={"vfdOther"}
                                  label={"Other"}
                                  value={values?.vfdOther}
                                  onChange={handleInputChange}
                                  mode={mode}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}>
                          {values?.vfdHzIsSelect && (
                            <Chip
                              label={
                                ((100 * (values?.vfdHz ?? 0)) / 60)
                                  .toFixed(3)
                                  .toString() + " %"
                              }
                              color="warning"
                              m={1}
                              size="small"
                            />
                          )}
                          {values?.vfdPercentageIsSelect && (
                            <Chip
                              label={
                                ((60 * (values?.vfdPercentage ?? 0)) / 100)
                                  .toFixed(3)
                                  .toString() + " Hz"
                              }
                              color="warning"
                              m={1}
                              size="small"
                            />
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Type of Duct
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {params.step?.toLowerCase() === "step2" && (
                        <Grid item xs={6}>
                          <Typography mt={2}>
                            {values?.ductTypeId === DuctType.Rectangular &&
                              "Rectangular"}
                          </Typography>
                          <Typography mt={2}>
                            {values?.ductTypeId === DuctType.Round && "Round"}
                          </Typography>
                          <Typography mt={2}>
                            {values?.ductTypeId === DuctType.Oval && "Oval"}
                          </Typography>
                        </Grid>
                      )}
                      {params.step?.toLowerCase() === "step1" && (
                        <Grid container>
                          <Grid item width={"20%"}>
                            <FormCheckBox
                              name={"isRectangleType"}
                              onChange={() => {
                                refreshCalcValue(DuctType.Rectangular);
                              }}
                              label={"Rectangular"}
                              showSkeleton={showskeleton}
                              value={
                                values?.ductTypeId === DuctType.Rectangular
                              }
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          </Grid>
                          <Grid item width={"20%"}>
                            <FormCheckBox
                              name={"isRoundType"}
                              onChange={() => {
                                refreshCalcValue(DuctType.Round);
                              }}
                              label={"Round"}
                              showSkeleton={showskeleton}
                              value={values?.ductTypeId === DuctType.Round}
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          </Grid>
                          <Grid item width={"20%"}>
                            <FormCheckBox
                              name={"isOvalType"}
                              onChange={() => {
                                refreshCalcValue(DuctType.Oval);
                              }}
                              label={"Oval"}
                              showSkeleton={showskeleton}
                              value={values?.ductTypeId === DuctType.Oval}
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Method for Measurements
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {params.step?.toLowerCase() === "step2" && (
                        <Grid item xs={6}>
                          <Typography mt={2}>
                            {values?.methodMeasurementsId ===
                              MethodMeasurement.AABC && "AABC"}
                          </Typography>
                          <Typography mt={2}>
                            {values?.methodMeasurementsId ===
                              MethodMeasurement.LogTchebycheff &&
                              "Log Tchebycheff"}
                          </Typography>
                          <Typography mt={2}>
                            {values?.methodMeasurementsId ===
                              MethodMeasurement.EqualAreaTABB &&
                              "Equal Area/TABB"}
                          </Typography>
                          <Typography mt={2}>
                            {values?.methodMeasurementsId ===
                              MethodMeasurement.NEBB && "NEBB"}
                          </Typography>
                          <Typography mt={2}>
                            {values?.methodMeasurementsId ===
                              MethodMeasurement.AMCA && "AMCA"}
                          </Typography>
                        </Grid>
                      )}
                      {params.step?.toLowerCase() === "step1" && (
                        <Grid container>
                          <Grid item width={"20%"}>
                            <FormCheckBox
                              name={"AABC"}
                              onChange={() => {
                                refreshCalcValue(
                                  undefined,
                                  MethodMeasurement.AABC
                                );
                              }}
                              label={"AABC"}
                              showSkeleton={showskeleton}
                              value={
                                values?.methodMeasurementsId ===
                                MethodMeasurement.AABC
                              }
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          </Grid>
                          <Grid item width={"20%"}>
                            {(values?.ductTypeId === DuctType.Round ||
                              values?.ductTypeId === DuctType.Rectangular) && (
                              <FormCheckBox
                                name={"LogTchebycheff"}
                                onChange={() => {
                                  refreshCalcValue(
                                    undefined,
                                    MethodMeasurement.LogTchebycheff
                                  );
                                }}
                                label={"Log Tchebycheff"}
                                showSkeleton={showskeleton}
                                value={
                                  values?.methodMeasurementsId ===
                                  MethodMeasurement.LogTchebycheff
                                }
                                disabled={disableStudy}
                                mode={mode}
                              ></FormCheckBox>
                            )}
                          </Grid>
                          <Grid item width={"20%"}>
                            {(values?.ductTypeId === DuctType.Round ||
                              values?.ductTypeId === DuctType.Rectangular) && (
                              <FormCheckBox
                                name={"EqualAreaTABB"}
                                onChange={() => {
                                  refreshCalcValue(
                                    undefined,
                                    MethodMeasurement.EqualAreaTABB
                                  );
                                }}
                                label={"Equal Area/TABB"}
                                showSkeleton={showskeleton}
                                value={
                                  values?.methodMeasurementsId ===
                                  MethodMeasurement.EqualAreaTABB
                                }
                                disabled={disableStudy}
                                mode={mode}
                              ></FormCheckBox>
                            )}
                          </Grid>
                          <Grid item width={"20%"}>
                            {(values?.ductTypeId === DuctType.Round ||
                              values?.ductTypeId === DuctType.Rectangular) && (
                              <FormCheckBox
                                name={"NEBB"}
                                onChange={() => {
                                  refreshCalcValue(
                                    undefined,
                                    MethodMeasurement.NEBB
                                  );
                                }}
                                label={"NEBB"}
                                showSkeleton={showskeleton}
                                value={
                                  values?.methodMeasurementsId ===
                                  MethodMeasurement.NEBB
                                }
                                disabled={disableStudy}
                                mode={mode}
                              ></FormCheckBox>
                            )}
                          </Grid>
                          <Grid item width={"20%"}>
                            {(values?.ductTypeId === DuctType.Round ||
                              values?.ductTypeId === DuctType.Rectangular) && (
                              <FormCheckBox
                                name={"AMCA"}
                                onChange={() => {
                                  refreshCalcValue(
                                    undefined,
                                    MethodMeasurement.AMCA
                                  );
                                }}
                                label={"AMCA"}
                                showSkeleton={showskeleton}
                                value={
                                  values?.methodMeasurementsId ===
                                  MethodMeasurement.AMCA
                                }
                                disabled={disableStudy}
                                mode={mode}
                              ></FormCheckBox>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {values?.ductTypeId === DuctType.Rectangular && (
                    <Grid container mt={4}>
                      <Grid item xs={3} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          OD Duct Dimension
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid item xs={4} textAlign={"center"}>
                            <FormNumeric2
                              name={"ductSizeBase"}
                              label={"Hole Side ↔"}
                              value={values?.ductSizeBase?.toString() ?? ""}
                              onChange={handleInputChange}
                              fullWidth="150px"
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              onBlur={() => {
                                saveOnBlurHandler();
                                validateAmcaAlert();
                              }}
                              decimalScale={3}
                              thousandSeparator={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Grid container>
                              <Grid
                                item
                                width={"20px"}
                                textAlign={"left"}
                                ml={2}
                                mt={2}
                              >
                                <Typography display={"inline"}>
                                  {systemOfMeasurement.get("in")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                width={"40px"}
                                textAlign={"center"}
                                ml={2}
                                mt={2}
                              >
                                <Typography
                                  display={"inline"}
                                  variant="subtitle2"
                                  ml={2}
                                  mt={2}
                                >
                                  by
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} textAlign={"center"}>
                            <FormNumeric2
                              name={"ductSizeHeight"}
                              label={"Points Side ↕"}
                              value={values?.ductSizeHeight?.toString() ?? ""}
                              onChange={handleInputChange}
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              decimalScale={3}
                              thousandSeparator={true}
                              onBlur={validateAmcaAlert}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        width={"20px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      >
                        <Typography display={"inline"}>
                          {systemOfMeasurement.get("in")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"} ml={4}>
                        <FormNumeric2
                          name={"ductSize"}
                          label={systemOfMeasurement.get("sqft")}
                          value={values?.ductSize?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton}
                          disabled={disableStudy}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                          onBlur={validateAmcaAlert}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container>
                          <Grid item xs={6} mt={2} textAlign={"right"}>
                            <Typography display={"inline"} mr={2}>
                              Internal Insulation
                            </Typography>
                          </Grid>
                          <Grid item xs={4} textAlign={"center"}>
                            <FormNumeric2
                              name={"internalInsulation"}
                              label={""}
                              value={
                                values?.internalInsulation?.toString() ?? ""
                              }
                              onChange={handleInputChange}
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              onBlur={() => {
                                saveOnBlurHandler();
                                validateAmcaAlert();
                              }}
                              decimalScale={3}
                              thousandSeparator={true}
                            />
                          </Grid>
                          <Grid item xs={2} mt={2}>
                            <Typography display={"inline"} ml={2}>
                              {systemOfMeasurement.get("in")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {values?.ductTypeId === DuctType.Rectangular &&
                    values?.methodMeasurementsId ===
                      MethodMeasurement.EqualAreaTABB && (
                      <Grid container mt={4}>
                        <Grid item xs={3} pr={3}></Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid item xs={4} textAlign={"center"}>
                              <FormSelect
                                name={"rectTabbX"}
                                label={"Hole Side ↔"}
                                value={values?.rectTabbX}
                                onChange={handleInputChange}
                                options={holePointSide}
                                showSkeleton={showskeleton}
                                disabled={disableStudy}
                                mode={mode}
                              ></FormSelect>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={4} textAlign={"center"}>
                              <FormSelect
                                name={"rectTabbY"}
                                label={"Points Side ↔"}
                                value={values?.rectTabbY}
                                onChange={handleInputChange}
                                options={holePointSide}
                                showSkeleton={showskeleton}
                                disabled={disableStudy}
                                mode={mode}
                              ></FormSelect>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                  {values?.ductTypeId === DuctType.Round && (
                    <Grid container mt={4}>
                      <Grid item xs={3} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Round duct diameter ID (↔)
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <FormNumeric2
                          name={"ductSizeBase"}
                          label={"Dimension"}
                          value={values?.ductSizeBase?.toString() ?? ""}
                          onChange={handleInputChange}
                          onBlur={saveOnBlurHandler}
                          fullWidth="150px"
                          showSkeleton={showskeleton}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid
                        item
                        width={"20px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      >
                        <Typography display={"inline"}>
                          {systemOfMeasurement.get("in")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"} ml={4}>
                        <FormNumeric2
                          name={"ductSize"}
                          label={systemOfMeasurement.get("sqft")}
                          value={values?.ductSize?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton}
                          disabled={disableStudy}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid item xs={7} mt={2} textAlign={"right"}>
                            <Typography display={"inline"} mr={2}>
                              Round or Rect Insulation Thickness
                            </Typography>
                          </Grid>
                          <Grid item xs={3} textAlign={"center"}>
                            <FormNumeric2
                              name={"internalInsulation"}
                              label={""}
                              value={
                                values?.internalInsulation?.toString() ?? ""
                              }
                              onChange={handleInputChange}
                              onBlur={saveOnBlurHandler}
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              decimalScale={3}
                              thousandSeparator={true}
                            />
                          </Grid>
                          <Grid item xs={2} mt={2}>
                            <Typography display={"inline"} ml={2}>
                              {systemOfMeasurement.get("in")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {values?.ductTypeId === DuctType.Oval && (
                    <Grid container mt={4}>
                      <Grid item xs={3} pr={3}>
                        <Typography
                          variant="subtitle2"
                          mt={2}
                          textAlign={"right"}
                        >
                          Oval duct size ID
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <FormNumeric2
                          name={"ductSizeBase"}
                          label={"Dimension ↔"}
                          onBlur={saveOnBlurHandler}
                          value={values?.ductSizeBase?.toString() ?? ""}
                          onChange={handleInputChange}
                          fullWidth="150px"
                          showSkeleton={showskeleton}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid container>
                          <Grid
                            item
                            width={"20px"}
                            textAlign={"left"}
                            ml={2}
                            mt={2}
                          >
                            <Typography display={"inline"}>
                              {systemOfMeasurement.get("in")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            width={"40px"}
                            textAlign={"center"}
                            ml={2}
                            mt={2}
                          >
                            <Typography
                              display={"inline"}
                              variant="subtitle2"
                              ml={2}
                              mt={2}
                            >
                              by
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <FormNumeric2
                          name={"ductSizeHeight"}
                          label={"Dimension ↕"}
                          onBlur={saveOnBlurHandler}
                          value={values?.ductSizeHeight?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeleton}
                          disabled={disableStudy}
                          mode={mode}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid
                        item
                        width={"20px"}
                        textAlign={"left"}
                        ml={2}
                        mt={2}
                      >
                        <Typography display={"inline"}>
                          {systemOfMeasurement.get("in")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} textAlign={"center"} ml={4}>
                        <FormNumeric2
                          name={"ductSize"}
                          label={systemOfMeasurement.get("sqft")}
                          value={values?.ductSize?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton}
                          disabled={disableStudy}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container>
                          <Grid item xs={6} mt={2} textAlign={"right"}>
                            <Typography display={"inline"} mr={2}>
                              Internal Insulation
                            </Typography>
                          </Grid>
                          <Grid item xs={4} textAlign={"center"}>
                            <FormNumeric2
                              name={"internalInsulation"}
                              label={""}
                              value={
                                values?.internalInsulation?.toString() ?? ""
                              }
                              onBlur={saveOnBlurHandler}
                              onChange={handleInputChange}
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              decimalScale={3}
                              thousandSeparator={true}
                            />
                          </Grid>
                          <Grid item xs={2} mt={2}>
                            <Typography display={"inline"} ml={2}>
                              {systemOfMeasurement.get("in")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Divider my={6}></Divider>
                  <Grid container>
                    <Grid item xs={12} pb={2} textAlign={"center"}>
                      <Chip
                        label={`Desired Pitot hole "distance/location" from disturbance (ie. bend, expansion, contraction)`}
                        color="primary"
                        m={1}
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Desired Stream Diameters
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"desiredDownStream"}
                        label={"Down"}
                        value={values?.desiredDownStream?.toString() ?? ""}
                        onChange={handleInputChange}
                        fullWidth="150px"
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={mode}
                        onBlur={saveOnBlurHandler}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"desiredUpStream"}
                        label={"Up"}
                        value={values?.desiredUpStream?.toString() ?? ""}
                        onChange={handleInputChange}
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={mode}
                        onBlur={saveOnBlurHandler}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} textAlign={"center"}>
                      <FormNumeric2
                        name={"totalIdealDuctLength"}
                        label={`Total "Ideal Straight" Duct Length`}
                        value={values?.totalIdealDuctLength}
                        onChange={handleInputChange}
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={"read"}
                        suffix={systemOfMeasurement.get("in")}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container pt={3}>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Max. Straight Duct Available
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"maxStraightDuctAvailable"}
                        label={""}
                        value={values?.maxStraightDuctAvailable}
                        onChange={handleInputChange}
                        fullWidth="150px"
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={mode}
                        onBlur={saveOnBlurHandler}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item xs={1} pt={2} pl={2}>
                      <Typography display={"inline"}>
                        {systemOfMeasurement.get("in")}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"percentajeOfIdeal"}
                        label={"% of Ideal"}
                        value={values?.percentajeOfIdeal}
                        onChange={handleInputChange}
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={"read"}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container pt={3}>
                    <Grid item xs={3} pr={3}>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        textAlign={"right"}
                      >
                        Pitot Traverse Point Location
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"desirePitotGoleFromDisturbanceDownStream"}
                        label={"Dn-Stream"}
                        value={values?.desirePitotGoleFromDisturbanceDownStream}
                        onChange={handleInputChange}
                        fullWidth="150px"
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={"read"}
                        suffix={systemOfMeasurement.get("in")}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item xs={1} pt={2} pl={2} textAlign={"center"}>
                      <Typography display={"inline"}>Or</Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <FormNumeric2
                        name={"desirePitotGoleFromDisturbanceUpStream"}
                        label={"Up-Stream"}
                        value={values?.desirePitotGoleFromDisturbanceUpStream}
                        onChange={handleInputChange}
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={"read"}
                        suffix={systemOfMeasurement.get("in")}
                        decimalScale={3}
                        thousandSeparator={true}
                      />
                    </Grid>
                  </Grid>
                  <Divider my={6}></Divider>
                  <Grid container mt={4} spacing={3}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"totalHeat"}
                        label={"Total Heat"}
                        showSkeleton={showskeleton}
                        onChange={handleInputChange}
                        disabled={
                          disableStudy || params.step?.toLowerCase() === "step2"
                        }
                        value={values?.totalHeat}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={3} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"velocityReadingTemperatureCompensated"}
                        label={"Temperature Correction Required"}
                        showSkeleton={showskeleton}
                        onChange={handleInputChangeCompesation}
                        disabled={
                          disableStudy || params.step?.toLowerCase() === "step2"
                        }
                        value={values?.velocityReadingTemperatureCompensated}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={3} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"barometricalyCompensated"}
                        label={"Barometric Correction Required"}
                        showSkeleton={showskeleton}
                        onChange={handleInputChange}
                        disabled={
                          disableStudy || params.step?.toLowerCase() === "step2"
                        }
                        value={values?.barometricalyCompensated}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                      <FormNumeric2
                        name={"requiredCFM"}
                        label={`Required ${systemOfMeasurement.get("cfm")}`}
                        value={values?.requiredCFM?.toString() ?? ""}
                        onChange={handleInputChange}
                        disabled={disableStudy}
                        onBlur={saveOnBlurHandler}
                        showSkeleton={showskeleton}
                        decimalScale={3}
                        mode={
                          params.step?.toLowerCase() === "step2"
                            ? "read"
                            : "read&Write"
                        }
                        thousandSeparator={true}
                      ></FormNumeric2>
                    </Grid>
                    {values?.barometricalyCompensated && (
                      <Grid item xs={3}>
                        <FormNumeric2
                          name={"localBarometricPressure"}
                          label={`Local Barometric Pressure (${systemOfMeasurement.get(
                            "inhg"
                          )}) Pb`}
                          value={
                            values?.localBarometricPressure?.toString() ?? ""
                          }
                          onChange={handleInputChange}
                          onBlur={saveOnBlurHandler}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          mode={
                            params.step?.toLowerCase() === "step2"
                              ? "read"
                              : "read&Write"
                          }
                          thousandSeparator={true}
                          disabled={disableStudy}
                        ></FormNumeric2>
                      </Grid>
                    )}
                  </Grid>
                  {params.step?.toLowerCase() === "step2" && values && (
                    <>
                      <Grid container pt={3} spacing={2}>
                        {values?.barometricalyCompensated && (
                          <>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3} textAlign={"left"}>
                              <FormCheckSwitch
                                name={"isActualLocalBarometricPressure"}
                                label={"Use Site Specific Default"}
                                showSkeleton={showskeleton}
                                onChange={handleChangeSpecificDefault}
                                disabled={disableStudy}
                                value={values?.isActualLocalBarometricPressure}
                              ></FormCheckSwitch>
                            </Grid>
                            <Grid item xs={3}>
                              <>
                                {values?.isActualLocalBarometricPressure && (
                                  <FormNumeric2
                                    name={"useSiteSpecificDefault"}
                                    label={"Local Barometric Pressure Default"}
                                    value={
                                      values?.useSiteSpecificDefault?.toString() ??
                                      ""
                                    }
                                    onChange={handleInputChange}
                                    onBlur={saveOnBlurHandler}
                                    showSkeleton={showskeleton}
                                    decimalScale={3}
                                    mode={"read"}
                                    thousandSeparator={true}
                                    disabled={disableStudy}
                                  ></FormNumeric2>
                                )}
                                {!values?.isActualLocalBarometricPressure && (
                                  <FormNumeric2
                                    name={"localBarometricPressure"}
                                    label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                    value={
                                      values?.localBarometricPressure?.toString() ??
                                      ""
                                    }
                                    onChange={handleInputChange}
                                    onBlur={saveOnBlurHandler}
                                    showSkeleton={showskeleton}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                    disabled={disableStudy}
                                  ></FormNumeric2>
                                )}
                              </>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Divider my={3}></Divider>
                        </Grid>
                        {values?.ductTypeId === DuctType.Oval && (
                          <>
                            <Grid item xs={12} textAlign={"center"}>
                              <Typography variant="h6">
                                Rectangular part of the duct
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider my={3}></Divider>
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={12}
                          pb={3}
                          style={{
                            overflowX: "auto",
                          }}
                        >
                          {values &&
                            id &&
                            (((values.reportTypeId === 5 ||
                              ((values.ductTypeId === 1 ||
                                values.ductTypeId === 4) &&
                                values.methodMeasurementsId === 1)) &&
                              values.ductTypeId !== 2 &&
                              values.methodMeasurementsId !== 2) ||
                              values?.ductTypeId === DuctType.Oval) && (
                              <PitotFanTraverseGrid
                                id={id}
                                saveItems={saveItems}
                                disabled={disableStudy}
                                showSkeleton={showskeleton}
                                onBlur={calcTotal}
                                systemOfMeasurement={systemOfMeasurement}
                              />
                            )}
                          {values?.ductTypeId === DuctType.Oval && (
                            <>
                              <Grid item xs={12}>
                                <Divider my={3}></Divider>
                              </Grid>
                              <Grid item xs={12} textAlign={"center"}>
                                <Typography variant="h6">
                                  Round part of the duct
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider my={3}></Divider>
                              </Grid>
                            </>
                          )}
                          {values &&
                            id &&
                            ((values.reportTypeId === 20 &&
                              !(
                                (values.ductTypeId === 1 ||
                                  values.ductTypeId === 4) &&
                                values.methodMeasurementsId === 1
                              )) ||
                              values.ductTypeId === 3 ||
                              values.ductTypeId === 2 ||
                              values.methodMeasurementsId === 2 ||
                              values?.ductTypeId === DuctType.Oval) && (
                              <PitotFanTraverseTTGrid
                                saveItems={saveItems}
                                disabled={disableStudy}
                                showSkeleton={showskeleton}
                                onBlur={calcTotalTT}
                                systemOfMeasurement={systemOfMeasurement}
                                values={values}
                              />
                            )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} pt={3}>
                        <Grid item xs={2}>
                          <FormNumeric2
                            name={"avgVelocity"}
                            label={"Avg Velocity"}
                            value={values?.avgVelocity?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={2}>
                          <FormNumeric2
                            name={"trueCorectedAirVelocity"}
                            label={"True Corrected Average Velocity"}
                            value={
                              values?.trueCorectedAirVelocity?.toString() ?? ""
                            }
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={2}>
                          <FormNumeric2
                            name={"totalCFM"}
                            label={`Actual ${systemOfMeasurement.get("acfm")}`}
                            value={values?.totalCFM?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                            color={
                              values &&
                              values?.requiredCFM &&
                              values?.totalCFM &&
                              values?.toleranceMax &&
                              (values?.totalCFM <
                                values?.requiredCFM -
                                  values?.requiredCFM *
                                    (values?.toleranceMax / 100) ||
                                values?.totalCFM >
                                  values?.requiredCFM *
                                    (1 + values?.toleranceMax / 100))
                                ? "error"
                                : undefined
                            }
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={2}>
                          <FormNumeric2
                            name={"percentageOfDesign"}
                            label={"% of Design"}
                            value={values?.percentageOfDesign?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            color={
                              values &&
                              values?.percentageOfDesign &&
                              (values?.percentageOfDesign >
                                (values?.requiredCFMSystemToleranceTop
                                  ? values?.requiredCFMSystemToleranceTop
                                  : 0) +
                                  100 ||
                                values?.percentageOfDesign <
                                  100 -
                                    (values?.requiredCFMSystemToleranceBottom
                                      ? values?.requiredCFMSystemToleranceBottom
                                      : 0))
                                ? "error"
                                : undefined
                            }
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        {values?.totalHeat && (
                          <Grid item xs={2}>
                            <FormNumeric2
                              name={"btuh"}
                              label={`Total ${systemOfMeasurement.get("btuh")}`}
                              value={values?.btuh?.toString() ?? ""}
                              onChange={handleInputChange}
                              mode="read"
                              showSkeleton={showskeleton || isLoadingUpdate}
                              decimalScale={2}
                              thousandSeparator={true}
                            ></FormNumeric2>
                          </Grid>
                        )}
                        <Grid item xs={2}>
                          <FormNumeric2
                            name={"sensibleHeat"}
                            label={`Sensible ${systemOfMeasurement.get(
                              "btuh"
                            )}`}
                            value={values?.sensibleHeat?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"dryBulbF"}
                                label={
                                  "Airflow Temperature For Energy Calculation"
                                }
                                value={values?.dryBulbF?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={onBlurCalcPsycometrics}
                                disabled={disableStudy}
                                showSkeleton={showskeleton}
                                error={errors.dryBulbF}
                                decimalScale={3}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography pl={2} pt={1}>
                                DB
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {values.totalHeat && (
                          <>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              {values?.dryBulbFId ===
                                DryBulbF.Relativeumidity && (
                                <FormNumeric2
                                  name={"humidityValue"}
                                  label={"Relative Humidity"}
                                  value={
                                    values?.humidityValue?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={onBlurCalcPsycometrics}
                                  disabled={disableStudy}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                              {values?.dryBulbFId === DryBulbF.WetBulbF && (
                                <FormNumeric2
                                  name={"humidityValue"}
                                  label={"Wet bulb F"}
                                  value={
                                    values?.humidityValue?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={onBlurCalcPsycometrics}
                                  disabled={disableStudy}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                              {values?.dryBulbFId === DryBulbF.DewPoint && (
                                <FormNumeric2
                                  name={"humidityValue"}
                                  label={"Dew Point"}
                                  value={
                                    values?.humidityValue?.toString() ?? ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={onBlurCalcPsycometrics}
                                  disabled={disableStudy}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                            </Grid>
                            <Grid item xs={5}>
                              <RadioGroup aria-label="" name="" row>
                                <FormControlLabel
                                  checked={
                                    values?.dryBulbFId ===
                                    DryBulbF.Relativeumidity
                                  }
                                  control={<Radio />}
                                  label="Relative Humidity"
                                  onChange={() => {
                                    handleChangeRadio(DryBulbF.Relativeumidity);
                                  }}
                                  name={"relativeHumidityBool"}
                                  disabled={disableStudy}
                                />
                                <FormControlLabel
                                  checked={
                                    values?.dryBulbFId === DryBulbF.WetBulbF
                                  }
                                  control={<Radio />}
                                  label="Wet bulb F"
                                  onChange={() => {
                                    handleChangeRadio(DryBulbF.WetBulbF);
                                  }}
                                  name={"wetBulbFBool"}
                                  disabled={disableStudy}
                                />
                                <FormControlLabel
                                  checked={
                                    values?.dryBulbFId === DryBulbF.DewPoint
                                  }
                                  control={<Radio />}
                                  label="Dew Point"
                                  onChange={() => {
                                    handleChangeRadio(DryBulbF.DewPoint);
                                  }}
                                  name={"dewPointBool"}
                                  disabled={disableStudy}
                                />
                              </RadioGroup>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={2}>
                          <FormNumeric2
                            name={"centerlineStaticPressure"}
                            label={`Centerline Static Pressure`}
                            value={values?.centerlineStaticPressure}
                            showSkeleton={showskeleton || isLoadingUpdate}
                            onChange={handleInputChange}
                            decimalScale={3}
                            thousandSeparator={true}
                            disabled={disableStudy}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={4}>
                          <RadioGroup aria-label="" name="" row>
                            <FormControlLabel
                              checked={
                                values?.centerlineStaticPressureId ===
                                CenterlineStaticPressure.Plus
                              }
                              control={<Radio />}
                              label="+"
                              onChange={() => {
                                setValues({
                                  ...values,
                                  centerlineStaticPressureId:
                                    CenterlineStaticPressure.Plus,
                                });
                              }}
                              name={"plus"}
                              disabled={disableStudy}
                            />
                            <FormControlLabel
                              checked={
                                values?.centerlineStaticPressureId ===
                                CenterlineStaticPressure.Minus
                              }
                              control={<Radio />}
                              label="-"
                              onChange={() => {
                                setValues({
                                  ...values,
                                  centerlineStaticPressureId:
                                    CenterlineStaticPressure.Minus,
                                });
                              }}
                              name={"minus"}
                              disabled={disableStudy}
                            />
                            <FormControlLabel
                              checked={
                                values?.centerlineStaticPressureId ===
                                CenterlineStaticPressure.NotAvailable
                              }
                              control={<Radio />}
                              label="Not Available"
                              onChange={() => {
                                setValues({
                                  ...values,
                                  centerlineStaticPressureId:
                                    CenterlineStaticPressure.NotAvailable,
                                });
                              }}
                              name={"notAvailable"}
                              disabled={disableStudy}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={activeStep}
                      stepsCount={STEPS.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName={""}
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      disabled={disableStudy}
                      previousStepHandler={handleBackStep}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={
          <>
            <Typography>
              SQ/FT is not enough to perform AMCA Method. Please use another
              Measurement Method or verify your duct size.
            </Typography>
            <Typography>
              The minimum recommended SQ/FT for AMCA is 1 SQ/FT
            </Typography>
            <Typography>However AMCA is recommended for big ducts. </Typography>
          </>
        }
        showPopup={amcaAlertModal}
        setShowPopup={setAmcaAlertModal}
        onSave={() => {}}
        hideAccept={true}
        isSubmitting={false}
        cancelTextButton="Close"
      ></DialogMessagePopup>
      {params?.step?.toLowerCase() !== "step1" &&
        values &&
        values?.projectId && (
          <SteadyStateDialog
            reportId={values?.id}
            reportTypeId={values?.reportTypeId}
            projectId={values?.projectId}
            reportCompleted={values?.isComplete}
          />
        )}
    </>
  );
};

export default PitotFanTraverse;
