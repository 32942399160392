import {
  Grid,
  Stack,
  Typography,
  Divider as MuiDivider,
  Button,
} from "@mui/material";
import styled from "@emotion/styled";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISendOutToBidProject } from "src/ts/interfaces/projectOutToBid";
import {
  ICurrentContestByProject,
  IProjectReporDisplayt,
} from "src/ts/interfaces";
import {
  FormLabel,
  FormButton,
  FormRadioGroup,
  FormNumeric2,
} from "src/components/formControls";
import { useAuth, useLoading, useLog } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing } from "@mui/system";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { IProposalProject } from "src/ts/interfaces/projectEstimatedTime";
import useFormTyped from "src/hooks/useFormTyped";
import { estimatedTime } from "src/constants";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import sendOutToBidProjectService from "src/services/sendOutToBidProjectService";
import EquipmentCalibration from "./projectStudies/components/EquipmentCalibration";
import Tolerances from "./projectStudies/components/Tolerances";
import projectReportService from "src/services/study/projectReportService";
import Study from "./projectStudies/components/Study";
import projectContestService from "src/services/projectContestService";
import { Validator } from "src/ts/types/Validator";
import TotalCombinedPopUp from "../projectOutForBid/Components/TotalCombinedPopUp";
import TestingUnitCostModal from "src/components/modals/TestingUnitCostModal";
import projectService from "src/services/project/projectService";
import FormCountdown from "src/components/formControls/FormCountdown";

type ProjectOutForBidParams = {
  id: string;
};

const initialValues: IProposalProject = {
  estimatedTime: 0,
  isDays: false,
};

const Divider = styled(MuiDivider)(spacing);

export default function TemporalProjectContest() {
  const { log } = useLog();
  const [projectSingle, setProjectSingle] = useState<ProjectDTO>();
  const [showTotalCombinedPopUp, setShowTotalCombinedPopUp] = useState(false);
  const { user } = useAuth();

  const [currentProject, setCurrentProject] =
    useState<ICurrentContestByProject>();
  const [sendOutToBidProject, setSendOutToBidProject] = useState<
    ISendOutToBidProject[]
  >([]);
  const [testingUnitCostModalShow, setTestingUnitCostModalShow] =
    useState(false);
  const [studies, setStudies] = useState<IProjectReporDisplayt[]>([]);

  let { id } = useParams<ProjectOutForBidParams>();
  const navigate = useNavigate();
  const projectId = Number(id);
  const { pathname } = useLocation();

  const { isLoading, startRequest, endRequest } = useLoading();

  const validate: any = (fieldValues: IProposalProject = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.estimatedTime = new Validator(fieldValues, "estimatedTime")
      .isRequired("The time duration is required.")
      .greatThan(0, "Must be great than 0")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IProposalProject>(initialValues, false, validate);

  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        startRequest("projectInfo");
        const responses = await Promise.all([
          projectService.getContestProject(projectId),
          sendOutToBidProjectService.getSendOutToBidProject(projectId),
          projectReportService.getStudiesByContest(projectId),
          projectContestService.getCurrentContestByProject(projectId),
        ]);
        setProjectSingle(responses[0].data);
        setSendOutToBidProject(responses[1].data);
        setStudies(responses[2].data);
        setCurrentProject(responses[3].data);
        endRequest("projectInfo");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSetValues = () => {
      if (currentProject?.estimatedTimeDurationUnit === null) {
        setValues({
          ...values,
          estimatedTime: currentProject?.estimatedTimeDuration ?? 0,
          isDays: true,
        });
      } else {
        setValues({
          ...values,
          estimatedTime: currentProject?.estimatedTimeDuration ?? 0,
          isDays:
            currentProject?.estimatedTimeDurationUnit === 2 ? true : false,
        });
      }
    };

    getSetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleInputChange = async (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleInputBlur = async (e: any) => {
    if (!validate()) return;
    try {
      await projectContestService.postUpdateEstimatedTime(
        projectId,
        values.estimatedTime,
        values.isDays ? "2" : "1"
      );
      log.success("Estimated time was updated.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      await projectContestService.postUpdateEstimatedTime(
        projectId,
        Number(values.estimatedTime),
        values.isDays ? "2" : "1"
      );
      log.success("Estimated time was updated.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handlerProposalBid = () => {
    if (values.estimatedTime === null || values.estimatedTime === 0) {
      log.warning("Please enter project time duration first.");
      if (!validate()) return;
    } else {
      setShowTotalCombinedPopUp(true);
    }
  };
  const declinedProjectStatus =
    currentProject?.declinedDate === null ? false : true;

  return (
    <>
      <HeaderPage
        title="Project Proposal Opportunity"
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <>
            {projectSingle?.contestDueDate && (
              <Stack direction={"column"}>
                <Typography variant="subtitle2" textAlign={"center"}>
                  This project proposal deadline finishes in:
                </Typography>
                <FormCountdown date={projectSingle.contestDueDate} />
              </Stack>
            )}
          </>
        }
        pageYoutube=""
      ></HeaderPage>

      <Divider my={6} />
      <Grid container>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign={"center"} mb={4}>
              <b>Proposal Opportunity For:</b> {projectSingle?.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={2}>
            <FormButton
              color="success"
              text={"Testing Unit Cost"}
              onClick={() => {
                setTestingUnitCostModalShow(true);
              }}
              startIcon={<AttachMoneyIcon />}
              size="small"
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent={"end"}>
              <Typography mt={1} mr={5}>
                <b>Access Project Bid Documents</b>
              </Typography>
              <FormButton
                color="primary"
                text={"Documents"}
                onClick={() => {
                  navigate(
                    `/app/DocumentLibrary/${id}/${user?.companyId}/ProjectOutToBid`
                  );
                }}
                size="small"
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Code</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel text={projectSingle?.code} showSkeleton={isLoading} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Project</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel text={projectSingle?.name} showSkeleton={isLoading} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Description</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={projectSingle?.projectDescription}
                showSkeleton={isLoading}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Location</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={`${projectSingle?.buildingName} `}
                showSkeleton={isLoading}
                sx={{ fontWeight: "bold", marginRight: "1px" }}
              />
              <FormLabel
                text={` - ${projectSingle?.buildingAddress}`}
                showSkeleton={isLoading}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="column" textAlign={"right"}>
              <Typography>
                <b>For Project Award - Owner Request</b>
              </Typography>
              <Typography>
                <b>You Enter a Project Time Duration</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormNumeric2
                    name="estimatedTime"
                    label="Time"
                    value={values.estimatedTime.toString()}
                    onChange={handleInputChange}
                    error={errors.estimatedTime}
                    onBlur={handleInputBlur}
                    showSkeleton={isLoading}
                    decimalScale={0}
                    disabled={declinedProjectStatus}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormRadioGroup
                    name="isDays"
                    label=""
                    row={true}
                    value={values?.isDays ? 1 : 0}
                    onChange={(e) => {
                      values.isDays = e.target.value === "1" ? true : false;
                      setValues({ ...values });
                    }}
                    items={estimatedTime}
                    showSkeleton={isLoading}
                    defaultValue={1}
                    disabled={declinedProjectStatus}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormButton
                    text={"Update"}
                    size="medium"
                    onClick={saveHandler}
                    disabled={declinedProjectStatus}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        {projectSingle?.sendProposalThroughAMP && (
          <>
            {" "}
            <Grid container xs={12} spacing={5} mb={5} item>
              <Grid item xs={4}>
                <Stack direction="column" textAlign={"right"}>
                  <Typography>
                    <b>Send proposal through </b>
                  </Typography>
                  <Typography>
                    <b>AMP's Auto Proposal Features</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Button
                    onClick={handlerProposalBid}
                    size="small"
                    variant="outlined"
                  >
                    <AttachMoneyIcon />
                  </Button>
                </Stack>
              </Grid>
            </Grid>{" "}
          </>
        )}
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end">
              <Typography>
                <b>Send Project BID to</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            {sendOutToBidProject.map((item) => {
              if (item.teamMemberId === null) {
                return (
                  <>
                    <FormLabel
                      text={`${item.name} ${item.lastName}, ${item.email}, ${item.phone}`}
                      showSkeleton={isLoading}
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <FormLabel
                      text={`${item.teamMember.name} ${item.teamMember.lastName}, ${item.teamMember.email}, ${item.teamMember?.workPhoneNumber}`}
                      showSkeleton={isLoading}
                    />
                  </>
                );
              }
            })}
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end">
                <Typography>
                  <b>Project Type</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.projectType}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end">
                <Typography>
                  <b>Required Comprehensive Energy Test</b>
                </Typography>
                <FormLabel text="" />
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.requiredComprehensiveEnergyTest
                      ? "Yes"
                      : "No"
                  }
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} item>
            {projectSingle !== undefined && (
              <EquipmentCalibration project={projectSingle} readOnly={true} />
            )}
          </Grid>

          <Grid container xs={12} spacing={5} item>
            <Grid item xs={12}>
              <Stack
                direction="column"
                justifyContent="end"
                alignItems="center"
              >
                {projectSingle !== undefined && (
                  <Tolerances
                    project={projectSingle}
                    setProject={setProjectSingle}
                    readOnly={true}
                    isLoading={isLoading}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} direction={"row"} justifyContent={"center"}>
            <Typography variant="h6">
              Static / Read Only sample of each study type and the required data
              entry for this project.
            </Typography>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            {studies.map((item) => (
              <Grid item xs={12} md={6} key={item.id}>
                <Study study={item} isSelectable={true} readOnly={true} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid xs={12} sx={{ textAlign: "center" }} item>
          <FormButton
            text="Close"
            size="medium"
            variant="outlined"
            onClick={() => {
              navigate("/app/projectsOutForBid");
            }}
          />
        </Grid>
      </Grid>
      {showTotalCombinedPopUp && (
        <TotalCombinedPopUp
          showTotalCombinedPopUp={showTotalCombinedPopUp}
          setShowTotalCombinedPopUp={setShowTotalCombinedPopUp}
          codeContractor={projectSingle?.code ?? ""}
          nameContractor={""}
          informationContractor={Number(id)}
          getprojectContest={projectSingle}
          dataEntry={declinedProjectStatus}
          buildingInformation={undefined}
        />
      )}
      {testingUnitCostModalShow && (
        <TestingUnitCostModal
          showPopup={testingUnitCostModalShow}
          setShowPopup={setTestingUnitCostModalShow}
        />
      )}
    </>
  );
}
