import { useEffect, useState } from "react";
import { Autocomplete, Chip, Skeleton, TextField } from "@mui/material";
import roleService from "src/services/roleService";
import { IRoleAlias } from "src/ts/interfaces";
import accountService from "src/services/accountService";
import { useAuth } from "src/hooks";

interface EditUserRolesProps {
  userRole: string;
  userId: number;
  userAuth: string;
  setRolesData: (val: IRoleAlias[]) => void;
  setSelectedRoles: (val: string[]) => void;
  showSkeleton?: boolean;
}

const EditUserRoles = (props: EditUserRolesProps) => {
  const {
    userRole,
    userId,
    userAuth,
    setRolesData,
    setSelectedRoles,
    showSkeleton,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fixedOptions, setFixedOptions] = useState<string[]>([]);
  const { user } = useAuth();
  const [currentRoles, setCurrentRoles] = useState<string[]>([]); //current values from api
  const [currentRolesUser, setCurrentRolesUser] = useState<string[]>([]); //current values from api
  const [disabledRoles, setDisabledRoles] = useState<string[]>([]); //current values from api
  const [availableRoles, setAvailableRoles] = useState<{ title: string }[]>(); //show in the dropdown

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [intialUserRoles, setInitialRoles] = useState<IRoleAlias[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roleCatalog, setRoleCatalog] = useState<IRoleAlias[]>([]);

  useEffect(() => {
    const getRoles = async () => {
      const availableRolesResponse = await roleService.getRolesCanView();
      setRoleCatalog(availableRolesResponse.data);
      setRolesData(availableRolesResponse.data);

      const availableRolesFiltered = availableRolesResponse.data
        .filter((role) =>
          role.roleCanAdd.find((rolCanAdd) => rolCanAdd.name === userRole)
        )
        .map((role) => ({
          title: role.alias,
        }))
        .filter((item: any) => {
          return item.title !== "Super Administrator";
        })
        .filter((item: any) => {
          return user?.subscriptionId !== 3
            ? item.title !== "Architect Assistant"
            : true;
        });
      console.log(availableRolesFiltered);
      setAvailableRoles(availableRolesFiltered);

      const currentRoleResponse = await accountService.getAccountUserByGuid(
        userAuth
      );

      setInitialRoles(currentRoleResponse.data.roles);
      const currentRolesNames = currentRoleResponse.data.roles.map(
        (role) => role.alias
      );

      const disabled = availableRolesResponse.data
        .filter((item) =>
          item.roleCanAdd.map((element) => element.name).includes(userRole)
        )
        .map((role) => role.alias);

      setCurrentRoles(currentRolesNames);
      setSelectedRoles(currentRolesNames);
      setCurrentRolesUser(currentRolesNames);
      setDisabledRoles(disabled);
      const r = availableRolesFiltered
        .filter((role) => currentRolesNames.indexOf(role.title) === -1)
        .map((x) => x.title);
      setFixedOptions(r);
    };
    if (userAuth !== "" && userId !== 0) {
      getRoles();
    } else {
      const rolesView = async () => {
        const availableRolesResponse = await roleService.getRolesCanView();
        setRoleCatalog(availableRolesResponse.data);
        setRolesData(availableRolesResponse.data);
        setDisabledRoles(availableRolesResponse.data.map((role) => role.alias));
        const availableRolesFiltered = availableRolesResponse.data
          .filter((role) =>
            role.roleCanAdd.find((rolCanAdd) => rolCanAdd.name === userRole)
          )
          .map((role) => ({
            title: role.alias,
          }))
          .filter((item: any) => {
            return item.title !== "Super Administrator";
          })
          .filter((item: any) => {
            return user?.subscriptionId !== 3
              ? item.title !== "Architect Assistant"
              : true;
          });
        console.log(availableRolesFiltered);
        setAvailableRoles(availableRolesFiltered);
      };
      rolesView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userAuth]);

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={40}></Skeleton>
      ) : (
        <Autocomplete
          multiple
          id="tags-filled"
          value={currentRoles}
          options={availableRoles?.map((option) => option.title) ?? []}
          getOptionDisabled={(option) => currentRoles.includes(option)}
          size="small"
          disableClearable
          onChange={(event, newValue) => {
            setCurrentRoles(newValue);
            setSelectedRoles(newValue);
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              return (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option}
                  sx={{ color: "black", fontWeight: "bolder" }}
                  {...getTagProps({ index })}
                  disabled={!disabledRoles.includes(option)}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Roles"
              placeholder="Select Role"
            />
          )}
        />
      )}
    </>
  );
};

export default EditUserRoles;
