import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Grid,
  Stack,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import { ColumnType } from "src/types/enhancedTable";
import { useAuth, useLog } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IPlaqueCompanyOrders } from "src/ts/interfaces";
import plaqueOrderService from "src/services/plaqueOrderService";
import { convertUTCDateToLocalDate } from "src/utils/utils";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

interface FilterType {
  filter: string;
}

const initialValues: FilterType = {
  filter: "",
};

const CopyChip = styled(Chip)(() => ({
  //add custom CSS styles below this
  backgroundColor: "#FFCC00",
}));

function PlaqueCompanyOrders() {
  const { user } = useAuth();
  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [plaqueCompanyOrders, setPlaqueCompanyOrders] = useState<
    IPlaqueCompanyOrders[]
  >([]);

  const getColumns = (
    goTo: (
      orderId: number,
      projectId: number,
      sendToCar: boolean,
      projectPurchaseId?: number
    ) => void
  ): ColumnType[] => [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "projectName",
      label: "Project",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/YYYY",
    },
    {
      id: "orderDetail",
      label: "Order Detail",
      type: "custom",
      sort: false,
      callback: (row: IPlaqueCompanyOrders) => {
        return (
          <>
            {row?.plaqueOrderDetail?.map((item) => {
              return (
                <>
                  <Typography>{`[${item.quantity}] - ${item.plaques.name}`}</Typography>
                </>
              );
            })}
          </>
        );
      },
    },
    {
      id: "shortByOrderStatus",
      label: "Order Status",
      type: "custom",
      sort: true,
      callback: (row: IPlaqueCompanyOrders) => {
        return (
          <>
            {!(row.projectPurchaseId !== null && row.orderStatus === 0) && (
              <>
                {row.orderStatus === 2 ? (
                  <CopyChip
                    label={row.shortByOrderStatus}
                    size="small"
                  ></CopyChip>
                ) : (
                  <Chip
                    label={row.shortByOrderStatus}
                    size="small"
                    color={
                      row.orderStatus === 0
                        ? "warning"
                        : row.orderStatus === 1
                        ? "warning"
                        : row.orderStatus === 3
                        ? "info"
                        : row.orderStatus === 4
                        ? "success"
                        : "default"
                    }
                  ></Chip>
                )}
              </>
            )}
            {row.projectPurchaseId !== null && row.orderStatus === 0 && (
              <Chip
                label={row.shortByOrderStatus}
                size="small"
                color="info"
              ></Chip>
            )}
          </>
        );
      },
    },
    {
      id: "paymentStatus",
      label: "Payment Status",
      type: "custom",
      sort: true,
      callback: (row: IPlaqueCompanyOrders) => {
        return (
          <>
            <Chip
              label={row.paymentStatus}
              size="small"
              color={row.orderStatus > 0 ? "success" : "warning"}
            ></Chip>
          </>
        );
      },
    },
    {
      id: "updatedDate",
      label: "Payment Date",
      type: "custom",
      sort: true,
      callback: (row: IPlaqueCompanyOrders) => {
        return (
          <>
            {row.orderStatus > 0 && (
              <Typography>
                {convertUTCDateToLocalDate(row.updatedDate ?? "")?.format(
                  "MM/DD/yyyy"
                )}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IPlaqueCompanyOrders) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={"forward"}
              onClick={() => {
                goTo(
                  row.id,
                  row.projectId,
                  row.projectPurchaseId !== null && row.orderStatus === 0
                    ? true
                    : false,
                  row.projectPurchaseId
                );
              }}
              tooltip={"View"}
            />
          </Stack>
        </div>
      ),
    },
  ];

  const goTo = (
    orderId: number,
    projectId: number,
    sendToCar: boolean,
    projectPurchaseId?: number
  ) => {
    if (projectPurchaseId === null) {
      navigate(`/app/selectPlaque/${projectId}/1/plaqueCompanyOrder`);
    } else if (!sendToCar) {
      navigate(`/app/viewOrder/${orderId}/plaqueCompanyOrder`);
    } else navigate(`/app/completeOrder/${projectId}/plaqueCompanyOrder`);
  };

  let columns = getColumns(goTo);

  const { values, errors, handleInputChange } = useForm(
    initialValues,
    false,
    []
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const request = await plaqueOrderService.getPlaqueCompanyOrders(
          user?.companyId
        );
        setPlaqueCompanyOrders(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        title={"PLAQUE ORDER(S)"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container>
            <Grid item p={1}></Grid>
          </Grid>
        }
      ></HeaderPage>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalEnhancedTable<IPlaqueCompanyOrders>
                      columns={columns}
                      data={plaqueCompanyOrders ?? []}
                      defaultSortColumn="id"
                      orderColumn="desc"
                      defaultRowPerPage={10}
                      query={values.filter}
                      filterCustom={["project.name", "orderDetail"]}
                      showSkeleton={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default PlaqueCompanyOrders;
